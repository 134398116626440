import axios from "axios";
import getCookieValue from "../../utils";


/** API URL, variable dependent on what branch you ar eon, dev staging, or main branches*/
// const baseURL = process.env.REACT_APP_APIURL

// API call for when a user goes to the next page, it stores the time the user spent in the page of the survey and redirects to the next one
export async function sendPageTime(page, windowReplace) {
    const axiosInstance = axios.create({withCredentials: true});

    axiosInstance
            .post(
                process.env.REACT_APP_APIURL +
                "/api/pagetimestamp/",
                {"page": page},
                {
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": getCookieValue("csrftoken"),
                    },
                }
            )
            .then (() => {
                window.location.replace(process.env.REACT_APP_APPURL + windowReplace);
            })
            .catch((error) => {
                console.log(error);
            });
}

// Page Logout API call
export const handleLogout = (e) => {
    const axiosInstance = axios.create({withCredentials: true});
    // Perform GET request to get a CSRFToken from the API and store it in sessionStorage
    axiosInstance
        .get(process.env.REACT_APP_APIURL + "/api/auth/logout/")
        .then((res) => {
            localStorage.clear();
            sessionStorage.clear();
            window.location.replace(process.env.REACT_APP_APPURL + "/login");
        });
};
