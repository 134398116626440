import axios from "axios";
import getCookieValue from "../../../utils";
import { sendPageTime } from "../../../layout-and-styling/API-calls/common-api-calls";
import { reloadResources } from "i18next";


/** API URL, variable dependent on what branch you ar eon, dev staging, or main branches*/
// const baseURL = process.env.REACT_APP_APIURL
const axiosInstance = axios.create({withCredentials: true});
const CurrentUserData = JSON.parse(localStorage.getItem("current_user_data"));


// Page Process Involvment and Knowledge API call
export async function UpdateKnowledge(setButtonClicked) {
    const IndividualImpact = JSON.parse(
        localStorage.getItem("individual_impact_score")
    );
    const KnowledgeAndTimeRatios = JSON.parse(
        localStorage.getItem("SelfRatedKnowledge")
    );

    /**Initialize and Populate every entry knowledge per business process and time_portion for the current user*/
    const KnowledgeAndTimeRatiosArray = [];

    for (let i = 0; i < KnowledgeAndTimeRatios.length; i++) {
        KnowledgeAndTimeRatiosArray.push({
            employee: CurrentUserData[0].id,
            process: KnowledgeAndTimeRatios[i].process_id,
            time_portion: KnowledgeAndTimeRatios[i].time_portion,
            self_rated_knowledge: parseInt(KnowledgeAndTimeRatios[i].self_rated_knowledge),
        });
    }


    /** Populate overall impact self survey for the current user */
    const IndividualImpactObject = {
        individual_impact: parseInt(IndividualImpact),
        employee_survey_id: CurrentUserData[0].id,
    };
    /** Do POST request for KnowledgeAndTimeRatiosArray and PUT request for IndividualImpactObject */
    try {
        /** POST request for KnowledgeAndTimeRatiosArray  */
        const promise1 = await new Promise((resolve, reject) => {
            axiosInstance
                .post(
                    process.env.REACT_APP_APIURL +
                    "/api/processefficacysurvey/batch_update/",
                    KnowledgeAndTimeRatiosArray,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "X-CSRFToken": getCookieValue("csrftoken"),
                        },
                    }
                )
                .then((res) => {
                    resolve("resolved promise1");
                })
                .catch((error) => {
                    setButtonClicked(false);
                    console.log(error);
                });
        });
        if (promise1) {
            /** PUT request for IndividualImpactObject  */
            const promise2 = await new Promise((resolve, reject) => {
                axiosInstance
                    .put(
                        process.env.REACT_APP_APIURL +
                        "/api/myimpact/" +
                        CurrentUserData[0].id +
                        "/",
                        IndividualImpactObject,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                "X-CSRFToken": getCookieValue("csrftoken"),
                            },
                        }
                    )
                    .then((res) => {
                        resolve("resolved promise1");
                    })
                    .catch((error) => {
                        setButtonClicked(false);
                        console.log(error);
                    });
            });
            /** If API requests are resolved, save page time and re-direct to next page */
            if (promise2) {
                sendPageTime("page-knowledge", "/survey-rootcause-chatbot")
            }
        }
    } catch (err) {
        setButtonClicked(false);
        console.log(err);
    }
}

// Page Good Feedback API call
export async function UpdateFeedbackOptimal(setButtonClicked) {
    const axiosInstance = axios.create({withCredentials: true});

    const votes = JSON.parse(localStorage.getItem("optimalFeedbackAllAnswers"));
    const data = [CurrentUserData[0].id, 'HL', votes]

    try {
        const promise1 = await new Promise((resolve, _reject) => {
            axiosInstance
                .post(process.env.REACT_APP_APIURL + "/api/myfeedbacks/update_batch/", data, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": getCookieValue("csrftoken"),
                    },
                })
                .then((res) => {
                    resolve("resolved promise1");
                })

                .catch((error) => {
                    setButtonClicked(false);
                    console.log(error);
                });
        });
        /** If API requests are resolved, save page time and re-direct to next page */
        if (promise1) {
            sendPageTime("page-good-feedback", "/survey-business-process-selection-feedback-improvement")
        }
    } catch (err) {
        setButtonClicked(false);
        console.log(err);
    }
}

// Page Bad Feedback API call
export async function UpdateFeedbackImprovement(setButtonClicked) {
    const axiosInstance = axios.create({withCredentials: true});

    const votes = JSON.parse(localStorage.getItem("answersImprovementFeedback"));
    const data = [CurrentUserData[0].id, 'LL', votes]

    try {
        const promise1 = await new Promise((resolve, _reject) => {
            axiosInstance
                .post(process.env.REACT_APP_APIURL + "/api/myfeedbacks/update_batch/", data, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": getCookieValue("csrftoken"),
                    },
                })
                .then((res) => {
                    resolve("resolved promise1");
                })

                .catch((error) => {
                    setButtonClicked(false);
                    console.log(error);
                });
        });
        /** If API requests are resolved, save page time and re-direct to next page */
        if (promise1) {
            sendPageTime("page-bad-feedback", "/survey-completed")
        }
    } catch (err) {
        setButtonClicked(false);
        console.log(err);
    }
}


// API call to get the next question for the chatbot
export async function GetUnfinishedConversations(){
    const axiosInstance = axios.create({withCredentials: true});
    const baseURL = process.env.REACT_APP_APIURL;
    
    try{
        return axiosInstance.get(baseURL+"/api/unfinished_conversations/", {
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCookieValue("csrftoken")
            },
          })
          .then((result) => {
            sessionStorage.setItem("unfinished_conversation", result.data);
          })
          .catch((error) => {
            console.log("ERROR: Could not get the response", error);
            return "error";
          })
    }
    catch(error){
        console.log(error);
    }
  }


// API call to get the next question for the chatbot
export async function GetResponse(user_message){
    const axiosInstance = axios.create({withCredentials: true});
    const baseURL = process.env.REACT_APP_APIURL;
    const data = JSON.stringify({ 
      "user_message": user_message
    })
    
    try{
        return axiosInstance.post(baseURL+"/api/get_response/", data, {
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCookieValue("csrftoken")
            },
          })
          .then((result) => {
            return result.data;
          })
          .catch((error) => {
            console.log("ERROR: Could not get the response", error);
            return "error";
          })
    }
    catch(error){
        console.log(error);
    }
  }