import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {ReactComponent as SurveyFooterBackButton} from "../../assets/footer/BackButton_Survey_56px.svg";
import { UpdateKnowledge, UpdateFeedbackOptimal, UpdateFeedbackImprovement } from './api-calls/survey-text-processing-api-calls';
import ProgressBar from "@ramonak/react-progress-bar";
import { sendPageTime } from '../../layout-and-styling/API-calls/common-api-calls';
import { StyledDivRegular } from '../../layout-and-styling/standardized-components-library/Styling-TextInput';
import {useTranslation} from "react-i18next";
import logo from "../../assets/dashboard/load.gif";


function SurveyTextProcessingFooter(props) {
    /** Component to build footer buttons, with the corresponding API calls.
     * First section you find all the API get, post and update request functions
     * Second section you find the if statements for specific pages, to load in specific styling and API requests
     * Third section you find the styled div css styling
     */

    /** Go from one directory to another via useHistory */
    let history = useHistory();
    const {t} = useTranslation();
    const [advance, setAdvance] = useState(false);


    /** Workaround for API and rendering issues, with timeouts and forced re-renders
     * TODO: async await API handshakes
     * TODO: proper first render of mapped localStorage data, without the need of page refresh
     */
    /** If else statement, to only push ArrayCloseColleagues for now at "PageCommFreqMeasFilter */

    if (props.page === "PageKnowledge") {
        /** Built in check whether anything is filled in */
        const input_check_individual_impact = props.input_check_individual_impact;
        const slider_checked = props.sliderCheck;
        const knowledge_processes_checked = props.input_check_knowledge_processes;

        return (
            <Wrapper MarginTop={props.FooterMarginTop} MarginLeft={props.FooterMarginLeft}
                     Width={props.FooterWidth} Height={props.FooterHeight}>

                <StyledDivRegular MarginTop='28px' MarginLeft={(100 * 220 / 1920).toString() + "%"}>
                    <StyledDivRegular Width='100px'> 1 of 2 </StyledDivRegular>
                    <StyledDivRegular MarginTop='30px'>
                        <ProgressBar completed={100 * 1 / 2} customLabel=" " height='16px' width='280px' bgColor="#e2336b" baseBgColor="#E3E5E4"/>
                    </StyledDivRegular>
                </StyledDivRegular>

                <ButtonWrapperBack MarginTop={props.MarginTop} MarginLeft={props.BackButtonMarginLeft} Height='56px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.NextButtonMarginLeft} PointerEvents={"all"} Height='56px' Width='160px'
                                   BackgroundColor={"#e2336B"} Cursor={"pointer"}>
                    <button
                        onClick={() => {
                            props.setNextButtonClicked(true)
                            if(knowledge_processes_checked && input_check_individual_impact && slider_checked){
                                setAdvance(true)
                                UpdateKnowledge(props.setNextButtonClicked)
                            }
                        }}
                    >
                        {advance && (
                        <LoadLogo MarginTop={"-13px"}>
                            <img src={logo} alt="loading..."/>
                        </LoadLogo>
                        )}
                        {t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    }

    else if (props.page === "PrePageProcessGoodFeedback") {
        /** Built in check whether anything is filled in */
        const feedback_checked = props.input_check;

        return (
            <Wrapper MarginTop={props.FooterMarginTop} MarginLeft={props.FooterMarginLeft} Width={props.FooterWidth} Height={props.FooterHeight}>

                <StyledDivRegular MarginTop='28px' MarginLeft={(100 * 220 / 1920).toString() + "%"}>
                    <StyledDivRegular Width='100px'> 8 of 10 </StyledDivRegular>
                    <StyledDivRegular MarginTop='30px'>
                        <ProgressBar completed={100 * 8 / 10} customLabel=" " height='16px' width='280px' bgColor="#e2336b" baseBgColor="#E3E5E4"/>
                    </StyledDivRegular>
                </StyledDivRegular>

                <ButtonWrapperBack MarginTop={props.MarginTop} MarginLeft={props.BackButtonMarginLeft} Height='56px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.NextButtonMarginLeft} PointerEvents={"all"} Height='56px' Width='160px'
                                   BackgroundColor={"#e2336B"} Cursor={"pointer"}>
                    <button
                        onClick={() => {
                            props.setNextButtonClicked(true)
                            if(feedback_checked){
                                setAdvance(true)
                                sendPageTime("page-good-feedback-process-selector", "/survey-feedback-optimal")
                            }
                        }}
                    >
                        {advance && (
                        <LoadLogo MarginTop={"-13px"}>
                            <img src={logo} alt="loading..."/>
                        </LoadLogo>
                        )}
                        {t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    }

    else if (props.page === "PageProcessGoodFeedback") {
        /** Built in check whether anything is filled in */
        const feedback_checked = props.input_check;

        return (
            <Wrapper MarginTop={props.FooterMarginTop} MarginLeft={props.FooterMarginLeft} Width={props.FooterWidth} Height={props.FooterHeight}>

                <StyledDivRegular MarginTop='28px' MarginLeft={(100 * 220 / 1920).toString() + "%"}>
                    <StyledDivRegular Width='100px'> 8 of 10 </StyledDivRegular>
                    <StyledDivRegular MarginTop='30px'>
                        <ProgressBar completed={100 * 8 / 10} customLabel=" " height='16px' width='280px' bgColor="#e2336b" baseBgColor="#E3E5E4"/>
                    </StyledDivRegular>
                </StyledDivRegular>

                <ButtonWrapperBack MarginTop={props.MarginTop} MarginLeft={props.BackButtonMarginLeft} Height='56px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.NextButtonMarginLeft} PointerEvents={"all"} Height='56px' Width='160px'
                                   BackgroundColor={"#e2336B"} Cursor={"pointer"}>
                    <button
                        onClick={() => {
                            props.setNextButtonClicked(true)
                            if(feedback_checked){
                                setAdvance(true)
                                UpdateFeedbackOptimal(props.setNextButtonClicked)
                            }
                        }}
                    >
                        {advance && (
                        <LoadLogo MarginTop={"-13px"}>
                            <img src={logo} alt="loading..."/>
                        </LoadLogo>
                        )}
                        {t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    }

    else if (props.page === "PrePageProcessImprovementFeedback") {
        /** Built in check whether anything is filled in */
        const feedback_checked = props.input_check;

        return (
            <Wrapper MarginTop={props.FooterMarginTop} MarginLeft={props.FooterMarginLeft} Width={props.FooterWidth} Height={props.FooterHeight}>

                <StyledDivRegular MarginTop='28px' MarginLeft={(100 * 220 / 1920).toString() + "%"}>
                    <StyledDivRegular Width='100px'> 9 of 10 </StyledDivRegular>
                    <StyledDivRegular MarginTop='30px'>
                        <ProgressBar completed={100 * 9 / 10} customLabel=" " height='16px' width='280px' bgColor="#e2336b" baseBgColor="#E3E5E4"/>
                    </StyledDivRegular>
                </StyledDivRegular>

                <ButtonWrapperBack MarginTop={props.MarginTop} MarginLeft={props.BackButtonMarginLeft} Height='56px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.NextButtonMarginLeft} PointerEvents={"all"} Height='56px' Width='160px'
                                   BackgroundColor={"#e2336B"} Cursor={"pointer"}>
                    <button
                        onClick={() => {
                            props.setNextButtonClicked(true)
                            if(feedback_checked){
                                setAdvance(true)
                                sendPageTime("page-bad-feedback-process-selector", "/survey-rootcause-chatbot")
                            }
                        }}
                    >
                        {advance && (
                        <LoadLogo MarginTop={"-13px"}>
                            <img src={logo} alt="loading..."/>
                        </LoadLogo>
                        )}
                        {t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    }

    else if (props.page === "PageProcessBadFeedback") {
        /** Built in check whether anything is filled in */
        const feedback_checked = props.input_check;

        return (
            <Wrapper MarginTop={props.FooterMarginTop} MarginLeft={props.FooterMarginLeft}
                     Width={props.FooterWidth} Height={props.FooterHeight}>

                <StyledDivRegular MarginTop='28px' MarginLeft={(100 * 220 / 1920).toString() + "%"}>
                    <StyledDivRegular Width='100px'> 10 of 10 </StyledDivRegular>
                    <StyledDivRegular MarginTop='30px'>
                        <ProgressBar completed={100 * 10 / 10} customLabel=" " height='16px' width='280px' bgColor="#e2336b" baseBgColor="#E3E5E4"/>
                    </StyledDivRegular>
                </StyledDivRegular>

                <ButtonWrapperBack MarginTop={props.MarginTop} MarginLeft={props.BackButtonMarginLeft} Height='56px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.NextButtonMarginLeft} PointerEvents={"all"} Height='56px' Width='160px'
                                   BackgroundColor={"#e2336B"} Cursor={"pointer"}>
                    <button
                        onClick={() => {
                            props.setNextButtonClicked(true)
                            if(feedback_checked){
                                setAdvance(true)
                                UpdateFeedbackImprovement(props.setNextButtonClicked)
                            }
                        }}
                    >
                        {advance && (
                        <LoadLogo MarginTop={"-13px"}>
                            <img src={logo} alt="loading..."/>
                        </LoadLogo>
                        )}
                        {t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    }
    
    else {
        return (
            <Wrapper Height='104px'>
                <ButtonWrapperBack MarginTop='853px' MarginLeft='1464px' Height='56px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop='853px' MarginLeft='1540px' Height='56px' Width='160px'>
                    <button
                        onClick={() => {
                            history.push(props.nextdirectory);
                        }}
                    >{t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    };
};

export default SurveyTextProcessingFooter

const Wrapper = styled.div`
  position: fixed;
  margin-left: ${props => props.MarginLeft || ""};
  height: ${props => props.Height || "104px"};
  width: ${props => props.Width || "100%"};
  background-color: ${props => props.BackgroundColor || "#FCFCFC"};
  border-top: 1px solid #E3E5E4;
  z-index: 1;
  display: ${props => props.Display || ""};
  bottom: 0; //fixes the footer to the bottom of the page

  img {
    -drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`;

const LoadLogo = styled.div`
  display: flex;
  justify-content: flex-start;
  position: absolute;

  img {
    width: 50px;
    margin-left: ${props => props.MarginLeft || "55px"};
    margin-top: ${props => props.MarginTop || "3px"};
  }

  button {
    opacity: 0.2;
    position: absolute;
    cursor: unset;
  }
`;

const ButtonWrapperBack = styled.div`
  position: ${props => props.Position || "fixed"};
  margin-bottom: ${props => props.MarginBottom || ""};
  margin-top: ${props => props.MarginTop || "39.4%"};
  margin-left: ${props => props.MarginLeft || "76.5%"};

  @media only screen and (max-width: 1700px) {
    margin-left: ${props => props.MarginLeft || "75.5%"};
  }

  z-index: 4;
  width: ${props => props.Width || "39.4%"};
  height: ${props => props.Height || "39.4%"};

  button {
    border: none;
    padding: 0;
    background: none;
    cursor: ${props => props.Cursor || "pointer"};
  }
`;

const ButtonWrapperNext = styled.div`
  justify-content: center;
  margin-top: ${props => props.MarginTop || "22px"};
  margin-left: ${props => props.MarginLeft || "80.1%"};
  z-index: 4;

  button {
    width: ${props => props.Width || "136px"};
    height: ${props => props.Height || "48px"};
    border: ${props => props.Border || "none"};
    border-radius: ${props => props.BorderRadius || "8px"};
    background-color: ${props => props.BackgroundColor || "#e2336B"};
    color: ${props => props.Color || "#FCFCFC"};
    font-size: ${props => props.FontSize || "18px"};
    font-weight: ${props => props.FontWeight || "400"};
    font-family: ${props => props.FontFamily || "Overpass"};
    opacity: ${props => props.isDisabled ? '0.5' : props.Opacity || '1'};
    cursor: ${props => props.isDisabled ? '' : props.Cursor || 'pointer'};
    transition: all 0.2s ease-in;
    align-items: flex-start;

    pointer-events: ${props => props.PointerEvents || "none"};

    :hover {
        background-color: ${props => props.isDisabled ? '' : '#B3194A'};
    }
  }
`;