import React, {useEffect, useState, useRef} from 'react';
import styled from '@emotion/styled';
import { StyledDivButton, StyledDivRegular } from '../../../layout-and-styling/standardized-components-library/Styling-TextInput';
import ComponentOptionSelector from './Component-OptionSelector';
import {useTranslation} from 'react-i18next';
import SendIcon from "../../../assets/SendIcon.svg"
import SendIconGrey from "../../../assets/SendIconGrey.svg"
import SendIconDisabled from "../../../assets/SendIconDisabled.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export default function SendMessageComponent(props){
    const {t} = useTranslation();

    // message written
    const [answer, setAnswer] = useState('');
    const [enable, setEnable] = useState(false);
    const [isOptions, setIsOptions] = useState(false);
    const [placeholderOptionsHeight, setPlaceholderOptionsHeight] = useState(0);

    const isPortraitMode = window.matchMedia("(orientation: portrait)").matches;
    const maxLength = 500;

    // reference to the text area
    const textAreaRef = useRef();

      // Reference for the container to measure its height
    const containerRef = useRef();

    // selects the text area so that the user is available to write
    useEffect(() => {
        if(textAreaRef.current)
            textAreaRef.current.select();

        if (props.options && props.options.length > 0 && isPortraitMode) {
          props.setSelectOptionsHeight(placeholderOptionsHeight);
          setIsOptions(true); // Set isOptions to true if options are present
        } else {
          setIsOptions(false); // Set isOptions to false if no options
        }
    
    }, [props.botWriting]);
 
    // handles if the answer is unselected
    const handleAnswer = (new_answer) => {
        if(new_answer !== "" && new_answer !== answer){
            setAnswer(new_answer)
            setEnable(true)
        }
        else{
            setAnswer("")
            setEnable(false)
        }
      };

    // when the send button is clicked, calls the next message function from PageRootcauseChatbot.js
    function send() {
        if(answer !== ""){
            props.nextMessage(answer);

            if (textAreaRef.current && isPortraitMode) {
              textAreaRef.current.style.height = '134px';
            }
        }
        setAnswer("");
        setEnable(false)
    }

    useEffect(() => {
        if (containerRef.current && isPortraitMode) {
            const height = containerRef.current.offsetHeight;
            props.setSendMessageHeight(height);
        }
    }, [answer, enable]);

    const autoGrow = (event) => {
        const textarea = event.target;
        textarea.style.height = 'auto'; // Reset height to auto to recalculate
        textarea.style.height = `${textarea.scrollHeight}px`; // Set the new height based on scrollHeight
      };

      return (
        <>
          <Container ref={containerRef} isOptions={isOptions}>
            {/* Button for stages PRESENTATION or INTRODUCTION */}
            {props.stage.includes("INTRODUCTION") || props.stage.includes("START_FEEDBACK") ? (
              <ButtonDiv>
                <StyledDivButton MarginTop="0px" MarginLeft="0px" Width="100%" Height="80px" BackgroundColor={!props.botThinking && !props.botWriting ? "#E2336B" : "#E3E5E4"} FontSize="18px" Display="flex"
                  JustifyContent="center" Position="relative" Cursor={!props.botThinking && !props.botWriting ? "pointer" : "auto"} Border="0px solid #271B36" Color="#FCFCFC"
                  PhoneWidth={"calc(90% + 28px)"} PhoneFontSize="40px" PhoneBorderRadius="20px" PhonePadding="0px 40px" PhoneButtonHeight="134px">
                  <button disabled={props.botThinking || props.botWriting} onClick={() => props.nextMessage(props.options[0])}>
                    {props.options[0]}
                  </button>
                </StyledDivButton>
              </ButtonDiv>
            ) : (
              // Regular chat mode (input options or message box)
              <>
                {props.options.length > 0 ? (
                  <ComponentOptionSelector stage={props.stage} options={props.options} handleAnswer={handleAnswer} setEnable={setEnable} setSelectOptionsHeight={setPlaceholderOptionsHeight} />
                ) : (
                  <>
                    {/* the user should only be able to type in the text are if Elly is not thinking nor writing */}
                    <textarea ref={textAreaRef} style={!isPortraitMode ? { fontSize: "18px" } : {}} maxLength={maxLength} id="chatbotmessagebox"
                      placeholder={t( isPortraitMode ? "PageRootcauseChatbot.PhoneMessageBoxPlaceholder" : "PageRootcauseChatbot.MessageBoxPlaceholder" )} value={answer}
                      onChange={(e) => !props.botThinking && !props.botWriting ? handleAnswer(e.target.value) : ""} onInput={isPortraitMode ? autoGrow : undefined} />
                    
                    {/* the answer length / 500 is only displayed for the desktop version */}
                    {!isPortraitMode && (
                      <StyledDivRegular Position="absolute" Bottom="0px" Right="124px" ZIndex="5" FontWeight={answer.length < maxLength ? "400" : "700"} Color={answer.length < maxLength ? "#5F6366" : "#F72B2B"} Width="fit-content" >
                        {answer.length}/{maxLength}
                      </StyledDivRegular>
                    )}
                  </>
                )}

                {/* In case we are not in the introduction or presentation stage, the Send button is always there */}
                <StyledDivButton MarginTop="0px" MarginLeft="0px" Width="100px" Height="80px" BackgroundColor="#E2336B" FontSize="18px" Display="flex" JustifyContent="center" Position="relative"
                  Cursor={!props.botThinking && !props.botWriting && enable ? "pointer" : "auto"} Border="0px solid #271B36" Color={enable ? "#FCFCFC" : "#EE85A6"}
                  PhoneFontSize="40px" PhoneFontWeight="600" PhoneButtonHeight="134px" PhoneHeight="auto" PhoneWidth={isOptions ? "calc(90% + 28px);" : "13%"} PhoneButtonWidth="100%" PhoneImgWidth="auto" PhoneImgHeight="50px" PhoneBorderRadius="20px" PhoneGap="20px">
                  <button onClick={() => send()}>
                    {t(!isPortraitMode ? "PageRootcauseChatbot.SendButtonText" : isPortraitMode && isOptions ? "PageRootcauseChatbot.ConfirmButtonText" : "")}
                    {isPortraitMode && isOptions ? ( <FontAwesomeIcon icon={faCheck} /> ) : (
                    <img src={ !props.botThinking && !props.botWriting && enable ? SendIcon : !enable ? SendIconDisabled : SendIconGrey } alt="Send Icon" /> )}
                    </button>
                </StyledDivButton>
              </>
            )}
          </Container>
        </>
      );      
};

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: calc(100%);
    height: 80px;
    gap: 16px;

    textarea {
    height: calc(80px - 16px);
    border: none; 
    background-color: #F1F3F4; 
    font-size: 16px; 
    font-family: 'Overpass';
    color: #000000; 
    line-height: 20px; 
    border-radius: 4px; 
    resize: none; 
    flex-grow: 3; 
    padding: 8px;
    }

    @media (orientation: portrait) {
    position: fixed;
    bottom: 0;
    z-index: 1;
    align-items: end;
    justify-content: space-evenly;

    width: 100%;
    gap: 0px;
    background-color: #FFFFFF;
    padding-bottom: 40px;
    padding-top: 40px;

    min-height: 100px;
    height: auto;
    max-height: 50vh; // using viewport height to make sure container's max height is set based on device's screen height

      textarea {
        background-color: #FFFFFF;
        border: 3px solid #E9EBEA;

        flex-grow: 0;
        width: 75%;
        background-color: #FCFCFC; 
        font-size: 34px;
        line-height: 35px;
        border-radius: 10px;
        padding: 30px;
        box-sizing: border-box;
        resize: none;
        min-height: 134px;
        max-height: 500px;
        
        &::placeholder {
          color: #C6CBCB;
        }

        transition: border 0.2s ease-in-out;

        &:focus {
          outline: none;
          border: 3px solid #E2336B;
        }
      }
    }
`;

const ButtonDiv = styled.div`
    position: relative;
    width: 100%;
`;