import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {SubProcessBlock} from '../../../layout-and-styling/standardized-components-library/Styling-ProcessBlocks';
import { OverlayContext } from '../../../layout-and-styling/context-hooks/OverlayContext';
import { TooltipKnowledgePage } from '../../standardized-components/Survey-text-processing-tooltips';
import Fade from '@mui/material/Fade';

const ProcessData = JSON.parse(localStorage.getItem('business_process_data'));

const ComponentBusinessProcessDropdown = (props) => {
    const [hover, setHover] = useState(false);
    const {t} = useTranslation();
    const containerRef = React.useRef(null);

    const numProcessesSelected = (List) => {
        let selected = 0
        for (let i = 0; i < List.length; i++) {
            if(List[i])
                ++selected
        }
        return selected
    };

    return (
        <Container outline={numProcessesSelected(props.ProcessesSelected) === 0 && props.nextButtonClicked ? "2px solid #F72B2B" :  ""}>
            <IncompleteContentText display={numProcessesSelected(props.ProcessesSelected) === 0 && props.nextButtonClicked  ? "flex" : "none"}>
              {t('PageKnowledge.ProcessesClickError')}
            </IncompleteContentText>
            <TopHeader>

                <Header>
                    {t('PageKnowledge.ProcessHeaderText')}
                </Header>

                <InfoHover onMouseOver={() => setHover(true)}
                           onMouseOut={() => setHover(false)}>?
                    {hover && (<TooltipKnowledgePage whichTooltip={"BusinessProcess"}/>
                    )}
                </InfoHover>

            </TopHeader>

            <ProcessComponentWrapper>
                {ProcessData.map((current_process_object, current_process_id, all_process_objects) => {
                    return (
                        <ProcessComponent>
                            <MappedSubProcess
                                setSliderCheck={props.setSliderCheck}
                                widths={props.widths}
                                setWidths={props.setWidths}
                                ProcessesSelected={props.ProcessesSelected}
                                current_process_object={current_process_object}
                                current_process_id={current_process_id} reRender={props.reRender}
                                SelfRatedKnowledge={props.SelfRatedKnowledge}
                                containerRef={containerRef}
                            />
                        </ProcessComponent>
                    )
                })}
            </ProcessComponentWrapper>
        </Container>

    )
}
export default ComponentBusinessProcessDropdown

const MappedSubProcess = (props) => {
    const [hover, setHover] = useState(false)
    const {t, i18n} = useTranslation();
    const context = useContext(OverlayContext);

    const numProcessesSelected = (List) => {
        let selected = 0
        for (let i = 0; i < List.length; i++) {
            if(List[i])
                ++selected
        }
        return selected
    };

    const changeChecked = (current_process_id) => {

        props.ProcessesSelected[current_process_id] = !props.ProcessesSelected[current_process_id]
        localStorage.setItem('ProcessesSelected', JSON.stringify(props.ProcessesSelected))

        let new_widths = new Array(props.SelfRatedKnowledge.length).fill(0)
        let numProcSelected = numProcessesSelected(props.ProcessesSelected)

        for (var i = 0; i < props.SelfRatedKnowledge.length; i++) {
            if(props.ProcessesSelected[props.SelfRatedKnowledge[i].id]){
                props.SelfRatedKnowledge[i].time_portion = ((100 / numProcSelected) / 100).toFixed(4)
                new_widths[i] = 100 / numProcSelected
            }
            else{  
                props.SelfRatedKnowledge[i].time_portion = (0).toFixed(4)
                new_widths[i] = 0
            }
                
        }
        props.setWidths(new_widths)
        localStorage.setItem("ProcessesTimePortion", JSON.stringify(props.widths));
        localStorage.setItem("SelfRatedKnowledge", JSON.stringify(props.SelfRatedKnowledge))

        props.reRender()

        if (numProcessesSelected(props.ProcessesSelected) === 1) {
            props.setSliderCheck(true);
        } else {
            props.setSliderCheck(false);
        }

    };
    let contains = props.ProcessesSelected[props.current_process_id];

    return (
        <>
            {props.current_process_id === 0 &&
                <>
                    <ArrowProcess
                        onMouseOver={() => setHover(true)}
                        onMouseOut={() => setHover(false)}
                        Background={contains ? "#B3194A" : hover ? "#E2336B" : "#271B36"}
                        onClick={() => changeChecked(props.current_process_id)}
                        RightBorderTop={"40px solid transparent"}
                        RightBorderBottom={"40px solid transparent"}
                        RightBorderLeft={contains ? "40px solid #B3194A" : hover ? "40px solid #E2336B" : "40px solid #271B36"}
                        RightBorderRight={"none"}
                        ref={props.containerRef}

                    >
                        <ArrowTitle id={props.current_process_object.name}>
                            {context.setProcess(props.current_process_object.name, i18n.language)}
                        </ArrowTitle>
                    </ArrowProcess>
                    <Fade in={hover} timeout={600} container={props.containerRef.current}>
                        <HoverTooltip>
                            {props.current_process_object.children.map((subprocess, subprocess_index) => {
                                if (subprocess_index === props.current_process_object.children.length - 1) {
                                    return (
                                        <>
                                            <SubProcessColumnWrapper>
                                                <SubProcessBlock MarginRight='16px'>
                                                    <SubProcessText>{context.setProcess(subprocess, i18n.language)}</SubProcessText>
                                                </SubProcessBlock>
                                            </SubProcessColumnWrapper>
                                            <BreakColumn/>
                                        </>
                                    )
                                } else {
                                    return (
                                        <SubProcessColumnWrapper>

                                            <SubProcessBlock MarginRight='16px'>
                                                <SubProcessText>{context.setProcess(subprocess, i18n.language)}</SubProcessText>
                                            </SubProcessBlock>

                                        </SubProcessColumnWrapper>
                                    )
                                }
                            })}
                        </HoverTooltip>
                    </Fade>
                </>
            }

            {props.current_process_id === ProcessData.length - 1 &&
                <>
                    <ArrowProcess3
                        onMouseOver={() => setHover(true)}
                        onMouseOut={() => setHover(false)}
                        onClick={() => changeChecked(props.current_process_id)}
                        Background={contains ? "#B3194A" : hover ? "#E2336B" : "#271B36"}
                        LeftBorderTop={"40px solid transparent"}
                        LeftBorderBottom={"40px solid transparent"}
                        LeftBorderLeft={"40px solid #FCFCFC"}
                        LeftBorderRight={"none"}
                        ref={props.containerRef}
                    >
                        <ArrowTitle id={props.current_process_object.name}>
                            {context.setProcess(props.current_process_object.name, i18n.language)}
                        </ArrowTitle>
                    </ArrowProcess3>

                    <Fade in={hover} timeout={600} container={props.containerRef.current}>
                        <HoverTooltip>
                            {props.current_process_object.children.map((subprocess, subprocess_index) => {
                                if (subprocess_index === props.current_process_object.children.length - 1) {
                                    return (
                                        <>
                                            <SubProcessColumnWrapper>
                                                <SubProcessBlock MarginRight='16px'>
                                                    <SubProcessText>{context.setProcess(subprocess, i18n.language)}</SubProcessText>
                                                </SubProcessBlock>
                                            </SubProcessColumnWrapper>
                                            <BreakColumn/>
                                        </>
                                    )
                                } else {
                                    return (
                                        <SubProcessColumnWrapper>

                                            <SubProcessBlock MarginRight='16px'>
                                                <SubProcessText>{context.setProcess(subprocess, i18n.language)}</SubProcessText>
                                            </SubProcessBlock>

                                        </SubProcessColumnWrapper>
                                    )
                                }
                            })
                            }
                        </HoverTooltip>
                    </Fade>
                </>

            }

            {props.current_process_id !== 0 && props.current_process_id !== ProcessData.length - 1 &&
                <>
                    <ArrowProcess2
                        onMouseOver={() => setHover(true)}
                        onMouseOut={() => setHover(false)}
                        onClick={() => changeChecked(props.current_process_id)}
                        Background={contains ? "#B3194A" : hover ? "#E2336B" : "#271B36"}
                        LeftBorderTop={"40px solid transparent"}
                        LeftBorderBottom={"40px solid transparent"}
                        LeftBorderLeft={"40px solid #FCFCFC"}
                        LeftBorderRight={"none"}
                        RightBorderTop={"40px solid transparent"}
                        RightBorderBottom={"40px solid transparent"}
                        RightBorderLeft={contains ? "40px solid #B3194A" : hover ? "40px solid #E2336B" : "40px solid #271B36"}
                        RightBorderRight={"none"}
                        ref={props.containerRef}
                    >
                        <ArrowTitle id={props.current_process_object.name}>
                            {context.setProcess(props.current_process_object.name, i18n.language)}
                        </ArrowTitle>
                    </ArrowProcess2>
                    <Fade in={hover} timeout={600} container={props.containerRef.current}>
                        <HoverTooltip>
                            <SubProcessWrapper>
                                {props.current_process_object.children.map((subprocess, subprocess_index) => {
                                    if (subprocess_index === props.current_process_object.children.length - 1) {
                                        return (
                                            <>
                                                <SubProcessColumnWrapper>
                                                    <SubProcessBlock MarginRight='16px'>
                                                        <SubProcessText>{context.setProcess(subprocess, i18n.language)}</SubProcessText>
                                                    </SubProcessBlock>
                                                </SubProcessColumnWrapper>
                                                <BreakColumn/>
                                            </>
                                        )
                                    } else {
                                        return (
                                            <SubProcessColumnWrapper>
                                                <SubProcessBlock MarginRight='16px'>
                                                    <SubProcessText>{context.setProcess(subprocess, i18n.language)}</SubProcessText>
                                                </SubProcessBlock>
                                            </SubProcessColumnWrapper>
                                        )
                                    }
                                })}
                            </SubProcessWrapper>
                        </HoverTooltip>
                    </Fade>
                </>
            }
        </>
    )
}


const Container = styled.div`
  background: #FCFCFC;
  min-width: 1044px;
  margin-right: 20px;
  border-radius: ${props => props.BorderRadius || '5px'};
  font-family: 'Overpass';
  outline: ${props => props.outline || ''};
  outline-offset: -2px;
`;

const IncompleteContentText = styled.div`
  position: absolute;
  display: ${(props) => props.display || ""};
  margin-left: 416px;
  margin-top: 4px;
  border-radius: ${(props) => props.BorderRadius || "5px"};
  color: #F72B2B;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
`;

const Header = styled.div`
  margin-top: 20px;
  margin-left: 20px;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
`;

const ProcessComponentWrapper = styled.div`
  margin: 24px 0 24px 20px;
  display: flex;
  flex-direction: row;
  background: #FCFCFC;
  width: calc(100% - 20px);
`;

const ProcessComponent = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

const ArrowProcess = styled.div`
  width: 160px;
  height: 80px;
  background: ${props => props.Background || "#271B36"};
  border-radius: 2px;
  color: #FCFCFC;
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  cursor: pointer;

  &:after {
    content: " ";
    z-index: 4;
    position: absolute;
    margin-left: 200px;
    border-right: ${props => props.RightBorderRight || "28px solid #271B36"};
    border-top: ${props => props.RightBorderTop || "28px solid transparent"};
    border-bottom: ${props => props.RightBorderBottom || "28px solid transparent;"};
    border-left: ${props => props.RightBorderLeft || "28px solid #271B36"};
  }
`;

const ArrowProcess2 = styled.div`
  width: 160px;
  height: 80px;
  background: ${props => props.Background || ""};
  border-radius: 2px;
  color: #FCFCFC;
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  cursor: pointer;

  &:before {
    content: " ";
    position: absolute;
    margin-right: 130px;
    border-top: ${props => props.LeftBorderTop || "28px solid #271B36"};
    border-right: ${props => props.LeftBorderRight || "28px solid #271B36"};
    border-bottom: ${props => props.LeftBorderBottom || "28px solid #271B36"};
    border-left: ${props => props.LeftBorderLeft || "28px solid transparent"};
  }

  &:after {
    content: " ";
    z-index: 4;
    position: absolute;
    margin-left: 200px;
    border-right: ${props => props.RightBorderRight || "28px solid #271B36"};;
    border-top: ${props => props.RightBorderTop || "28px solid transparent"};
    border-bottom: ${props => props.RightBorderBottom || "28px solid transparent;"};
    border-left: ${props => props.RightBorderLeft || "28px solid #271B36"};
  }
`;

const ArrowProcess3 = styled.div`
  width: 160px;
  height: 80px;
  background: ${props => props.Background || ""};
  border-radius: 2px;
  color: #FCFCFC;
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  cursor: pointer;

  &:before {
    content: " ";
    position: absolute;
    margin-right: 130px;
    border-top: ${props => props.LeftBorderTop || "28px solid #271B36"};
    border-right: ${props => props.LeftBorderRight || "28px solid #271B36"};
    border-bottom: ${props => props.LeftBorderBottom || "28px solid #271B36"};
    border-left: ${props => props.LeftBorderLeft || "28px solid transparent"};
  }
`;

const ArrowTitle = styled.div`
  margin-left: 40px;
  text-align: center;
  width: 88px;
`;

const HoverTooltip = styled.div`
  //display: flex;
  //align-items: center;
  position: absolute;
  padding: 0 0 10px 20px;
  top: 380px;
  z-index: 3;
  background: #FCFCFC;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

const SubProcessColumnWrapper = styled.div`
`;

const SubProcessWrapper = styled.div`
`;
const SubProcessText = styled.p`
  margin: 0;
  padding: 3px;
  width: 115px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-family: 'Overpass';
  font-weight: ${props => props.FontWeight || "400"};
  font-size: ${props => props.FontSize || "14px"};
  line-height: 18px;
overflow: hidden;
`;
const BreakColumn = styled.div`
  flex-basis: ${props => props.FlexBasis || "100%"};
`;

const TopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;

const InfoHover = styled.div`
  float: right;
  font-size: 18px;
  font-family: 'Overpass', sans-serif;
  font-weight: 300;
  position: relative;
  color: #FCFCFC;
  background-color: #E2336B;
  margin-right: 16px;
  margin-top: 16px;
  border: 2px solid #E2336B;
  border-radius: 500px;
  width: 20px;
  text-align: center;
  height: 20px;
  cursor: pointer;
`;
