import React, { createContext, useState, useEffect } from 'react'
// Error page
import ErrorPage from '../layout-and-styling/common-pages/ErrorPage';
import load from "../assets/dashboard/load.gif";
import styled from "styled-components";


// This page contains the dashboard context, it allows us to control in which tab are we and which page do we render depending on the selected tab
export const DashboardContext = createContext();

export const DashboardContextProvider = (props) => {
  // State to control the selected tab, the tab can only be changed in through the sidebar 
  const [selectedTab, setSelectedTab] = useState("TabProblemDeepDive");
  // State to control the selected subYab, the subTab can only be changed in through the sidebar (at the moment it is not really used)
  const [selectedSubTab, setSelectedSubTab] = useState("SubTabNetworkFeedback");
  // State to control the mode of the dashboard, only if the user has the role developer it will be able to see the toggle and enable this mode with which you can see more dashboard pages used for analysis
  const [developerMode, setDeveloperMode] = useState(false);
  // State to control the page to render depending on the selectedTab
  // by default the starting page is a loading gif
  const [page, setPage] = useState(
    <LoadLogo>
      {" "} 
      <img src={load} alt="loading..."/>
    </LoadLogo>
  );

  /**
   * Updates the page state depending on the selectedTab
   * @throws {ErrorPage} is rendered in case there is any permission or accessability problem.
   */
  async function updatePage() {
    // we switch with the selected tab
    switch (selectedTab) {

      // ------- User pages --------

      // Problem - Root cause Deep Dive
      case "TabProblemDeepDive":
        try {
          // importing and setting the page to render
          const ProblemDeepDive = require('../dashboard-text-processing/problem-deep-dive/ProblemDeepDive')
          setPage(<ProblemDeepDive.default/>)
        } catch (error) {
          // Error page in case the page is not accessible
          setPage(<ErrorPage type="Access"/>)
        }
        break;
      
      // Social Network
      case "TabSocialNetwork":
        try {
          // importing and setting the page to render
          const SocialNetwork = require('../dashboard-social-network/social-network/SocialNetwork')
          setPage(<SocialNetwork.default/>)          
        } catch (error) {
          // Error page in case the page is not accessible
          setPage(<ErrorPage type="Access"/>)
        }
        break;

      // ------- Developer pages --------

      // Problem Network
      case "TabProblemNetwork":
        try {
          // importing and setting the page to render
          const ProblemNetwork = require('../dashboard-text-processing/problem-network/ProblemNetwork')
          setPage(<ProblemNetwork.default/>)
        } catch (error) {
          // Error page in case the page is not accessible
          setPage(<ErrorPage type="Access"/>)
        }
        break;

      // Social Network Feedback
      case "TabSocialNetworkFeedback":
        try {
          // importing and setting the page to render
          const SocialNetworkFeedback = require('../dashboard-social-network/social-network-feedback/SocialNetworkFeedback')
          setPage(<SocialNetworkFeedback.default/>)
        } catch (error) {
          // Error page in case the page is not accessible
          setPage(<ErrorPage type="Access"/>)
        }
        break;

      // default option (Error page)
      default:
        setPage(<ErrorPage type="Access"/>)
    }
  }
  
  /**
   * useEffect executed every time the page renders and the variable selectedTab has a different value than before
   */
  useEffect(()=> {
    updatePage()

    }, [selectedTab]
  );


  return (
    <DashboardContext.Provider value={
      {
        selectedTab: selectedTab,
        setSelectedTab: setSelectedTab,
        selectedSubTab: selectedSubTab,
        setSelectedSubTab: setSelectedSubTab,
        page: page,
        setPage: setPage,
        developerMode: developerMode,
        setDeveloperMode: setDeveloperMode,
      }
    }>
    {props.children}
    </DashboardContext.Provider>
  )
};


const LoadLogo = styled.div`
  img {
    width: 50px;
  }

  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
`;