import React, {useContext, useState} from 'react';
import styled from 'styled-components';
import { StyledSlider } from '../../../layout-and-styling/standardized-components-library/Styling-TextInput';
import {useTranslation} from "react-i18next";
import clockThumb from "../../common-assets/clockThumb.png";

const ComponentFrequencySlider = (props) => {
    const {t} = useTranslation();
    const min = t('PagePlannedMeetings.Mins');
    const hrs = t('PagePlannedMeetings.Hrs');

    const marks = [
        {
            value: 1,
            minutes: 0,
            label: "0 " + hrs
        },
        {
            value: 120,
            minutes: 120,
            label: "2 " + hrs
        },
    ];

    let currentHours = 0;
    if (props.MeetingsDays[props.selectedDay].meetings[props.selectedMeeting])
        currentHours = props.MeetingsDays[props.selectedDay].meetings[props.selectedMeeting].HrsData;

    function toHHMMSS(totalMinutes) {
        const minutes = totalMinutes % 60;
        const hours = Math.floor(totalMinutes / 60);
        if (!hours) {
            return `00:${padTo2Digits(Math.floor(minutes))}`;

        } else {
            return `${padTo2Digits(hours)}:${padTo2Digits(Math.floor(minutes))}`;
        }
    }

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    function valueLabelFormat(value) {
        return toHHMMSS(value);
    }

    return (
        <SliderWrapper MarginLeft={props.marginLeft} MarginTop={props.marginTop}
            PointerEvents={props.MeetingsDays[props.selectedDay].meetings[props.selectedMeeting] || props.selectedDay === 1 ? "" : "none"}>

            <StyledSlider id="slippedyslider" MarginLeft='8px' MarginTop="12px"
                onChange={(event, value) => { props.sliderChange(value); }}
                value={props.meetingDuration}
                valueLabelDisplay="on" marks={marks} valueLabelFormat={valueLabelFormat}
                size="small" step={15} min={0} max={120} orientation="horizontal"
                sx={{
                    height: "16px",
                    width: "calc(100% - 48px)",
                    color: '#C6CBCB',
                    '& .MuiSlider-thumb': {
                        color: "#3E4245",
                        width: '20px',
                        height: '20px',
                        backgroundImage: "url(" + clockThumb + ")",
                        '&:focus, &:hover, &.Mui-active': {
                            boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',  
                        },
                    },
                    '& .MuiSlider-track': {
                        backgroundColor: '#B3194A',
                    },
                    '& .MuiSlider-valueLabel': {
                        backgroundColor: '#FCFCFC',
                        color: '#271B36',
                        fontFamily: 'Overpass',
                        fontSize: '14px',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.25)',
                    },
                    '& .MuiSlider-mark': {
                        fontFamily: 'Overpass',
                        fontSize: '14px',
                        color: '#271B36',
                        opacity: '0%',
                    }
                }}
            />

        </SliderWrapper>
    )
}

export default ComponentFrequencySlider;

const SliderWrapper = styled.div`
  width: 320px;
  height: 80px;
  background-color: #FCFCFC;
  margin-top: ${props => props.MarginTop || "177px" };
  margin-left: ${props => props.MarginLeft || "0px" };
  opacity: ${props => props.Opacity || "1"};
  pointer-events: ${props => props.PointerEvents || ""};
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px #B3194A;
  border-radius: ${props => props.BorderRadius || '10px'};
  z-index: 1;
`;

const TitleSlider = styled.div`
  width: fit-content;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-left: 20px;
  margin-top: 20px;
  color: #271B36;

  span {
    color: #B3194A;
  }
`

const InfoHover = styled.div`
  float: right;
  font-size: 18px;
  font-family: 'Overpass', sans-serif;
  font-weight: 700;
  position: relative;
  color: #E2336B;
  margin-right: 16px;
  margin-top: 16px;
  border: 2px solid #E2336B;
  border-radius: 500px;
  width: 20px;
  text-align: center;
  height: 20px;
  cursor: pointer;
`;

const TopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;