import styled from 'styled-components';
import { StyledDivRegular } from '../../../layout-and-styling/standardized-components-library/Styling-TextInput';
import ComponentFrequencySlider from './Component-frequency-slider';
import {useTranslation} from "react-i18next";

const ComponentAverage = (props) => {

  const {t} = useTranslation();

  function toHHMMSS(totalMinutes) {
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);
    if (!hours) {
        return `00:${padTo2Digits(Math.floor(minutes))}`;

    } else {
        return `${padTo2Digits(hours)}:${padTo2Digits(Math.floor(minutes))}`;
    }
  }

  function padTo2Digits(num) {
      return num.toString().padStart(2, '0');
  }


  function sliderChange(value) {
    if(props.average){
      let employee = props.spontaneousTimes.filter((emp) => emp["employee"] === props.selectedColleague)[0]
      employee["average"] = value
      for (let i = 0; i < employee["days"].length; i++) {
        employee["days"][i] = value;
        
      }
      props.spontaneousTimes.filter((emp) => emp["employee"] === props.selectedColleague)[0] = employee
      props.setSpontaneousTimes(props.spontaneousTimes)
      localStorage.setItem('spontaneousTimes', JSON.stringify(props.spontaneousTimes))
      props.reRender()
    }
  }

  return (
    <Container Padding={props.id > 4 ? "12px 0px 12px 24px" : "12px 24px 12px 24px"} Opacity={props.Opacity}>

      <SliderContainer>
        <StyledDivRegular Width="156px" Position="relative" MarginRight="24px" FontWeight="600">
          {t('PageSpontaneousCollaboration.DailyAverage')}
        </StyledDivRegular>

        <ComponentFrequencySlider id="sponslider" meetingTime={props.meetingsTimes.filter((emp) => emp["employee"] === props.selectedColleague)[0]["average"]} average={props.average} element={props.element}
                                  spontaneousTime={props.spontaneousTimes.filter((emp) => emp["employee"] === props.selectedColleague)[0]["average"]} sliderChange={sliderChange} />

        <StyledDivRegular Width="64px" Position="relative" MarginLeft="24px">
          { toHHMMSS(props.meetingsTimes.filter((emp) => emp["employee"] === props.selectedColleague)[0]["average"] + props.spontaneousTimes.filter((emp) => emp["employee"] === props.selectedColleague)[0]["average"]) }
        </StyledDivRegular>
        <StyledDivRegular Width="fit-content" Position="relative" MarginLeft="0px" FontWeight="600">
          {"h"}
        </StyledDivRegular>
      </SliderContainer>

    </Container>

  );
};

export default ComponentAverage;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  opacity: ${props => props.Opacity || "1"};
  height: 42px;
  min-height: 42px;
  padding: ${props => props.Padding || ""};
  background-color: #FCFCFC;
  position: relative;
  border: ${props => props.Border || ''};
  border-radius: ${props => props.BorderRadius || '10px'};
  font-family: "Overpass";
  /* box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25); */
  opacity: ${props => props.Opacity || ''};
  /* z-index: 1; */
`;

const SliderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  button {
    display: flex;
    vertical-align: middle;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 100%;
    padding: 24px 12px 24px 12px;
    margin-left: 12px;
    background: #C6CBCB;
    border-radius: 12px;
    border: solid 0.5px #271B36;
    font-size: 16px;
    font-family: "Overpass";
    cursor: pointer;
    gap: 8px;
    z-index: 5;
  }

  button:hover {
    background: #919191;
    color: #FCFCFC;
  }
`;