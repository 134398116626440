import axios from 'axios';

export const getCookieValue = (name) => (
    document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
  )

export function validateSession(){
    const axiosInstance = axios.create({withCredentials: true})
    // Perform GET request to get a CSRFToken from the API and store it in sessionStorage
    axiosInstance.get(process.env.REACT_APP_APIURL + "/api/auth/validatesession/")
        .then((res) => true)
        .catch((error) => false)
}

export default getCookieValue