import styled from '@emotion/styled';
import React, {useContext} from 'react';
import {ReactComponent as ProcessBackground} from "../../common-assets/feedback_page_process.svg";
import {useTranslation} from "react-i18next";
import { OverlayContext } from '../../../layout-and-styling/context-hooks/OverlayContext';

/** Specific component used to keep track of the selected process in the optimal feedback page */
export default function ComponentFeedbackTrackerGood(props) {
  
    const context = useContext(OverlayContext);
    const {t, i18n} = useTranslation();

    return (
        <WrapperLeft>

            <TitleContainer> {t('PageGoodFeedback.TitleContainer')}</TitleContainer>

            <ProcessContainer>
                <Processes>
                  <ProcessLabel>{t('PageGoodFeedback.ProcessLabel1')}</ProcessLabel>
                  <SubProcessName>
                    <SubProcessSquare>
                      <p>{context.setProcess(props.selectedProcessInfo[props.currentProcessPointer].subprocess, i18n.language)}</p>
                    </SubProcessSquare>
                  </SubProcessName>
                  <ProcessLabel>{t('PageGoodFeedback.ProcessLabel2')}</ProcessLabel>
                  {/* The split is used because Avocado's processes are written: "Name" / "Chinese Name". With this you only see
                  the chinese name with the chinese translation. */}
                  <ProcessName>
                    <StyledProcessBackground/>
                    <p>{context.setProcess(props.selectedProcessInfo[props.currentProcessPointer].process, i18n.language)}</p>
                  </ProcessName>                     
                </Processes>
            </ProcessContainer>

          {/* Remains of deprecated code, it stays to keep the shape of the component intact. */}
            <ButtonsContainer/>

        </WrapperLeft>
    )
}


const WrapperLeft = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fcfcfc;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  flex: 557px;
  height: 300px;
  width: 100%;
  align-items: center;
  justify-content: center;
  max-width: 700px;
`;

const TitleContainer = styled.div`
  height: 40px;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom:15%;
  margin-top: 3%;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
`;

const ProcessContainer = styled.div`
  display: flex;
  flex: 4;
  height: calc(100% - 190px);
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  font-family: 'Overpass';
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
`;

const Processes = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 50%;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 64px;
  font-family: 'Overpass';
`;

const ProcessName = styled.div`
  display: flex;
  position: relative;
  flex: 224px;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;

  p {
    z-index: 1;
  }
`;

const SubProcessSquare = styled.div`
  display: flex;
  position: relative;
  height: 16px;
  width: calc(50% - 16px);
  color: #fcfcfc;
  border-radius: 8px;
  margin: 16px 16px 5% 16px;
  padding: 24px 8px 24px 8px;
  background-color: #06D6A0;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
`;

const SubProcessName = styled.div`
  display: flex;
  position: relative;
  flex: 192px;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const ButtonsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
  width: calc(100% - 60px);
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;

  p {
    font-family: 'Overpass';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0;
    text-align: center;
    color: #06D6A0;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
  }
`;

const StyledProcessBackground = styled(ProcessBackground)`
  position: absolute;
  z-index: 0;
  height: 100%;
  width: 125%;
`;

const ProcessLabel = styled.p`
  margin-top: 16px;
  margin-bottom: 16px;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0;
  text-align: center;
  color: #271B36;
`