import {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { StyledDivRegular, StyledDivButton } from '../layout-and-styling/standardized-components-library/Styling-TextInput';
import Logo from '../assets/LogoElephantsLanding.svg';
import IntakeLogo from '../assets/colors_icon_intake_logo.svg'
import IntakeOverlay from '../assets/LandingIntakeOverlay.svg'
import SurveyLogo from '../assets/flat-color-icons_survey.svg'
import SurveyOverlay from '../assets/LandingSurveyOverlay.svg'
import ChatbotLogo from '../assets/ChatbotLogo.svg'
import ChatbotOverlay from '../assets/LandingChatbotOverlay.svg'
import DashboardLogo from '../assets/carbon_dashboard.svg'
import DashboardOverlay from "../assets/LandingDashboardOverlay.svg"
import {ReactComponent as Logout} from "../assets/dashboard/logout.svg";
import i18next from "i18next";
import { OverlayContext } from '../layout-and-styling/context-hooks/OverlayContext';
import {useTranslation} from 'react-i18next';
import {Accordion, AccordionDetails, AccordionSummary, Switch} from '@mui/material';
import {Select, MenuItem, FormControl, InputLabel} from "@mui/material";
import ComponentLanguageSwitcher from "../layout-and-styling/standardized-components-library/Component-language-switcher";
import {Checkbox} from '@mui/material';


const CurrentUserData = JSON.parse(localStorage.getItem('current_user_data'));
const IntakeData = JSON.parse(localStorage.getItem('intakeData'));

// This file contains the home page where the user can choose if he/she wants to do the survey, talk with Elly or see the dashboard
const Home = () => {
  const {t} = useTranslation();
  // the demo mode randomizes the names of the employees in the social network, so you will see the social network of the company and survey you selected but the names will be random
  const currentUser = JSON.parse(localStorage.getItem("current_user_data"))[0];
  const [currentLanguage, setCurrentLanguage] = useState('en-US');
  const [isOpen, setIsOpen] = useState(false);
  const demoMode = JSON.parse(localStorage.getItem('demo_mode'));
  const context = useContext(OverlayContext);
  const [companies_data, setCompaniesData] = useState(JSON.parse(localStorage.getItem("companies_data")) ? JSON.parse(localStorage.getItem("companies_data")) : []);
  const [expanded, setExpanded] = useState(false);
  const [render, setRender] = useState(false);
  // Availability states
  const [allowSurvey, setAllowSurvey] = useState(false);
  const [allowChatbot, setAllowChatbot] = useState(false);
  const [allowDashboard, setAllowDashboard] = useState(false);

  const reRender = () => {
      setRender(!render)
  };

  // initial selected company and survey (not important because they will be reset after)
  const [selectedCompany, setSelectedCompany] = useState(CurrentUserData[0].is_developer ? companies_data.filter((comp) => comp.id === CurrentUserData[0].company)[0] : CurrentUserData[0].company);
  const [selectedSurvey, setSelectedSurvey] = useState(CurrentUserData[0].is_developer ? selectedCompany.current_survey : 0);

  /** Check if already authenticated, and get the availability for the different sections.*/
  useEffect(() => {
      const axiosInstance = axios.create({withCredentials: true})
      // Perform GET request to get a CSRFToken from the API and store it in sessionStorage
      axiosInstance.get(process.env.REACT_APP_APIURL + '/api/auth/validatesession/')
          .catch((error) => {
              console.log('error, you are not logged in yet!')
              window.location.replace(process.env.REACT_APP_APPURL + '/login');
          })

      // if the user is a developer the availability depends on the access the of the submodules he has
      if (CurrentUserData[0].is_developer) {
          setCompaniesData(JSON.parse(localStorage.getItem("companies_data")))
          try {
              // trying to import the api calls of the social network survey to check if the use has access to the submodule
              const surveyAPIcalls = require('../survey-social-network/Survey-social-network-routes')
              setAllowSurvey(true);
            } catch (error) {
              // Not available
              setAllowSurvey(false);
          }
          try {
            // trying to import the api calls of the text processing (chatbot) to check if the use has access to the submodule
            const chatbotAPIcalls = require('../survey-text-processing/Survey-text-processing-routes')
            setAllowChatbot(true);
          } catch (error) {
            // Not available
            setAllowChatbot(false);
          }
        setAllowDashboard(true);
      }
      // if the user is a normal user the availability depends on the parameter stored in the survey module in the database
      else{
        // these local storage values are stored in the login
        setAllowSurvey(JSON.parse(localStorage.getItem('allow_survey')));
        setAllowChatbot(JSON.parse(localStorage.getItem('allow_chatbot')));
        setAllowDashboard(JSON.parse(localStorage.getItem('allow_dashboard')));
      }
  }, []);

  const goToIntake = () => {
    // method that replaces the url to start the intake
    window.location.replace(process.env.REACT_APP_APPURL + '/intake-builder')
  }
  
  const goToSurvey = () => {
    // method that replaces the url to start social network survey
    window.location.replace(process.env.REACT_APP_APPURL + '/user-welcome')
  }

  const goToChatbot = () => {
    // method that replaces the url to start chatbot
    window.location.replace(process.env.REACT_APP_APPURL + '/survey-rootcause-chatbot')
  }

  const goToDashboard = () => {
    // method that replaces the url to go to the dashboard
    window.location.replace(process.env.REACT_APP_APPURL + "/dashboard");
  }

  const toggleDemoMode = (value) => {
    // the demo mode randomizes the names of the employees in the social network, so you will see the social network of the company and survey you selected but the names will be random
    localStorage.setItem('demo_mode', JSON.stringify(value));
    reRender();
  }

  const handleAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }

  const handleSelectedCompany = (e) => {
    if(e.target.value == "None"){
      setSelectedCompany(companies_data.filter((comp) => comp.id === CurrentUserData[0].company)[0])
      setSelectedSurvey(companies_data.filter((comp) => comp.id === CurrentUserData[0].company)[0].current_survey)
    } else {
      setSelectedCompany(e.target.value)
      setSelectedSurvey(e.target.value.current_survey)
    }
    localStorage.setItem('selected_company', JSON.stringify(e.target.value))
    localStorage.setItem('selected_survey', JSON.stringify(e.target.value.current_survey))
  }

  const handleSelectedSurvey = (e) => {
    setSelectedSurvey(e.target.value)
    localStorage.setItem('selected_survey', JSON.stringify(e.target.value))
  }

  function profilePicture(){
    return (
      <StyledDivRegular Position="absolute" BorderRadius="50%" Width="36px" Height="36px" Background="#D0E1F1"
          Color="#271B36" Display="flex" AlignItems="center" JustifyContent="center"
      >
          {context.setFirstName(currentUser.first_name, 0).charAt(0)
              + context.setFirstName(currentUser.last_name, 0).split(" ").filter((str) => str !== '').pop().charAt(0)}
      </StyledDivRegular>
    )
  }

  return(
      <Container>
        
        <LogoWrapper>
            <img src={Logo} alt=""/>
        </LogoWrapper>
        <CheckboxWrapper Width="fit-content">

            <ComponentLanguageSwitcher MarginLeft="0%" MarginRight="24px"
                                        set_language_switcher={setCurrentLanguage}/>

            <Checkbox style={{fontSize: 35}}
                        icon={profilePicture()}
                        checkedIcon={profilePicture()}
                        onClick={() => setIsOpen(!isOpen)}/>

            {isOpen && 
            (<DropdownContent>
                <button onClick={() => window.location.replace(process.env.REACT_APP_APPURL + '/logout')}>

                    <Logout/>
                    <p>
                        {t('Logout.LogoutButton')}
                    </p>

                </button>
            </DropdownContent>)}

        </CheckboxWrapper>
        
        <NavigationWrapper>
          <TitleWrapper>
              <Title> {t('LandingPage.Title')}, {context.setFirstName(CurrentUserData[0]['first_name'], i18next.language)}! </Title>

              <Subtitle>{t('LandingPage.Subtitle')}</Subtitle>

          </TitleWrapper>

          {/* Intake section */}
          {IntakeData != null &&
          <Section Background={IntakeOverlay} onClick={() => goToIntake()}>
            <SectionContent>
              <img src={IntakeLogo} alt=""></img>
              <SectionText>
                <SectionTitle>{t('LandingPage.Intake')}</SectionTitle>
                <SectionDescription>
                  {t('LandingPage.IntakeDescription')}
                </SectionDescription>
              </SectionText>
            </SectionContent>
          </Section>}
          
          {/* Survey section */}
          <Section Background={SurveyOverlay} onClick={() => goToSurvey()} changeOpacity={allowSurvey} changeEvent={allowSurvey}>
              <SectionContent>
                  <img src={SurveyLogo} alt=""/>

                  <SectionText>
                      <SectionTitle>{t('LandingPage.Survey')} </SectionTitle>
                      <SectionDescription>
                          {t('LandingPage.SurveyDescription')}
                      </SectionDescription>
                  </SectionText>
              </SectionContent>
          </Section>
          
          {/* Chatbot section */}
          {/* Uncomment this part only if you use the front-end with the chatbot-v2 branch in the backend (for now) */}
          <Section Background={ChatbotOverlay} onClick={() => goToChatbot()} changeOpacity={allowChatbot} changeEvent={allowChatbot}>
              <SectionContent>
                  <img src={ChatbotLogo} alt=""/>

                  <SectionText>
                      <SectionTitle>{t('LandingPage.Chatbot')} </SectionTitle>
                      <SectionDescription>
                        {t('LandingPage.ChatbotDescription')}
                      </SectionDescription>
                  </SectionText>
              </SectionContent>
          </Section>
          
          {/* Dashboard section */}
          {/* developer user */}
          {CurrentUserData[0].is_developer ? (
            <Accordion expanded={expanded === 'dashboard'} onChange={handleAccordion('dashboard')}
              sx={{ width: "fit-content", justifyContent: 'center'}}>
              <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header"
                  sx={{ color: '#292929', fontSize: 16, fontFamily: 'Overpass', fontStyle: 'normal', fontWeight: 700,
                      '& .MuiAccordionSummary-content': {
                          justifyContent: 'center',
                      }
                  }}>
                  
                  <Section Background={DashboardOverlay} changeOpacity={allowDashboard} changeEvent={allowDashboard}>
                    <SectionContent>
                        <img src={DashboardLogo} alt=""/>

                        <SectionText>
                            <SectionTitle> {t('LandingPage.Dashboard')}
                            </SectionTitle>

                            <SectionDescription>
                                {t('LandingPage.DashboardDescription')}
                            </SectionDescription>
                        </SectionText>
                    </SectionContent>
                  </Section>
              </AccordionSummary>

              <AccordionDetails sx={{display: 'flex', flexDirection: 'row', height: 'fit-content'}} >
                  <DeveloperViewDashboard>
                    <ToggleDemoMode>
                        <TextToggle>Demo mode:</TextToggle>
                        <Switch checked={demoMode} onChange={(event, value) => toggleDemoMode(value)}/>
                    </ToggleDemoMode>

                    <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-filled-label">Company</InputLabel>
                        <Select value={selectedCompany} label="Company" onChange={handleSelectedCompany} >
                            {companies_data && companies_data.map((company, company_index) => {
                                if(company.id === 4) {
                                  return (
                                    <MenuItem value={company}>{!demoMode ? company.name : "Venice"}</MenuItem>
                                  )
                                } else if(company.id === 6) {
                                  return (
                                    <MenuItem value={company}>{!demoMode ? company.name : "Avocado"}</MenuItem>
                                  )
                                } else if(company.id === 3) {
                                  return (
                                    <MenuItem value={company}>{company.name}</MenuItem>
                                  )
                                }
                            })}
                        </Select>
                    </FormControl>

                    <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-filled-label">Survey</InputLabel>
                        <Select value={selectedSurvey} label="Survey" onChange={handleSelectedSurvey}  >
                          {selectedCompany.survey_set && selectedCompany.survey_set.map((survey, company_index) => {
                                if(survey === 1) { // EITR survey 1
                                  return (
                                    <MenuItem value={survey}>1</MenuItem>
                                  )
                                } else if(survey === 9) { // Venice survey 0
                                  return (
                                    <MenuItem value={survey}>0</MenuItem>
                                  )
                                } else if(survey === 2) { // Venice survey 1
                                  return (
                                    <MenuItem value={survey}>1</MenuItem>
                                  )
                                } else if(survey === 3) { // Avocado survey 1
                                  return (
                                    <MenuItem value={survey}>1</MenuItem>
                                  )
                                } else if(survey === 5) { // Avocado survey 2
                                  return (
                                    <MenuItem value={survey}>2</MenuItem>
                                  )
                                } else if(survey === 6) { // EITR survey 2
                                  return (
                                    <MenuItem value={survey}>2</MenuItem>
                                  )
                                } else if(survey === 8) { // Avocado survey 3
                                  return (
                                    <MenuItem value={survey}>3</MenuItem>
                                  )
                                }
                          })}
                        </Select>
                    </FormControl>

                    <StyledDivButton MarginTop='0px' MarginLeft='32px' Width='64px' Height='fit-content' BoxShadow='0px 2px 4px rgba(0, 0, 0, 0.25)' 
                                          Padding='8px 4px 8px 4px' FontSize='16px' Display='flex' JustifyContent='center' Position="relative" BackgroundColor={'#FCFCFC'}
                                          Border={'0px solid #271B36'} Color={'#271B36'}>
                          <button onClick={() => goToDashboard()} > Go </button>
                    </StyledDivButton>
                  </DeveloperViewDashboard>
              </AccordionDetails>
            </Accordion>
          // normal user 
          ) : (
            <Section Background={DashboardOverlay} onClick={() => goToDashboard()} changeOpacity={allowDashboard} changeEvent={allowDashboard}>
                <SectionContent>
                    <img src={DashboardLogo} alt=""/>

                    <SectionText>
                        <SectionTitle> {t('LandingPage.Dashboard')}
                        </SectionTitle>

                        <SectionDescription>
                            {t('LandingPage.DashboardDescription')}
                        </SectionDescription>

                    </SectionText>
                </SectionContent>

            </Section>
          )}

        </NavigationWrapper>

      </Container>
  )
};
export default Home

const Container = styled.div`
  background: #FCFCFC;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: calc(1 / 1.25);
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: calc(1 / 1.5);
  }
  @media (-webkit-device-pixel-ratio: 1.75) {
    zoom: calc(1 / 1.75);
  }
`;

const LogoWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 36px;
  margin-left: 46px;
`;

const CheckboxWrapper = styled.div`
  position: absolute;
  top: 54px;
  right: 54px;
  cursor: pointer;
  margin-top: ${props => props.MarginTop || "0%"};
  margin-left: ${props => props.MarginLeft || "0%"};
  width: ${props => props.Width || "24px"};
  height: ${props => props.Height || "24px"};
  color: ${props => props.inputColor || 'transparent'};
  background-color: #fcfcfc;
  border-color: ${props => props.BorderColor || 'transparent'}; //1px solid #E3E5E4 
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
`;

const DropdownContent = styled.div`
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  top: 45px;
  margin-right: 112px;

  button {
    padding-left: 10px;
    height: 48px;
    border: none;
    background: #f9f9f9;
    color: ${props => props.Color || '#5F6366'};
    border-radius: 10px;
    cursor: pointer;
    font-family: ${props => props.FontFamily || 'Overpass'};
    font-size: ${props => props.FontSize || '16px'};
    line-height: ${props => props.LineHeight || '20px'};
    font-weight: ${props => props.FontWeight || '400'};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: -webkit-fill-available;
    column-gap: 15px;
  }
`;

const TitleWrapper = styled.div`
  justify-content: left;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  justify-content: left;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 52px;
  margin-bottom: 40px;
`;

const Subtitle = styled.div`
  justify-content: center;
  display: flex;
  font-family: 'Overpass', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #271B36;
  margin-bottom: 40px;

`;

const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const Section = styled.div`
  width: 720px;
  height: 106px;
  left: 679px;
  top: 408px;
  background: #FCFCFC;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-bottom: 32px;
  background: url(${props => props.Background || ""}) no-repeat right bottom;
  cursor: pointer;
  opacity: ${props => props.changeOpacity ? "1" : "0.2"};
  pointer-events: ${props => props.changeEvent ? "auto" : "none"};
`;

const SectionContent = styled.div`
  margin: 20px;
  display: flex;
  gap: 24px;
`;

const SectionText = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionTitle = styled.div`
  font-family: 'Overpass', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
`;

const SectionDescription = styled.div`
  font-family: 'Overpass', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
`;

const DeveloperViewDashboard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: fit-content;
`;

const ToggleDemoMode = styled.div`
  margin: 0 40px;
  align-content: center;
  font-family: 'Overpass', sans-serif;
  justify-content: center;
`;

const TextToggle = styled.div`
  font-size: 16px;
`;
