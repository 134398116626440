import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Component} from 'react';
import reportWebVitals from './reportWebVitals';
import {createRoot} from 'react-dom/client';
import './i18n';

// Context
import {OverlayContextProvider} from "./layout-and-styling/context-hooks/OverlayContext";

/** Login and Auth pages */
import Login from './authentication-and-home/Login'; 
import Logout from './authentication-and-home/Logout'; 
import Home from "./authentication-and-home/Home";
/** Intake */
import PageIntakeBuilder from './intake/intake-builder/Intake_Builder';
import PageProcessTime from './intake/PageProcessTime';
import IntakeRepresentativeChoice from './intake/PageIntakeRepresentative';
/** Error page */
import ErrorPage from './layout-and-styling/common-pages/ErrorPage';
/** Survey completed */
import PageSurveyCompleted from './layout-and-styling/common-pages/Page-Survey-Completed';

/** Dashboard pages */
import {DashboardContextProvider} from "./dashboard/DashboardContext";
import PageDashboard from "./dashboard/Dashboard";

// Survey social network
let SurveySocialNetworkRoutes;
try {
    // trying to reach the file with the routes
    let page = require('./survey-social-network/Survey-social-network-routes')
    SurveySocialNetworkRoutes = page.default
} catch (error) {
    // otherwise we display the home page
    SurveySocialNetworkRoutes = null
}

// Survey social network
let SurveyTextProcessingRoutes;
try {
    // trying to reach the file with the routes
    let page = require('./survey-text-processing/Survey-text-processing-routes')
    SurveyTextProcessingRoutes = page.default
} catch (error) {
    // otherwise we display the home page
    SurveyTextProcessingRoutes = null
}

// Error handling (displays error page in case a runtime error happens)
// window.addEventListener("error", function (e) {
//     this.window.location.replace(process.env.REACT_APP_APPURL + "/error-page")
//   })

class App extends Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route exact path="/" component={Login}/>
                    <Route exact path="/login" component={Login}/>
                    <Route exact path='/logout' component={Logout}/>
 
                    <OverlayContextProvider>
                        {/* Home */}
                        <Route exact path="/home" component={Home}/>

                        {/* Intake */}
                        <Route exact path="/intake-builder" component={PageIntakeBuilder}/>
                        <Route exact path="/intake-representative-choice" component={IntakeRepresentativeChoice}/>
                        <Route exact path="/intake-process-time" component={PageProcessTime}/>

                        {/* Social Network Survey */}
                        { SurveySocialNetworkRoutes != null ?
                            <SurveySocialNetworkRoutes/>
                        : ""
                        }

                        {/* Text Processing Survey */}
                        { SurveyTextProcessingRoutes != null ? 
                            <SurveyTextProcessingRoutes/>
                        : ""
                        }

                        {/* Survey Completed */}
                        <Route exact path="/survey-completed" component={PageSurveyCompleted}/>

                        {/* Dashboard */}
                        <DashboardContextProvider>
                            <Route exact path="/dashboard" component={PageDashboard}/>
                        </DashboardContextProvider>

                        {/* Error page */}
                        <Route exact path="/error-page" component={ErrorPage}/>

                    </OverlayContextProvider>

                </Switch>
            </Router>
        );
    }
};

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App/>);

reportWebVitals();
