import {useContext, useEffect, useRef, useState, useLayoutEffect} from "react";
import D3Class from "./D3Class";
import styled,{css} from "styled-components";
import { OverlayContext } from "../../../layout-and-styling/context-hooks/OverlayContext";
import { ProblemNetworkVis } from "../ProblemNetwork";


function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

export default function RenderComponent(props) {
    const {height, width} = useWindowDimensions();
    const language = props.language;
    /** React js hook usestate for tracking data changes */

    /** The useRef Hook creates a variable that "holds on" to a value across rendering passes.
     * In this case it will hold our component's SVG DOM element.
     * It's initialized null and React will assign it later (see the return statement) */
    const Ref = useRef();
    const graphRef = useRef();
    let context = useContext(OverlayContext);

    // Function to center containig box of the social network in the center
    function centerVis(containingBoxX, containingBoxY, medianX, medianY) {
        Ref.current.scrollLeft = ( containingBoxX - Ref.current.offsetWidth ) / 2 + (( containingBoxX - Ref.current.offsetWidth ) / containingBoxX) * medianX;
        Ref.current.scrollTop = ( containingBoxY - Ref.current.offsetHeight ) / 2 + (( containingBoxY - Ref.current.offsetHeight ) / containingBoxY) * medianY;
    }

    /** It adds content to the DOM outside of React’s virtual DOM mechanism */
    useEffect(() => {
        let demo_mode = JSON.parse(localStorage.getItem('demo_mode'));
        let data = props.graphData
        let selected_survey = data.survey
        let zoom = props.Zoom

        if (data) {
            const d3Props = {
                data,
                width,
                height,
                zoom,
                context,
                demo_mode,
                language,
                selected_survey,
            };

            ProblemNetworkVis[0] = new D3Class(graphRef.current, d3Props)
            props.setZoom(width / ProblemNetworkVis[0].containingBoxX)
            props.setGraphWidth(ProblemNetworkVis[0].containingBoxX)
            props.setGraphHeight(ProblemNetworkVis[0].containingBoxY)
            centerVis(ProblemNetworkVis[0].containingBoxX, ProblemNetworkVis[0].containingBoxY, ProblemNetworkVis[0].medianX, ProblemNetworkVis[0].medianY)
            
        }
    }, [props.graphData]);
    
    useEffect(() => {
        if (!Ref.current) return;
        let isDown = false;
        let startX;
        let startY;
        let scrollLeft;
        let scrollTop;

        Ref.current.addEventListener("mousedown", (e) => {
            isDown = true;
            startX = e.pageX - Ref.current.offsetLeft;
            startY = e.pageY - Ref.current.offsetTop;
            scrollLeft = Ref.current.scrollLeft;
            scrollTop = Ref.current.scrollTop;
        });
        Ref.current.addEventListener("mouseleave", () => {
            isDown = false;
        });
        Ref.current.addEventListener("mouseup", () => {
            isDown = false;
        });
        Ref.current.addEventListener("mousemove", (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - Ref.current.offsetLeft;
            const y = e.pageY - Ref.current.offsetTop;
            const walk = x - startX; // scroll-fast
            const walk2 = y - startY; // scroll-fast
            Ref.current.scrollLeft = scrollLeft - walk;
            Ref.current.scrollTop = scrollTop - walk2;
        });
    }, [Ref]);

    const onScroll = (e) => {
        const delta = e.deltaY * -0.001;
        const newZoom = props.Zoom + delta;
        if(newZoom > 0 && newZoom < 2) props.setZoom(newZoom)
    };


    return (
        <Container Width={"100%"} Height={"100%"} ref={Ref} onWheel={onScroll} id="container" Left={props.Left} Position={"relative"}>
            <Graph Width={props.graphWidth+"px"} Height={props.graphHeight+"px"} ref={graphRef} id="Graph" className="flex-child graph" Transform={`scale(${props.Zoom})`}/>
        </Container>
        
    )
}

const Container = styled.div`
  position: ${props => props.Position || "fixed"};
  overflow: hidden;
  width: ${props => props.Width || "100%"};
  height: ${props => props.Height || "100%"};
  top: 0;
  left:  ${props => props.Left || 0};
  cursor: grab;
`;

const Graph = styled.div`
  position: relative;
  overflow: hidden;
  width: ${props => props.Width || "100%"};
  height: ${props => props.Height || "100%"};
  top: 0;
  left: 0;
  cursor: grab;
  transform: ${props => props.Transform || "scale(1.0)"};

`;
