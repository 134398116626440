import {Route} from 'react-router-dom';

// Route paths for the survey of the social network

/** User Introduction */
import PageWelcome from './introduction/Survey_Welcome';
import PageBenefits from './introduction/Survey_Benefits';
import PageInstructions from './introduction/Survey_Instructions';

/** Survey pages */
import PageCloseColleagueFilter from './close-colleagues/PageCloseColleagueFilter';
import PagePlannedMeetingsQuestion from './planned-meetings/PagePlannedMeetingsQuestion';
import PagePlannedMeetings from './planned-meetings/PagePlannedMeetings';
import PageSpontaneousCollaboration from './spontaneous-interaction/PageSpontaneousCollaboration';
import PageCollaborationMeasurement from './collaboration-quality/PageCollaborationMeasurement';
import PageProactiveness from './proactiveness/PageProactiveness';

const SurveySocialNetworkRoutes = () => {
    return (
        <>

            {/* Introduction */}
            <Route exact path="/user-welcome" component={PageWelcome}/>
            <Route exact path="/user-benefits" component={PageBenefits}/>
            <Route exact path="/user-instructions" component={PageInstructions}/>

            {/* Social Network Survey */}
            <Route exact path="/survey-close-colleagues" component={PageCloseColleagueFilter}/>
            <Route exact path="/survey-planned-meetings-question" component={PagePlannedMeetingsQuestion}/>
            <Route exact path="/survey-planned-meetings" component={PagePlannedMeetings}/>
            <Route exact path="/survey-spontaneous-collaboration" component={PageSpontaneousCollaboration}/>
            <Route exact path="/survey-collaboration-measurement" component={PageCollaborationMeasurement}/>
            <Route exact path="/survey-proactiveness" component={PageProactiveness}/>

        </>
    );
};

export default SurveySocialNetworkRoutes;
