import { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as DashboardLogo } from "../assets/dashboard/EITR_Logo.svg";
import { ReactComponent as DashboardLogoExpanded } from "../assets/dashboard/EITR_Logo_Expanded.svg";
import { ReactComponent as ElephantIdentificationIcon } from "../assets/dashboard/Sidebar/ElephantIdentificationIcon.svg";
import { ReactComponent as ElephantIdentificationIconClicked } from "../assets/dashboard/Sidebar/ElephantIdentificationIconClicked.svg";
import { ReactComponent as DiagnosticsIcon } from "../assets/dashboard/Sidebar/DiagnosticsIcon.svg";
import { ReactComponent as DiagnosticsIconClicked } from "../assets/dashboard/Sidebar/DiagnosticsIconClicked.svg";
import { ReactComponent as SeparationLineShort } from "../assets/dashboard/SeparationLineShort.svg";
import { ReactComponent as SeparationLineShortExpanded } from "../assets/dashboard/SeparationLineShortExpanded.svg";
import { ReactComponent as HelpIcon } from "../assets/dashboard/HelpIcon.svg";
import { ReactComponent as FlagCh } from "../assets/translation/flag-ch.svg";
import { ReactComponent as FlagNl } from "../assets/translation/flag-nl.svg";
import { ReactComponent as FlagEn } from "../assets/translation/flag-en.svg";
import { ReactComponent as SeparationLineLong } from "../assets/dashboard/SeparationLineLong.svg";
import { ReactComponent as SeparationLineLongExpanded } from "../assets/dashboard/SeparationLineLongExpanded.svg";
import { ReactComponent as Logout } from "../assets/dashboard/logout.svg";
import presentationThumbIconOn from "../assets/dashboard/PresentationModeThumbOn.png";
import presentationThumbIconOff from "../assets/dashboard/PresentationModeThumbOff.png";

import ComponentLanguageSwitcher from "../layout-and-styling/standardized-components-library/Component-language-switcher";

import { DashboardContext } from "./DashboardContext";
import { OverlayContext } from "../layout-and-styling/context-hooks/OverlayContext";
import { useTranslation } from "react-i18next";

import { StyledDivRegular } from "../layout-and-styling/standardized-components-library/Styling-TextInput";
import { Switch } from "@mui/material";

// This page contains the sidebar of the dashboard, where you can click at the different tabs and navigate through the dashboard
const SideBar = () => {
  const dashboard_context_value = useContext(DashboardContext);
  const currentUser = JSON.parse(localStorage.getItem("current_user_data"))[0];
  const context = useContext(OverlayContext);
  // State to control if the sidebar is expanded, this happens when the user hovers the sidebar
  const [sidebarExpanded, setSidebarExpanded] = useState(false);

  const [render, setRender] = useState(true);
  const reRender = () => {
    setRender(!render);
  };

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (
      !i18n.language ||
      (i18n.language != "en-US" &&
        i18n.language != "ch-CH" &&
        i18n.language != "nl-NL")
    ) {
      i18n.changeLanguage("en-US");
    }
  }, []);

  function getFlag(flagCode) {
    if (flagCode === "ch") return <FlagCh />;
    else if (flagCode === "nl") return <FlagNl />;
    else return <FlagEn />;
  }

  /**
   * Updates the tab when the developer toggle button is pressed 
   */
  function handleSwitch(){
    dashboard_context_value.setDeveloperMode(!dashboard_context_value.developerMode);
    // the conditions should be the opposite (TO CHECK)
    if(!dashboard_context_value.developerMode){
      dashboard_context_value.setSelectedTab("TabProblemNetwork");
      dashboard_context_value.setSelectedSubTab("SubTabNetworkFeedback");
    }
    else{
      dashboard_context_value.setSelectedTab("TabProblemDeepDive");
    }
  }

  return (
    <SidebarContainer onMouseEnter={() => setSidebarExpanded(true)} onMouseLeave={() => setSidebarExpanded(false)} Width={sidebarExpanded ? "232px" : "88px"}>
      {/* sidebar tab buttons */}
      <TopWrapper>
        {/* Logo */}
        <HeaderLogoWrapper
          onClick={() =>
            window.location.replace(process.env.REACT_APP_APPURL + "/home")
          }
        >
          {sidebarExpanded ? <DashboardLogoExpanded /> : <DashboardLogo />}
        </HeaderLogoWrapper>

        {/* Normal user */}
        {!dashboard_context_value.developerMode ? (
          <ButtonsWrapper>
            {/* Problem - Root cause Deep Dive tab button*/}
            <SidebarButton Width={sidebarExpanded ? "2000px" : "44px"} Background={ dashboard_context_value.selectedTab === "TabProblemDeepDive" ? "#F7C9D9" : "transparent"}
              Color={ dashboard_context_value.selectedTab === "TabProblemDeepDive" ? "#B3194A" : "#5F6366" } MarginTop="0px">
              <button
                onClick={() => {
                  dashboard_context_value.setSelectedTab("TabProblemDeepDive");
                  setSidebarExpanded(false);
                }}
              >
                {dashboard_context_value.selectedTab === "TabProblemDeepDive" ? (
                    <ElephantIdentificationIconClicked />
                ) : (
                    <ElephantIdentificationIcon MarginBottom="10px"/>
                )}
                <SidebarButtonText Display={sidebarExpanded === false ? "none" : ""} Color={dashboard_context_value.selectedTab === "TabProblemDeepDive" ? "#B3194A" : "#5F6366"} FlexDirection={"row"} Width="fit-content" Height="fit-content">
                  Deep dive
                </SidebarButtonText>
              </button>
            </SidebarButton> 
            
            {/* Social Network tab button */}
            <SidebarButton Width={sidebarExpanded ? "2000px" : "44px"} Background={ dashboard_context_value.selectedTab === "TabSocialNetwork" ? "#F7C9D9" : "transparent"}
              Color={ dashboard_context_value.selectedTab === "TabSocialNetwork" ? "#B3194A" : "#5F6366" } MarginTop="0px">
              <button
                onClick={() => {
                  dashboard_context_value.setSelectedTab("TabSocialNetwork");
                  setSidebarExpanded(true);
                }}
              >
                {dashboard_context_value.selectedTab === "TabSocialNetwork" ? (
                    <DiagnosticsIconClicked />
                ) : (
                    <DiagnosticsIcon MarginBottom="10px"/>
                )}
                <SidebarButtonText Display={sidebarExpanded === false ? "none" : ""} Color={dashboard_context_value.selectedTab === "TabSocialNetwork" ? "#B3194A" : "#5F6366"} FlexDirection={"row"} Width="fit-content" Height="fit-content">
                  Social Network
                </SidebarButtonText>
              </button>
            </SidebarButton> 
            
          </ButtonsWrapper>

        ) : (
          // developer user
          <ButtonsWrapper>

            {/* Problems Network tab button */}
            <SideBarPresentationButtonWrapper 
              onClick={() => {
                dashboard_context_value.setSelectedTab("TabProblemNetwork");
              }}>
              <SideBarPresentationButton BackgroundColor={dashboard_context_value.selectedTab === "TabProblemNetwork" ? "#F7C9D9" : "#FCFCFC"}>
                <SideBarPresentationButtonBall BackgroundColor={dashboard_context_value.selectedTab === "TabProblemNetwork" ? "#E2336B" : "#271B36"}>
                  1</SideBarPresentationButtonBall>
                <SideBarPresentationText Display={sidebarExpanded === false ? "none": ""}>Problem Network</SideBarPresentationText>
              </SideBarPresentationButton>
            </SideBarPresentationButtonWrapper>

            {/* Social Network team Feedback tab button */}
            <SideBarPresentationButtonWrapper 
              onClick={() => {
                dashboard_context_value.setSelectedTab("TabSocialNetworkFeedback");
                dashboard_context_value.setSelectedSubTab("SubTabNetworkFeedback");
              }}>
              <SideBarPresentationButton BackgroundColor={dashboard_context_value.selectedTab === "TabSocialNetworkFeedback" ? "#F7C9D9" : "#FCFCFC"}>
                <SideBarPresentationButtonBall BackgroundColor={dashboard_context_value.selectedTab === "TabSocialNetworkFeedback" ? "#E2336B" : "#271B36"}>
                  2</SideBarPresentationButtonBall>
                <SideBarPresentationText Display={sidebarExpanded === false ? "none": ""}>Teams Feedback</SideBarPresentationText>
              </SideBarPresentationButton>
            </SideBarPresentationButtonWrapper>

          </ButtonsWrapper>
        )}
        
      </TopWrapper>
      
      {/* language and help buttons */}
      <BottomWrapper MarginTop={(sidebarExpanded && dashboard_context_value.selectedTab === "TabRootCauseDiagnostics") ? "272px" : "350px"}>

        {/* developer toggle button */}
        {currentUser.is_developer ? (
          <PresentationSwitch>
            <Switch disableRipple onChange={handleSwitch} sx={{
              color: '#F7C9D9', height: '38px', padding: '3px', 
              '& .MuiSwitch-switchBase': {
                padding: 1, transitionDuration: '300ms',
              },
              '& .MuiSwitch-thumb': {
                width: '24px', height: '24px', backgroundSize: '24px', color: '#F7C9D9',
                backgroundImage: dashboard_context_value.developerMode ? "url(" + presentationThumbIconOn + ")": "url(" + presentationThumbIconOff + ")", 
              },
              '& .MuiSwitch-track': {
                backgroundColor: '#F1F3F4', borderRadius: 13,
                '&:before, &:after': {
                  transform: 'translateX(40)',
                }
              },
              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
              backgroundColor: '#F7C9D9'
              },
            }}/>
            {sidebarExpanded && 
              <PresentationText>
                Analysis
              </PresentationText>}
          </PresentationSwitch>
        ) : (
          ""
        )}
        
        {sidebarExpanded ? ( <SeparationLineShortExpanded /> ) : ( <SeparationLineShort /> )}

        {/* help button */}
        <SidebarButton Width={sidebarExpanded ? "220px" : "44px"} MarginTop={"4px"} Bottom={"0"} Background={"transparent"} Color={"#5F6366"} >
          <button>
            <HelpIcon />

            <SidebarButtonText Color={"#5F6366"} Display={sidebarExpanded === false ? "none" : ""} >
              {t('Component-header-block-need-help')}
            </SidebarButtonText>
          </button>
        </SidebarButton>

        {/* language button */}
        <SidebarButton Width={"fit-content"} MarginTop={"12px"} MarginLeft={"12px"} MarginBottom={"28px"} Bottom={"0"} Background={"transparent"} Color={"#5F6366"} >
          <FlagWrapper Display={sidebarExpanded ? "none" : "flex"}>
            {" "}
            {getFlag(i18n.language.split("-")[0])}
          </FlagWrapper>

          <SidebarButtonText Display={sidebarExpanded ? "flex" : "none"} MarginTop={"4px"} MarginLeft={"-74px"}>
            <ComponentLanguageSwitcher Display={sidebarExpanded ? "flex" : "none"} reRender={reRender} Position={"relative"}/>
          </SidebarButtonText>
        </SidebarButton>

        {sidebarExpanded ? <SeparationLineLongExpanded /> : <SeparationLineLong />}

          {/* profile button */}
         <SidebarButton Width={sidebarExpanded ? "220px" : "44px"} Position={"relative"} MarginTop={sidebarExpanded ? "4px" : "4px"} MarginLeft={sidebarExpanded ? "23px" : "20px"} MarginBottom={"12px"} Background={"transparent"} Color={"#5F6366"} >
           <CheckboxWrapper MarginBottom="0px" MarginLeft="0px" MarginRight="0px" MarginTop="0px" Height="50px" Width="50px" >
             <PersonPic>
               <StyledDivRegular Position="absolute" BorderRadius="50%" Width="36px" Height="36px" Background="#D0E1F1" Color="#271B36" Display="flex" AlignItems="center" JustifyContent="center" TextIndent="0" >
                 {context.setFirstName(currentUser.first_name, 0).charAt(0) +
                   context
                     .setFirstName(currentUser.last_name, 0)
                     .split(" ")
                     .filter((str) => str !== "")
                     .pop()
                     .charAt(0)}
               </StyledDivRegular>
             </PersonPic>
           </CheckboxWrapper>
           <SidebarButtonText Color={"#5F6366"} Display={sidebarExpanded ? "flex" : "none"} MarginTop={"12px"} MarginLeft={"12px"} >
             {context.setFirstName(currentUser.first_name, 0) +
               " " +
               context
                 .setFirstName(currentUser.last_name, 0)
                 .split(" ")
                 .filter((str) => str !== "")
                 .pop()}
           </SidebarButtonText>
           <SidebarLogOutButton Color={"#5F6366"} Display={sidebarExpanded ? "flex" : "none"} MarginTop={"12px"} >
             <button
               onClick={() =>
                 window.location.replace(process.env.REACT_APP_APPURL + "/logout")
               }
             >
               <Logout />
             </button>
           </SidebarLogOutButton>
         </SidebarButton>
      </BottomWrapper>
    </SidebarContainer>
  );
};
export default SideBar;

export const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  margin-top: ${(props) => props.MarginTop || "0px"};
  margin-left: ${(props) => props.MarginLeft || "0%"};
  width: ${(props) => props.Width || ""};
  height: ${(props) => props.Height || "100%"};
  border: ${(props) => props.Border || "none"};
  border-radius: ${(props) => props.BorderRadius || ""};
  box-shadow: ${(props) => props.BoxShadow || "none"};
  background: ${(props) => props.inputBackground || "#FCFCFC"};
  color: ${(props) => props.inputColor || "#929699"};
  font-family: ${(props) => props.FontFamily || "Overpass"};
  font-size: ${(props) => props.FontSize || "16px"};
  line-height: ${(props) => props.LineHeight || "20px"};
  font-weight: ${(props) => props.FontWeight || "400"};
  z-index: 2;
  display: ${(props) => props.Display || "flex"};
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  transition: all 0.05s linear;
`;

const TopWrapper = styled.div`
  position: relative;
  display: "flex";
  flex-direction: column;
  text-align: center;
  width: 100%;
`;

const ButtonsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const BottomWrapper = styled.div`
  position: relative;
  display: "flex";
  flex-direction: column;
  text-align: center;
  width: 100%;
`;

const HeaderLogoWrapper = styled.div`
  position: relative;
  display: flex;
  z-index: 1;
  cursor: pointer;
  margin: 16px 0 32px 24px;
`;

const PresentationSwitch = styled.div`
position: relative;
display: flex;
flex-direction: row;
justify-content: ${(props) => props.JustifyContent || "center"};
`;

const PresentationText = styled.div`
position: relative;
display: flex;
width: 130px;
padding: 12px 0px 0px 20px;
font-family: 'Overpass';
font-size: 20px;
color: #271B36;
`;

const SideBarPresentationButtonWrapper = styled.div`
position: relative;
display: flex;
cursor: pointer;
justify-content: space-between;
margin: 0px 20px 8px 20px; 
`

const SideBarPresentationButton = styled.div`
position: relative;
display: flex;
background-color: ${(props) => props.BackgroundColor || "#F7C9D9"};
width: 100%;
height: 48px;
border-radius: 10px;
`
const SideBarPresentationButtonBall = styled.div`
position: relative;
display: flex;
width: 24px;
height: 24px;
color: #FCFCFC;
font-family: montserrat;
font-size: 16;
background-color: ${(props) => props.BackgroundColor || "#271b36"};
border-radius: 100px;
margin: 12px 12px 12px 12px;
align-items: center;
justify-content: center;
`

const SideBarPresentationText = styled.div`
position: relative;
display: ${(props) => props.Display || "flex"};
align-items: center;
margin-left: 8px;
font-family: 'Overpass';
font-size: 16px;
color: #271b36;
font-weight: bold;
`

const SidebarButton = styled.div`
  position: ${(props) => props.Position || "relative"};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: transparent;
  bottom: ${(props) => props.Bottom || ""};
  margin-top: ${(props) => props.MarginTop || "0px"};
  margin-left: ${(props) => props.MarginLeft || "0px"};
  margin-bottom: ${(props) => props.MarginBottom || "0px"};
  z-index: 1;
  width: ${(props) => props.Width || "189px"};
  button {
    padding: 0;
    margin: 0px 20px 0px 20px; // padding inside input box
    height: ${(props) => props.Background || "fit-content"};
    border: none;
    background: ${(props) => props.Background || ""};
    color: ${(props) => props.Color || "#5F6366"};
    border-radius: 10px;
    cursor: pointer;
    font-family: ${(props) => props.FontFamily || "Overpass"};
    font-size: ${(props) => props.FontSize || "16px"};
    line-height: ${(props) => props.LineHeight || "0px"};
    font-weight: ${(props) => props.FontWeight || "400"};
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const SidebarButtonText = styled.div`
  display: ${(props) => props.Display || ""};
  flex-direction: ${(props) => props.FlexDirection || ""};
  margin-left: ${(props) => props.MarginLeft || ""};
  margin-top: ${(props) => props.MarginTop || ""};
  margin-bottom: ${(props) => props.MarginBottom || ""};
  right: ${(props) => props.Right || ""};
  font-size: 16px;
  text-align: left;
  line-height: 24px;
  padding-right: 12px;
  color: ${(props) => props.Color || "#5F6366"};
  width: ${(props) => props.Width || "126px"};
  height: ${(props) => props.Height || "fit-content"};
  max-width: 126px;
  cursor: ${(props) => props.Cursor || ""};
`;

const CheckboxWrapper = styled.div`
  position: relative;
  margin-top: ${(props) => props.MarginTop || "0%"};
  margin-bottom: ${(props) => props.MarginBottom || "0%"};
  margin-left: ${(props) => props.MarginLeft || "0%"};
  margin-right: ${(props) => props.MarginRight || "0%"};
  width: ${(props) => props.Width || "24px"};
  height: ${(props) => props.Height || "24px"};
  color: ${(props) => props.inputColor || "transparent"};
  background-color: #fcfcfc;
  border-color: ${(props) =>
    props.BorderColor || "transparent"};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
`;

const PersonPic = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
`;

const SidebarLogOutButton = styled.div`
  display: ${(props) => props.Display || ""};
  flex-direction: ${(props) => props.FlexDirection || ""};
  margin-top: ${(props) => props.MarginTop || ""};
  font-size: 16px;
  text-align: left;
  color: ${(props) => props.Color || "#5F6366"};
`;

const FlagWrapper = styled.div`
  display: ${(props) => props.Display || "flex"};
  margin-left: 20px;
`;
