import axios from "axios";
import getCookieValue from "../../utils";

/** API URL, variable dependent on what branch you ar eon, dev staging, or main branches*/
// const baseURL = process.env.REACT_APP_APIURL

/**  View for the Dashboard Presentation page */
export async function TagNetworkPage(selected_company, selected_survey) {
    /** define axiosInstance for API requests */
    const axiosInstance = axios.create({withCredentials: true});

    axiosInstance
        .get(
            process.env.REACT_APP_APIURL +
            "/api/tag_network_data/" +
            selected_company + "/" +
            selected_survey
        )
        .then((res) => {
            localStorage.setItem("tag_network_data", JSON.stringify(res.data));
            window.dispatchEvent(new Event('tag_network_data_event'))
        })
        .catch((error) => {
            console.log("ERROR: Could not fetch /api/developer_dashboard_comparison/");
        });

}