import React, {useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from "react-i18next";
import { StyledDivRegular } from '../../../layout-and-styling/standardized-components-library/Styling-TextInput';

const ComponentAddDay = (props) => {
    const [hover, setHover] = useState(false);
    const {t} = useTranslation();

    return (
      <Container>

            <StyledDivRegular Position={"relative"} Width={"fit-content"} Height={"fit-content"} TextAlign={"center"}>
            {t("PagePlannedMeetings.AddDay")}
            </StyledDivRegular>

            <ButtonWrapper Border={!props.average ? "solid 2px #6E6E6E" : ""} Cursor={!props.average ? "pointer" : ""}>
              <button onClick={props.addDay}>+</button>
            </ButtonWrapper>

              
        </Container>
    );
};

export default ComponentAddDay;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: ${props => props.Opacity || "1"};
  pointer-events: ${props => props.PointerEvents || ""};
  margin-left: 32px;
  margin-right: 32px;
  height: 42px;
  min-height: 42px;
  padding: 12px 24px 12px 24px;
  background-color: #FCFCFC;
  position: relative;
  border: ${props => props.Border || ''};
  border-radius: ${props => props.BorderRadius || '10px'};
  font-family: "Overpass";
  /* box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25); */
  gap: 32px;
`;

const ButtonWrapper = styled.div`
  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 50px;
    height: 50px;
    padding: 8px 12px 8px 12px;
    background: #C6CBCB;
    border-radius: 27px;
    border: solid 2px #C6CBCB;
    font-size: 26px;
    font-family: "Overpass";
    cursor: ${props => props.Cursor || ''};
  }

  button:hover {
    border: ${props => props.Border || ''};
  }
`;