import React, {useContext, useState, useEffect, useRef} from "react";
import styled from "styled-components";
import * as d3 from "d3";
import { OverlayContext } from "../../../layout-and-styling/context-hooks/OverlayContext";
import {StyledDivButton, StyledDivRegular} from "../../../layout-and-styling/standardized-components-library/Styling-TextInput";
import {DashboardContext} from "../../../dashboard/DashboardContext";
import {useTranslation} from 'react-i18next';
import load from "../../../assets/dashboard/load.gif";

import { ComparisonVis } from "./ComparisonPage";
import { SocialNetworkVis } from "./SocialNetworkPage";
    
const Toolbox = (props) => {
    const context = useContext(OverlayContext);
    const {t, i18n} = useTranslation();
    
    /** The useRef Hook creates a variable that "holds on" to a value across rendering passes.
     * In this case it will hold our component's SVG DOM element.
     * It's initialized null and React will assign it later (see the return statement) */

    const [zone, setZone] = useState("");
    const [leaders, setLeaders] = useState("");
    const [hoverButtonZone, setHoverButtonZone] = useState("");
    const [hoverLeader, setHoverLeader] = useState("");
    
    const [hoverToolbox, setHoverToolbox] = useState(false);
    const [toolboxClicked, setToolboxClicked] = useState(false);
    
    const [graphData, setGraphData] = useState(JSON.parse(localStorage.getItem("graph_data")));
    const [comparisonData, setComparisonData] = useState(JSON.parse(localStorage.getItem("comparison_data")));
   
    const dashboard_context_value = useContext(DashboardContext);

    /** Adding a listener that runs when graph data is stored in the local storage */
    useEffect(() => {      
        window.addEventListener('graph_data_event', () => {
            setGraphData(JSON.parse(localStorage.getItem("graph_data")));
        })

        window.addEventListener('comparison_data_event', () => {
            setComparisonData(JSON.parse(localStorage.getItem("comparison_data")));
          })
    }, [])

    //rerendering helps team legend rerender?...where to trigger this new render, not finished
    const [render, setRender] = useState(true);
    const reRender = () => {
      setRender(!render);
    };

    // handles when a zones buttons is clicked, selecting the new clicked button or unselecting the clicked button if it was currently selected
    function zoneButtons(button) {
        if (button !== zone) {
            setZone(button);

            if(props.Page=="Comparison"){
                ComparisonVis[0].zones(button);
                ComparisonVis[1].zones(button);
            }
            else SocialNetworkVis[0].zones(button);
            
            d3.select('.ColorScale').style('display', 'flex');
            
        } else {
            setZone("");

            if(props.Page=="Comparison"){
                ComparisonVis[0].zones("");
                ComparisonVis[1].zones("");
            }
            else SocialNetworkVis[0].zones("");

            d3.select('.ColorScale').style('display', 'none');
        }
    }

    // handles when a leaders buttons is clicked, selecting the new clicked button or unselecting the clicked button if it was currently selected
    function leaderButtons(button) {
        if (button !== leaders) {
            setLeaders(button);

            if(props.Page=="Comparison"){
                ComparisonVis[0].highlightNodes(button);
                ComparisonVis[1].highlightNodes(button);
            }
            else SocialNetworkVis[0].highlightNodes(button);

        } else {
            setLeaders("");

            if(props.Page=="Comparison"){
                ComparisonVis[0].highlightNodes("");
                ComparisonVis[1].highlightNodes("");
            }
            else SocialNetworkVis[0].highlightNodes("");
        }
    }

    // handles the hover function of the zones buttons
    function hoverZoneButtons(button) {
        if (button !== zone) {
            setHoverButtonZone(button);
        } else {
            setHoverButtonZone("");
        }
    }

    // handles the hover function of the leaders buttons
    function hoverLeadersButtons(button) {
        if (button !== leaders) {
            setHoverLeader(button);
        } else {
            setHoverLeader("");
        }
    }

    return (
        <Container JustifyContent={props.JustifyContent}>
            {graphData || comparisonData ? (
                <>
                    <ToolboxWrapper>
                        <ToolboxButton Border={hoverToolbox === 'Impact' ? '2px solid #E2336B' : '0px solid #271B36'} 
                                onClick={() => setToolboxClicked(!toolboxClicked)} onMouseOver={() => setHoverToolbox(true)} onMouseLeave={() => setHoverToolbox(false)}>
                            {t('DashboardSocialNetwork.ToolBox')}
                        </ToolboxButton>

                        <ToolboxDetails Display={toolboxClicked ? "flex" : "none"}  Width={toolboxClicked ? "fit-content" : "100px"} Height={toolboxClicked ? "fit-content" : "100px"}
                                        Right={props.JustifyContent === "center" ? "-300%" : "0px"}>
                            <ScoresAttributesWrapper>
                                <StyledDivRegular Position="relative" MarginLeft="0px" Width="fit-content" MarginBottom="0px" FontWeight="700">
                                    {t('DashboardSocialNetwork.CompanyAverages')}
                                </StyledDivRegular>
                                <ScoresWrapper>
                                    <ScoreWrapper>
                                        <IconText>
                                            {props.LeftScore}
                                        </IconText>
                                        <LeftScore/>
                                    </ScoreWrapper>

                                    <SeparatingLine1/>

                                    <ScoreWrapper>
                                        <IconText>
                                            {props.RightScore}
                                        </IconText>
                                        <RightScore/>
                                    </ScoreWrapper>

                                </ScoresWrapper>
                            </ScoresAttributesWrapper>

                            <ZonesWrapper>
                                <StyledDivRegular Position="relative" MarginLeft="0px" Width="fit-content" MarginBottom="0px" FontWeight="700">
                                    {t('DashboardSocialNetwork.Metrics')}
                                </StyledDivRegular>
                                <ZonesButtons>
                                    <StyledDivButton MarginTop='0px' MarginLeft='0px' Width='180px' Height='48px' BoxShadow='0px 2px 4px rgba(0, 0, 0, 0.25)' 
                                                    Padding='8px 4px 8px 4px' FontSize='16px' Display='flex' JustifyContent='center' Position="relative" BackgroundColor={zone === 'Impact' ? '#E2336B' : '#FCFCFC'}
                                                    Border={hoverButtonZone === 'Impact' ? '2px solid #E2336B' : '0px solid #271B36'} Color={zone === 'Impact' ? '#FCFCFC' : '#271B36'}>

                                        <button onClick={() => zoneButtons("Impact")} onMouseOver={() => hoverZoneButtons("Impact")} onMouseLeave={() => hoverZoneButtons("")}>
                                            {t('DashboardSocialNetwork.Impact')}
                                        </button>
                                    </StyledDivButton>
                                    <StyledDivButton MarginTop='0px' MarginLeft='0px' Width='180px' Height='48px' BoxShadow='0px 2px 4px rgba(0, 0, 0, 0.25)' Padding='8px 4px 8px 4px'
                                                    FontSize='16px' Display='flex' JustifyContent='center'  Position="relative" BackgroundColor={zone === 'Pleasantness' ? '#E2336B' : '#FCFCFC'} 
                                                    Border={hoverButtonZone === 'Pleasantness' ? '2px solid #E2336B' : '0px solid #271B36'} Color={zone === 'Pleasantness' ? '#FCFCFC' : '#271B36'}>

                                        <button onClick={() => zoneButtons("Pleasantness")} onMouseOver={() => hoverZoneButtons("Pleasantness")} onMouseLeave={() => hoverZoneButtons("")}>
                                            {t('DashboardSocialNetwork.Pleasantness')}
                                        </button>
                                    </StyledDivButton>
                                    <StyledDivButton MarginTop='0px' MarginLeft='0px' Width='180px' Height='48px' BoxShadow='0px 2px 4px rgba(0, 0, 0, 0.25)' Padding='8px 4px 8px 4px'
                                                    FontSize='16px' Display='flex' JustifyContent='center'  Position="relative" BackgroundColor={zone === 'Proactiveness' ? '#E2336B' : '#FCFCFC'} 
                                                    Border={hoverButtonZone === 'Proactiveness' ? '2px solid #E2336B' : '0px solid #271B36'} Color={zone === 'Proactiveness' ? '#FCFCFC' : '#271B36'}>

                                        <button onClick={() => zoneButtons("Proactiveness")} onMouseOver={() => hoverZoneButtons("Proactiveness")} onMouseLeave={() => hoverZoneButtons("")}>
                                            {t('DashboardSocialNetwork.DrivingForce')}
                                        </button>
                                    </StyledDivButton>
                                </ZonesButtons>
                            </ZonesWrapper>
                            {props.Page=="SocialNetwork" ? (
                                <LeadersWrapper>
                                    <StyledDivRegular Position="relative" MarginLeft="0px" Width="fit-content" MarginBottom="0px" FontWeight="700">
                                        {t('DashboardSocialNetwork.SeeLeaders')}
                                    </StyledDivRegular>
                                    <LeadersButtons>
                                        <StyledDivButton MarginTop='0px' MarginLeft='0px' Width='180px' Height='48px' BoxShadow='0px 2px 4px rgba(0, 0, 0, 0.25)' 
                                                        Padding='8px 4px 8px 4px' FontSize='16px' Display='flex' JustifyContent='center' Position="relative" BackgroundColor={leaders === 'FormalLeaders' ? '#E2336B' : '#FCFCFC'}
                                                        Border={hoverLeader === 'FormalLeaders' ? '2px solid #E2336B' : '0px solid #271B36'} Color={leaders === 'FormalLeaders' ? '#FCFCFC' : '#271B36'}>

                                            <button onClick={() => leaderButtons("FormalLeaders")} onMouseOver={() => hoverLeadersButtons("FormalLeaders")} onMouseLeave={() => hoverLeadersButtons("")}>
                                                {t('DashboardSocialNetwork.FormalLeaders')}
                                            </button>
                                        </StyledDivButton>
                                        <StyledDivButton MarginTop='0px' MarginLeft='0px' Width='180px' Height='48px' BoxShadow='0px 2px 4px rgba(0, 0, 0, 0.25)' Padding='8px 4px 8px 4px'
                                                        FontSize='16px' Display='flex' JustifyContent='center'  Position="relative" BackgroundColor={leaders === 'ActualLeaders' ? '#E2336B' : '#FCFCFC'} 
                                                        Border={hoverLeader === 'ActualLeaders' ? '2px solid #E2336B' : '0px solid #271B36'} Color={leaders === 'ActualLeaders' ? '#FCFCFC' : '#271B36'}>

                                            <button onClick={() => leaderButtons("ActualLeaders")} onMouseOver={() => hoverLeadersButtons("ActualLeaders")} onMouseLeave={() => hoverLeadersButtons("")}>
                                                {t('DashboardSocialNetwork.ActualLeaders')}
                                            </button>
                                        </StyledDivButton>
                                        <StyledDivButton MarginTop='0px' MarginLeft='0px' Width='180px' Height='48px' BoxShadow='0px 2px 4px rgba(0, 0, 0, 0.25)' Padding='8px 4px 8px 4px'
                                                        FontSize='16px' Display='flex' JustifyContent='center'  Position="relative" BackgroundColor={leaders === 'PotentialLeaders' ? '#E2336B' : '#FCFCFC'} 
                                                        Border={hoverLeader === 'PotentialLeaders' ? '2px solid #E2336B' : '0px solid #271B36'} Color={leaders === 'PotentialLeaders' ? '#FCFCFC' : '#271B36'}>

                                            <button onClick={() => leaderButtons("PotentialLeaders")} onMouseOver={() => hoverLeadersButtons("PotentialLeaders")} onMouseLeave={() => hoverLeadersButtons("")}>
                                                {t('DashboardSocialNetwork.PotentialLeaders')}
                                            </button>
                                        </StyledDivButton>
                                    </LeadersButtons>
                                </LeadersWrapper>
                            ) : (
                                ""
                            )}
                                                        
                        </ToolboxDetails>
                    </ToolboxWrapper>
                </>
            ) : (
                <LoadLogo>
                    {" "}
                    <img src={load} alt="loading..."/>
                </LoadLogo>
            )}

        </Container>
    );
};

export default Toolbox;

const Container = styled.div`
  position: absolute;
  width: calc(100% - 200px);
  height: fit-content;
  padding: 20px 100px;
  display: flex;
  flex-direction: row;
  justify-content: ${ props => props.JustifyContent || "right" };
  z-index: 3;
  font-family: 'Overpass', sans-serif;
`;

const ToolboxButton = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Overpass";
  font-weight: 700;
  width: fit-content;
  height: fit-content;
  min-width: 64px;
  padding: 32px 12px 32px 12px;
  background: #FCFCFC;
  color: #271B36;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  z-index: 1;
  cursor: pointer;
`;

const ToolboxWrapper = styled.div`
  position: absolute;
  font-family: "Overpass";
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background: #FCFCFC;
  gap: 24px;
  z-index: 2;
`;

const ToolboxDetails = styled.div`
  position: absolute;
  display: flex;
  font-family: "Overpass";
  width: fit-content;
  height: fit-content;
  top: 100px;
  right: ${ props => props.Right || "0px" };
  display: ${ props => props.Display || "none" };
  flex-direction: column;
  background: #FCFCFC;
  padding: 16px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  gap: 24px;
  z-index: 1;
`;

const ScoresAttributesWrapper = styled.div`
  display: flex;
  position: relative;
  font-family: "Overpass";
  flex-direction: column;
  align-items: flex-start;
`;


const ScoresWrapper = styled.div.attrs({
    className: "Score",
})`
  display: flex;
  flex-direction: row;
  background-color: #FCFCFC;
  width: 100%;
  height: fit-content;
  color: #271B36;
  text-align: center;
  justify-content: space-evenly;
  margin-left: 0;
  font-family: 'Overpass';
  padding: 8px 0px 8px 0px;
  border-radius: 10px;
  z-index: 3;
`;

const IconText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  width: 164px;
`;


const ScoreWrapper = styled.div.attrs({
    className: "ScoreWrapper",
})`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;


const LeftScore = styled.div.attrs({
    className: "LeftScore",
})`
  display: flex;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Overpass';
  background-color: #271B36;
  width: 40px;
  height: 40px;
  color: #FCFCFC;
  padding: 18px 8px 0 8px;
  text-align: center;
  justify-content: center;
  border-style: solid;
  border-width: 3px;
  border-radius: 50%;
`;

const RightScore = styled.div.attrs({
    className: "RightScore",
})`
  display: flex;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Overpass';
  background-color: #271B36;
  width: 40px;
  height: 40px;
  color: #FCFCFC;
  padding: 18px 8px 0 8px;
  text-align: center;
  justify-content: center;
  border-style: solid;
  border-width: 3px;
  border-radius: 50%;
`;

const SeparatingLine1 = styled.div.attrs({
    className: "Line1",
})`
  border-left: 1px solid #3E4245;
  height: 64px;
`;

const ZonesWrapper = styled.div.attrs({
    className: "Zones",
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  height: fit-content;
  width: 100%;
  color: #271B36;
  z-index: 2;
  gap: 12px;
`;

const ZonesButtons = styled.div.attrs({
    className: "Zones",
})`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  position: relative;
  height: fit-content;
  width: 100%;
  color: #271B36;
  z-index: 2;
  gap: 24px;
`;

const LeadersWrapper = styled.div.attrs({
    className: "Leaders",
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  height: fit-content;
  width: 100%;
  color: #271B36;
  z-index: 2;
  gap: 12px;
`;

const LeadersButtons = styled.div.attrs({
    className: "Leaders",
})`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  position: relative;
  height: fit-content;
  width: 100%;
  color: #271B36;
  z-index: 2;
  gap: 24px;
`;

const LoadLogo = styled.div`
  img {
    width: 50px;
  }

  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
`;
