import {useContext, useEffect, useRef, useState, useLayoutEffect} from "react";
import D3Class from './D3Class'; 
import styled,{css} from "styled-components";
import { OverlayContext } from "../../../layout-and-styling/context-hooks/OverlayContext";
import RandomNames from "../assets/random_names/names.js";
import { ComparisonVis } from "./ComparisonPage";
import { SocialNetworkVis } from "./SocialNetworkPage";
import { PresentationVis } from "../../social-network-feedback/SocialNetworkFeedback";


function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

export default function RenderComponent(props) {
    const {height, width} = useWindowDimensions();
    const language = props.language;
    /** React js hook useState for tracking data changes */

    /** The useRef Hook creates a variable that "holds on" to a value across rendering passes.
     * In this case it will hold our component's SVG DOM element.
     * It's initialized null and React will assign it later (see the return statement) */
    const Ref = useRef();
    const graphRef = useRef();

    // Function to center containing box of the social network in the center
    function centerVis(containingBoxX, containingBoxY, medianX, medianY) {
        Ref.current.scrollLeft = ( containingBoxX - Ref.current.offsetWidth ) / 2 + (( containingBoxX - Ref.current.offsetWidth ) / containingBoxX) * medianX;
        Ref.current.scrollTop = ( containingBoxY - Ref.current.offsetHeight ) / 2 + (( containingBoxY - Ref.current.offsetHeight ) / containingBoxY) * medianY;
    }

    /** It adds content to the DOM outside of React’s virtual DOM mechanism */
    useEffect(() => {
        let demo_mode = JSON.parse(localStorage.getItem('demo_mode'));
        let data = props.graphData
        let zoom = props.Zoom
        let page = props.Page
        let iteration = props.Iteration
        
        if (data) {
            const d3Props = {
                data,
                width,
                height,
                zoom,
                context,
                demo_mode,
                RandomNames,
                language,
            };

            if(page == "Comparison") {
                if(iteration === "before"){
                    ComparisonVis[0] = new D3Class(graphRef.current, d3Props)
                    props.setZoom(width / ComparisonVis[0].containingBoxX)
                    props.setGraphWidth(ComparisonVis[0].containingBoxX)
                    props.setGraphHeight(ComparisonVis[0].containingBoxY)
                    centerVis(ComparisonVis[0].containingBoxX, ComparisonVis[0].containingBoxY, ComparisonVis[0].medianX, ComparisonVis[0].medianY)
                } 
                else if(iteration === "after") {
                    ComparisonVis[1] = new D3Class(graphRef.current, d3Props)
                    props.setZoom(width / ComparisonVis[1].containingBoxX)
                    props.setGraphWidth(ComparisonVis[1].containingBoxX)
                    props.setGraphHeight(ComparisonVis[1].containingBoxY)
                    centerVis(ComparisonVis[1].containingBoxX, ComparisonVis[1].containingBoxY, ComparisonVis[1].medianX, ComparisonVis[1].medianY)
                }
            } else if (page == "Presentation") {
                PresentationVis[0] = new D3Class(graphRef.current, d3Props)
                props.setZoom(width / PresentationVis[0].containingBoxX)
                props.setGraphWidth(PresentationVis[0].containingBoxX)
                props.setGraphHeight(PresentationVis[0].containingBoxY)
                centerVis(PresentationVis[0].containingBoxX, PresentationVis[0].containingBoxY, PresentationVis[0].medianX, PresentationVis[0].medianY)
            } else {
                SocialNetworkVis[0] = new D3Class(graphRef.current, d3Props)
                props.setZoom(width / SocialNetworkVis[0].containingBoxX)
                props.setGraphWidth(SocialNetworkVis[0].containingBoxX)
                props.setGraphHeight(SocialNetworkVis[0].containingBoxY)
                centerVis(SocialNetworkVis[0].containingBoxX, SocialNetworkVis[0].containingBoxY, SocialNetworkVis[0].medianX, SocialNetworkVis[0].medianY)
            }
        }
    }, [props.graphData]);

    /** Adding a listener that runs when graph data is stored in the local storage */
    let context = useContext(OverlayContext);
    
    useEffect(() => {
        if (!Ref.current) return;
        let isDown = false;
        let startX;
        let startY;
        let scrollLeft;
        let scrollTop;

        Ref.current.addEventListener("mousedown", (e) => {
            isDown = true;
            startX = e.pageX - Ref.current.offsetLeft;
            startY = e.pageY - Ref.current.offsetTop;
            scrollLeft = Ref.current.scrollLeft;
            scrollTop = Ref.current.scrollTop;
        });
        Ref.current.addEventListener("mouseleave", () => {
            isDown = false;
        });
        Ref.current.addEventListener("mouseup", () => {
            isDown = false;
        });
        Ref.current.addEventListener("mousemove", (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - Ref.current.offsetLeft;
            const y = e.pageY - Ref.current.offsetTop;
            const walk = x - startX; // scroll-fast
            const walk2 = y - startY; // scroll-fast
            Ref.current.scrollLeft = scrollLeft - walk;
            Ref.current.scrollTop = scrollTop - walk2;
        });
    }, [Ref]);

    const onScroll = (e) => {
        const delta = e.deltaY * -0.001;
        const newZoom = props.Zoom + delta;
        if(newZoom > 0 && newZoom < 2) props.setZoom(newZoom)
    };


    return (
        <Container Width={"100%"} Height={"100%"} ref={Ref} onWheel={onScroll} id="container" Left={props.Left} Position={props.Page == "Comparison" ? "absolute" : "fixed"}>
            <Graph Width={props.graphWidth+"px"} Height={props.graphHeight+"px"} ref={graphRef} id="Graph" className="flex-child graph" Transform={`scale(${props.Zoom})`}/>
        </Container>
        
    )
}

const Container = styled.div`
  position: ${props => props.Position || "fixed"};
  overflow: hidden;
  width: ${props => props.Width || "100%"};
  height: ${props => props.Height || "100%"};
  top: 0;
  left:  ${props => props.Left || 0};
  cursor: grab;
`;

const Graph = styled.div`
  position: relative;
  overflow: hidden;
  width: ${props => props.Width || "100%"};
  height: ${props => props.Height || "100%"};
  top: 0;
  left: 0;
  cursor: grab;
  transform: ${props => props.Transform || "scale(1.0)"};

`;
