import styled from 'styled-components';
import React, { useContext, useState } from "react"
import { Dock } from "react-dock"
import { OverlayContext } from '../../layout-and-styling/context-hooks/OverlayContext';
import { Trans, useTranslation } from 'react-i18next';
import { StyledDivBold, StyledDivRegular } from '../../layout-and-styling/standardized-components-library/Styling-TextInput';
import { useWindowSize } from '../../layout-and-styling/standardized-components-library/Component-Responsive';

import { TooltipCulturePage, TooltipIntroductionCloseColleagueFilter, TooltipIntroSpontaneousCollaborationPage } from "./Survey-social-network-tooltips";
import ChooseColleagueGif from '../common-assets/intro-gifs/ChooseColleague.gif';
import SliderKnowledgeGif from '../common-assets/intro-gifs/DrivingKnowledgeSlider.gif';
import ImpactGif from '../common-assets/intro-gifs/ImpactGif.gif';
import SelectColleagues from '../common-assets/intro-gifs/SelectColleagues.gif';
import CustomersOrSupplierGif from '../common-assets/intro-gifs/CustomersOrSuppliers.gif';
import TimeCustomersGif from '../common-assets/intro-gifs/TimeCustomers.gif';
import AddMeetingGif from '../common-assets/intro-gifs/AddMeeting.gif';
import TimeFrameGif from '../common-assets/intro-gifs/TimeFrameGif.gif';

import SpotaneousInteractionOldGif from '../common-assets/intro-gifs/SpontaniousTime-old.gif';
import SpotaneousInteractionOldGif2 from '../common-assets/intro-gifs/SpontaniousTime-old2.gif';
import SliderCulture from '../common-assets/intro-gifs/SliderCulture.gif';
import SliderCultureExplanation from '../common-assets/intro-gifs/cultureSliderExplanationGif.gif'
import Participants from '../common-assets/intro-gifs/Participants.svg';
import DayDuration from '../common-assets/intro-gifs/DayDuration.svg';
import PlannedMeetingsResources from '../common-assets/intro-gifs/PlannedMeetingsResources.svg';
import SpontaneousInteractionsResources from '../common-assets/intro-gifs/SpontaneousInteractionsResources.svg';

function SurveySocialNetworkOverlay(props) {
    /**
     * ComponentDockOver is the functional component and it's styling for creating overlays such as the Survey Introduction pages.
     * Dock is imported from react-dock and where you can look up it's arguments described there
     */

    /** Needed for back and next button browser url history */
    const { t, i18n } = useTranslation();

    /** Load in OverlayContext to toggle tooltip opacity */
    const context = useContext(OverlayContext);

    /** Responsive window sizing and useLayoutEffect trigger */
    useWindowSize();
    const [hover1, setHover1] = useState(false);
    const [hover3, setHover3] = useState(false);
    const [hoverInteraction, setHoverInteraction] = useState(false);
    const [hoverTypicalWeek, setHoverTypicalWeek] = useState(false);
    const [hoverCustomerSupplier, setHoverCustomerSupplier] = useState(false);


    if (props.page === "PageCloseColleagueFilter") {

        return (
            <Dock position='top' isVisible={props.dock} dimMode='opaque' size={1} duration={100}>

                <DockBodyWrapper Zoom1="calc(1 / 1.25)" Zoom2="calc(1 / 1.5)" JustifyContent="center">

                    <SurveyIntroHeadingBg />

                    <SurveyIntroHeadingBlock MarginTop="0%" MarginRight="710px" Position="absolute" BoxShadow='-20px 4px 60px rgba(0, 0, 0, 0.1)'>

                        <StyledDivRegular Position='fixed' MarginTop='40px' MarginLeft='0px' Width='200px'
                            Color='#E2336B' FontSize='30px' FontWeight='700' LineHeight='28px' FontFamily='Montserrat'>
                            {t('Component-Dock-Overlay-IntroductionText')}

                        </StyledDivRegular>

                        <StyledDivBold MarginTop='86px' MarginLeft='0px' Width='100%' Color='#FCFCFC' FontSize='36px' LineHeight='52px'>
                            <Trans i18nKey="IntroPageCloseColleagueFilter.Title">
                                Who <span style={{ color: "#FCFCFC" }}> Who </span>
                                do you interact with in a normal with in a normal week?
                            </Trans>
                        </StyledDivBold>

                        <StyledDivRegular Position='fixed' MarginTop='154px' MarginLeft='0px' Width='100%'
                            Color='#FCFCFC' FontSize='26px' FontWeight='400' LineHeight='28px' FontFamily='Montserrat'>
                            {t('IntroPageCloseColleagueFilter.Subtitle')}
                        </StyledDivRegular>

                    </SurveyIntroHeadingBlock>

                    <StepsWrapper>
                        <StepOneDiv MarginTop='280px' MarginLeft="20px">

                            <IntroOverlayNumber><p>1</p></IntroOverlayNumber>

                            <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='500px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                <Trans i18nKey="IntroPageCloseColleagueFilter.ExplanationBox1">
                                Go through all the employees in your company and select the ones you usually
                                <HoverTooltip onMouseOver={() => setHoverInteraction(true)}
                                    onMouseOut={() => setHoverInteraction(false)}>
                                        {hoverInteraction && (<TooltipIntroductionCloseColleagueFilter
                                        whichTooltip={"Interaction"} /> 
                                    )} 
                                </HoverTooltip>
                                    with in a
                                </Trans>
                                <Trans i18nKey="IntroPageCloseColleagueFilter.ExplanationBox1TypicalWeek">
                                typical week
                                <HoverTooltip onMouseOver={() => setHoverTypicalWeek(true)}
                                    onMouseOut={() => setHoverTypicalWeek(false)}>
                                        {hoverTypicalWeek && (<TooltipIntroductionCloseColleagueFilter
                                        whichTooltip={"TypicalWeek"} /> 
                                    )} 
                                </HoverTooltip>
                                </Trans>
                                <Trans i18nKey="IntroPageCloseColleagueFilter.ExplanationBox1After">
                                for chinese translation only
                                <HoverTooltip onMouseOver={() => setHoverInteraction(true)}
                                    onMouseOut={() => setHoverInteraction(false)}>
                                        {hoverInteraction && (<TooltipIntroductionCloseColleagueFilter
                                        whichTooltip={"Interaction"} /> 
                                    )} 
                                </HoverTooltip>
                                    with in a
                                </Trans>
                            </StyledDivRegular>

                            <IntroImageWrapper Position="absolute" MarginTop="70px" MarginLeft="10px">
                                <img height={300} src={SelectColleagues} />
                            </IntroImageWrapper>

                        </StepOneDiv>


                        <StepTwoDiv MarginTop='280px' MarginLeft="20px">

                            <IntroOverlayNumber><p>2</p></IntroOverlayNumber>

                            <StyledDivRegular Position="" TextAlign='center' MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='500px'  Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                <Trans i18nKey="IntroPageCloseColleagueFilter.ExplanationBox2">
                                    Optional: If you have any interaction with the 
                                <HoverTooltip onMouseOver={() => setHoverCustomerSupplier(true)}
                                    onMouseOut={() => setHoverCustomerSupplier(false)}> 
                                        {hoverCustomerSupplier && (<TooltipIntroductionCloseColleagueFilter
                                        whichTooltip={"CustomerSupplier"} /> 
                                    )} 
                                </HoverTooltip>
                                , select them too on the right
                                </Trans>
                            </StyledDivRegular>

                            <IntroImageWrapper Position="absolute" MarginTop="70px" MarginLeft="10px">
                                <img height={300} src={CustomersOrSupplierGif} />
                            </IntroImageWrapper>

                        </StepTwoDiv>

                        <StepThreeDiv MarginTop='280px' MarginLeft="20px">

                            <IntroOverlayNumber><p>3</p></IntroOverlayNumber>

                            <StyledDivRegular Position="" MarginTop='-20px' MarginLeft="0" TextAlign='center' Width='fit-content' MaxWidth='500px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                <Trans i18nKey="IntroPageCloseColleagueFilter.ExplanationBox3"></Trans>
                            </StyledDivRegular>

                            <IntroImageWrapper Position="absolute" MarginTop="70px" MarginLeft="10px">
                                <img height={300} src={TimeCustomersGif} />
                            </IntroImageWrapper>
                        </StepThreeDiv>

                    </StepsWrapper>

                    <HideDockButtonWrapper Display="flex" MarginTop='800px' ButtonWidth='271px' ButtonHeight='56px' MarginLeft="0px" >
                        <button onClick={() => {
                            props.setDock(false);
                        }}>
                            {t('Component-Dock-Overlay-Button')}
                        </button>
                    </HideDockButtonWrapper>

                </DockBodyWrapper>

            </Dock>
        );
    }

    else if (props.page === "PagePlannedMeetingsQuestion") {
        return (
            <Dock position='top' isVisible={props.dock} dimMode='opaque' size={1} duration={100}>

                <DockBodyWrapper JustifyContent="center">

                    <SurveyIntroHeadingBg />

                    <SurveyIntroHeadingBlock MarginTop="0%" MarginRight="710px" Position="absolute" BoxShadow='-20px 4px 60px rgba(0, 0, 0, 0.1)'>

                        <StyledDivRegular Position='fixed' MarginTop='56px' MarginLeft='0px' Width='200px'
                            Color='#E2336B' FontSize='30px' FontWeight='700' LineHeight='28px' FontFamily='Montserrat'>
                            {t('Component-Dock-Overlay-IntroductionText')}

                        </StyledDivRegular>

                        <StyledDivBold MarginTop='108px' MarginLeft='0px' Width='100%' Color='#FCFCFC' FontSize='36px' LineHeight='52px'>
                            <Trans i18nKey="IntroPagePlannedMeetingsQuestion.Title">
                                Share with us how often you usually collaborate with your colleages during a week
                            </Trans>

                        </StyledDivBold>

                    </SurveyIntroHeadingBlock>

                    <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='280px' MarginLeft="0" Width='fit-content' Color='#271B36' FontSize='28px' LineHeight='28px' ZIndex="3" FontWeight="700">
                        <Trans i18nKey="IntroPagePlannedMeetingsQuestion.Subtitle"></Trans>
                    </StyledDivRegular>

                    <StepsWrapper>
                            <StepOneDiv MarginTop='350px' MarginLeft="20px">

                                <IntroOverlayNumber><p>1</p></IntroOverlayNumber>

                                <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' Color='#271B36' FontSize='18px' LineHeight='28px' ZIndex="3" FontWeight="700">
                                    <Trans i18nKey="IntroPagePlannedMeetingsQuestion.Type1">
                                        Planned meetings
                                    </Trans>
                                </StyledDivRegular>

                                <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='32px' MarginLeft="0" Width='600px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                    <Trans i18nKey="IntroPagePlannedMeetingsQuestion.Type1Explanation"></Trans>
                                </StyledDivRegular>

                                <StyledDivRegular Position="absolute" MarginTop='116px' MarginLeft="0" Width='600px' Display="flex" FlexDirection="row">
                                    <IntroImageWrapper Position="absolute" MarginTop="0px" MarginLeft="-264px">
                                        <img height={24} src={DayDuration} />
                                    </IntroImageWrapper>
                                    <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='0px' MarginLeft="-112px" Width='fit-content' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                        <Trans i18nKey="IntroPagePlannedMeetingsQuestion.DayDuration"></Trans>
                                    </StyledDivRegular>
                                    <IntroImageWrapper Position="absolute" MarginTop="0px" MarginLeft="78px">
                                        <img height={24} src={Participants} />
                                    </IntroImageWrapper>
                                    <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='0px' MarginLeft="196px" Width='fit-content' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                        <Trans i18nKey="IntroPagePlannedMeetingsQuestion.Participants"></Trans>
                                    </StyledDivRegular>
                                </StyledDivRegular>


                                <IntroImageWrapper Position="absolute" MarginTop="172px" MarginLeft="10px">
                                    <img height={100} src={PlannedMeetingsResources} />
                                </IntroImageWrapper>

                            </StepOneDiv>

                            <StepTwoDiv MarginTop='350px' MarginLeft="20px">

                                <IntroOverlayNumber><p>2</p></IntroOverlayNumber>

                                <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' Color='#271B36' FontSize='18px' LineHeight='28px' ZIndex="3" FontWeight="700">
                                    <Trans i18nKey="IntroPagePlannedMeetingsQuestion.Type2">
                                        Spontaneous interactions
                                    </Trans>
                                </StyledDivRegular>

                                <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='32px' MarginLeft="0" Width='600px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                    <Trans i18nKey="IntroPagePlannedMeetingsQuestion.Type2Explanation"></Trans>
                                </StyledDivRegular>

                                <StyledDivRegular Position="absolute" MarginTop='146px' MarginLeft="0" Width='600px' Display="flex" FlexDirection="row">
                                    <IntroImageWrapper Position="absolute" MarginTop="0px" MarginLeft="-264px">
                                        <img height={24} src={DayDuration} />
                                    </IntroImageWrapper>
                                    <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='0px' MarginLeft="-112px" Width='fit-content' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                        <Trans i18nKey="IntroPagePlannedMeetingsQuestion.DayDuration"></Trans>
                                    </StyledDivRegular>
                                    <IntroImageWrapper Position="absolute" MarginTop="0px" MarginLeft="78px">
                                        <img height={24} src={Participants} />
                                    </IntroImageWrapper>
                                    <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='0px' MarginLeft="196px" Width='fit-content' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                        <Trans i18nKey="IntroPagePlannedMeetingsQuestion.Participants"></Trans>
                                    </StyledDivRegular>
                                </StyledDivRegular>

                                <IntroImageWrapper Position="absolute" MarginTop="192px" MarginLeft="10px">
                                    <img height={100} src={SpontaneousInteractionsResources} />
                                </IntroImageWrapper>

                            </StepTwoDiv>

                        </StepsWrapper>

                        <HideDockButtonWrapper Display="flex" MarginTop='780px' ButtonWidth='271px' ButtonHeight='56px' MarginLeft="0px" >
                            <button onClick={() => {
                                props.setDock(false);
                            }}>
                                {t('Component-Dock-Overlay-Button')}
                            </button>
                        </HideDockButtonWrapper>

                </DockBodyWrapper>

            </Dock>
        );
    }

    else if (props.page === "PagePlannedMeetings") {
        return (
            <Dock position='top' isVisible={props.dock} dimMode='opaque' size={1} duration={100}>

                <DockBodyWrapper JustifyContent="center">

                    <SurveyIntroHeadingBg />

                    <SurveyIntroHeadingBlock MarginTop="0%" MarginRight="710px" Position="absolute" BoxShadow='-20px 4px 60px rgba(0, 0, 0, 0.1)'>

                        <StyledDivRegular Position='fixed' MarginTop='40px' MarginLeft='0px' Width='200px'
                            Color='#E2336B' FontSize='30px' FontWeight='700' LineHeight='28px' FontFamily='Montserrat'>
                            {t('Component-Dock-Overlay-IntroductionText')}

                        </StyledDivRegular>

                        <StyledDivBold MarginTop='86px' MarginLeft='0px' Width='100%' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                            <Trans i18nKey="IntroPagePlannedMeetings.Title"></Trans>
                        </StyledDivBold>

                        <StyledDivRegular Position='fixed' MarginTop='154px' MarginLeft='0px' Width='100%'
                            Color='#FCFCFC' FontSize='26px' FontWeight='400' LineHeight='28px' FontFamily='Montserrat'>
                            {t('IntroPagePlannedMeetings.Subtitle')}

                        </StyledDivRegular>

                    </SurveyIntroHeadingBlock>

                    <StepsWrapper>
                            <StepOneDiv MarginTop='280px' MarginLeft="20px">

                                <IntroOverlayNumber><p>1</p></IntroOverlayNumber>

                                <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='500px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                    <Trans i18nKey="IntroPagePlannedMeetings.ExplanationBox1"></Trans>
                                </StyledDivRegular>

                                <IntroImageWrapper Position="absolute" MarginTop="70px" MarginLeft="10px">
                                    <img height={300} src={AddMeetingGif} />
                                </IntroImageWrapper>

                            </StepOneDiv>

                            <StepTwoDiv MarginTop='280px' MarginLeft="20px">

                                <IntroOverlayNumber><p>2</p></IntroOverlayNumber>

                                <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='550px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                    <Trans i18nKey="IntroPagePlannedMeetings.ExplanationBox2"></Trans>
                                </StyledDivRegular>

                                <IntroImageWrapper Position="absolute" MarginTop="70px" MarginLeft="10px">
                                    <img height={300} src={TimeFrameGif} />
                                </IntroImageWrapper>

                            </StepTwoDiv>

                            <StepThreeDiv MarginTop='280px' MarginLeft="20px">

                                <IntroOverlayNumber><p>3</p></IntroOverlayNumber>

                                <StyledDivRegular Position="absolute" TextAlign="center"  MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='500px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                    <Trans i18nKey="IntroPagePlannedMeetings.ExplanationBox3"></Trans>
                                </StyledDivRegular>

                                <IntroImageWrapper Position="absolute" MarginTop="70px" MarginLeft="10px">
                                    <img height={300} src={SelectColleagues} />
                                </IntroImageWrapper>

                            </StepThreeDiv>

                        </StepsWrapper>
                    
                        <HideDockButtonWrapper Display="flex" MarginTop='800px' ButtonWidth='271px' ButtonHeight='56px' MarginLeft="0px" >
                            <button onClick={() => {
                                props.setDock(false);
                            }}>
                                {t('Component-Dock-Overlay-Button')}
                            </button>
                        </HideDockButtonWrapper>

                </DockBodyWrapper>

            </Dock>
        );
    }

    else if (props.page === "PageSpontaneousCollaboration") {
        return (
            <Dock position='top' isVisible={props.dock} dimMode='opaque' size={1} duration={100}>
                <DockBodyWrapper Zoom1="calc(1 / 1.25)" Zoom2="calc(1 / 1.5)" JustifyContent="center">

                    <SurveyIntroHeadingBg />

                    <SurveyIntroHeadingBlock MarginTop="0%" MarginRight="710px" Position="absolute" BoxShadow='-20px 4px 60px rgba(0, 0, 0, 0.1)'>

                        <StyledDivRegular Position='fixed' MarginTop='40px' MarginLeft='0px' Width='200px'
                            Color='#E2336B' FontSize='30px' FontWeight='700' LineHeight='28px' FontFamily='Montserrat'>
                            {t('Component-Dock-Overlay-IntroductionText')}

                        </StyledDivRegular>

                        <StyledDivBold MarginTop='86px' MarginLeft='0px' Width='100%' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                            <Trans i18nKey="IntroPageSpontaneousCollaboration.Title"></Trans>
                        </StyledDivBold>

                        <StyledDivRegular Position='fixed' MarginTop='154px' MarginLeft='0px' Width='100%'
                            Color='#FCFCFC' FontSize='26px' FontWeight='400' LineHeight='28px' FontFamily='Montserrat'>
                            {t('IntroPageSpontaneousCollaboration.Subtitle')}

                        </StyledDivRegular>

                    </SurveyIntroHeadingBlock>


                    <StepsWrapper>
                        <StepOneDiv MarginTop='280px' MarginLeft="20px">

                            <IntroOverlayNumber><p>1</p></IntroOverlayNumber>

                            <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='500px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                <Trans i18nKey="IntroPageSpontaneousCollaboration.ExplanationBox1"></Trans>
                            </StyledDivRegular>

                            <IntroImageWrapper Position="absolute" MarginTop="90px" MarginLeft="10px">
                                <img height={300} src={SelectColleagues} />
                            </IntroImageWrapper>

                        </StepOneDiv>

                        <StepTwoDiv MarginTop='280px' MarginLeft="20px">

                            <IntroOverlayNumber><p>2</p></IntroOverlayNumber>

                            <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='520px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                <Trans i18nKey="IntroPageSpontaneousCollaboration.ExplanationBox2"></Trans>
                            </StyledDivRegular>

                            <IntroImageWrapper Position="absolute" MarginTop="90px" MarginLeft="10px">
                                <img height={300} src={SpotaneousInteractionOldGif2} />
                            </IntroImageWrapper>

                        </StepTwoDiv>

                        <StepThreeDiv MarginTop='280px' MarginLeft="20px">

                            <IntroOverlayNumber><p>3</p></IntroOverlayNumber>

                            <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='500px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                            <Trans i18nKey="IntroPageSpontaneousCollaboration.ExplanationBox3">
                                Drag the sliders to set the values. You can also add <HoverTooltip onMouseOver={() => setHover1(true)}
                                        onMouseOut={() => setHover1(false)}>
                                        {hover1 && (<TooltipIntroSpontaneousCollaborationPage
                                            whichTooltip={"ExtraDays"} />
                                        )}extra days
                                    </HoverTooltip> if you want
                                </Trans>
                            </StyledDivRegular>

                            <IntroImageWrapper Position="absolute" MarginTop="90px" MarginLeft="10px">
                                {/* <img height={300} src={AddExtraDay} /> */}
                                <img height={300} src={SpotaneousInteractionOldGif} />
                            </IntroImageWrapper>

                        </StepThreeDiv>

                    </StepsWrapper>

                    <HideDockButtonWrapper Display="flex" MarginTop='800px' ButtonWidth='271px' ButtonHeight='56px' MarginLeft="0px" >
                        <button onClick={() => {
                            props.setDock(false);
                        }}>
                            {t('Component-Dock-Overlay-Button')}
                        </button>
                    </HideDockButtonWrapper>

                </DockBodyWrapper>

            </Dock>
        );
    }

    else if (props.page === "PageCollaborationMeasurement") {
        return (
            <>
                <Dock position='top' isVisible={props.dock} dimMode='opaque' size={1} duration={100}>
                    <DockBodyWrapper Zoom1="calc(1 / 1.25)" Zoom2="calc(1 / 1.5)" JustifyContent="center" Display="flex">

                        <SurveyIntroHeadingBg />

                        <SurveyIntroHeadingBlock MarginTop="0%" MarginRight="710px" Position="absolute"
                            BoxShadow='-20px 4px 60px rgba(0, 0, 0, 0.1)'>

                            <StyledDivRegular Position='fixed' MarginTop='40px' MarginLeft='0px' Width='200px'
                                Color='#E2336B' FontSize='30px' FontWeight='700' LineHeight='28px' FontFamily='Montserrat'>
                                {t('Component-Dock-Overlay-IntroductionText')}

                            </StyledDivRegular>

                            <StyledDivBold MarginTop='86px' MarginLeft='0px' Width='100%' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                                {t('IntroPageCollaborationMeasurement.Title')}
                            </StyledDivBold>

                            <StyledDivRegular Position='fixed' MarginTop='154px' MarginLeft='0px' Width='100%'
                                Color='#FCFCFC' FontSize='26px' FontWeight='400' LineHeight='28px' FontFamily='Montserrat'>
                                {t('IntroPageCollaborationMeasurement.Subtitle')}

                            </StyledDivRegular>


                        </SurveyIntroHeadingBlock>

                        <StepsWrapper>
                            <StepOneDiv MarginTop='280px' MarginLeft="20px">

                                <IntroOverlayNumber><p>1</p></IntroOverlayNumber>

                                <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='500px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                    <Trans i18nKey="IntroPageCollaborationMeasurement.ExplanationBox1"></Trans>
                                </StyledDivRegular>

                                <IntroImageWrapper Position="absolute" MarginTop="90px" MarginLeft="10px">
                                    <img height={300} src={ChooseColleagueGif} />
                                </IntroImageWrapper>

                            </StepOneDiv>

                            <StepTwoDiv MarginTop='280px' MarginLeft="20px">

                                <IntroOverlayNumber><p>2</p></IntroOverlayNumber>

                                <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='550px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                    <Trans i18nKey="IntroPageCollaborationMeasurement.ExplanationBox2"></Trans>
                                </StyledDivRegular>

                                <IntroImageWrapper Position="absolute" MarginTop="90px" MarginLeft="10px">
                                    <img height={300} src={ImpactGif} />
                                </IntroImageWrapper>

                            </StepTwoDiv>


                        </StepsWrapper>

                        <HideDockButtonWrapper Position="absolute" Display="flex" MarginTop='800px' ButtonWidth='271px' ButtonHeight='56px' MarginLeft="0px" >
                            <button onClick={() => {
                                props.setDock(false);
                            }}>
                                {t('Component-Dock-Overlay-Button')}
                            </button>
                        </HideDockButtonWrapper>

                    </DockBodyWrapper>
                </Dock>
            </>
        );
    }

    else if (props.page === "PageProactiveness") {
        return (
            <>
                <Dock position='top' isVisible={props.dock} dimMode='opaque' size={1} duration={100}>

                    <DockBodyWrapper Zoom1="calc(1 / 1.25)" JustifyContent="center" Display="flex">

                        <SurveyIntroHeadingBg />

                        <SurveyIntroHeadingBlock  MarginTop="0%" MarginRight="710px" Position="absolute"
                            BoxShadow='-20px 4px 60px rgba(0, 0, 0, 0.1)'>

                            <StyledDivRegular Position='fixed' MarginTop='40px' MarginLeft='0px' Width='200px' Color='#E2336B' FontSize='30px' FontWeight='700' LineHeight='28px' FontFamily='Montserrat'>
                                {t('Component-Dock-Overlay-IntroductionText')}

                            </StyledDivRegular>

                            <StyledDivBold MarginTop='86px' MarginLeft='0px' Width='100%' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                                {t('IntroPageProactiveness.Title')}
                            </StyledDivBold>

                            <StyledDivRegular Position='fixed' MarginTop='154px' MarginLeft='0px' Width='100%'
                                Color='#FCFCFC' FontSize='26px' FontWeight='400' LineHeight='28px' FontFamily='Montserrat'>
                                {t('IntroPageProactiveness.Subtitle')}

                            </StyledDivRegular>
                        </SurveyIntroHeadingBlock>


                        <StepsWrapper>

                            <StepOneDiv MarginTop='280px' MarginLeft="20px">

                                <IntroOverlayNumber><p>1</p></IntroOverlayNumber>


                                <StyledDivRegular Position="absolute" MarginTop='0' MarginLeft="0" MaxWidth='500px' Width="fit-content" Color='#271B36' FontSize='16px' TextAlign="center" LineHeight='28x'>
                                    {t('IntroPageProactiveness.ExplanationBox1')}
                                </StyledDivRegular>

                                <IntroImageWrapper Position="absolute" MarginTop="100px" MarginLeft="10px">
                                    <img height={300} src={ChooseColleagueGif} />
                                </IntroImageWrapper>



                            </StepOneDiv>

                            <StepTwoDiv MarginTop='280px' MarginLeft="20px">
                                <IntroOverlayNumber><p>2</p></IntroOverlayNumber>

                                <StyledDivRegular Position="absolute" MarginTop='0' MarginLeft="0" MaxWidth='620px' Width="fit-content" Color='#271B36' FontSize='16px' TextAlign="center" LineHeight='28x'>
                                    {t('IntroPageProactiveness.ExplanationBox2')}
                                </StyledDivRegular>

                                <IntroImageWrapper Position="absolute" MarginTop="100px" MarginLeft="10px">
                                    <img height={300} src={SliderKnowledgeGif} />
                                </IntroImageWrapper>

                            </StepTwoDiv>

                        </StepsWrapper>

                        <HideDockButtonWrapper MarginTop="800px" MarginLeft="0px" ButtonWidth='271px' ButtonHeight='56px'>
                            <button onClick={() => {
                                props.setDock(false);
                                context.toggleOverlay();
                            }}>
                                {t('Component-Dock-Overlay-Button')}
                            </button>
                        </HideDockButtonWrapper>

                    </DockBodyWrapper>

                </Dock>

            </>
        );
    }

    else if (props.page === "PageCulture") {
        return (
            <Dock position='top' isVisible={props.dock} dimMode='opaque' size={1} duration={100}>
                <DockBodyWrapper JustifyContent="center" Display="flex">

                    <SurveyIntroHeadingBg />

                    <SurveyIntroHeadingBlock  MarginTop="0%" MarginRight="710px" Position="absolute"
                        BoxShadow='-20px 4px 60px rgba(0, 0, 0, 0.1)'>

                        <StyledDivRegular Position='fixed' MarginTop='40px' MarginLeft='0px' Width='200px'
                            Color='#E2336B' FontSize='30px' FontWeight='700' LineHeight='28px' FontFamily='Montserrat'>
                            {t('Component-Dock-Overlay-IntroductionText')}
                        </StyledDivRegular>

                        <StyledDivBold MarginTop='86px' MarginLeft='0px' Width='100%' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                            {t('IntroPageCulture.Title')}
                        </StyledDivBold>

                        <StyledDivRegular Position='fixed' MarginTop='154px' MarginLeft='0px' Width='100%'
                            Color='#FCFCFC' FontSize='26px' FontWeight='400' LineHeight='28px' FontFamily='Montserrat'>
                            {t('IntroPageCulture.Subtitle')}

                        </StyledDivRegular>
                    </SurveyIntroHeadingBlock>

                    <StepsWrapper>
                        <StepOneDiv MarginTop='280px' MarginLeft="20px">

                            <IntroOverlayNumber><p>1</p></IntroOverlayNumber>

                            <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='520px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                <Trans i18nKey="IntroPageCulture.ExplanationBox1">
                                </Trans>
                                <br />
                                <Trans i18nKey="IntroPageCulture.ExplanationBox2">
                                    Show in
                                    How would you rate your <HoverTooltip onMouseOver={() => setHover3(true)} onMouseOut={() => setHover3(false)}>
                                        {hover3 && (<TooltipCulturePage
                                            whichTooltip={"Slider"} />
                                        )}
                                    </HoverTooltip>
                                
                                </Trans>
                            </StyledDivRegular>

                            <IntroImageWrapper Position="absolute" MarginTop="80px" MarginLeft="10px">
                                <img height={300} src={SliderCulture} width={600} />
                            </IntroImageWrapper>

                        </StepOneDiv>

                        <StepTwoDiv MarginTop='280px' MarginLeft="20px">

                            <IntroOverlayNumber><p>2</p></IntroOverlayNumber>

                            <StyledDivRegular Position="" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='550px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3" TextAlign="center">
                                <Trans i18nKey="IntroPageCulture.ExplanationBox3">
                                </Trans>
                            </StyledDivRegular>

                            <IntroImageWrapper Position="absolute" MarginTop="80px" MarginLeft="20px">
                                <img height={300} src={SliderCultureExplanation} />
                            </IntroImageWrapper>


                        </StepTwoDiv>
                    </StepsWrapper>

                    <HideDockButtonWrapper MarginTop='800px' MarginLeft="0px" ButtonWidth='271px' ButtonHeight='56px'>
                        <button onClick={() => {
                            props.setDock(false);
                        }}>
                            {t('Component-Dock-Overlay-Button')}
                        </button>

                    </HideDockButtonWrapper>

                </DockBodyWrapper>

            </Dock>
        );
    }

};
export default SurveySocialNetworkOverlay

const DockBodyWrapper = styled.div`
                    display: flex;
                    z-index: 10;
                    overflow-x: hidden;
                    justify-content: ${props => props.JustifyContent || "flex-start"};
                    @media (-webkit-device-pixel-ratio: 1.25) {
                        zoom: ${props => props.Zoom1 || ""};
  }
                    @media (-webkit-device-pixel-ratio: 1.5) {
                        zoom: ${props => props.Zoom2 || ""};
  }
                    `;


const SurveyIntroHeadingBg = styled.div`
                    position: fixed;
                    width: 100%;
                    height: 215px;
                    margin-top: ${props => props.MarginTop || "0%"};
                    margin-left: ${props => props.MarginLeft || "0%"};
                    box-shadow: ${props => props.BoxShadow || '0px 10px 10px -15px rgba(0,0,0,0.25)'};
                    background: #271B36;
                    z-index: 10;
                    `;

const SurveyIntroHeadingBlock = styled.div`
                    position: ${props => props.Position || "fixed"};
                    width: ${props => props.Width || "900px"};
                    height: 240px;
                    margin-top: ${props => props.MarginTop || "0%"};
                    margin-left: ${props => props.MarginLeft || "0%"};
                    margin-right: ${props => props.MarginRight || "0%"};

                    z-index: 10;
                    `;

const IntroImageWrapper = styled.div`
                    position: ${props => props.Position || "fixed"};
                    display: flex;
                    align-items: center;
                    margin-top: ${props => props.MarginTop || "0%"};
                    margin-left: ${props => props.MarginLeft || "0%"};
                    justify-content: center;
                    width: ${props => props.Width || ""};
                    `;


const HideDockButtonWrapper = styled.div`
                    position: absolute;
                    margin-top: ${props => props.MarginTop || "50%"};
                    margin-left: ${props => props.MarginLeft || "36%"};

                    button {
                        height: ${props => props.ButtonHeight || "56px"};
                    width: ${props => props.ButtonWidth || "212px"};
                    border: none;
                    border-radius: 10px;
                    background-color: #e2336B;
                    color: #FCFCFC;
                    font-size: 18px;
                    font-weight: 400;
                    font-family: 'Overpass';
                    cursor: pointer;
                    transition: all 0.2s ease-in;
                    :hover {
                        background-color: #B3194A;
    }
  }
                    `;

const IntroOverlayNumber = styled.div`
                    position: fixed;
                    margin-top: -90px;
                    margin-bottom: ${props => props.MarginBottom || "0%"};
                    width: ${props => props.Width || "40px"};
                    height: ${props => props.Height || "40px"};
                    background: #e2336B;
                    z-index: 3;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: ${props => props.FontFamily || 'Overpass'};
                    font-size: ${props => props.FontSize || '18px'};
                    line-height: ${props => props.LineHeight || '28px'};
                    font-weight: ${props => props.FontWeight || '400'};
                    color: #fcfcfc;

                    p {
                        height: 25px;
  }
                    `;

const StepsWrapper = styled.div`
                    display: flex;
                    flex-direction: row;
                    width: ${props => props.Width || "70%"};
                    height: 100%;
                    justify-content: center;
                    margin: 0 auto;
                    margin-top: 70px;
                    @media only screen and (max-width: 2300px) {
                        width: 100%;
  }
                    `

const StepOneDiv = styled.div`
                    display: flex; 
                    flex-direction: column;
                    align-items: center;
                    margin-top: ${props => props.MarginTop || "0"};
                    margin-left: ${props => props.MarginLeft || "0"};
                    width: ${props => props.Width || "33%"};
                    margin-right: 40px;
                    @media only screen and (min-width: 2000px) {
                        margin-right: 0px;
                    margin-left: 0px;
  }
                    `; 


const StepTwoDiv = styled.div`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: ${props => props.MarginTop || "0"};
                    margin-left: ${props => props.MarginLeft || "0"};
                    width: ${props => props.Width || "33%"};
                    margin-right: 40px;
                    @media only screen and (min-width: 2000px) {
                        margin-right: 0px;
                    margin-left: 0px;

  }
                    `;

const StepThreeDiv = styled.div`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: ${props => props.MarginTop || "0"};
                    margin-left: ${props => props.MarginLeft || "0"};
                    width: 33%;
                    margin-right: 40px;

                    @media only screen and (min-width: 2000px) {
                        margin-right: 0px;
                    margin-left: 0px;
  }
                    `;


const HoverTooltip = styled.div`
                    text-decoration: underline;
                    display: inline;
                    color: #E2336B;
                    cursor: pointer;
                    position: relative;
                    z-index: 2;
                    `;
