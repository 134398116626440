import React, {useEffect, useState, useRef} from 'react';
import styled from 'styled-components';

import {useTranslation} from "react-i18next";
import {ReactComponent as ButtonPlay} from '../../../assets/intake/ButtonPlay.svg';
import {ReactComponent as ButtonReplay} from '../../../assets/intake/ButtonReplay.svg';

const IntroductionVideo = (componentprops) => {
    const {t} = useTranslation();

    const [isPlaying, setIsPlaying] = useState(false);
    const [replayEnabled, setReplayEnabled] = useState(false);
    const videoRef = useRef(null);

    useEffect(() => {
        const componentClickedBefore = localStorage.getItem(`componentClicked${componentprops.identifier}`);
      
        if (componentClickedBefore) {
            componentprops.setComponentClicked(true);
            setReplayEnabled(true);
        }
    }, []);

    const playVideo = () => {
        setIsPlaying(true);
        componentprops.setComponentClicked(true);
        localStorage.setItem(`componentClicked${componentprops.identifier}`, 'true');
        videoRef.current.play();

        if (videoRef.current.requestFullscreen) {
            videoRef.current.requestFullscreen();
        } else if (videoRef.current.webkitRequestFullscreen) { // Chrome, Safari, and Opera
            videoRef.current.webkitRequestFullscreen();
        } else if (videoRef.current.msRequestFullscreen) { // Internet Explorer and Edge
            videoRef.current.msRequestFullscreen();
        } else if (videoRef.current.mozRequestFullScreen) { // Firefox
            videoRef.current.mozRequestFullScreen();
        }
    };

    const replayVideo = () => {
        setReplayEnabled(false);
        videoRef.current.currentTime = 0;
        playVideo();
    };

    const showReplay = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
        } else if (document.webkitFullscreenElement) { // Chrome, Safari, and Opera
            document.webkitExitFullscreen();
        } else if (document.msFullscreenElement) { // Internet Explorer and Edge
            document.msExitFullscreen();
        } else if (document.mozFullScreenElement) { // Firefox
            document.mozCancelFullScreen();
        }

        setIsPlaying(false);
        setReplayEnabled(true);
    };

    return (
        <Container>
            <VideoPlayer ref={videoRef} src={componentprops.video} isPlaying={isPlaying} onEnded={showReplay} controls={isPlaying} controlsList="nodownload"/>

            {!isPlaying && (
                replayEnabled ? (
                  <VideoOverlay onClick={replayVideo}>
                      <ButtonReplay/>
                  </VideoOverlay>
                ) : (
                  <VideoOverlay onClick={playVideo}>
                      <ButtonPlay/>
                  </VideoOverlay>
                )
            )}
        </Container>
    );
}

export default IntroductionVideo;

const Container = styled.div`
  position: relative;
  display: flex;
  max-width: 100%;
  height: calc(100vh - 384px);
  min-height: 219px;
  max-height: 438px;
  aspect-ratio: 16 / 9;
  justify-content: center;
  align-items: center;
`;

const VideoPlayer = styled.video`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  filter: ${props => props.isPlaying ? '' : 'brightness(50%)'};
`;

const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
