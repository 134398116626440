import styled from 'styled-components';
import React, { useEffect, useState, useRef } from "react"
import { Dock } from "react-dock"
import { Trans, useTranslation } from 'react-i18next';
import { StyledDivBold, StyledDivRegular } from '../../layout-and-styling/standardized-components-library/Styling-TextInput';
import { useWindowSize } from '../../layout-and-styling/standardized-components-library/Component-Responsive';

import { TooltipKnowledgePage, TooltipProcessBadFeedback } from "./Survey-text-processing-tooltips";
import ProcessTimePortionGif from '../common-assets/intro-gifs/ProcessTimePortion.gif';
import KnowledgeBusinessProcessGif from '../common-assets/intro-gifs/KnowledgeBusinessProcess.gif';
import ImpactCompanyGif from '../common-assets/intro-gifs/ImpactCompany.gif';
import BestPracticesGif from '../common-assets/intro-gifs/BestPractices.gif';
import TagsBestPracticesGif from '../common-assets/intro-gifs/TagsBestPractices.gif';
import WrittenGoodFeedbackGif from '../common-assets/intro-gifs/WrittenGoodFeedback.gif';
import reversedBotLogo from '../chatbot/assets/reversedBotLogo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { keyframes } from 'styled-components';
import { t } from 'i18next';
import { useSwipeable } from 'react-swipeable';

function SurveyTextProcessingOverlay(props) {
    /**
     * ComponentDockOver is the functional component and it's styling for creating overlays such as the Survey Introduction pages.
     * Dock is imported from react-dock and where you can look up it's arguments described there
     */

    /** Needed for back and next button browser url history */
    const { t, i18n } = useTranslation();

    /** Responsive window sizing and useLayoutEffect trigger */
    useWindowSize();
    const [hover1, setHover1] = useState(false);
    const [hover2, setHover2] = useState(false);
    const [hover3, setHover3] = useState(false);
    const [isPortraitMode, setIsPortraitMode] = useState(window.matchMedia("(orientation: portrait)").matches);
    const wrapperRef = useRef(null);
    const [direction, setDirection] = useState('next');
    const [currentStep, setCurrentStep] = useState(0);
    const currentStepRef = useRef(currentStep); // Store currentStep in a ref to keep it updated in swipe handlers
    const [animationKey, setAnimationKey] = useState(0);

    const steps = [
        { title: "IntroPageBadFeedback.ExplanationTitle1", text: "IntroPageBadFeedback.ExplanationBox1" },
        { title: "IntroPageBadFeedback.ExplanationTitle2", text: "IntroPageBadFeedback.ExplanationBox2" },
        { title: "IntroPageBadFeedback.ExplanationTitle3", text: "IntroPageBadFeedback.ExplanationBox3" },
    ];

    const handleNext = () => {
        if (currentStepRef.current < steps.length - 1) {
            setDirection('next');
            setCurrentStep((prevStep) => prevStep + 1);
            currentStepRef.current = currentStepRef.current + 1;
            setAnimationKey((prevKey) => prevKey + 1);
        } else {
            props.setDock(false);
        }
    };

    const handlePrevious = () => {
        if (currentStepRef.current > 0) {
            setDirection('previous');
            setCurrentStep((prevStep) => prevStep - 1);
            currentStepRef.current = currentStepRef.current - 1;
            setAnimationKey((prevKey) => prevKey + 1);
        }
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => {if (currentStepRef.current < steps.length - 1) handleNext()},
        onSwipedRight: () => {if (currentStepRef.current > 0) handlePrevious()},
      });

    useEffect(() => {
        const mediaQuery = window.matchMedia("(orientation: portrait)");

        // Event listener callback for orientation change
        const handleOrientationChange = (event) => {
            setIsPortraitMode(event.matches);
        }

        // Set initial value
        setIsPortraitMode(mediaQuery.matches);

        // Add the event listener
        mediaQuery.addEventListener('change', handleOrientationChange);

        return () => {
            mediaQuery.removeEventListener('change', handleOrientationChange);
        };
    }, []);

    if (props.page === "PageKnowledge") {
        return (
            <Dock style={{ display: "none" }} position='top' isVisible={props.dock} dimMode='opaque' size={1} duration={100}>

                <DockBodyWrapper JustifyContent="center">

                    <SurveyIntroHeadingBg />

                    <SurveyIntroHeadingBlock  MarginTop="0%" MarginRight="710px" Position="absolute" BoxShadow='-20px 4px 60px rgba(0, 0, 0, 0.1)'>

                        <StyledDivRegular Position='fixed' MarginTop='40px' MarginLeft='0px' Width='200px'
                            Color='#E2336B' FontSize='30px' FontWeight='700' LineHeight='28px' FontFamily='Montserrat'>
                            {t('Component-Dock-Overlay-IntroductionText')}

                        </StyledDivRegular>

                        <StyledDivBold MarginTop='86px' MarginLeft='0px' Width='100%' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                            {t('IntroPageKnowledge.Title')}
                        </StyledDivBold>

                        <StyledDivRegular Position='fixed' MarginTop='154px' MarginLeft='0px' Width='100%'
                            Color='#FCFCFC' FontSize='26px' FontWeight='400' LineHeight='28px' FontFamily='Montserrat'>
                            {t('IntroPageKnowledge.Subtitle')}

                        </StyledDivRegular>
                    </SurveyIntroHeadingBlock>

                    <StepsWrapper>
                        <StepOneDiv MarginTop='280px' MarginLeft="20px">

                            <IntroOverlayNumber><p>1</p></IntroOverlayNumber>

                            <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='600px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                            <Trans i18nKey="IntroPageKnowledge.ExplanationBox1">
                                    Show in
                                    How would you rate your <HoverTooltip onMouseOver={() => setHover1(true)} onMouseOut={() => setHover1(false)}>
                                        {hover1 && (<TooltipKnowledgePage
                                            whichTooltip={"Step1"} />
                                        )}
                                    </HoverTooltip>
                                </Trans>
                                <br></br>
                            <Trans i18nKey="IntroPageKnowledge.ExplanationBox2">
                                </Trans>

                            </StyledDivRegular>

                            <IntroImageWrapper Position="absolute" MarginTop="70px" MarginLeft="10px">
                                <img height={300} src={ProcessTimePortionGif} alt="Process Time Portion" />
                            </IntroImageWrapper>

                        </StepOneDiv>

                        <StepTwoDiv MarginTop='280px' MarginLeft="20px">

                            <IntroOverlayNumber><p>2</p></IntroOverlayNumber>

                            <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='600px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                           
                            <Trans i18nKey="IntroPageKnowledge.ExplanationBox3">
                                </Trans>
                                <br></br>
                                <Trans i18nKey="IntroPageKnowledge.ExplanationBox4">
                                    Show in
                                    How would you rate your <HoverTooltip onMouseOver={() => setHover2(true)} onMouseOut={() => setHover2(false)}>
                                        {hover2 && (<TooltipKnowledgePage
                                            whichTooltip={"Step2"} />
                                        )}
                                    </HoverTooltip>
                                </Trans>
                            </StyledDivRegular>

                            <IntroImageWrapper Position="absolute" MarginTop="70px">
                                <img height={300} src={ImpactCompanyGif} alt="Impact Company" />
                            </IntroImageWrapper>

                        </StepTwoDiv>

                        <StepThreeDiv MarginTop='280px' MarginLeft="20px">

                            <IntroOverlayNumber><p>3</p></IntroOverlayNumber>

                            <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='600px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                            <Trans i18nKey="IntroPageKnowledge.ExplanationBox5">
                                    Show in
                                    How would you rate your <HoverTooltip onMouseOver={() => setHover3(true)} onMouseOut={() => setHover3(false)}>
                                        {hover3 && (<TooltipKnowledgePage
                                            whichTooltip={"Step3"} />
                                        )}
                                    </HoverTooltip>
                                </Trans>
                                <br></br>
                            <Trans i18nKey="IntroPageKnowledge.ExplanationBox6">
                                </Trans>
                            </StyledDivRegular>

                            <IntroImageWrapper Position="absolute" MarginTop="70px">
                                <img height={300} src={KnowledgeBusinessProcessGif} alt="Knowledge Business Process" />
                            </IntroImageWrapper>

                        </StepThreeDiv>
                    </StepsWrapper>

                    <HideDockButtonWrapper Display="flex" MarginTop='800px' MarginLeft="0px" ButtonWidth='271px' ButtonHeight='56px'>
                        <button onClick={() => {
                            props.setDock(false);
                        }}>
                            {t('Component-Dock-Overlay-Button')}
                        </button>

                    </HideDockButtonWrapper>

                </DockBodyWrapper>

            </Dock>
        )
    }

    else if (props.page === "PageBusinessProcessGoodFeedback") {
        return (
            <>
                <Dock position='top' isVisible={props.dock} dimMode='opaque' size={1} duration={100}>
                    <DockBodyWrapper JustifyContent="center" Display="flex">

                        <SurveyIntroHeadingBg />

                        <SurveyIntroHeadingBlock MarginTop="0%" MarginRight="710px" Position="absolute" BoxShadow='-20px 4px 60px rgba(0, 0, 0, 0.1)'>

                            <StyledDivRegular Position='fixed' MarginTop='40px' MarginLeft='0px' Width='200px'
                                Color='#E2336B' FontSize='30px' FontWeight='700' LineHeight='28px' FontFamily='Montserrat'>
                                {t('Component-Dock-Overlay-IntroductionText')}

                            </StyledDivRegular>

                            <StyledDivBold MarginTop='86px' MarginLeft='0px' Width='100%' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                                {t('IntroPageGoodFeedback.Title')}
                            </StyledDivBold>

                            <StyledDivRegular Position='fixed' MarginTop='154px' MarginLeft='0px' Width='100%'
                                Color='#FCFCFC' FontSize={i18n.language === "nl-NL" ? '22px' : '26px'} FontWeight='400' LineHeight='28px' FontFamily='Montserrat'>
                                {t('IntroPageGoodFeedback.Subtitle')}

                            </StyledDivRegular>
                        </SurveyIntroHeadingBlock>


                        <StepsWrapper>
                            <StepOneDiv MarginTop='280px' MarginLeft="20px">

                                <IntroOverlayNumber><p>1</p></IntroOverlayNumber>

                                <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='500px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                <Trans i18nKey="IntroPageGoodFeedback.ExplanationBox1">
                                    Show in
                                    How would you rate your <HoverTooltip onMouseOver={() => setHover1(true)} onMouseOut={() => setHover1(false)}>
                                        {hover1 && (<TooltipProcessBadFeedback
                                            whichTooltip={"businessFlow"} />
                                        )}
                                    </HoverTooltip>
                                </Trans>
                                <br></br>
                            <Trans i18nKey="IntroPageGoodFeedback.ExplanationBox2">
                                </Trans>
                                </StyledDivRegular>

                                <IntroImageWrapper Position="absolute" MarginTop="70px" MarginLeft="10px">
                                    <img height={300} src={BestPracticesGif} alt="Best Practices" />
                                </IntroImageWrapper>

                            </StepOneDiv>

                            <StepTwoDiv MarginTop='280px' MarginLeft="20px">

                                <IntroOverlayNumber><p>2</p></IntroOverlayNumber>

                                <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='580px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                    <Trans i18nKey="IntroPageGoodFeedback.ExplanationBox3"></Trans>
                                </StyledDivRegular>

                                <IntroImageWrapper Position="absolute" MarginTop="70px" MarginLeft="10px">
                                    <img height={300} src={TagsBestPracticesGif} alt="Tags Best Practices" />
                                </IntroImageWrapper>

                            </StepTwoDiv>

                            <StepThreeDiv MarginTop='280px' MarginLeft="20px">

                                <IntroOverlayNumber><p>3</p></IntroOverlayNumber>

                                <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='500px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                    <Trans i18nKey="IntroPageGoodFeedback.ExplanationBox4"></Trans>
                                </StyledDivRegular>

                                <IntroImageWrapper Position="absolute" MarginTop="70px" MarginLeft="10px">
                                    <img height={300} src={WrittenGoodFeedbackGif} alt="Written Good Feedback" />
                                </IntroImageWrapper>

                            </StepThreeDiv>

                        </StepsWrapper>

                        <HideDockButtonWrapper Display="flex" MarginTop='800px' ButtonWidth='271px' ButtonHeight='56px' MarginLeft="0px" >
                            <button onClick={() => {
                                props.setDock(false);
                            }}>
                                {t('Component-Dock-Overlay-Button')}
                            </button>
                        </HideDockButtonWrapper>

                    </DockBodyWrapper>
                </Dock>

            </>
        );
    }

    else if (props.page === "EllyPage") {
        return (
            <>
                <Dock position='top' isVisible={props.dock} dimMode='opaque' size={1} duration={100}>

                    <SurveyIntroHeadingBg>

                        <SurveyIntroHeadingBlock Position="relative" BoxShadow='-20px 4px 60px rgba(0, 0, 0, 0.1)'>
                            {isPortraitMode ? 
                                <StyledDivRegular Position='relative' Display="flex" Width="fit-content" Color='#E2336B' FontFamily='Montserrat'
                                    PhoneFontWeight='700' PhoneFontSize='64px' PhoneMarginBottom='40px'>
                                    {t('Component-Dock-Overlay-IntroductionTextPhone')}
                                </StyledDivRegular>
                            : 
                                <>
                                    <StyledDivRegular Position='relative' Display="flex" Width="fit-content" Color='#E2336B' FontSize='30px' FontWeight='700' LineHeight='28px' FontFamily='Montserrat'>
                                        {t('Component-Dock-Overlay-IntroductionText')}
                                    </StyledDivRegular>

                                    <StyledDivBold Position='relative' Display="flex" Width="fit-content" Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                                        {t('IntroPageBadFeedback.Title')}
                                    </StyledDivBold>
                                </>
                            }
                            {!isPortraitMode ?
                            <StyledDivRegular Position='relative' Display="flex" MarginTop="-12px" Width={i18n.language === "en-US" ? "902px" : "nl-NL" ? "800px" : "600px"} Color='#FCFCFC' FontSize={i18n.language === "nl-NL" ? '22px' : '26px'} FontWeight='400' LineHeight='28px' FontFamily='Montserrat'>
                                {t('IntroPageBadFeedback.Subtitle')}
                            </StyledDivRegular>
                            : null }
                        </SurveyIntroHeadingBlock>

                    </SurveyIntroHeadingBg> 

                    {!isPortraitMode ?
                        <EllyDiv MarginTop="64px">
                            <StyledDivBold Color='#E2336B' FontSize='28px' Position="relative" LineHeight='80px'>
                                {t('IntroPageBadFeedback.MeetEllyText')}
                            </StyledDivBold>
                            <img src={reversedBotLogo} alt='EllyIcon' style={{width: '80px', height: '80px'}} ></img>
                        </EllyDiv>
                    :
                        <EllyDiv >
                            <img src={reversedBotLogo} alt='EllyIcon' style={{width: '80px', height: '80px'}} ></img>
                            <StyledDivBold Color='#271B36' PhoneFontSize='60px' Position="relative" PhoneLineHeight='72px'>
                                {t('IntroPageBadFeedback.EllyTitle')}
                            </StyledDivBold>
                        </EllyDiv>
                    }
                    
                    {!isPortraitMode ?
                        <DockBodyWrapper JustifyContent="center">

                            <StepsWrapper MarginTop='32px' Width="95%">
                                <StepDiv>

                                    <StyledDivBold Color='#E2336B' FontSize='24px' Position="relative" LineHeight='52px'>
                                        {t('IntroPageBadFeedback.ExplanationTitle1')}
                                    </StyledDivBold>

                                    <StyledDivRegular Position="relative" TextAlign="center" MarginTop='32px' MarginLeft="0" Width='fit-content' Height='fit-content' MaxWidth='500px' Color='#271B36' FontSize='18px' LineHeight='28px' ZIndex="3">
                                        {t('IntroPageBadFeedback.ExplanationBox1')}
                                    </StyledDivRegular>

                                </StepDiv>

                                <StepDiv>

                                    <StyledDivBold Color='#E2336B' FontSize='24px' Position="relative" LineHeight='52px'>
                                        {t('IntroPageBadFeedback.ExplanationTitle2')}
                                    </StyledDivBold>

                                    <StyledDivRegular Position="relative" TextAlign="center" MarginTop='32px' MarginLeft="0" Width='fit-content' Height='fit-content' MaxWidth='500px' Color='#271B36' FontSize='18px' LineHeight='28px' ZIndex="3">
                                        {t('IntroPageBadFeedback.ExplanationBox2')}
                                    </StyledDivRegular>

                                </StepDiv>

                                <StepDiv>

                                    <StyledDivBold Color='#E2336B' FontSize='24px' Position="relative" LineHeight='52px'>
                                        {t('IntroPageBadFeedback.ExplanationTitle3')}
                                    </StyledDivBold>

                                    <StyledDivRegular Position="relative" TextAlign="center" MarginTop='32px' MarginLeft="0" Width='fit-content' Height='fit-content' MaxWidth='500px' Color='#271B36' FontSize='18px' LineHeight='28px' ZIndex="3">
                                        {t('IntroPageBadFeedback.ExplanationBox3')}
                                    </StyledDivRegular>

                                </StepDiv>

                            </StepsWrapper>

                            <StyledDivRegular Position="relative" TextAlign="center" MarginTop='48px' MarginLeft="0" Width='fit-content' Height='fit-content' Color='#E2336B' FontSize='18px' FontWeight='400' ZIndex="3" >
                                {t('IntroPageBadFeedback.ExplanationDisclaimer')}
                            </StyledDivRegular>

                            <HideDockButtonWrapper Display="flex" MarginTop="64px" ButtonWidth='271px' ButtonHeight='56px'>
                                <button onClick={() => {
                                    props.setDock(false);
                                }}>
                                    {t('IntroPageBadFeedback.StartConversation')}
                                </button>
                            </HideDockButtonWrapper>

                        </DockBodyWrapper>
                    :
                        <DockBodyWrapper id="dock-body-wrapper" ref={wrapperRef} {...handlers}>
                            <StepDiv direction={direction} key={animationKey}> 
                                <StyledDivBold Color='#E2336B' FontSize='24px' Position="relative" LineHeight='52px' PhoneFontSize='42px' PhoneLineHeight='62px' PhonePadding='1px' PhoneWidth='100%'>
                                    {t(`IntroPageBadFeedback.ExplanationTitle${currentStep + 1}`)}
                                </StyledDivBold>
                                <StyledDivRegular Position="relative" Width='fit-content' Height='fit-content' Color='#271B36' ZIndex="3" PhoneLineHeight='70px' PhoneFontSize='40px' PhonePadding='1px'>
                                    {t(`IntroPageBadFeedback.ExplanationBox${currentStep + 1}`)}
                                </StyledDivRegular>
                            </StepDiv>
                
                            <Indicator>
                                {steps.map((_, index) => (
                                    <div key={index} className={`dot ${index === currentStep ? 'active' : ''}`}></div>
                                ))}
                            </Indicator>
                
                            <NextButton >
                                {currentStep > 0 && (
                                <button onClick={() => handlePrevious()} className='previous-button' >
                                    <FontAwesomeIcon icon={faArrowLeft} /> {t('IntroPageBadFeedback.PreviousButton')}
                                </button>
                                )}

                                <button onClick={() => handleNext()}>
                                    {currentStep === steps.length - 1 ? ( <>{t('IntroPageBadFeedback.StartConversationButton')} <FontAwesomeIcon icon={faChevronRight} /> </> ) : (<> {t('IntroPageBadFeedback.NextButton')} <FontAwesomeIcon icon={faArrowRight} /></>)}
                                    </button>
                            </NextButton>
                        </DockBodyWrapper>
                    }

                </Dock>
            </>
        );
    }
};

export default SurveyTextProcessingOverlay

const DockBodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.JustifyContent || "flex-start"};
    align-items: center;
    z-index: 10;
    width: 100%;
    overflow-x: hidden;
    @media (-webkit-device-pixel-ratio: 1.25) {
        zoom: ${props => props.Zoom1 || ""};
    }
    @media (-webkit-device-pixel-ratio: 1.5) {
        zoom: ${props => props.Zoom2 || ""};
    }

    @media (orientation: portrait) {
    position: relative;
    width: 90%;
    height: 70%;
    margin: 0 auto;
}
`;

const SurveyIntroHeadingBg = styled.div`
    position: relative;
    width: calc(100% - 128px);
    height: fit-content;
    margin-top: ${props => props.MarginTop || "0%"};
    margin-left: ${props => props.MarginLeft || "0%"};
    box-shadow: ${props => props.BoxShadow || '0px 10px 10px -15px rgba(0,0,0,0.25)'};
    background: #271B36;
    z-index: 10;
    padding: 64px;

    @media (orientation: portrait) {
        height: 5%;
    }
`;

const SurveyIntroHeadingBlock = styled.div`
    position: ${props => props.Position || "fixed"};
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: ${props => props.Width || "900px"};
    margin-top: ${props => props.MarginTop || "0%"};
    margin-left: ${props => props.MarginLeft || "0%"};
    margin-right: ${props => props.MarginRight || "0%"};
    z-index: 10;

    @media (orientation: portrait) {
      width: 100%;
      position: relative;
      margin: 0 auto;
      height: 100%;
      justify-content: end;
    }
`;

const IntroImageWrapper = styled.div`
    position: ${props => props.Position || "fixed"};
    display: flex;
    align-items: center;
    margin-top: ${props => props.MarginTop || "0%"};
    margin-left: ${props => props.MarginLeft || "0%"};
    justify-content: center;
    width: ${props => props.Width || ""};
`;


const HideDockButtonWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: ${props => props.MarginTop || "0px"};
    margin-left: ${props => props.MarginLeft || "0px"};

    button {
      height: ${props => props.ButtonHeight || "56px"};
      width: ${props => props.ButtonWidth || "212px"};
      border: none;
      border-radius: 10px;
      background-color: #e2336B;
      color: #FCFCFC;
      font-size: 18px;
      font-weight: 400;
      font-family: 'Overpass';
      cursor: pointer;
      transition: all 0.2s ease-in;
    
      :hover {
        background-color: #B3194A;
      }
    }
`;

const IntroOverlayNumber = styled.div`
    position: fixed;
    margin-top: -90px;
    margin-bottom: ${props => props.MarginBottom || "0%"};
    width: ${props => props.Width || "40px"};
    height: ${props => props.Height || "40px"};
    background: #e2336B;
    z-index: 3;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${props => props.FontFamily || 'Overpass'};
    font-size: ${props => props.FontSize || '18px'};
    line-height: ${props => props.LineHeight || '28px'};
    font-weight: ${props => props.FontWeight || '400'};
    color: #fcfcfc;

    p {
        height: 25px;
    }
`;

const EllyDiv = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 32px;
    justify-content: center;
    margin-top: ${props => props.MarginTop || "0"};
    margin-left: ${props => props.MarginLeft || "0"};

    @media only screen and (min-width: 2000px) {
      margin-right: 0px;
    }

    @media (orientation: portrait) {
      width: fit-content;
      margin-left: 5%;
      margin-top: 5%;
      justify-content: start;
      align-items: center;

      img {
        width: 120px !important; // !important to override the styles for phone version
        height: 120px !important;
      }
    }
`; 

const StepsWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: ${props => props.Width || "70%"};
    height: 100%;
    justify-content: space-evenly;
    margin: 0 auto;
    margin-top: ${props => props.MarginTop || "70px"};

    @media only screen and (max-width: 2300px) {
    }
`;
  
const StepDiv = styled.div`
    position: relative;
    display: flex; 
    flex-direction: column;
    align-items: center;
    margin-top: ${props => props.MarginTop || "0"};
    margin-left: ${props => props.MarginLeft || "0"};
    width: ${props => props.Width || "33%"};

    @media only screen and (min-width: 2000px) {
      margin-right: 0px;
    } 

    @media (orientation: portrait) {
      margin-top: 60px;
      width: 100%;
      gap: 10px;
      align-items: start;
      overflow: hidden;   // hide the scrollbar
      margin-bottom: 100px;
        
      animation: ${({direction }) => (direction === 'next' ? slideInFromRight : slideInFromLeft)} 0.5s ease-out;
    }
`; 

const Indicator = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 200px;

    & .dot {
        height: 20px;
        width: 20px;
        margin: 0 10px;
        border-radius: 50%;
        background-color: #D9D9D9;
        transition: width 0.5s, background-color 0.5s;
    }
    
    & .active {
        background-color: #E2336B;
        width: 50px;
        border-radius: 28%;
    }
`;

const NextButton = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: ${props => props.MarginTop || "auto"};
    margin-left: ${props => props.MarginLeft || "0px"};
    width: 100%;
    gap: 40px;
    margin-bottom: 20px; // adjust if margin-bottom is not enough

    button {
      height: 120px;
      width: 100%;
      border: none;
      border-radius: 20px;
      background-color: ${props => props.BackgroundColor || "#e2336B"};
      color: #FCFCFC;
      font-size: 34px;
      font-weight: 400;
      font-family: 'Overpass';
      cursor: pointer;
      transition: all 0.2s ease-in;
      text-align: center;

      svg {
        margin: 0 10px;
      }

      :active {
        background-color: ${props => props.BackgroundColor || "#e2336B"};
        outline: none; // Prevents default focus outline
      }
    }

    .previous-button {
        background-color: #F1F3F4;
        color: #000; /* Adjust color of text if needed */

        &:active {
            background-color: #E3E5E4;
        }
    }
`;

const StepOneDiv = styled.div`
    display: flex; 
    flex-direction: column;
    align-items: center;
    margin-top: ${props => props.MarginTop || "0"};
    margin-left: ${props => props.MarginLeft || "0"};
    width: ${props => props.Width || "33%"};
    margin-right: 40px;
    @media only screen and (min-width: 2000px) {
    margin-right: 0px;
    margin-left: 0px;
  }`; 


const StepTwoDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${props => props.MarginTop || "0"};
    margin-left: ${props => props.MarginLeft || "0"};
    width: ${props => props.Width || "33%"};
    margin-right: 40px;
    @media only screen and (min-width: 2000px) {
    margin-right: 0px;
    margin-left: 0px;
  }`;

const StepThreeDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${props => props.MarginTop || "0"};
    margin-left: ${props => props.MarginLeft || "0"};
    width: 33%;
    margin-right: 40px;

    @media only screen and (min-width: 2000px) {
      margin-right: 0px;
      margin-left: 0px;
    }
`;

const HoverTooltip = styled.div`
    display: inline;
    color: #E2336B;
    font-weight: 700;
    cursor: pointer;
    position: relative;
    z-index: 2;
`;

// Keyframes for animations
const slideInFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

const slideInFromRight = keyframes`
    from {
        opacity: 0;
        transform: translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;