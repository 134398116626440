import {useState, useLayoutEffect } from 'react';

export const SurveyHeaderHeight=64;
export const SurveyFooterHeight=104;
export const ColleagueFilterComponentHeight = 694;
export const ColleagueFilterComponent1Width = 880;

/** Part to make page responsive to WindowSize changes, via useLayoutEffect, and functions to convert Float/String with px/% */
export function useWindowSize() {
    
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;  
}

/** functions to easily convert Figma design px positions to %, for responsive design based on document.width and height */
export function HeightFloatToString(num) {
    const windowHeight = useWindowSize()[1]
    let pixels = Math.round(num * windowHeight);
    const result = pixels ? pixels.toString()+"px" : '';
    return result
}

export function ImageFloatToString(num) {
    const windowHeight = useWindowSize()[1]
    let pixels = Math.round((854/900)*windowHeight)-100;
    const result = pixels ? pixels.toString()+"px" : '';
    return result
}

/** output where the center container margin-left starts in bigger screens */
export function CenterContainerVarLeftMargin(marginleft) {
    const windowWidth = useWindowSize()[0]

    if (windowWidth > 1920 ){
        let pixels = ((windowWidth-1920)/2 + marginleft);
        const result = pixels ? pixels.toString()+"px" : '';
        return result
    }
    else{
        const result = marginleft ? marginleft.toString()+"px" : '';
        return result
    }
}

export function CenterContainerVarLeftMarginPx(marginleft) {
    const windowWidth = useWindowSize()[0]

    if (windowWidth > 1920 ){
        let pixels = ((windowWidth-1920)/2 + marginleft);
        return pixels
    }
    else{
        return marginleft
    }
}

export function MarginAndImageWidth(imagesize) {
    const windowWidth = useWindowSize()[0]

    if (imagesize !== null && windowWidth !== null){
        let pixels = Math.round((windowWidth -imagesize)/2);
        const result = pixels ? pixels.toString()+"px" : '';
        return result;
    }
    else{}
}

export function TwoImagePositioning(imagesize) {
    const windowWidth = useWindowSize()[0]

    if (imagesize !== null && windowWidth !== null){
        let pixels = Math.round((windowWidth/2)+imagesize);
        const result = pixels ? pixels.toString()+"px" : '';
        return result
    }
    else{}
}

export function ThreeImagePositioning(imagesize) {
    const windowWidth = useWindowSize()[0]

    if (imagesize !== null && windowWidth !== null){
        let pixels = Math.round((windowWidth/3)+imagesize);
        const result = pixels ? pixels.toString()+"px" : '';
        return result
    }
    else{}
}

export function ThreeImagePositioningChoice(offset, posi) {
    const windowWidth = useWindowSize()[0]
    //imagesize not used yet

    if (offset !== null && windowWidth !== null && posi !== null){
        let third = Math.round(windowWidth/3);
        let placing = third*posi-(third/2) - offset;
        const result = placing ? placing.toString()+"px" : '';
        return result
    }
    else{}
}

export function MarginAndImageHeight(imagesize) {
    const windowHeight = useWindowSize()[1]
    let pixels = Math.round((windowHeight-imagesize)/2);
    const result = pixels ? pixels.toString()+"px" : '';
    return result
}


export function TooltipBottomTopMargin(componentheight) {
    let pixels = SurveyHeaderHeight + componentheight;
    const result = pixels ? pixels.toString()+"px" : '';
    return result
}

export function NumTooltipBottomTopMargin(componentheight) {
    let pixels = SurveyHeaderHeight + componentheight;
    return pixels
}

export function NumToPxStr(num){
    const result = num ? num.toString()+"px" : '';
    return result
}
