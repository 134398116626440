import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import ComponentTeamPersonFilter from '../../close-colleagues/page-specific-components/Component-team-person-filter';
import {TooltipMeetingsPage} from "../../standardized-components/Survey-social-network-tooltips";
import {useTranslation} from "react-i18next";

const ComponentFrequencyEmployees = (props) => {
    const [hover, setHover] = useState(false);
    const {t, i18n} = useTranslation();

    const [formattedColleagues, setFormattedColleagues] = useState(restructureCloseColleagues());
    const [userList, setUserList] = useState(prepareEmployees());
    const [currentUser, setCurrentUser] = useState(0);

    useEffect(() => {
        checkAllGradedandCalculateInteractionTime()
    },[])



    function checkAllGradedandCalculateInteractionTime() {
        let allGraded = true;
        let totalHours = 0;

        for (let i = 0; i < formattedColleagues.length; i++) {
            for (let j = 0; j < formattedColleagues[i].team_members.length; j++) {
                if (formattedColleagues[i].team_members[j].interactionTime > 0) 
                {
                    formattedColleagues[userList[currentUser].team_index].team_members[userList[currentUser].user_index].graded = true;
                }
                
                totalHours += formattedColleagues[i].team_members[j].interactionTime ? formattedColleagues[i].team_members[j].interactionTime : 0;
                if (!formattedColleagues[i].team_members[j].graded) {
                    allGraded = false;
                }
            }
        }
    }

    function prepareEmployees() {
        let listUnTeamedEmployees = [];
        for (let i = 0; i < formattedColleagues.length; i++) {
            listUnTeamedEmployees.push(...formattedColleagues[i].team_members);
        }

        return listUnTeamedEmployees;
    }

    function findInList(list, item) {
        if (list.length > 0) {
            for (var i = 0; i < list.length; i++) {
                if (list[i].team === item) {
                    return true;
                }
            }
        }

        return false;
    }

    function addToTeam(list, employee, team) {
        for (var i = 0; i < list.length; i++) {
            if (list[i].team === team) {
                employee.team_index = i;
                employee.user_index = list[i].team_members.length;
                list[i].team_members.push(employee);
            }
        }
    }


    //Changes structure from SignificantColleagues to fit component requirements
    function restructureCloseColleagues() {
        let colleagues = JSON.parse(localStorage.getItem("SignificantColleagues"))

        let formattedColleagues = []

        for (let i = 0; i < colleagues.length; i++) {
            if (!findInList(formattedColleagues, colleagues[i].team)) {
                formattedColleagues.push({"team": colleagues[i].team, "team_members": [colleagues[i]]});
                formattedColleagues[formattedColleagues.length - 1].team_members[0].team_index = formattedColleagues.length - 1
                formattedColleagues[formattedColleagues.length - 1].team_members[0].user_index = 0
            } else {
                addToTeam(formattedColleagues, colleagues[i], colleagues[i].team)
            }
        }

        if (localStorage.getItem("colleaguesSpontInteractions")) {
            let filledInList = JSON.parse(localStorage.getItem("colleaguesSpontInteractions"));
            for (let i = 0; i < filledInList.length; i++) {
                for (let j = 0; j < filledInList[i].team_members.length; j++) {
                    formattedColleagues = checkIfMemberExists(formattedColleagues, filledInList[i].team_members[j]);
                }
            }
        }

        fixActiveUser(formattedColleagues)
        formattedColleagues[0].team_members[0].active = true;

        return formattedColleagues
    }

    function checkIfMemberExists(currentColleagues, member) {
        for (var i = 0; i < currentColleagues.length; i++) {
            for (var j = 0; j < currentColleagues[i].team_members.length; j++) {
                if (currentColleagues[i].team_members[j].id === member.id) {
                    currentColleagues[i].team_members[j].interactionTime = member.interactionTime;
                    currentColleagues[i].team_members[j].graded = member.graded;
                    return currentColleagues
                }
            }
        }

        return currentColleagues
    }

    function fixActiveUser(list) {
        for (let i = 0; i < list.length; i++) {
            for (let j = 0; j < list[i].team_members.length; j++) {
                list[i].team_members[j].active = false;
            }
        }
    }

    function selectAll() {  
        props.MeetingsDays[props.selectedDay].meetings.map((meeting, index) => {
            if (index === props.selectedMeeting) {
                meeting.allColleagues = !meeting.allColleagues
            }
        });
        props.reRender()
        props.setMeetingsDays(props.MeetingsDays)
        localStorage.setItem('MeetingsDays', JSON.stringify(props.MeetingsDays))
      }

    return (

        <WrapperEmployees outline={!props.checkMeetingMembers & props.nextButtonClicked ? "2px solid #F72B2B" : ""}>
            <IncompleteContentText display={!props.checkMeetingMembers && props.nextButtonClicked ? "flex" : "none"}>
                {t("PagePlannedMeetings.MeetingMembersError")}
            </IncompleteContentText>
            <TopHeader>
                <TitleCategories>
                    {t('PagePlannedMeetings.EmployeesTitle')}
                </TitleCategories>
                {/* <ButtonWrapper Opacity={props.selectedMeeting < props.MeetingsDays[props.selectedDay].meetings.length ? '1' : '0.5'} Background={props.selectedMeeting >= props.MeetingsDays[props.selectedDay].meetings.length ? "#E3E5E4" : props.MeetingsDays[props.selectedDay].meetings[props.selectedMeeting].allColleagues ? "#B3194A" : "#E3E5E4"} 
                    Color={props.selectedMeeting >= props.MeetingsDays[props.selectedDay].meetings.length ? "#271B36" : props.MeetingsDays[props.selectedDay].meetings[props.selectedMeeting].allColleagues ? "#FCFCFC" : "#271B36"} Cursor={props.selectedMeeting < props.MeetingsDays[props.selectedDay].meetings.length ? "pointer" : "normal"}
                    BorderHover={props.selectedMeeting >= props.MeetingsDays[props.selectedDay].meetings.length ? "solid 2px #271B36" : "solid 2px #B3194A"} Border={props.selectedMeeting >= props.MeetingsDays[props.selectedDay].meetings.length ? "solid 2px #271B36" : props.MeetingsDays[props.selectedDay].meetings[props.selectedMeeting].allColleagues ?  "solid 2px #FCFCFC" : "solid 2px #271B36"}>
                    <button onClick={() => selectAll()}>
                        {t("PagePlannedMeetings.SelectAll")}
                    </button>
                </ButtonWrapper> */}
                <InfoHover onMouseOver={() => setHover(true)}
                            onMouseOut={() => setHover(false)}>?
                    {hover && (<TooltipMeetingsPage whichTooltip={"PeopleSelected"}/>
                    )}
                </InfoHover>
            </TopHeader>

            <ComponentTeamPersonFilter MeetingsDays={props.MeetingsDays} setMeetingsDays={props.setMeetingsDays} selectedDay={props.selectedDay}
                                        selectedMeeting={props.selectedMeeting} reRender={props.reRender} whichFilter="PagePlannedMeetings">

            </ComponentTeamPersonFilter>
        </WrapperEmployees>
    );
}

export default ComponentFrequencyEmployees;

const WrapperEmployees = styled.div`
  height: 100%;
  width: 35%;
  min-width: 530px;
  background-color: #FCFCFC;
  margin-left: 0px;
  opacity: ${props => props.Opacity || "1"};
  pointer-events: ${props => props.PointerEvents || ""};
  position: relative;
  border-radius: ${props => props.BorderRadius || '10px'};
  
  @media only screen and (max-width: 1700px)  and (-webkit-device-pixel-ratio: 1) {
    width: ${props => props.Width || "480px"};
  }
  @media only screen and (max-width: 1600px)  and (-webkit-device-pixel-ratio: 1) {
    width: ${props => props.Width || "360px"};
  }
  outline: ${props => props.outline || "none"};
  outline-offset: -2px;
`;

const IncompleteContentText = styled.div`
  position: absolute;
  display: ${(props) => props.display || ""};
  border-radius: ${(props) => props.BorderRadius || "5px"};
  color: #F72B2B;
  margin-left: 100px;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  z-index: 1;
`;


const TitleCategories = styled.div`
  justify-content: center;
  font-family: 'Overpass';
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: #271B36;
  margin-top: 16px;
  margin-left: 20px;
  margin-bottom: ${(props) => props.Marginbot || "0"};
`

const InfoHover = styled.div`
  float: right;
  font-size: 18px;
  font-family: 'Overpass', sans-serif;
  font-weight: 700;
  position: relative;
  color: #FCFCFC;
  background: #E2336B;
  margin-right: 16px;
  margin-top: 16px;
  border: 2px solid #E2336B;
  border-radius: 500px;
  width: 20px;
  text-align: center;
  height: 20px;
  cursor: pointer;
`;

const TopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  button {
    display: flex;
    vertical-align: middle;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 32px;
    margin-top: 12px;
    padding: 8px 12px 8px 12px;
    color: ${props => props.Color || "#271B36"};
    background: ${props => props.Background || "#E3E5E4"};
    border-radius: 12px;
    border: ${props => props.Border || "solid 2px #271B36"};
    font-size: 16px;
    font-family: "Overpass";
    cursor: ${props => props.Cursor || "pointer"};
    gap: 8px;
    opacity: ${props => props.Opacity || "1"};
  }

  button:hover {
    border: ${props => props.BorderHover || "solid 2px #271B36"};
  }
`;