import axios from "axios";
import getCookieValue from "../../utils";

/** API URL, variable dependent on what branch you ar eon, dev staging, or main branches*/
// const baseURL = process.env.REACT_APP_APIURL

/**  View for the dashboard Social Network page with developer mode */
export async function DeveloperSocialNetworkPage(selected_company, selected_survey) {
    /** define axiosInstance for API requests */
    const axiosInstance = axios.create({withCredentials: true});

    axiosInstance
        .get(
            process.env.REACT_APP_APIURL +
            "/api/developer_social_network/" +
            selected_company + "/" +
            selected_survey
        )
        .then((res) => {
            localStorage.setItem("graph_data", JSON.stringify(res.data));
            window.dispatchEvent(new Event('graph_data_event'))
        })
        .catch((error) => {
            console.log("ERROR: Could not fetch /api/developer_social_network/");
        });

}

/**  View for the dashboard Social Network page */
export async function DashboardSocialNetworkPage() {
    /** define axiosInstance for API requests */
    const axiosInstance = axios.create({withCredentials: true});

    axiosInstance
        .get(
            process.env.REACT_APP_APIURL + "/api/social_network/"
        )
        .then((res) => {
            localStorage.setItem("graph_data", JSON.stringify(res.data));
            window.dispatchEvent(new Event('graph_data_event'))
        })
        .catch((error) => {
            console.log("ERROR: Could not fetch /api/social_network/");
        });
    
}

/**  View for the dashboard Network comparison page with developer mode */
export async function DeveloperComparisonPage(selected_company, selected_survey) {
    /** define axiosInstance for API requests */
    const axiosInstance = axios.create({withCredentials: true});

    axiosInstance
        .get(
            process.env.REACT_APP_APIURL +
            "/api/developer_comparison/" +
            selected_company + "/" +
            selected_survey
        )
        .then((res) => {
            localStorage.setItem("comparison_data", JSON.stringify(res.data));
            window.dispatchEvent(new Event('comparison_data_event'))
        })
        .catch((error) => {
            console.log("ERROR: Could not fetch /api/developer_dashboard_comparison/");
        });

}

/**  View for the dashboard Network comparison page */
export async function DashboardComparisonPage() {
    /** define axiosInstance for API requests */
    const axiosInstance = axios.create({withCredentials: true});

    axiosInstance
        .get(
            process.env.REACT_APP_APIURL + "/api/dashboard_comparison/"
        )
        .then((res) => {
            localStorage.setItem("comparison_data", JSON.stringify(res.data));
            window.dispatchEvent(new Event('comparison_data_event'))
        })
        .catch((error) => {
            console.log("ERROR: Could not fetch /api/dashboard_comparison/");
        });

}


/**  View for the Dashboard Presentation page with developer mode */
export async function DeveloperNetworkFeedbackPage(selected_company, selected_survey) {
    /** define axiosInstance for API requests */
    const axiosInstance = axios.create({withCredentials: true});

    axiosInstance
        .get(
            process.env.REACT_APP_APIURL +
            "/api/developer_network_feedback/" +
            selected_company + "/" +
            selected_survey
        )
        .then((res) => {
            localStorage.setItem("presentation_data_overview", JSON.stringify({"tags_overview" : res.data["tags_overview"],
                                                                                "company" : res.data["company"],
                                                                                "survey" : res.data["survey"]}));
            localStorage.setItem("presentation_data_actions", JSON.stringify({"tags_actions" : res.data["tags_actions"],
                                                                            "company" : res.data["company"],
                                                                            "survey" : res.data["survey"]}));
            window.dispatchEvent(new Event('presentation_data_event'))
        })
        .catch((error) => {
            console.log("ERROR: Could not fetch /api/developer_network_feedback/");
        });

}
