import React from 'react';
import styled from 'styled-components';
import { StyledDivRegular } from '../../../layout-and-styling/standardized-components-library/Styling-TextInput';
import ProgressBar from "@ramonak/react-progress-bar";
import i18next from "i18next";
import {Trans, useTranslation} from 'react-i18next';

const ProgressBarComponent = (props) => {
  const {t, i18n} = useTranslation();

  const startLabel = t('ProgressBar.Start');
  const finishLabel = t("ProgressBar.Finish");

    const steps = [
        {
            value: "0%",
            label: startLabel
        },
        // {
        //     value: "25%",
        //     label: "Problem"
        // },
        // {
        //     value: "50%",
        //     label: "Root cause"
        // },
        // {
        //     value: "75%",
        //     label: "Solutions"
        // },
        {
            value: "90%",
            label: finishLabel
        },
    ];

    const progressBarHeight = window.matchMedia("(orientation: portrait)").matches ? "20px" : "8px";

    return (
        <ProgressBarWrapper>
            <Bar style={{ width: '100%' }}>
              <ProgressBar completed={props.progress} customLabel=" " height={progressBarHeight} width='100%' bgColor="#e2336b" baseBgColor="#E3E5E4" />
            </Bar>
            <Labels>
                {steps.map((step, index) => (
                    <StyledDivRegular key={index} Position="relative" Width='fit-content' Height='fit-content'> {step["label"]} </StyledDivRegular>
                ))}
            </Labels>
        </ProgressBarWrapper>
    );
}

export default ProgressBarComponent;

const ProgressBarWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: fit-content;
  gap: 8px;

  @media (orientation: portrait) {
    gap: 20px;
  }

`;

const Bar = styled.div`
  width: 100%;
`;

const Labels = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: fit-content;
  flex-direction: row;
  justify-content: space-between;
  gap: 64px;
`;
