import styled from "styled-components";
import TimeField from 'react-simple-timefield';
import {FormControl, Select, Slider} from '@mui/material';

export const StyledDivRegular = styled.div`
  position: ${props => props.Position || 'fixed'};
  margin-top: ${props => props.MarginTop || "0%"};
  margin-left: ${props => props.MarginLeft || "0%"};
  margin-right: ${props => props.MarginRight || "0%"};
  margin-bottom: ${props => props.MarginBottom || "0%"};
  width: ${props => props.Width || "24px"};
  min-width: ${props => props.MinWidth || ''};
  max-width: ${props => props.MaxWidth || ''};
  min-height: ${props => props.MinHeight || ''};
  max-height: ${props => props.MaxHeight || ''};
  height: ${props => props.Height || "24px"};
  border: ${props => props.Border || 'none'}; //1px solid #E3E5E4
  border-radius: ${props => props.BorderRadius || '5px'};
  box-shadow: ${props => props.BoxShadow || 'none'}; //0px 10px 10px -15px rgba(0,0,0,0.25)
  color: ${props => props.Color || '#271B36'};
  background: ${props => props.Background || 'none'};
  padding: ${props => props.Padding || '0px 0px 0px 0px'};
  display: ${props => props.Display || 'inline'};
  align-items: ${props => props.AlignItems || 'center'};
  justify-content: ${props => props.JustifyContent || 'center'};
  flex-direction: ${props => props.FlexDirection || ''};
  font-family: ${props => props.FontFamily || 'Overpass'};
  font-size: ${props => props.FontSize || '16px'};
  line-height: ${props => props.LineHeight || '20px'};
  font-weight: ${props => props.FontWeight || '400'};
  font-style: ${props => props.FontStyle || ''};
  letter-spacing: ${props => props.LetterSpacing || ''};
  flex-wrap: ${props => props.FlexWrap || ''};
  overflow-wrap: ${props => props.OverflowWrap || ''};
  hyphens: ${props => props.Hyphens || ''};
  white-space: ${props => props.WhiteSpace || ''};
  overflow: ${props => props.Overflow || ''};
  text-overflow: ${props => props.TextOverflow || ''};
  gap: ${props => props.Gap || ''};
  overflow: ${props => props.OVerflow || ''};
  border-left: ${props => props.BorderLeft || ''};
  border-top: ${props => props.BorderTop || ''};
  text-decoration: ${props => props.TextDecoration || ''};
  cursor: ${props => props.Cursor || ''};
  text-align: ${props => props.TextAlign || ''};
  z-index: ${(props) => props.ZIndex || ""};
  float: ${(props) => props.Float || ""};
  top: ${(props) => props.Top || ""};
  left: ${(props) => props.Left || ""};
  right: ${(props) => props.Right || ""};
  bottom: ${(props) => props.Bottom || ""};
  word-break: ${(props) => props.WordBreak || ""};

  span {
    color: ${props => props.SpanColor || '#e2336B'};
  }

  @media (orientation: portrait) {
    display: ${props => props.PhoneDisplay || 'inline'};
    position: ${props => props.PhonePosition || 'relative'};
    gap: ${props => props.PhoneGap || ''};
    align-items: ${props => props.PhoneAlignItems || ''};
    justify-content: ${props => props.PhoneJustifyContent || ''};
    align-self: ${props => props.PhoneAlignSelf || ''};

    margin: ${props => props.PhoneMargin || ''};
    margin-top: ${props => props.PhoneMarginTop || "0%"};
    margin-left: ${props => props.PhoneMarginLeft || "0%"};
    margin-right: ${props => props.PhoneMarginRight || "0%"};
    margin-bottom: ${props => props.PhoneMarginBottom || '0%'};

    padding-left: ${props => props.PhonePaddingLeft || ''};
    padding-right: ${props => props.PhonePaddingRight || ''};
    padding-top: ${props => props.PhonePaddingTop || ''};
    padding-bottom: ${props => props.PhonePaddingBottom || ''};
    padding: ${props => props.PhonePadding || ''};

    width: ${props => props.PhoneWidth || ''};
    height: ${props => props.PhoneHeight || ''};
    min-width: ${props => props.PhoneMinWidth || ''};
    max-width: ${props => props.PhoneMaxWidth || ''};
    
    font-size: ${props => props.PhoneFontSize || '36px'};
    line-height: ${props => props.PhoneLineHeight || '40px'};
    font-weight: ${props => props.PhoneFontWeight || '400'};
    text-align: ${props => props.PhoneTextAlign || ''};

    a {
    text-decoration: ${props => props.PhoneLinkTextDecoration || ''};
    }

    background-color: ${props => props.PhoneBackgroundColor || ''};
    border: ${props => props.PhoneBorder || 'none'};
    border-radius: ${props => props.PhoneBorderRadius || '5px'};
    box-sizing: ${props => props.PhoneBoxSizing || ''};
}
`;

export const StyledDivBold = styled.div`
  position: ${props => props.Position || 'fixed'};
  margin-top: ${props => props.MarginTop || "0%"};
  margin-left: ${props => props.MarginLeft || "0%"};
  margin-right: ${props => props.MarginRight || "0%"};
  margin-bottom: ${props => props.MarginBottom || "0%"};
  width: ${props => props.Width || ""};
  height: ${props => props.Height || ""};
  border: ${props => props.Border || 'none'}; //1px solid #E3E5E4
  border-radius: ${props => props.BorderRadius || '5px'};
  box-shadow: ${props => props.BoxShadow || 'none'}; //0px 10px 10px -15px rgba(0,0,0,0.25)
  color: ${props => props.Color || '#271B36'};
  background: ${props => props.Background || 'none'};
  padding: ${props => props.Padding || '0px 0px 0px 0px'}; /* text-indent: 20px; */
  transition: all 0.2s ease-in;
  font-family: ${props => props.FontFamily || 'Montserrat'};
  font-size: ${props => props.FontSize || '16px'};
  line-height: ${props => props.LineHeight || '20px'};
  font-weight: ${props => props.FontWeight || '700'};
  letter-spacing: ${props => props.LetterSpacing || ''};
  display: ${props => props.Display || 'inline'};
  align-items: ${props => props.AlignItems || 'center'};
  justify-content: ${props => props.JustifyContent || 'center'};
  gap: ${props => props.Gap || ''};
  overflow: ${props => props.Overflow || 'hidden'};
  text-align: ${props => props.TextAlign || ''};
  z-index: ${props => props.ZIndex || ''};

  @media (orientation: portrait) {
    display: ${props => props.PhoneDisplay || 'inline'};
    gap: ${props => props.PhoneGap || ''};
    color: ${props => props.PhoneColor || ''};
    position: ${props => props.PhonePosition || 'relative'};

    margin-top: ${props => props.PhoneMarginTop || "0%"};
    margin-left: ${props => props.PhoneMarginLeft || "0%"};
    margin-right: ${props => props.PhoneMarginRight || "0%"};
    margin-bottom: ${props => props.PhoneMarginBottom || '0%'};

    width: ${props => props.PhoneWidth || ''};
    height: ${props => props.PhoneHeight || ''};

    font-size: ${props => props.PhoneFontSize || '32px'};
    line-height: ${props => props.PhoneLineHeight || '40px'};
    text-align: ${props => props.PhoneTextAlign || ''};
    font-weight: ${props => props.PhoneFontWeight || '700'};

    border: ${props => props.PhoneBorder || 'none'};
    border-radius: ${props => props.PhoneBorderRadius || '5px'};
    
    padding: ${props => props.PhonePadding || '0px'};
  }
`;

export const StyledInputTextRegular = styled.input`
  position: ${(props) => props.Position || "fixed"};
  margin-top: ${props => props.MarginTop || "0%"};
  margin-left: ${props => props.MarginLeft || "0%"};
  width: ${props => props.Width || "610px"};
  height: ${props => props.Height || "32px"};
  text-indent: 20px;
  border: ${props => props.Border || 'none'}; //1px solid #E3E5E4
  border-radius: ${props => props.BorderRadius || ''};
  box-shadow: ${props => props.BoxShadow || 'none'}; //0px 10px 10px -15px rgba(0,0,0,0.25)
  color: ${props => props.inputColor || '#FCFCFC'};
  background: ${props => props.inputBackground || '#271B36'};
  padding: ${props => props.Padding || '0px 0px 0px 0px'};
  transition: all 0.2s ease-in;
  font-family: ${props => props.FontFamily || 'Overpass'};
  font-size: ${props => props.FontSize || '16px'};
  line-height: ${props => props.LineHeight || '20px'};
  font-weight: ${props => props.FontWeight || '400'};
  overflow: ${props => props.OVerflow || ''};
  z-index: ${props => props.ZIndex || '3'};

  &:focus {
    outline: none !important;
  }

  //stop black border to appear 
  ::placeholder {
    color: ${props => props.PlaceholderColor || '#C6CBCB'};
    font-family: ${props => props.PlaceholderFontFamily || 'Overpass'};
    font-size: ${props => props.PlaceholderFontSize || '16px'};
    font-weight: ${props => props.PlaceholderFontWeight || '400'};
  }
`;

export const StyledInputTextBold = styled.input`
  position: fixed;
  margin-top: ${props => props.MarginTop || "0%"};
  margin-left: ${props => props.MarginLeft || "0%"};
  width: ${props => props.Width || "610px"};
  height: ${props => props.Height || "32px"};
  text-indent: 20px;
  border: ${props => props.Border || 'none'}; //1px solid #E3E5E4
  border-radius: ${props => props.BorderRadius || ''};
  box-shadow: ${props => props.BoxShadow || 'none'}; //0px 10px 10px -15px rgba(0,0,0,0.25)
  color: ${props => props.inputColor || '#FCFCFC'};
  background: ${props => props.inputBackground || '#271B36'};
  padding: 0px 0px 0px 0px; // padding inside input box
  transition: all 0.2s ease-in;
  font-family: ${props => props.FontFamily || 'Montserrat'};
  font-size: ${props => props.FontSize || '16px'};
  line-height: ${props => props.LineHeight || '20px'};
  font-weight: ${props => props.FontWeight || '700'};
  z-index: ${props => props.ZIndex || '3'};

  &:focus {
    outline: none !important;
  }

  //stop black border to appear 
  ::placeholder {
    color: ${props => props.PlaceholderColor || '#C6CBCB'};
    font-family: ${props => props.PlaceholderFontFamily || 'Overpass'};
    font-size: ${props => props.PlaceholderFontSize || '16px'};
    font-weight: ${props => props.PlaceholderFontWeight || '700'};
  }
`;

export const StyledInputTextAreaRegular = styled.textarea`
  position: ${(props) => props.Position || "fixed"};
  margin-top: ${props => props.MarginTop || "0%"};
  margin-left: ${props => props.MarginLeft || "0%"};
  width: ${props => props.Width || "96px"};
  height: ${props => props.Height || "40px"};
  color: ${props => props.inputColor || "#271B36"};
  background: ${props => props.inputBackground || '#FCFCFC'};
  border: ${props => props.Border || '1px solid #E3E5E4'};
  border-radius: ${props => props.BorderRadius || '5px'};
  box-shadow: ${props => props.BoxShadow || '0px 10px 10px -15px rgba(0,0,0,0.25)'};
  padding: ${props => props.Padding || '20px 0px 0px 20px'};
  transition: all 0.2s ease-in;
  font-family: ${props => props.FontFamily || 'Overpass'};
  font-size: ${props => props.FontSize || '16px'};
  line-height: ${props => props.LineHeight || '20px'};
  font-weight: ${props => props.FontWeight || '400'};
  resize: none; //disable resize option
  z-index: 3; /* overflow: hidden; */


  &:focus {
    outline: none !important;
  }

  //stop black border to appear 
  ::placeholder {
    color: ${props => props.PlaceholderColor || '#929699'};
    font-family: 'Overpass', sans-serif;
    font-size: 16px;
    font-weight: 400;
  }

  @media only screen and (max-width: 1900px) {
    width: 455px;
  }

  @media only screen and (max-width: 1600px) {
    width: 230px;
  }

  @media only screen and (max-width: 1360px) {
    width: 160px;
  }
`;

export const StyledTextAreaFeedbackJustification = styled.textarea`
  position: ${(props) => props.Position || "fixed"};
  margin-top: ${props => props.MarginTop || "0%"};
  margin-right: ${props => props.MarginRight || "0%"};
  margin-left: ${props => props.MarginLeft || "0%"};
  width: ${props => props.Width || "96px"};
  height: ${props => props.Height || "40px"};
  color: ${props => props.inputColor || "#271B36"};
  background: ${props => props.inputBackground || '#FCFCFC'};
  border: ${props => props.Border || '1px solid #E3E5E4'};
  border-radius: ${props => props.BorderRadius || '5px'};
  box-shadow: ${props => props.BoxShadow || '0px 10px 10px -15px rgba(0,0,0,0.25)'};
  padding: ${props => props.Padding || '20px 0px 0px 20px'};
  transition: all 0.2s ease-in;
  font-family: ${props => props.FontFamily || 'Overpass'};
  font-size: ${props => props.FontSize || '16px'};
  line-height: ${props => props.LineHeight || '20px'};
  font-weight: ${props => props.FontWeight || '400'};
  resize: none; //disable resize option
  z-index: ${props => props.ZIndex || '3'};

  &:focus {
    outline: none !important;
  }

  //stop black border to appear 
  ::placeholder {
    color: ${props => props.PlaceholderColor || '#929699'};
    font-family: 'Overpass', sans-serif;
    font-size: 16px;
    font-weight: 400;
  }
  //
  //@media only screen and (max-width: 1900px) {
  //  width: 455px;
  //}
  //
  @media only screen and (max-width: 1600px) {
    padding: 32px 20px 12px 20px;
    height: 145px;
  }

  @media only screen and (max-width: 1360px) {
    padding: 32px 20px 12px 20px;
    height: 145px;
  }
`;

export const StyledTextAreaFeedbackProposal = styled.textarea`
  position: ${(props) => props.Position || "fixed"};
  margin-top: ${props => props.MarginTop || "0%"};
  margin-left: ${props => props.MarginLeft || "0%"};
  margin-right: ${props => props.MarginRight || "0%"};
  width: ${props => props.Width || "96px"};
  height: ${props => props.Height || "40px"};
  color: ${props => props.inputColor || "#271B36"};
  background: ${props => props.inputBackground || '#FCFCFC'};
  border: ${props => props.Border || '1px solid #E3E5E4'};
  border-radius: ${props => props.BorderRadius || '5px'};
  box-shadow: ${props => props.BoxShadow || '0px 10px 10px -15px rgba(0,0,0,0.25)'};
  padding: ${props => props.Padding || '20px 0px 0px 20px'};
  transition: all 0.2s ease-in;
  font-family: ${props => props.FontFamily || 'Overpass'};
  font-size: ${props => props.FontSize || '16px'};
  line-height: ${props => props.LineHeight || '20px'};
  font-weight: ${props => props.FontWeight || '400'};
  resize: none; //disable resize option
  z-index: ${props => props.ZIndex || '3'};

  &:focus {
    outline: none !important;
  }

  //stop black border to appear 
  ::placeholder {
    color: ${props => props.PlaceholderColor || '#929699'};
    font-family: 'Overpass', sans-serif;
    font-size: 16px;
    font-weight: 400;
  }
  //
  @media only screen and (max-width: 1900px) {
    padding: 32px 20px 12px 20px;
    height: 145px;
  }

  @media only screen and (max-width: 1600px) {
    padding: 52px 20px 12px 20px;
    height: 125px;
  }

  @media only screen and (max-width: 1360px) {
    padding: 52px 20px 12px 20px;
    height: 125px;
  }
`;

export const StyledDivButton = styled.div`
  background-color: #FCFCFC;
  position: ${props => props.Position || "fixed"};
  margin-top: ${props => props.MarginTop || "39.4%"};
  margin-left: ${props => props.MarginLeft || "80.1%"};
  border-radius: ${props => props.BorderRadius || "5px"};
  z-index: ${props => props.ZIndex || 4};

  button {
    width: ${props => props.Width || "136px"};
    height: ${props => props.Height || "48px"};
    border: ${props => props.Border || "none"};
    border-radius: ${props => props.BorderRadius || "5px"};
    background-color: ${props => props.BackgroundColor || "#e2336B"};
    color: ${props => props.Color || "#FCFCFC"};
    box-shadow: ${props => props.BoxShadow || ''};
    font-size: ${props => props.FontSize || "18px"};
    font-weight: ${props => props.FontWeight || "400"};
    font-family: ${props => props.FontFamily || "Overpass"};
    line-height: ${props => props.LineHeight || ""};
    cursor: ${props => props.Cursor || "pointer"};
    transition: ${props => props.Transition || ""}; //transition: all 0.2s ease-in;
    display: ${props => props.Display || 'flex'};
    align-items: ${props => props.AlignItems || 'center'};
    justify-content: ${props => props.JustifyContent || 'center'};
    gap: ${props => props.Gap || '0px 8px'};
    padding: ${props => props.Padding || ''};
  }

  @media (orientation: portrait) {
    padding: ${props => props.PhonePadding || ''};
    position: ${props => props.PhonePosition || ""};
    margin: ${props => props.PhoneMargin || ""};
    width: ${props => props.PhoneWidth || ""};
    height: ${props => props.PhoneHeight || ""};

    button {
      gap: ${props => props.PhoneGap || ""};
      width: ${props => props.PhoneButtonWidth || ""};
      height: ${props => props.PhoneButtonHeight || ""};
      font-size: ${props => props.PhoneFontSize || ""};
      font-weight: ${props => props.PhoneFontWeight || ""};
      border-radius: ${props => props.PhoneBorderRadius || ""};
      border: ${props => props.PhoneBorder || ""};
      

      img {
        width: ${props => props.PhoneImgWidth || ""};
        height: ${props => props.PhoneImgHeight || ""};
      }
    }
  }
`;

export const StyledInput = styled.input`
  position: ${props => props.Position || 'fixed'};
  margin-top: ${props => props.MarginTop || "0%"};
  margin-left: ${props => props.MarginLeft || "0%"};
  width: ${props => props.Width || "96px"};
  height: ${props => props.Height || "40px"};
  color: ${props => props.inputColor || "#271B36"};
  background: ${props => props.inputBackground || '#FCFCFC'};
  margin: 0 0.6rem; // margin between input boxes .8rem 
  border: ${props => props.Border || '1px solid #E3E5E4'};
  border-radius: ${props => props.BorderRadius || '5px'};
  box-shadow: ${props => props.BoxShadow || '0px 10px 10px -15px rgba(0,0,0,0.25)'};
  padding: 0px 15px 0px; // padding inside input box
  transition: all 0.2s ease-in;
  font-family: 'Overpass';
  font-size: 16px;
  font-weight: 400;

  &:focus {
    outline: none !important;
    border: 1px solid #e2336B;
  }

  &:active {
    background: ${props => props.ActiveBackground || '#271B36'};
  }

  ::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }

  ::placeholder {
    color: ${props => props.PlaceholderColor || '#C6CBCB'};
    font-family: ${props => props.PlaceholderFontFamily || 'Overpass'};
    font-size: ${props => props.PlaceholderFontSize || '16px'};
    font-weight: ${props => props.PlaceholderFontWeight || '400'};
  }

  @media (orientation: portrait) {
    font-size: 40px;
    line-height: 60px;
    position: relative;
    width: 96%;
    height: 120px;
    margin: 0;
    border-radius: 25px;
    padding-left: 20px;

    ::placeholder {
    font-size: 40px;
  }
}
`;

export const StyledInputLeaderIntake = styled.input`
  position: fixed;
  margin-top: ${props => props.MarginTop || "0%"};
  margin-left: ${props => props.MarginLeft || "0%"};
  width: ${props => props.Width || "96px"};
  height: ${props => props.Height || "40px"};
  color: ${props => props.inputColor || "#271B36"};
  background: ${props => props.inputBackground || '#FCFCFC'};
  border-style: solid;
  border: 1px solid #E3E5E4;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  /* box-shadow: 0px 10px 10px -15px rgba(0,0,0,0.25); */
  text-indent: 20px;
  transition: all 0.2s ease-in;
  font-family: 'Overpass', sans-serif;
  font-size: 16px;
  font-weight: 400;

  &:focus {
    outline: none !important;
  }

  //stop black border to appear 
`;

/** StyledTimeField is an external library to have a customized react default input type ='time', then inherit styled-components class */
export const StyledTimeField = styled(TimeField)`
  margin-right: 20px;
  border: 1px solid #E3E5E4;
  font-family: 'Overpass';
  font-size: 16px;
  padding: 0px 15px 0px;
  color: #271B36;

  &:focus {
    outline: none !important;
    border: 1px solid #e2336B;
  }
`;

export const StyledCheckbox = styled.input.attrs({type: "checkbox"})`
  position: ${props => props.Position || 'relative'};
  margin-top: ${props => props.MarginTop || "0%"};
  margin-left: ${props => props.MarginLeft || "0%"};
  width: ${props => props.Width || "24px"};
  height: ${props => props.Height || "24px"};
  border: ${props => props.Border || '1px solid #E3E5E4'};
  border-radius: ${props => props.BorderRadius || '5px'};
  box-shadow: ${props => props.BoxShadow || '0px 2px 4px rgba(0, 0, 0, 0.08'}; //0px 10px 10px -15px rgba(0,0,0,0.25)
  color: ${props => props.Color || '#271B36'};
  background: ${props => props.Background || 'none'};
  padding: ${props => props.Padding || '0px 0px 0px 0px'};
  transition: all 0.2s ease-in;
  font-family: ${props => props.FontFamily || 'Montserrat'};
  font-size: ${props => props.FontSize || '16px'};
  line-height: ${props => props.LineHeight || '20px'};
  font-weight: ${props => props.FontWeight || '700'};
`;


export const StyledSelect = styled.select`
  position: ${props => props.Position || ''};
  margin-top: ${props => props.MarginTop || "0%"};
  margin-left: ${props => props.MarginLeft || "0%"};
  width: ${props => props.Width || "96px"};
  height: ${props => props.Height || "40px"};
  color: ${props => props.inputColor || "#271B36"};
  background: ${props => props.inputBackground || '#FCFCFC'};
  border: ${props => props.Border || '1px solid #0A070E'};
  border-radius: ${props => props.BorderRadius || '10px'};
  box-shadow: ${props => props.BoxShadow || '0px 10px 10px -15px rgba(0,0,0,0.25)'};
  padding: 0px 15px 0px; // padding inside input box
  transition: all 0.2s ease-in;
  font-family: 'Overpass';
  font-size: 18px;
  font-weight: 400;
  z-index: 3;
  cursor: pointer;
`;

export const StyledSelectOption = styled.option`
  position: ${props => props.Position || 'fixed'};
  margin-top: ${props => props.MarginTop || "0%"};
  margin-left: ${props => props.MarginLeft || "0%"};
  width: ${props => props.Width || "96px"};
  height: ${props => props.Height || "40px"};
  color: ${props => props.inputColor || "#271B36"};
  background: ${props => props.inputBackground || '#FCFCFC'};
  border: ${props => props.Border || '1px solid #E3E5E4'};
  border-radius: ${props => props.BorderRadius || '5px'};
  box-shadow: ${props => props.BoxShadow || '0px 10px 10px -15px rgba(0,0,0,0.25)'};
  padding: 0px 15px 0px;
  transition: all 0.2s ease-in;
  font-family: 'Overpass';
  font-size: 18px;
  font-weight: 400;

  &:active {
    background: ${props => props.ActiveBackground || '#271B36'};
  }

  ::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }

  ::placeholder {
    color: ${props => props.PlaceholderColor || '#C6CBCB'};
    font-family: ${props => props.PlaceholderFontFamily || 'Overpass'};
    font-size: ${props => props.PlaceholderFontSize || '16px'};
    font-weight: ${props => props.PlaceholderFontWeight || '400'};
  }
`;


export const StyledSlider = styled(Slider)`
  position: ${props => props.Position || 'relative'};
  margin-top: ${props => props.MarginTop || ""};
  margin-left: ${props => props.MarginLeft || "10px"};
  width: ${props => props.Width || "200px"};
  height: ${props => props.Height || "8px"};
  font-family: 'Overpass';
  font-size: 16px;
  color: #271B36;

  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: 1.25;
  }

  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: 1.5;
  }

  @media (-webkit-device-pixel-ratio: 1.75) {
    zoom: 1.75;
  }

  &:focus {
    outline: none !important;
    border: 1px solid #e2336B;
  }
`;

export const StyledSliderClientSupplier = styled(Slider)`
  position: ${props => props.Position || 'relative'};
  margin-top: ${props => props.MarginTop || ""};
  margin-left: ${props => props.MarginLeft || "10px"};
  width: ${props => props.Width || "200px"};
  height: ${props => props.Height || "8px"};
  font-family: 'Overpass';
  font-size: 16px;
  color: #271B36;

  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: 1.25;
    margin-right: 5%;
    width: 600px !important;
  }

  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: 1.5;
    margin-right: 5%;
    width: 200px !important;
  }

  @media (-webkit-device-pixel-ratio: 1.75) {
    zoom: 1.75;
    margin-right: 5%;
    width: 170px !important;
  }

  &:focus {
    outline: none !important;
    border: 1px solid #e2336B;
  }
`;

export const StyledIndividualPerformanceSliders = styled(Slider)`
  position: relative;
  height: 100%;
  font-family: 'Overpass', sans-serif;
  font-size: 14px;
  color: #271B36;
  width: calc(100% - 120px);
  margin-top: ${props => props.MarginTop || ""};
  margin-left: ${props => props.MarginLeft || ""};
  margin-right: ${props => props.MarginRight || ""};
  margin-bottom: ${props => props.MarginBottom || ""};

  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: 1.25;
  }

  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: 1.5;
  }
`;

export const StyledLanguageSwitcher = styled(Select)`
  font-family: 'Overpass';
  border: none !important;
  width: fit-content;
  font-size: 17px;
  
  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: 1.25
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: 1.5
  }
  @media (-webkit-device-pixel-ratio: 1.75) {
    zoom: 1.75
  }
`;

export const StyledFormControl = styled(FormControl)`
  min-width: ${props => props.minwidth || '150px'} !important;
  border: none !important;
  margin-bottom: 30px;
`;
