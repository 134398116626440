import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components";

import {useTranslation, Trans} from 'react-i18next';
import ComponentLanguageSwitcher from "../../../layout-and-styling/standardized-components-library/Component-language-switcher";
import {StyledDivBold, StyledDivRegular} from '../../../layout-and-styling/standardized-components-library/Styling-TextInput';
import {OverlayContext} from "../../../layout-and-styling/context-hooks/OverlayContext";
import {ReactComponent as IconChevronLeft} from '../../../assets/intake/IconChevronLeft.svg';
import {ReactComponent as SwitchPicture} from '../../../assets/intake/SwitchPicture.svg';
import {ReactComponent as InputPicture} from '../../../assets/intake/InputPicture.svg';
import {ReactComponent as HoverPicture} from '../../../assets/intake/HoverPicture.svg';
import { ReactComponent as Pink1 } from "../../../assets/intake/Pink1.svg";
import { ReactComponent as Pink2 } from "../../../assets/intake/Pink2.svg";
import { ReactComponent as IconSandclock } from "../../../assets/intake/Sandclock.svg";
import { ReactComponent as IconEstimation } from "../../../assets/intake/IconEstimation.svg";
import VideoIntakeTime from '../../../assets/intake/VideoIntakeTime.mp4';
import IntroductionVideoComponent from "./IntroductionVideoComponent";

const currentUserData = JSON.parse(localStorage.getItem('current_user_data'));

const IntroductionTimeComponent = (props) => {
    const context = useContext(OverlayContext);
    const { t } = useTranslation();

    
    const [indexes, setIndexes] = useState([]);
    useEffect(() => {
        setIndexes(Array.from({ length: 5 }, (_, index) => index));
    }, []);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [componentClicked, setComponentClicked] = useState(false);

    const showNextPage = () => {
        if (currentIndex < indexes.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    }

    const showPreviousPage = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    }

    const getStarted = () => {
        props.hideIntroduction();
    }

    return (
        <IntroductionContainer isShowingIntroduction={props.isShowingIntroduction}>
            <IntroductionButtonContainer Top='40px'>
                <ComponentLanguageSwitcher reRender={props.reRender} Position='static' Height='auto' MinWidth='0' Padding='4px 12px' MarginTop='0' MarginLeft='0' MarginRight='0' BorderRadius='8px' Background='#E3E5E4'/>
            </IntroductionButtonContainer>

            {currentIndex === 1 ? (

                <IntroductionWrapper>
                <HeaderWrapper>
                    <StyledDivBold Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                        {t('PageIntakeIntroductionTime.TutorialTextHeader')}
                    </StyledDivBold>

                    <StyledDivBold Position='static' Width='auto' Height='auto' Color='#C6CBCB' FontSize='20px' LineHeight='20px'>
                        <Trans i18nKey="PageIntakeIntroductionTime.TutorialTextSubHeader">
                            CLICK ON <span style={{color: "#F7C9D9"}}>THE VIDEO BELOW</span> TO WATCH <span style={{color: "#F7C9D9"}}>A TUTORIAL</span>
                        </Trans>
                    </StyledDivBold>
                </HeaderWrapper>

                <ExampleWrapper>
                    <IntroductionVideoComponent identifier='MeasuringTimeVideo' video={VideoIntakeTime} setComponentClicked={setComponentClicked}/>
                </ExampleWrapper>
                </IntroductionWrapper>

            ) : currentIndex === 2 ? (

                    <IntroductionWrapper>
                    <HeaderWrapper>
                        <StyledDivBold Position='static' Width={"910px"} Height='auto' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                            {t('PageIntakeIntroductionTime.Instructions2Heading')}
                        </StyledDivBold>
                    </HeaderWrapper>

                    <TextWrapper>
                    <StyledDivRegular Position='static' Width={"910px"} Height='auto'Color='#FCFCFC' FontSize='18px' LineHeight='28px' >
                            {t('PageIntakeIntroductionTime.Instructions2TextTop')}
                        </StyledDivRegular>
                    </TextWrapper>

                    <SubpointWrapper>
                        <Pink1/>
                        <SubPointTextWrapper>
                                <StyledDivRegular Position='static' Width={"910px"}  Height='auto' Color='#FCFCFC' FontSize='18px' LineHeight='28px' FontWeight='700' LetterSpacing='0.3px'>
                                    {t('PageIntakeIntroductionTime.SubpointMediumHeader')}
                                </StyledDivRegular>

                                <StyledDivRegular Position='static' Width={"910px"}  Height='auto' Color='#C6CBCB' FontSize='18px' LineHeight='28px'>
                                    {t('PageIntakeIntroductionTime.SubpointMediumText')}
                                </StyledDivRegular>
                            </SubPointTextWrapper>
                    </SubpointWrapper>

                    <SubpointWrapper>
                        <Pink2/>
                        <SubPointTextWrapper>
                                <StyledDivRegular Position='static' Width={"910px"}  Height='auto' Color='#FCFCFC' FontSize='18px' LineHeight='28px' FontWeight='700' LetterSpacing='0.3px'>
                                    {t('PageIntakeIntroductionTime.SubpointLargeHeader')}
                                </StyledDivRegular>

                                <StyledDivRegular Position='static' Width={"910px"}  Height='auto' Color='#C6CBCB' FontSize='18px' LineHeight='28px'>
                                    {t('PageIntakeIntroductionTime.SubpointLargeText')}
                                </StyledDivRegular>
                            </SubPointTextWrapper>
                    </SubpointWrapper>

                    <TextWrapper>
                    <StyledDivRegular Position='static' Width={"910px"} Height='auto'Color='#FCFCFC' FontSize='18px' LineHeight='28px' >
                            {t('PageIntakeIntroductionTime.Instructions2TextBottom')}
                        </StyledDivRegular>
                    </TextWrapper>

                    </IntroductionWrapper>

            ): currentIndex === 3 ? (

                <IntroductionWrapper>
                <HeaderWrapper>
                    <StyledDivBold Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                        {t('PageIntakeIntroduction.InstructionsTextHeader')}
                    </StyledDivBold>

                    <StyledDivBold Position='static' Width='auto' Height='auto' Color='#C6CBCB' FontSize='20px' LineHeight='20px'>
                        {t('PageIntakeIntroductionTime.Instructions1TextSubHeader')}
                    </StyledDivBold>
                </HeaderWrapper>

                <ExampleWrapper>
                    <ExamplePointWrapper>
                        <ExamplePointGifWrapper>
                            <InputPicture/>
                        </ExamplePointGifWrapper>

                        <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='18px' LineHeight='28px' TextAlign='center' FontWeight='700' LetterSpacing='0.3px'>
                            {t('PageIntakeIntroductionTime.Instructions1TextPoint1')}
                        </StyledDivRegular>
                    </ExamplePointWrapper>

                    <ExamplePointWrapper>
                        <ExamplePointGifWrapper>
                            <HoverPicture/>
                        </ExamplePointGifWrapper>

                        <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='18px' LineHeight='28px' TextAlign='center' FontWeight='700' LetterSpacing='0.3px'>
                            {t('PageIntakeIntroductionTime.Instructions1TextPoint2')}
                        </StyledDivRegular>
                    </ExamplePointWrapper>
                    
                </ExampleWrapper>

                <TextWrapper>
                <StyledDivRegular Position='static' Width={"75%"} Height='auto'Color='#FCFCFC' FontSize='18px' LineHeight='28px' >
                        {t('PageIntakeIntroductionTime.Text1')}
                    </StyledDivRegular>
                </TextWrapper>

            </IntroductionWrapper>

            ) : currentIndex === 4 ? (

                <IntroductionWrapper>
                    <HeaderWrapper>
                        <StyledDivBold Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                            {t('PageIntakeIntroduction.InstructionsTextHeader')}
                        </StyledDivBold>

                        <StyledDivBold Position='static' Width='auto' Height='auto' Color='#C6CBCB' FontSize='20px' LineHeight='20px'>
                            {t('PageIntakeIntroductionTime.SwitchSubheading')}
                        </StyledDivBold>
                    </HeaderWrapper>

                    <ExampleWrapper>
                        <ExamplePointWrapper>
                            <SwitchPicture/>

                            <TextWrapper>
                                <StyledDivRegular Position='static' Width='auto' Height='auto'Color='#FCFCFC' FontSize='18px' LineHeight='28px' >
                                    {t('PageIntakeIntroductionTime.Instructions3TextPoint')}
                                </StyledDivRegular>
                            </TextWrapper>
                        </ExamplePointWrapper>
                    </ExampleWrapper>
                </IntroductionWrapper>

            ) : (
                <IntroductionWrapper>
                    <StyledDivBold Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                        {t('PageIntakeIntroductionTime.Header')}
                    </StyledDivBold>

                    <StyledDivRegular Position='static' Width='auto' Height='auto'Color='#FCFCFC' FontSize='18px' LineHeight='28px' >
                            {t("PageIntakeIntroductionTime.Text")}
                    </StyledDivRegular>

                    <StyledDivBold Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='20px' LineHeight='20px'>
                        {t('PageIntakeIntroduction.WelcomeTextHeader2')}
                    </StyledDivBold>

                    <FocusWrapper>
                        <PointWrapper>
                            <IconSandclock/>

                            <PointTextWrapper>
                                <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='18px' LineHeight='28px' FontWeight='700' LetterSpacing='0.3px'>
                                    {t('PageIntakeIntroductionTime.WelcomeTextPoint1')}
                                </StyledDivRegular>

                                <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#C6CBCB' FontSize='18px' LineHeight='28px'>
                                    {t('PageIntakeIntroductionTime.WelcomeTextDescription1')}
                                </StyledDivRegular>
                            </PointTextWrapper>
                        </PointWrapper>

                        <PointWrapper>
                            <IconEstimation/>

                            <PointTextWrapper>
                                <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='18px' LineHeight='28px' FontWeight='700' LetterSpacing='0.3px'>
                                    {t('PageIntakeIntroductionTime.WelcomeTextPoint2')}
                                </StyledDivRegular>

                                <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#C6CBCB' FontSize='18px' LineHeight='28px'>
                                    {t('PageIntakeIntroductionTime.WelcomeTextDescription2')}
                                </StyledDivRegular>
                            </PointTextWrapper>
                        </PointWrapper>
                    </FocusWrapper>
                </IntroductionWrapper>
            )}

            <IntroductionButtonContainer Bottom='40px'>
                {(currentIndex > 0) && (
                    <IntroductionButtonWrapper onClick={showPreviousPage} Width={'auto'} Padding={'16px'} Color={'#E3E5E4'}>
                        <IconChevronLeft/>
                    </IntroductionButtonWrapper>
                )}

                {(currentIndex === indexes.length - 1) ? (
                    <IntroductionButtonWrapper onClick={getStarted}>
                        <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' Color='#FCFCFC' FontWeight='700' LetterSpacing='0.3px'>
                            {t('PageIntakeIntroduction.TextButtonGetStarted')}
                        </StyledDivRegular>
                    </IntroductionButtonWrapper>
                ) : (
                    <IntroductionButtonWrapper onClick={showNextPage}>
                        <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' Color='#FCFCFC' FontWeight='700' LetterSpacing='0.3px'>
                            {t('PageIntakeIntroduction.TextButtonNext')}
                        </StyledDivRegular>
                    </IntroductionButtonWrapper>
                )}
            </IntroductionButtonContainer>
        </IntroductionContainer>
    );
}

export default IntroductionTimeComponent;

const IntroductionContainer = styled.div`
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(39, 27, 54, 0.75);
    backdrop-filter: blur(12.5px);
    opacity: ${props => props.isShowingIntroduction ? '1' : '0'};
    visibility: ${props => props.isShowingIntroduction ? 'visible' : 'hidden'};
    z-index: ${props => props.isShowingIntroduction ? '49' : '-1'};
    transition: opacity 0.8s, visibility 0.8s, z-index 0.8s;
`;

const IntroductionWrapper = styled.div`
    display: flex;
    width: calc(100% - 80px);
    max-width: 1290px;
    flex-direction: column;
    justify-content: flex-start;
    gap: 40px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ExampleWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const SubpointWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const ExamplePointWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

const ExamplePointGifWrapper = styled.div`
  display: flex;
  border-radius: 8px;
  overflow: hidden;
`;

const FocusWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 160px;
  row-gap: 20px;
`;

const PointWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
`;

const PointTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 250px;
`;

const SubPointTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 65%;
`;

const IntroductionButtonContainer = styled.div`
    position: absolute;
    top: ${props => props.Top || ''};
    bottom: ${props => props.Bottom || ''};
    display: flex;
    width: calc(100% - 80px);
    max-width: 1290px;
    justify-content: flex-end;
    gap: 8px;

    @media (max-width: 1370px) {
        right: 40px;
    }
`;

const IntroductionButtonWrapper = styled.div`
    display: flex;
    width: ${props => props.Width || '118px'};
    padding: ${props => props.Padding || '16px 24px'};
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: ${props => props.Color || '#E2336B'};
    opacity: ${props => props.isDisabled ? '0.5' : ''};
    transition: opacity 0.3s, filter 0.15s;
    cursor: ${props => props.isDisabled ? '' : 'pointer'};
    user-select: none;

    &:hover {
        filter: ${props => props.isDisabled ? '' : 'brightness(90%)'};
    }
`;