import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MenuItem, Select } from "@mui/material";
import { ReactComponent as FlagCh } from "../../assets/translation/flag-ch.svg";
import { ReactComponent as FlagNl } from "../../assets/translation/flag-nl.svg";
import { ReactComponent as FlagEn } from "../../assets/translation/flag-en.svg";
import { useTranslation } from 'react-i18next';

const PhoneLanguageSwitcher = ({ reRender, MarginRight, MarginTop, JustifySelf, AlignSelf }) => {
    const { i18n } = useTranslation();
    const [open, setOpen] = useState(false);

    const languages = [
        { code: 'en-US', flag: <FlagEn />, name: 'English' },
        { code: 'nl-NL', flag: <FlagNl />, name: 'Nederlands' },
        { code: 'ch-CH', flag: <FlagCh />, name: '中文'}
    ];

    const handleChangeLanguage = (e) => {
        i18n.changeLanguage(e.target.value);
        setOpen(false);
        if (reRender) reRender();
    };

    useEffect(() => {
        if (!languages.some(lang => lang.code === i18n.language)) {
            i18n.changeLanguage('en-US');
        }
    }, [i18n.language, languages]);

    return (
        <LanguageSwitcher MarginRight={MarginRight} MarginTop={MarginTop} JustifySelf={JustifySelf} AlignSelf={AlignSelf}>
            <StyledSelect
                value={i18n.language}
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                onChange={handleChangeLanguage}
                IconComponent={() => null}  // Remove the dropdown arrow
                renderValue={(selected) => (
                    <FlagWrapper>
                        {languages.find(lang => lang.code === selected)?.flag}
                    </FlagWrapper>
                    
                )}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "left" // To align dropdown to the left of the flag
                  },
                  transformOrigin:{
                    vertical: "top",
                    horizontal: "right" // Make dropdown appear to the left
                  },
                  PaperProps: {
                    style: {
                      width: '300px',
                      borderRadius: '20px',
                      minHeight: '200px',
                      transform: 'translateX(-30px)', // Move dropdown to the right
                    }
                  }
                }}
            >
               {languages.map(({ code, flag, name }) => (
                    <StyledMenuItem key={code} value={code}>
                        <FlagWrapper>{flag}</FlagWrapper>
                        <LanguageName>{name}</LanguageName>
                    </StyledMenuItem>
                ))}
            </StyledSelect>
        </LanguageSwitcher>
    );
};

export default PhoneLanguageSwitcher;

const FlagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: auto;

  svg {
    width: 100%;
    height: auto;
  }
`;

const LanguageName = styled.span`
  font-size: 32px;
  font-weight: 400;
`;

const LanguageSwitcher = styled.div`
  justify-self: ${({ JustifySelf }) => JustifySelf || 'auto'};
  align-self: ${({ AlignSelf }) => AlignSelf || 'auto'};
  margin-top: ${({ MarginTop }) => MarginTop || '0'};
  margin-right: ${({ MarginRight }) => MarginRight || '0'};
`;

const StyledSelect = styled(Select)`
  padding: 0;
  margin: 0;

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiSelect-select {
    padding: 0 !important;
    margin: 0 !important;
    min-width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 15px !important;
  padding-left: 20px !important;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 30px;
`;
