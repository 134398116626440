import React, {useEffect, useState, useContext, useRef } from 'react';
import axios from 'axios';
import SurveyTextProcessingHeader from '../standardized-components/Survey-text-processing-header';
import styled from 'styled-components';
import ConversationWrapper from './page-specific-components/Component-Chatbox';
import SendMessageComponent from './page-specific-components/Component-Sendmessage';
import ProgressBarComponent from './page-specific-components/Component-ProgressBar';
import getCookieValue from "../../utils";
import { useWindowSize } from '../../layout-and-styling/standardized-components-library/Component-Responsive';
import {Trans, useTranslation} from 'react-i18next';
import { TooltipRootcauseChatbot } from '../standardized-components/Survey-text-processing-tooltips';
import { OverlayContext } from '../../layout-and-styling/context-hooks/OverlayContext';
import { GetUnfinishedConversations, GetResponse } from '../standardized-components/api-calls/survey-text-processing-api-calls';
import reversedBotLogo from './assets/reversedBotLogo.svg';
import PhoneLanguageSwitcher from '../../layout-and-styling/standardized-components-library/Component-language-switcher-phone';
import {Checkbox, Select } from '@mui/material';
import { ClickAwayListener } from '@mui/base';

import {ReactComponent as HeaderIconSettings} from "../../assets/header/Icon_settings.svg";
import {ReactComponent as HeaderIconSettingsActive} from "../../assets/header/Icon_settings_active.svg";
import LogoutWindow from "../../authentication-and-home/LogoutWindow";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket, faCircleQuestion, faX } from '@fortawesome/free-solid-svg-icons';
import { StyledDivBold, StyledDivRegular } from '../../layout-and-styling/standardized-components-library/Styling-TextInput';
import i18next from "i18next";

const PageRootcauseChatbot = () => {

  const context = useContext(OverlayContext);
  const {t, i18n} = useTranslation();
  const isPortraitMode = window.matchMedia("(orientation: portrait)").matches;
  const [isOpen, setIsOpen] = useState(false);
  const [isLogoutOpen, setIsLogoutOpen] = useState(false);
  const [isHelpOpen, setIsHelpOpen] = useState(false);

  const [sendMessageHeight, setSendMessageHeight] = useState(0);
  const [selectOptionsHeight, setSelectOptionsHeight] = useState(0);
  const conversationRef = useRef(null);
 
  // we use the session storage because every time they log in again we want the option to trigger
  const [unfinishedConversation, setUnfinishedConversation] = useState(JSON.parse(sessionStorage.getItem('unfinished_conversation')) ? JSON.parse(sessionStorage.getItem('unfinished_conversation')) : null);
  // current stage of the chatbot, we first check if there are messages stored in the local storage, if that is the case, the stage is the one from the last message, otherwise "INTRODUCTION"
  const [stage, setStage] = useState(JSON.parse(localStorage.getItem('chatbot_original_messages')) ? JSON.parse(localStorage.getItem('chatbot_original_messages'))[JSON.parse(localStorage.getItem('chatbot_original_messages')).length-1]["stage"] : "INTRODUCTION");
  // if it is elly's turn to write but she is not doing it yet, she is waiting for the backend to send the next question
  const [botThinking, setBotThinking] = useState(false);
  // is elly is writing, writing takes some time because the messages is written like work by word
  const [botWriting, setBotWriting] = useState(false);
  // options in case the answer is composed of options, we need to store also in the local storage in case of page refresh
  const [options, setOptions] = useState(JSON.parse(localStorage.getItem('chatbot_options')) ? JSON.parse(localStorage.getItem('chatbot_options')) : []);
  // tooltip hover
  const [hover1, setHover1] = useState(false);
  // progress of the conversation
  const [progress, setProgress] = useState(JSON.parse(localStorage.getItem('chatbot_progress')) ? JSON.parse(localStorage.getItem('chatbot_progress')) : 0);


  const [translate, setTranslate] = useState(false);

  // Array of dictionaries (a dictionary for each message) {message, owner_is_bot, stage}
  // the messages in this array are always in english, are the ones sended to the backend
  const [englishMessages, setEnglishMessages] = useState(JSON.parse(localStorage.getItem('chatbot_english_messages')) ? JSON.parse(localStorage.getItem('chatbot_english_messages')) : []);
  
  // Array of dictionaries (a dictionary for each message) {message, owner_is_bot, stage}
  // the messages in this array are in the language selected by the user and that he/she answers
  const [originalMessages, setOriginalMessages] = useState(JSON.parse(localStorage.getItem('chatbot_original_messages')) ? JSON.parse(localStorage.getItem('chatbot_original_messages')) : []);

  useWindowSize();

  const handleLogoutClick = () => {
    setIsLogoutOpen(true);
  };

  const handleCloseLogout = () => {
    setIsLogoutOpen(false);
  };
  
  async function checkPreviousConversations() {
    await GetUnfinishedConversations()
    let num = JSON.parse(sessionStorage.getItem('unfinished_conversation'))
    setUnfinishedConversation(num)
  }

  // the first time the page is rendered unfinishedConversation will be null, we do the api call to check if the user has any unfinished conversation
  useEffect(() => {

    if(unfinishedConversation === null){
      checkPreviousConversations()
    }
  }, [])

  // start of the conversation, if there are less than two messages in the conversation
  // we execute the next message, then we wait until botWriting changes its value, that will be when the first message is completely written
  // then next_message is executed again to get the second message
  useEffect(() => {
    if(!botWriting){
      // this number is the amount of consecutive messages at the start of the conversation. 
      // 2 means that 2 messages will be written in the screen without letting the user answer after the first one
      if (originalMessages.length < 2) {
        nextMessage("")
      }
    }

  }, [botWriting]);
    
  /**
     * Adds a message to the messages state, containing all the messages of the conversation. Also updates the stage 
     * @param {String} original_message - message in the conversation language (either from elly of from the user)
     * @param {String} english_message - message translated to english (either from elly of from the user)
     * @param {Boolean} owner_is_chatbot - true if message sent by elly, false if sent by user
     * @param {String} stage - stage of the conversation we are
     * @param {Array} options - options that the user will have to select in the answer ([] if the owner_is_chatbot is false)
     * the states and the local storage are updated
     */
  const addMessage = (original_message, english_message, owner_is_chatbot, stage, options) => {
    let new_messages = []

    // english messages
    // setting messages state + local storage
    new_messages = englishMessages
    new_messages.push({
      "message" : english_message,
      "owner_is_bot" : owner_is_chatbot,
      "stage" : stage
    })

    setEnglishMessages(new_messages);
    localStorage.setItem('chatbot_english_messages', JSON.stringify(new_messages));

    // original messages
    // setting messages state + local storage
    new_messages = originalMessages
    new_messages.push({
      "message" : original_message,
      "owner_is_bot" : owner_is_chatbot,
      "stage" : stage
    })

    setOriginalMessages(new_messages);
    localStorage.setItem('chatbot_original_messages', JSON.stringify(new_messages));
    
    // setting stage state
    setStage(stage)

    // setting the default options
    setOptions(options)
    localStorage.setItem('chatbot_options', JSON.stringify(options));
  }

  /**
     * Processes the messages sent by the user and calls the API to get the response
     * @param {String} message - Message sent by the user
     */
  async function nextMessage(message) {
    // user message
    // the condition of the if will only happen in the first messages where the chatbot sends messages without the user having to say anything 
    let english_message = message
    if(message != ""){
      // if language is not english we translate
      if(i18n.language != "en-US"  && translate){
        english_message = await translateWithDeepL(message, i18n.language.split('-')[1]);
      }

      // adding the message from the user to the state + local storage, options ([]) are not important here
      addMessage(message, english_message, false, stage, [])
    }

    // chatbot is processing the answer
    setBotThinking(true)

    // calling the response from the backend, we only send the message in english, the rest of info is stored in the backend itself or calculated there if needed
    let response = await GetResponse(english_message);
    let next_stage = response["stage"]
    let next_message = response["message"]
    let next_options = response["options"]
    setProgress(response["progress"])
    localStorage.setItem('chatbot_progress', JSON.stringify(response["progress"]));

    // chatbot message
    let original_message = next_message
    // if language is not english we translate
    if(i18n.language != "en-US" && translate){
      // english messages translated to original language
      original_message = await translateWithDeepL(next_message, i18n.language.split('-')[1]);
      // translation of the options (if we are in the process or subprocess selection we don't translate)
        for (let i = 0; i < next_options.length; i++) {
          // treating exception where deepL translates No to Geen
          if(i18n.language == "nl-NL" && next_options[i] == "No") 
            next_options[i] = "Nee"
          else
            next_options[i] = await translateWithDeepL(next_options[i], i18n.language.split('-')[1]);
        }
    }


    // adding the message from elly to the state + local storage
    addMessage(original_message, next_message, true, next_stage, next_options)

    // chatbot is processing the answer
    setBotThinking(false)
    
  }

  /**
     * Translates the message using DeepL api 
     * @param {String} text - Message to translate
     * @param {String} targetLanguage - Language that the message has to be translated to
     */
  async function translateWithDeepL(text, targetLanguage){
    const axiosInstance = axios.create({withCredentials: true});
    const baseURL = process.env.REACT_APP_APIURL;
    const data = `{ 
      "text": "${text}", 
      "targetLanguage": "${targetLanguage}"
    }`
    return axiosInstance.post(baseURL+"/api/translate/", data, {
      headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookieValue("csrftoken")
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return t("PageRootcauseChatbot.TranslationFail");
    })
  }

  return(
    <>
    {!isPortraitMode ?
    <Container>
      <HeaderAndOverlayWrapper>
        <SurveyTextProcessingHeader page='EllyPage'/>
      </HeaderAndOverlayWrapper>

      <ChatbotWrapper>
        <TitleWrapper>
          <Trans i18nKey="PageRootcauseChatbot.HeaderText">
            Talk <span style={{color: "#B3194A"}}> Talk </span>
            to Elly to find the root cause of your problems
          </Trans>
          <InfoHover onMouseOver={() => setHover1(true)} onMouseOut={() => setHover1(false)}>
            ?
            {hover1 && (<TooltipRootcauseChatbot tooltip="Chatbot"/> )}
          </InfoHover>
        </TitleWrapper>

        <ProgressBarComponent progress={progress}/>
        <ConversationWrapper messages={originalMessages} botThinking={botThinking} setBotWriting={setBotWriting}/>
        <SendMessageComponent nextMessage={nextMessage} botThinking={botThinking} botWriting={botWriting} stage={stage} options={options}/>
        
      </ChatbotWrapper>
    </Container>
    :
    <Container>
      <SurveyTextProcessingHeader page='EllyPage'/>
      <ChatbotWrapper>
        <PhoneHeaderWrapper>
          <TitleWrapper>
            <div>
              <img src={reversedBotLogo} />
              <Trans i18nKey="PageRootcauseChatbot.PhoneHeaderText"></Trans>
            </div>
            <div>
              <PhoneLanguageSwitcher />
                <StyledCheckbox 
                  icon={isOpen ? <HeaderIconSettingsActive/> : <HeaderIconSettings/>}
                  checkedIcon={isOpen ? <HeaderIconSettingsActive/> : <HeaderIconSettings /> }
                  onClick={() => setIsOpen(!isOpen)}>
                </StyledCheckbox>
                {isOpen &&
                  (<ClickAwayListener onClickAway={() => setIsOpen(false)}>
                    <DropdownContent >
                      <button onClick={() => handleLogoutClick()}>
                        <FontAwesomeIcon icon={faRightFromBracket} /> 
                          <p>{t('Logout.LogoutButton')}</p>
                      </button>
                      <button onClick={() => setIsHelpOpen(true)}>
                        <FontAwesomeIcon icon={faCircleQuestion} />
                        <p>{t('Component-header-block-need-help')}</p>
                      </button>
                    </DropdownContent>
                  </ClickAwayListener>
                )}
            </div>
          </TitleWrapper>
          <ProgressBarComponent progress={progress}/>
        </PhoneHeaderWrapper>

        <ConversationWrapper messages={originalMessages} botThinking={botThinking} setBotWriting={setBotWriting} marginBottom={options.length > 0 ? selectOptionsHeight : sendMessageHeight} ref={conversationRef} />
        <SendMessageComponent nextMessage={nextMessage} botThinking={botThinking} botWriting={botWriting} stage={stage} options={options} setSelectOptionsHeight={setSelectOptionsHeight} selectOptionsHeight={selectOptionsHeight} setSendMessageHeight={setSendMessageHeight}/>
      </ChatbotWrapper>

      {isLogoutOpen && <LogoutWindow onClose={handleCloseLogout} />}
      {isHelpOpen && <NeedHelpWrapper>
        <NeedHelpContent>
          <NeedHelpHeader>
            <StyledDivBold PhoneWidth='fit-content' Position='relative' PhoneFontSize='48px' PhoneLineHeight='58px' PhoneDisplay='flex' PhoneGap='20px' PhoneColor='#E2336B'>
              <FontAwesomeIcon icon={faCircleQuestion} />
              {t('PageRootcauseChatbot.HelpHeader')}
            </StyledDivBold>
          </NeedHelpHeader>
          <NeedHelpText>
            <StyledDivRegular Width='fit-content' Position='relative' PhoneFontSize='38px' PhoneLineHeight='52px'>
              {i18next.t('PageRootcauseChatbot.Tooltip1')}
              <br/><br/>
              {i18next.t('PageRootcauseChatbot.Tooltip2')}
              <br/><br/>
              {i18next.t('PageRootcauseChatbot.Tooltip3')}
            </StyledDivRegular>
          </NeedHelpText>
          <CloseButton onClick={() => setIsHelpOpen(false)}>
            {t('PhoneLogoutPopup.CloseButton')} <FontAwesomeIcon icon={faX} />
          </CloseButton>
        </NeedHelpContent>
      </NeedHelpWrapper> }
    </Container>
  }
  </>
  );
}

export default PageRootcauseChatbot

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #F1F3F4;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: calc(1 / 1.25);
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: calc(1 / 1.5);
  }

  @media (orientation: portrait) {
    max-height: 100vh;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  font-size: 35px;
  svg {
    width: 50px;
    height: 50px;
  }
`;

const ChatbotWrapper = styled.div`
  position: relative;
  margin-top: 100px;
  padding: 32px 32px 24px 32px;
  width: 90%;
  max-width: 1920px;
  height: calc(100% - 200px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #FCFCFC;
  border-radius: 12px;
  gap: 24px;

  @media (orientation: portrait) {
    background-color: #FCFCFC;
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
    gap: 0px;
  }
`;

const PhoneHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  height: 240px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #FCFCFC;
`;

const TitleWrapper = styled.div`
  height: fit-content;
  font-family: montserrat;
  font-weight: bold;
  font-size: 32px;
  background: #FCFCFC;
  text-align: center;
  width: 100%;

  @media (orientation: portrait) {
    font-size: 40px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    img {
      width: 80px;
    }
}
`;

const NeedHelpWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2); /* Dimming effect */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
`;

const NeedHelpContent = styled.div`
    background: white;
    padding: 60px;
    border-radius: 20px;
    width: 75%;
    min-height: 50%;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 50px;
`;

const NeedHelpHeader = styled.div`
`;

const NeedHelpText = styled.div`
  flex-grow: 1;
  width: 95%;
`;

const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: 'Overpass', sans-serif;
  background-color: #E2336B;
  color: white;
  border: none;
  border-radius: 20px;
  min-height: 120px;
  width: 100%;
  font-size: 34px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: #B3194A; /* Change background on hover */
  }

  svg {
      width: 20px;
      padding: 10px;
      margin-bottom: 4px;
  }
`;

const DropdownContent = styled.div`
  position: absolute;
  right: 120px;
  top: 50px;
  background-color: #ffffff;
  border: 2px solid #E5E5E5;
  border-radius: 20px;
  width: 300px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.4);
  z-index: 1;
  display: flex;
  flex-direction: column;

  button {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 30px;

    background-color: #ffffff;
    border: none;
    border-radius: 18px;
    width: 100%;
    min-height: 100px;
    color: #333333;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.2s ease;
    
    svg {
      width: 40px;
      height: auto;
      padding-left: 20px;
    }

    &:hover {
      background-color: #f1f1f1;
    }

    p {
      font-family: 'Overpass';
      margin: 0;
      font-size: 32px;
      font-weight: 700;
    }
  }
`;

const InfoHover = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  font-size: 18px;
  font-family: 'Overpass';
  font-weight: 700;
  color: #FCFCFC;
  background: #E2336B;
  border: 2px solid #E2336B;
  border-radius: 500px;
  width: 20px;
  text-align: center;
  height: 20px;
  cursor: pointer;
`;

const HeaderAndOverlayWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 6;
`;