import React, {useContext, useState, useEffect} from "react";
import styled from 'styled-components';
import * as d3 from "d3";
import {useTranslation} from 'react-i18next';
import {OverlayContext} from "../../../layout-and-styling/context-hooks/OverlayContext";
import {StyledDivButton, StyledDivRegular, StyledDivBold} from "../../../layout-and-styling/standardized-components-library/Styling-TextInput";
import { DeveloperNetworkFeedbackPage } from "../../api-calls/api-calls";
import DashedLine from "../../../assets/dashboard/score/DashedSeparationLine.svg";
import Users from "../assets/Users.svg";
import DropdownClosed from "../../../assets/dashboard/DropdownClosed.svg";
import DropdownOpened from "../../../assets/dashboard/DropdownOpened.svg";
import OpenedEyeIcon from "../assets/OpenedEyeIcon.svg";
import ClosedEyeIcon from "../assets/ClosedEyeIcon.svg";
import load from "../../../assets/dashboard/load.gif";

import { PresentationVis } from "../SocialNetworkFeedback";

const ActionsComponent = () => {
    const context = useContext(OverlayContext);
    const {t, i18n} = useTranslation();

    const currentUser = JSON.parse(localStorage.getItem("current_user_data"))[0];
    const [selectedCompany, setSelectedCompany] = useState(JSON.parse(localStorage.getItem("selected_company")));
    const [selectedSurvey, setSelectedSurvey] = useState(JSON.parse(localStorage.getItem("selected_survey")));

    const [presentationActionsData, setPresentationActionsData] = useState(JSON.parse(localStorage.getItem("presentation_data_actions")) && JSON.parse(localStorage.getItem("presentation_data_actions")).survey == selectedSurvey ? JSON.parse(localStorage.getItem("presentation_data_actions")) : null);

    // Function that make the api call to get the social network data if necessary
    async function loadData() {
      if (currentUser.is_developer) {
        if(! presentationActionsData) await DeveloperNetworkFeedbackPage(selectedCompany.id, selectedSurvey);
      }
    }

    //Wait for data to load, display loading icons beforehand
    useEffect(() => {

      loadData()

      window.addEventListener('presentation_data_event', () => {
        setPresentationActionsData(JSON.parse(localStorage.getItem("presentation_data_actions")));
      })
    }, [])

    //rerendering helps team legend rerender?...where to trigger this new render, not finished
    const [render, setRender] = useState(true);
    const reRender = () => {
      setRender(!render);
    };

    const [selectedTag, setSelectedTag] = useState(JSON.parse(localStorage.getItem("selected_tag")) && JSON.parse(localStorage.getItem("selected_tag")) > 0 ? JSON.parse(localStorage.getItem("selected_tag")) : 1);
    const [selectedReceivingTeam, setSelectedReceivingTeam] = useState(0);
    const [selectedGivingTeam, setSelectedGivingTeam] = useState(0);
    const [selectedMetric, setSelectedMetric] = useState("Proactiveness");
    const [showZones, setShowZones] = useState(false);

    function metricZones(show, metric) {
      if (show) {
        if (metric == "Proactiveness") PresentationVis[0].zones("Proactiveness");
        if (metric == "Knowledge") PresentationVis[0].zones("Impact");
        if (metric == "Collaboration") PresentationVis[0].zones("Pleasantness");
        d3.select('.ColorScale').style('display', 'flex');
      } else {
        PresentationVis[0].zones("");
        d3.select('.ColorScale').style('display', 'none');
      }
    }


    return (
      <Container>
        {presentationActionsData ? (
          <>
            <StyledDivRegular Position="relative" MarginLeft="0px" Width="fit-content" MarginBottom="0px" FontWeight="400" FontSize="24px">
              <b>Actions </b> to solve <b>{presentationActionsData["tags_actions"][selectedTag].tag}</b>
            </StyledDivRegular>

            <ActionTeamsWrapper>
              {presentationActionsData["tags_actions"][selectedTag].data.map((team, index) => {
                return(
                  <StyledDivButton MarginTop='0px' MarginLeft='0px' Width='160px' Height='60px' BoxShadow='0px 2px 4px rgba(0, 0, 0, 0.25)' 
                                  Padding='8px 4px 8px 4px' FontSize='16px' Display='flex' JustifyContent='flex-start' Position="relative" BackgroundColor={'#FCFCFC'}
                                  Border={selectedReceivingTeam === index ? '1px solid #E2336B' : '0px solid #271B36'} Color={'#271B36'}>
                    <button onClick={() => {setSelectedReceivingTeam(index)}}>
                    <ActionsTeamIndex BackgroundColor={selectedReceivingTeam === index ? "#E2336B" : "#271B36"}>
                      {index+1}
                    </ActionsTeamIndex>
                      {context.setTeams(team["receiving_team"], i18n.language).split(" ")[0]}
                    </button>
                  </StyledDivButton>
                )
              })}
            </ActionTeamsWrapper>

            <MetricsWrapper>
              <StyledDivRegular Position="relative" MarginLeft="0px" Width="fit-content" MarginBottom="0px" FontWeight="700" FontSize="16px">
                See action per Metric:
              </StyledDivRegular>
              <MetricsToggle>
                {["Proactiveness", "Knowledge", "Collaboration"].map((metric, index) => {
                  return(
                    <MetricToggleButton BackgroundColor={selectedMetric === metric ? '#FCFCFC' : 'transparent'} Border={selectedMetric === metric ? '1px solid #E2336B' : '0px solid transparent'}>
                      <button onClick={() => {setSelectedMetric(metric); metricZones(showZones, metric)}}>
                        {metric}
                      </button>
                    </MetricToggleButton>
                  )
                })}
              </MetricsToggle>
            </MetricsWrapper>

            <ActionsWrapper>
              <ActionsHeader>
                <ActionsHeaderMetric>
                  <MetricName>
                    <StyledDivRegular Position="relative" MarginLeft="0px" Width="124px" MarginBottom="0px" FontWeight="700" FontSize="18px" Color="#E2336B">
                      {selectedMetric}
                    </StyledDivRegular>

                    <StyledDivRegular Position="relative" MarginLeft="0px" Width="fit-content" MarginBottom="0px" FontWeight="400" FontSize="14px" Color="#929699">
                      <img src={Users}/> feedbacks
                    </StyledDivRegular>
                  </MetricName>

                  <ScoreContainer MarginLeft="32px" MarginRight="0px">
                    <StyledDivRegular Position="relative" MarginLeft="0px" Width="fit-content" MarginBottom="0px" FontWeight="400" FontSize="14px" Color="#271B36">
                      Current:
                    </StyledDivRegular>

                    <Percentage Border={"5px solid #FFD166"}>
                      <p>- %</p>
                    </Percentage>
                  </ScoreContainer>
                  
                  <img src={DashedLine}/>

                  <ScoreContainer MarginLeft="0px" MarginRight="0px">
                    <StyledDivRegular Position="relative" MarginLeft="0px" Width="fit-content" MarginBottom="0px" FontWeight="400" FontSize="14px" Color="#271B36">
                      Benckmark:
                    </StyledDivRegular>

                    <Percentage Border={"5px solid #FFD166"}>
                      <p>- %</p>
                    </Percentage>
                  </ScoreContainer>
                </ActionsHeaderMetric>
                
                <StyledDivButton Position="relative" Display="flex" Width="100%" Height="100%" MarginTop="0px" MarginLeft="0px" Padding="12px 12px 12px 12px" BackgroundColor='#FCFCFC'
                                  Border={showZones ? "1px solid #E2336B" : ""} JustifyContent="space-between" BorderRadius="4px" BoxShadow='0px 2px 4px rgba(0, 0, 0, 0.25)' Transition="all 0.2s ease">
                  <button onClick={() => {setShowZones(!showZones); metricZones(!showZones, selectedMetric)}}>
                    {showZones ? (
                      <img src={OpenedEyeIcon}/>
                    ) : (
                        <img src={ClosedEyeIcon}/>
                    )}    
                  </button>
                </StyledDivButton>

              </ActionsHeader>

              <ActionsBody>
                <StyledDivRegular Position="relative" MarginLeft="0px" Width="fit-content" Height="fit-content" MarginBottom="0px" FontWeight="400" FontSize="18px" 
                                  Background="#81C5E3" Color="#FCFCFC" Padding="8px 12px 8px 12px" BorderRadius="4px">
                  Actions
                </StyledDivRegular>
                <GivenTeamsWrapper>
                  {presentationActionsData["tags_actions"][selectedTag].data[selectedReceivingTeam][selectedMetric].map((giving_team, index) => {
                      return(
                        <>
                        <StyledDivButton Position="relative" Display="flex" Width="100%" Height="100%" MarginTop="0px" MarginLeft="0px" Padding="12px 12px 12px 12px" BackgroundColor='#FCFCFC'
                                          Border={selectedGivingTeam == index ? "1px solid #E2336B" : ""} JustifyContent="space-between" BorderRadius="4px">
                          <button onClick={() => {selectedGivingTeam == index ? setSelectedGivingTeam(-1) : setSelectedGivingTeam(index)}}>
                            <StyledDivRegular MarginTop='0px' MarginLeft='0px' Width='100%' Height='fit-content' FontSize='18px' SpanColor="#C6CBCB"
                                            Display='flex' JustifyContent='flex-start' Position="relative" BorderRadius="4px" Color='#271B36' Gap="6px">
                                <span>Team</span> {context.setTeams(giving_team["giving_team"], i18n.language)} <span>says:</span>
                            </StyledDivRegular>
                            {selectedGivingTeam == index ? (
                              <img src={DropdownOpened}/>
                            ) : (
                                <img src={DropdownClosed}/>
                            )}
                          </button>
                        </StyledDivButton>
                        {selectedGivingTeam == index ? (
                          <>
                          {giving_team["feedback"].map((feedback, index) => {
                            return(
                              <StyledDivRegular MarginTop='0px' MarginLeft='0px' Width='446px' Height='fit-content' FontSize='18px' Padding="0px 24px 0px 12px"
                                            Display='flex' JustifyContent='center' Position="relative" Color='#271B36'>
                                {index+1 + ". " + feedback}
                              </StyledDivRegular>
                            )
                          })}
                          </>
                        ) : (
                            ""
                        )}
                        </>
                    )
                  })}
                </GivenTeamsWrapper>
              </ActionsBody>
            </ActionsWrapper>
          </>
        ) : (
          <LoadLogo>
            {" "}
            <img src={load} alt="loading..."/>
          </LoadLogo>
        )}
          
      </Container>

    )
}
export default ActionsComponent;

const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: #FCFCFC;
  height: calc(100% - 48px);
  width: calc(572px - 48px);
  top: 0px;
  right: 0px;
  z-index: 1;
  padding: 32px;
`;

const ActionTeamsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  justify-content: space-between;
`;

const ActionsTeamIndex = styled.div`
  position: relative;
  display: flex;
  width: 24px;
  height: 24px;
  color: #FCFCFC;
  font-family: montserrat;
  font-size: 16;
  background-color: ${(props) => props.BackgroundColor || "#271b36"};
  border-radius: 5px;
  margin: 12px 12px 12px 12px;
  align-items: center;
  justify-content: center;
`

const MetricsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  border-radius: 8px;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #F1F3F4;
`;

const MetricsToggle = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  margin-top: 4px;
  padding: 8px;
  justify-content: space-around;
  background: #F1F3F4;
  border-radius: 40px;
`;

const MetricToggleButton = styled.div`
  background-color: ${props => props.BackgroundColor || "transparent"};
  position: relative;
  border-radius: 22px;

  button {
    width: 145px;
    height: 44px;
    border: ${props => props.Border || "0px solid transparent"};
    border-radius: 22px;
    background-color: ${props => props.BackgroundColor || "transparent"};
    color: #271B36;
    font-size: 16px;
    font-weight: 400;
    font-family: "Overpass";
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s ease;
  }
`;

const ActionsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  border-radius: 8px;
  padding: 16px;
  justify-content: space-between;
  border: 1px solid #F1F3F4;
`;

const ActionsHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ActionsHeaderMetric = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const MetricName = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

const ScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  margin-left: ${ props => props.MarginLeft || "" };
  margin-right: ${ props => props.MarginRight || "" };
`;

const Percentage = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: center;
   width: 52px;
   height: 52px;
   background: #271B36;
   border: ${ props => props.Border || "" };
   border-radius: 50px;
   color: #FCFCFC;
   opacity: ${ props => props.Opacity || "100%" };
`;

const ActionsBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #F1F3F4;
  border-radius: 8px;
  padding: 12px;
  gap: 12px;
`;

const GivenTeamsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 364px;
  background: #F1F3F4;
  border-radius: 4px;
  gap: 12px;
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #929699;
  }

`;

const LoadLogo = styled.div`
  img {
    width: 50px;
  }

  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
`;
