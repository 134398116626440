import {Route} from 'react-router-dom';

// Route paths for the survey of the text processing

/** Survey pages */
import PageKnowledge from './knowledge/Page-Knowledge';
import PrePageProcessGoodFeedback from './good-feedback/Pre-Page-Process-Good-Feedback';
import PageProcessGoodFeedback from './good-feedback/Page-Process-Good-Feedback';
import PrePageProcessBadFeedback from './bad-feedback/Pre-Page-Process-Bad-Feedback';
import PageProcessBadFeedback from './bad-feedback/Page-Process-Bad-Feedback';
import PageRootcauseChatbot from './chatbot/PageRootcauseChatbot';


const SurveyTextProcessingRoutes = () => {
    return (
        <>
            {/* Text Processing Survey */}
            <Route exact path="/survey-knowledge" component={PageKnowledge}/>
            <Route exact path="/survey-business-process-selection-feedback-optimal" component={PrePageProcessGoodFeedback}/>
            <Route exact path="/survey-feedback-optimal" component={PageProcessGoodFeedback}/>
            <Route exact path="/survey-business-process-selection-feedback-improvement" component={PrePageProcessBadFeedback}/>
            <Route exact path="/survey-feedback-improvement" component={PageProcessBadFeedback}/>
            <Route exact path="/survey-rootcause-chatbot" component={PageRootcauseChatbot}/>
        </>
    );
};

export default SurveyTextProcessingRoutes
