import React, { useRef, useEffect} from 'react';
import styled from '@emotion/styled';
import ComponentMessage from './Component-Message';

export default function ComponentChatbox(props){
  
  // reference to the text area
  const conversationRef = useRef();
  const messagesEndRef = useRef(null);

  // Function to scroll to the bottom of the chatbox
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // scrolls the conversation box down when a new message is added
  useEffect(() => {
    scrollToBottom();
  }, [props.messages, props.botThinking]); 

  // // scrolls the conversation box down when a new message is added
  // const scrollDown = () => {
  //   if(conversationRef.current)
  //     conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
  // }

  return(
      <Container ref={conversationRef} bottomMargin={props.marginBottom}>
        <MessagesWrapper>
          {props.messages.map((item, index)=>{
              const previousOwnerIsBot = index > 0 ? props.messages[index - 1]["owner_is_bot"] : false; // Check if previous message owner is bot
              
              if(item["owner_is_bot"]){
                  return <div style={{justifyContent: 'left', display: 'flex'}}> <ComponentMessage message={item["message"]} owner_is_bot={item["owner_is_bot"]} setBotWriting={props.setBotWriting} scrollDown={scrollToBottom} previousOwnerIsBot={previousOwnerIsBot} /> </div>
              }
              return <div style={{justifyContent: 'right', display: 'flex'}}> <ComponentMessage message={item["message"]} owner_is_bot={item["owner_is_bot"]} setBotWriting={props.setBotWriting} scrollDown={scrollToBottom} previousOwnerIsBot={previousOwnerIsBot} /> </div>
          })}
          {props.botThinking && <ComponentMessage message="loading" thinking={props.botThinking} owner_is_bot={true} setBotWriting={props.setBotWriting} scrollDown={scrollToBottom}/>}
           {/* Dummy div to scroll to the last message */}
        <div ref={messagesEndRef} />
        </MessagesWrapper>
      </Container>
  )
}

const Container = styled.div`
  display: flex;
  border-style: solid;
  flex-direction: column;
  width: calc(100% - 24px);
  height: calc(100% - 238px);
  background-color: #FCFCFC;
  border-color: #C6CBCB;
  border-radius: 20px;
  border-width: 1px;
  font-size: 14px;
  font-weight: 400;
  white-space: normal;
  overflow-y: scroll;
  padding: 24px 8px 12px 16px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #929699;
    border-radius: 5px;
  }

  @media (orientation: portrait) {
    overflow: hidden;
    flex-grow: 1;
    padding: 0px;
    margin-top: 320px;

    border-radius: 0px;
    border: 0px;
    background-color: #F5F5F5;
    margin-bottom: ${({ bottomMargin }) => (bottomMargin ? `${bottomMargin}px` : '200px')};
    width: 100%;
  }
`;

const MessagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 24px;

  @media (orientation: portrait) {
    flex-grow: 1;
    overflow-y: auto;
    max-height: 100%;
    gap: 20px;
    padding: 24px;
  }
`;