import React, {useContext, useState, useEffect} from "react";
import styled from 'styled-components';
import { DeveloperNetworkFeedbackPage } from "../../api-calls/api-calls";
import {StyledDivButton, StyledDivRegular, StyledDivBold} from "../../../layout-and-styling/standardized-components-library/Styling-TextInput";
import {useTranslation} from 'react-i18next';
import {OverlayContext} from "../../../layout-and-styling/context-hooks/OverlayContext";
import HamburgerMenu from "../assets/HamburgerMenu.png";
import HamburgerMenuHovered from "../assets/HamburgerMenuHovered.png";
import load from "../../../assets/dashboard/load.gif";
import Users from "../assets/Users.svg";


import { PresentationVis } from "../SocialNetworkFeedback";

const ProblemComponent = () => {
    const context = useContext(OverlayContext);
    const {t, i18n} = useTranslation();

    const currentUser = JSON.parse(localStorage.getItem("current_user_data"))[0];
    const [selectedCompany, setSelectedCompany] = useState(JSON.parse(localStorage.getItem("selected_company")));
    const [selectedSurvey, setSelectedSurvey] = useState(JSON.parse(localStorage.getItem("selected_survey")));  

    const [problemData, setProblemData] = useState(JSON.parse(localStorage.getItem("presentation_data_overview")) && JSON.parse(localStorage.getItem("presentation_data_overview")).survey == selectedSurvey ? JSON.parse(localStorage.getItem("presentation_data_overview")) : null);
    const [currentMenu, setCurrentMenu] = useState("Frequency");
    const [selectedTag, setSelectedTag] = useState(JSON.parse(localStorage.getItem("selected_tag")) ? JSON.parse(localStorage.getItem("selected_tag")) : 0);
    const [hoveredTag, setHoveredTag] = useState(0);
    const [burgerHovered, setBurgerHovered] = useState(false);
    const [hoveredProcess, setHoveredProcess] = useState("");
    const [hoveredSubprocess, setHoveredSubprocess] = useState("");

    // Function that make the api call to get the social network data if necessary
    async function loadData() {
      if (currentUser.is_developer) {
        if(! problemData) await DeveloperNetworkFeedbackPage(selectedCompany.id, selectedSurvey);
      }
    }

    //Wait for data to load, display loading icons beforehand
    useEffect(() => {

      loadData()

      window.addEventListener('presentation_data_event', () => {
        setProblemData(JSON.parse(localStorage.getItem("presentation_data_overview")));
      })
    }, []);
    
    function ChangeMenu(selection){
      if(currentMenu === selection){
        setCurrentMenu("closed");
        return;
      }
      setCurrentMenu(selection);
    }   
    
    function HandleTagChange(index){
      setSelectedTag(index)
      localStorage.setItem('selected_tag', JSON.stringify(index))
      setCurrentMenu("closed")
      PresentationVis[0].highlightSelectedTeams(problemData.tags_overview[index].employees)
    }

    return (
      <Container>
        {problemData ? (
            <>
            <SelectedTagWrapper BoxShadow=" 0px 12px 32.4px 0px rgba(0, 0, 0, 0.10)">
              <PercentageCircle>{selectedTag == 0 ? "" : problemData.tags_overview[selectedTag].percentage + "%"}</PercentageCircle>
              
              <TagTextWrapper>
                <StyledDivRegular Position="relative" Width="fit-content" FontWeight="700" FontSize="18px">
                  {problemData.tags_overview[selectedTag].tag}
                </StyledDivRegular>

                {selectedTag > 0 ? (
                  <StyledDivRegular Position="relative" Width="fit-content" FontWeight="400" FontSize="16px" Color="#5F6366">
                    Of employees talk about this
                  </StyledDivRegular>
                ) : (
                  ""
                )}
              </TagTextWrapper>

              <DropDownButton Image={burgerHovered ? HamburgerMenuHovered : HamburgerMenu}>
                <button onClick={() => ChangeMenu("Tags")} onMouseOver={() => setBurgerHovered(true)} onMouseLeave={() => setBurgerHovered(false)}></button>
              </DropDownButton>

            </SelectedTagWrapper>

            <ButtonsWrapper>
              {selectedTag > 0 ? (
                <StyledDivButton Position="relative" Display="flex" Width="192px" Height="52px" MarginTop="0px" MarginLeft="0px" Padding="12px 12px 12px 12px" BackgroundColor='#F1F3F4' 
                                Border={currentMenu == "Feedback" ? "1px solid #E2336B" : ""} BorderRadius="4px" Transition="all 0.2s ease" Color="#5F6366">
                  <button onClick={() => ChangeMenu("Feedback")}>
                    Feedback
                  </button>
                </StyledDivButton>
              ) : (
                ""
              )}
              <StyledDivButton Position="relative" Display="flex" Width={selectedTag == 0 ? "396px" : "192px"} Height="52px" MarginTop="0px" MarginLeft="0px" Padding="12px 12px 12px 12px" BackgroundColor='#F1F3F4' 
                                Border={currentMenu == "Frequency" ? "1px solid #E2336B" : ""} BorderRadius="4px" Transition="all 0.2s ease" Color="#5F6366">
                <button onClick={() => ChangeMenu("Frequency")}>
                  Frequency
                </button>
              </StyledDivButton>
            </ButtonsWrapper>

            {currentMenu === "Tags" ? (
              <Menu BoxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.08)">
                {problemData.tags_overview.map((tag, index) => {
                  return(
                    <StyledDivButton Position="relative" Display="flex" Width="100%" Height="fit-content" MarginTop="0px" MarginLeft="0px" Padding="0px" 
                                  BackgroundColor={hoveredTag == index ? '#F1F3F4' : '#FCFCFC'} Transition="all 0.2s ease" BorderRadius="4px">
                      <button onClick={() => HandleTagChange(index)} onMouseOver={() => setHoveredTag(index)} onMouseLeave={() => setHoveredTag(0)}>
                        <SelectedTagWrapper Width="100%" BackgroundColor={hoveredTag == index ? '#F1F3F4' : '#FCFCFC'}>
                          <PercentageCircle>{index == 0 ? "" : tag.percentage + "%"}</PercentageCircle>
                          
                          <TagTextWrapper>
                            <StyledDivRegular Position="relative" Width="fit-content" FontWeight="700" FontSize="18px">
                              {tag.tag}
                            </StyledDivRegular>
                            {index > 0 ? (
                              <StyledDivRegular Position="relative" Width="fit-content" FontWeight="400" FontSize="16px" Color="#5F6366">
                                Of employees talk about this
                              </StyledDivRegular>
                            ) : (
                              ""
                            )}
                          </TagTextWrapper>

                        </SelectedTagWrapper>
                      </button>
                    </StyledDivButton>
                  )
                })}
                

              </Menu>
            ) : currentMenu === "Feedback" ? (
              <Menu Top="-54px" Width="calc(100% - 32px)" Padding="16px">
                <FeedbackTitle>

                  <StyledDivRegular Position="relative" Width="fit-content" FontWeight="700" FontSize="16px" Color="#E2336B">
                    Feedback according to the employees
                  </StyledDivRegular>

                  <StyledDivRegular Position="relative" MarginLeft="0px" Width="fit-content" MarginBottom="0px" FontWeight="400" FontSize="14px" Color="#929699">
                    <img src={Users}/> feedbacks
                  </StyledDivRegular>

                </FeedbackTitle>
                
                <FeedbackWrapper>
                  {problemData.tags_overview[selectedTag].feedback.map((feedback, index) => {
                    return(
                      <FeedbackTeam>

                        <StyledDivRegular Position="relative" MarginLeft="0px" Width="fit-content" MarginBottom="0px" FontWeight="400" FontSize="16px" Color="#271B36">
                          {context.setTeams(feedback.team, i18n.language).split(" ")[0]} <img src={Users}/>
                        </StyledDivRegular>

                        <StyledDivRegular Position="relative" Width="fit-content" Height="fit-content" FontWeight="400" FontSize="16px" Color="#271B36">
                          {feedback.feedback}
                        </StyledDivRegular>
                        
                      </FeedbackTeam>
                    )
                  })}
                </FeedbackWrapper>
                
              </Menu>
            ) : currentMenu === "Frequency" ? (
              <Menu Top="-54px">
                <ProcessesWrapper>
                  {problemData.tags_overview[selectedTag].processes.map((process, index) => {
                    return(
                      <ProcessWrapper onMouseOver={() => setHoveredProcess(process.process)} onMouseLeave={() => setHoveredProcess("")}>

                        <StyledDivRegular Position="relative" Display="flex" Width="100%" Height="fit-content" FontWeight="400" FontSize="16px" Color="#271B36">
                          {context.setTeams(process.process, i18n.language)}
                        </StyledDivRegular>

                        <SubprocessesWrapper>
                          {process.subprocesses.map((subprocess, index) => {
                            return(
                              <SubprocessWrapper>
                                <StyledDivRegular Position="fixed" Display={hoveredProcess == process.process && hoveredSubprocess == subprocess.subprocess ? "flex" : "none"} Height="24px" Width="fit-content" Padding="2px 8px" ZIndex="1"
                                                  MarginTop="-36px" Left={index == 0 ? "132px" : ""} FontWeight="400" FontSize="14px" Background="#FCFCFC" Color="#271B36" BorderRadius="4px" BoxShadow="0px 2px 4px 0px rgba(0,0,0,0.25)" >
                                  {context.setTeams(subprocess.subprocess, i18n.language)}
                                </StyledDivRegular>
                                <IntroExplanationInnerTriangle Display={hoveredProcess == process.process && hoveredSubprocess == subprocess.subprocess ? "flex" : "none"} Top='0px' Left='7px' BorderTop="8px solid transparent" 
                                        Rotate="rotate(90deg)" BorderRight='30px solid transparent' BorderBottom="8px solid transparent" BorderLeft='8px solid #FCFCFC' />
                                <StyledDivButton Position="relative" Display="flex" Width="50px" Height="50px" MarginTop="0px" MarginLeft="0px" Padding="0px" FontWeight="400" FontSize="18px"
                                            BackgroundColor={"rgba(217, 8, 8," + 2*(subprocess.percentage/100) + ");"} Transition="all 0.2s ease" Color="#271B36" BorderRadius="4px">
                                  <button onMouseOver={() => setHoveredSubprocess(subprocess.subprocess)} onMouseLeave={() => setHoveredSubprocess("")}>
                                    {subprocess.percentage}%
                                  </button>
                                </StyledDivButton>
                              </SubprocessWrapper>
                            )
                          })}
                        </SubprocessesWrapper>

                      </ProcessWrapper>
                    )
                  })}
                </ProcessesWrapper>
              </Menu>
            ) : (
              ""
            )}
            </>
        ) : (
          <LoadLogo>
            {" "}
            <img src={load} alt="loading..."/>
          </LoadLogo>
        )}
          
      </Container>

    )
}
export default ProblemComponent

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 428px;
  height: 100%;
  margin-top: 24px;
  margin-left: 42px;
`;

const SelectedTagWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: ${props => props.BorderRadius || "46px"};
  background-color: ${props => props.BackgroundColor || "#FCFCFC"};
  height: 60px;
  width: ${props => props.Width || "calc(100% - 32px)"};
  padding: ${props => props.Padding || "16px"};
  box-shadow: ${props => props.BoxShadow || ""};
  z-index: 2;
  gap: 16px;
  transition: all 0.2s ease;
`

const TagTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 254px;
  gap: 6px;
  padding-top: 8px;
`

const PercentageCircle = styled.div`
  position: relative;
  display: flex;
  background-color: #E2236B;
  color: #FCFCFC;
  font-family: 'Overpass';
  font-weight: bold;
  font-size: 18px;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
`

const DropDownButton = styled.div`
  background-color: #FCFCFC;
  border-radius: 100px;
  height: 50px;
  width: 50px;
  button{
    height: 50px;
    width: 50px;
    cursor: pointer;
    border-radius: 100px;
    border: none;
    background-color: #FCFCFC;
    background-image: url(${props => props.Image || "HamburgerMenu"});
    background-size: 50px 50px;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 1;
  top: -46px;
  background-color: #FCFCFC;
  border-radius: 8px;
  width: calc(100% - 32px);
  height: 100px;
  align-items: end;
  padding: 16px;
  gap: 12px;
`

const Menu = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  top: ${props => props.Top || "-130px"};
  z-index: 1;
  width: ${props => props.Width || "100%"};
  background-color: #FCFCFC;
  border-radius: 4px;
  box-shadow: ${props => props.BoxShadow || ""};
  padding: ${props => props.Padding || ""};
`

const FeedbackTitle = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const FeedbackWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #FCFCFC;
  max-height: 564px;
  gap: 16px;

  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #929699;
  }
`

const FeedbackTeam = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #F1F3F4;
  height: fit-content;
  gap: 4px;
  padding: 12px;
`

const ProcessesWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #FCFCFC;
  align-items: center;
  gap: 16px;
  padding: 16px 32px 32px 32px;
`

const ProcessWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #FCFCFC;
  height: fit-content;
  justify-content: center;
  gap: 4px;
`

const SubprocessesWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: #FCFCFC;
  align-items: center;
  gap: 8px;

`

const SubprocessWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`

const IntroExplanationInnerTriangle = styled.div`
  position: absolute;
  display: ${props => props.Display || "none"};
  top: ${props => props.Top || ""};
  left: ${props => props.Left || ""};
  width: 1px;
  height: 1px;
  border-top: ${props => props.BorderTop || "20px solid transparent"};
  border-right: ${props => props.BorderRight || "30px solid transparent"};
  border-bottom: ${props => props.BorderBottom || "20px solid transparent"};
  box-shadow: "0px 2px 4px 0px rgba(0,0,0,0.25)";
  border-left: ${props => props.BorderLeft || ""};
  z-index: 1;
  transform: ${props => props.Rotate || ""};
`;


const LoadLogo = styled.div`
  img {
    width: 50px;
  }

  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
`;
