import React, {useContext, useState, useEffect, useRef} from "react";
import styled from "styled-components";
import * as d3 from "d3";
import {StyledDivButton, StyledDivRegular} from "../../../layout-and-styling/standardized-components-library/Styling-TextInput";
import ZoomInButton from "../assets/ZoomInButton.png";
import ZoomOutButton from "../assets/ZoomOutButton.png";
import RenderComponent from "./RenderComponent";
import { DashboardSocialNetworkPage, DeveloperSocialNetworkPage } from "../../api-calls/api-calls";
import {useTranslation} from 'react-i18next';
import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import load from "../../../assets/dashboard/load.gif";
import Toolbox from "./Toolbox";

export var SocialNetworkVis = []; // where the social network is stored
    
const SocialNetworkPage = (props) => {
    const {t, i18n} = useTranslation();
    
    /** The useRef Hook creates a variable that "holds on" to a value across rendering passes.
     * In this case it will hold our component's SVG DOM element.
     * It's initialized null and React will assign it later (see the return statement) */
    
    const currentUser = JSON.parse(localStorage.getItem("current_user_data"))[0];
    const [selectedCompany, setSelectedCompany] = useState(JSON.parse(localStorage.getItem("selected_company")));
    const [selectedSurvey, setSelectedSurvey] = useState(JSON.parse(localStorage.getItem("selected_survey")));
  

    // default sizes and zooms of the networks of the different surveys, after render they are recalculated automatically
    // every social network has different dimensions, which are calculated in the file SocialNetwork-render-component in the useEffect when props.graphData changes
    // these are the dimensions obtained there. On reload of the page these sizes are automatically assigned but at the first time it does not, that why we have to add these values here
    // TODO: make it automated also for the first load of the page 
    const [zoom, setZoom] = useState(
        selectedSurvey == 2 ? 0.62
        : selectedSurvey == 3 ? 0.55
        : selectedSurvey == 5 ? 0.40
        : selectedSurvey == 8 ? 0.52
        : selectedSurvey == 9 ? 0.56
        : 1);
    const [graphWidth, setGraphWidth] = useState(
        selectedSurvey == 2 ? 2465
        : selectedSurvey == 3 ? 2782
        : selectedSurvey == 5 ? 3770
        : selectedSurvey == 8 ? 2916
        : selectedSurvey == 9 ? 3286 
        : 0);
    const [graphHeight, setGraphHeight] = useState(
        selectedSurvey == 2 ? 2465
        : selectedSurvey == 3 ? 2782
        : selectedSurvey == 5 ? 3770
        : selectedSurvey == 8 ? 2916
        : selectedSurvey == 9 ? 3286 
        : 0);

    
    const [graphData, setGraphData] = useState(JSON.parse(localStorage.getItem("graph_data")) && JSON.parse(localStorage.getItem("graph_data")).survey == selectedSurvey ? JSON.parse(localStorage.getItem("graph_data")) : null);

    // Function that make the api call to get the social network data if necessary
    async function loadData() {
        if (currentUser.is_developer) {
            if(! graphData) await DeveloperSocialNetworkPage(selectedCompany.id, selectedSurvey);
        } else {
            if(! graphData) DashboardSocialNetworkPage();
        }
    }

    /** Adding a listener that runs when graph data is stored in the local storage */
    useEffect(() => {

        loadData()

        window.addEventListener('graph_data_event', () => {
            setGraphData(JSON.parse(localStorage.getItem("graph_data")));
        })

    }, [])

    
    const [legendExpanded, expandLegend] = useState(false);
   
    //rerendering helps team legend rerender?...where to trigger this new render, not finished
    const [render, setRender] = useState(true);
    const reRender = () => {
      setRender(!render);
    };

    const handleAccordions = (panel) => (event, isExpanded) => {
        if(panel === 'panel1') expandLegend(isExpanded ? panel : false);
    };

    return (
        <Container>
            {graphData ? (
                <>
                    <Toolbox Page={"SocialNetwork"} JustifyContent={"right"} LeftScore="Collaboration" RightScore="Collaboration Benchmark"/> 


                    <RenderComponent id="RenderComponent" graphData={graphData} Zoom={zoom} setZoom={setZoom} graphWidth={graphWidth} setGraphWidth={setGraphWidth}
                        graphHeight={graphHeight} setGraphHeight={setGraphHeight} language={i18n.language} Page={props.Page} />

                    <LegendZoomWrapper>
                        <ScaleColorWrapper>
                            {t('DashboardSocialNetwork.Low')}
                            <Color MarginLeft="12px" Color="#B3194A"/>
                            <Color Color="#F72B2B"/>
                            <Color Color="#FFBC1F"/>
                            <Color Color="#89F066"/>
                            <Color MarginRight="12px" Color="#559B3E"/>
                            {t('DashboardSocialNetwork.High')}
                        </ScaleColorWrapper>

                    </LegendZoomWrapper>

                    <TeamsLegendWrapper Height="fit-content" Width="fit-content" Left={"50%"}>

                        <Accordion expanded={legendExpanded === 'panel1'} onChange={handleAccordions('panel1')}
                                sx={{ width: "fit-content", borderTopRightRadius: 5, borderTopLeftRadius: 5, justifyContent: 'center' }}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header"
                                sx={{ color: '#292929', fontSize: 16, fontFamily: 'Overpass', fontStyle: 'normal', fontWeight: 700,
                                    '& .MuiAccordionSummary-content': {
                                        justifyContent: 'center',
                                    },
                                    '& .MuiAccordionSummary-root.Mui-expanded': {
                                        height: '32px',
                                    }
                                }}>
                                {t('DashboardSocialNetwork.TeamsLegend')}
                            </AccordionSummary>
                            <AccordionDetails sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} >
                                <TeamFiltersWrapper MarginTop="0px" MarginLeft="0px" Width="fit-content" Height="fit-content" Language= {i18n.language} reRender={reRender}/>
                            </AccordionDetails>
                        </Accordion>
                    </TeamsLegendWrapper>
                </>
            ) : (
                <LoadLogo>
                    {" "}
                    <img src={load} alt="loading..."/>
                </LoadLogo>
            )}
            
        </Container>
    );
};

export default SocialNetworkPage;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: start;
  flex-flow: nowrap;
  position: absolute;
  font-family: 'Overpass', sans-serif;
  @media only screen and (min-width: 1980px) {
    zoom: 1;
  }
`;

const LegendZoomWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  bottom: 0;
  right: 0;
  margin-bottom: 20px;
  margin-right: 20px;
  align-items: flex-end;
  gap: 16px;
`;

const ScaleColorWrapper = styled.div.attrs({
    className: "ColorScale",
})`
  display: none;
  background: #FCFCFC;
  flex-direction: row;
  font-family: "Overpass";
  color: #271B36;
  width: 224px;
  height: 24px;
  align-items: center;
  justify-content: center;
  z-index: 3;
  padding: 8px 4px;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;

const Color = styled.div`
  display: flex;
  flex-direction: row;
  width: 24px;
  height: 24px;
  margin-left: ${props => props.MarginLeft || "0px"};
  margin-right: ${props => props.MarginRight || "0px"};
  align-items: center;
  justify-content: center;
  background-color: ${props => props.Color};
  opacity: 0.6;
  z-index: 4;
`;
          
const TeamsLegendWrapper = styled.div`
  display: flex;
  z-index: 1;
  width: ${(props) => props.Width || "1199px"};
  height: ${(props) => props.Height || "126px"};
  position: fixed;
  justify-content: center;
  bottom: 0;
  left: ${(props) => props.Left || "50%"};
  transform: translateX(-50%);
`;

const TeamFiltersWrapper = styled.div.attrs({className: "TeamFilter",})`
  position: relative;
  margin-top: ${(props) => props.MarginTop || "20px"};
  margin-left: ${(props) => props.MarginLeft || "40px"};
  width: ${(props) => props.Width || "536px"};
  height: ${(props) => props.Height || "173px"};
  max-width: 1024px;
  background-color: #FCFCFC;
  z-index: 4;
  display: flex;
  justify-content: center;
  language: ${(props) => props.Language || "20px"};
`;

const LoadLogo = styled.div`
  img {
    width: 50px;
  }

  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
`;
