import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

import SurveyTextProcessingHeader from '../standardized-components/Survey-text-processing-header';
import SurveyTextProcessingFooter from '../standardized-components/Survey-text-processing-Footer';
import { useWindowSize } from '../../layout-and-styling/standardized-components-library/Component-Responsive';
import ComponentKnowledgeProcesses from './page-specific-components/Component-knowledge-processes';
import ComponentSliderProcessesInvolved from './page-specific-components/Component-slider-processes-involved';
import ComponentIndividualImpact from './page-specific-components/Component-individual-impact';
import { Trans, useTranslation } from "react-i18next";
import ComponentBusinessProcessDropdown from "./page-specific-components/ComponentBusinessProcessDropdown";

/** Get ProcessData datasets from API stored data from localStorage */
const ProcessData = JSON.parse(localStorage.getItem('business_process_data'));

const PageKnowledge = () => {
  const { t } = useTranslation();

  /** Survey page for filling in knowledge of business processes & individual satisfaction for the individual performance*/

  /** Check if already authenticated, and bring to dashboard if possible.*/
  useEffect(() => {
    const axiosInstance = axios.create({ withCredentials: true })
    // Perform GET request to get a CSRFToken from the API and store it in sessionStorage
    axiosInstance.get(process.env.REACT_APP_APIURL + '/api/auth/validatesession/')
      .catch((error) => {
        window.location.replace(process.env.REACT_APP_APPURL + '/login');
      })
  }, []);

  let InitialSelfRatedKnowledge = []

  for (let i = 0; i < ProcessData.length; i++) {
    InitialSelfRatedKnowledge.push({
      id: i,
      process_id: ProcessData[i].id,
      process_name: ProcessData[i].name,
      self_rated_knowledge: null,
      time_portion: 0
    })
  }

  const [SelfRatedKnowledge, setSelfRatedKnowledge] = useState(JSON.parse(localStorage.getItem("SelfRatedKnowledge")) ? JSON.parse(localStorage.getItem('SelfRatedKnowledge')) : InitialSelfRatedKnowledge);
  localStorage.setItem("SelfRatedKnowledge", JSON.stringify(SelfRatedKnowledge))

  const [render, setRender] = useState(false);
  const reRender = () => {
    setRender(!render)
  };

  const ProcessesSelected = JSON.parse(localStorage.getItem('ProcessesSelected')) ? JSON.parse(localStorage.getItem('ProcessesSelected')) : new Array(SelfRatedKnowledge.length).fill(false);
  localStorage.setItem("ProcessesSelected", JSON.stringify(ProcessesSelected));

  const [widths, setWidths] = useState(localStorage.getItem("ProcessesTimePortion") ? JSON.parse(localStorage.getItem("ProcessesTimePortion")) : new Array(SelfRatedKnowledge.length).fill(0));
  localStorage.setItem("ProcessesTimePortion", JSON.stringify(widths));

  // Check the time portion slider
  const [sliderCheck, setSliderCheck] = useState(localStorage.getItem('slider_check') ? JSON.parse(localStorage.getItem('slider_check')) : false);
  // Check the individual impact
  const [inputCheckIndividualImpact, setInputCheckIndividualImpact] = useState(localStorage.getItem('individual_impact_check') ? JSON.parse(localStorage.getItem('individual_impact_check')) : false);
  // Check the knowledge of the processes
  const [inputCheckIndividualKnowledgeProcesses, setInputCheckIndividualKnowledgeProcesses] = useState(localStorage.getItem('individual_knowledge_processes_check') ? JSON.parse(localStorage.getItem('individual_knowledge_processes_check')) : false);
  const [nextButtonClicked, setNextButtonClicked] = useState(false);

  useState(() => {
    setSliderCheck(JSON.parse(localStorage.getItem('slider_check')))
    setInputCheckIndividualImpact(JSON.parse(localStorage.getItem('individual_impact_check')))
    setInputCheckIndividualKnowledgeProcesses(JSON.parse(localStorage.getItem('individual_knowledge_processes_check')))
  }, [])

  useEffect(() => {
    localStorage.setItem("slider_check", JSON.stringify(sliderCheck));
    localStorage.setItem("individual_impact_check", JSON.stringify(inputCheckIndividualImpact));
    localStorage.setItem("individual_knowledge_processes_check", JSON.stringify(inputCheckIndividualKnowledgeProcesses));
  }, [sliderCheck, inputCheckIndividualImpact, inputCheckIndividualKnowledgeProcesses])

  return (
    <Container>
      <HeaderAndOverlayWrapper>
        <SurveyTextProcessingHeader page='Survey' reRender={reRender} directory='/survey-knowledge' />
      </HeaderAndOverlayWrapper>

      <CenterContainer>

        <BackgroundWrapper />

        <PageWrapper>

          <WrapperTop>
            <Trans i18nKey="PageKnowledge.BodyHeadingWrapper">
              In which <span
                style={{ color: "#B3194A" }}> processes </span>
              are you involved and how impactful are you?
            </Trans>
          </WrapperTop>

          <ComponentsWrapper>

            <LeftWrapper>

              <ComponentBusinessProcessDropdown widths={widths} setWidths={setWidths} ProcessesSelected={ProcessesSelected} 
                SelfRatedKnowledge={SelfRatedKnowledge} setSelfRatedKnowledge={setSelfRatedKnowledge} setSliderCheck={setSliderCheck} reRender={reRender} nextButtonClicked={nextButtonClicked}/>

              <ComponentSliderProcessesInvolved sliderCheck={sliderCheck} setSliderCheck={setSliderCheck} widths={widths} setWidths={setWidths} ProcessesSelected={ProcessesSelected}
                SelfRatedKnowledge={SelfRatedKnowledge} setSelfRatedKnowledge={setSelfRatedKnowledge} nextButtonClicked={nextButtonClicked}/>

              <ComponentIndividualImpact input_check_individual_impact={inputCheckIndividualImpact} set_input_check_individual_impact={setInputCheckIndividualImpact} nextButtonClicked={nextButtonClicked}/>

            </LeftWrapper>

            <ComponentKnowledgeProcesses SelfRatedKnowledge={SelfRatedKnowledge} setSelfRatedKnowledge={setSelfRatedKnowledge} inputCheckIndividualKnowledgeProcesses={inputCheckIndividualKnowledgeProcesses} 
              set_input_check_knowledge_processes={setInputCheckIndividualKnowledgeProcesses} nextButtonClicked={nextButtonClicked}/>

          </ComponentsWrapper>

        </PageWrapper>

      </CenterContainer>

      <SurveyTextProcessingFooter page='PageKnowledge' backDirectory='/home' FooterMarginTop={(useWindowSize()[1] - 104).toString() + "px"} FooterMarginLeft='0px' FooterHeight='104px' FooterWidth='100%'
        MarginTop='25px' BackButtonMarginLeft='65%' NextButtonMarginLeft='calc(65% + 66px)' sliderCheck={sliderCheck} input_check_knowledge_processes={inputCheckIndividualKnowledgeProcesses} 
        input_check_individual_impact={inputCheckIndividualImpact} nextButtonClicked={nextButtonClicked} setNextButtonClicked={setNextButtonClicked} />
    </Container>
  );

};
export default PageKnowledge

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% - 40px);
  background: #F1F3F4;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: calc(1 / 1.25);
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: calc(1 / 1.5);
  }
  @media (-webkit-device-pixel-ratio: 1.75) {
    zoom: calc(1 / 1.75);
  }
`;

const CenterContainer = styled.div`
  width: 1920px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  flex-flow: nowrap;
`;

const BackgroundWrapper = styled.div`
  position: absolute;
  background: #F1F3F4;
  top: ${props => props.Top || "0px"};
  left: ${props => props.Left || "0px"};
  width: 1920px;
  height: 100%;
`;

const HeaderAndOverlayWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  height: calc(100% - 171px);
  margin-top: 66px;
  width: 100%;
  @media only screen and (min-height: 1240px) {
    height: calc(70% - 171px);
  }
`;

const ComponentsWrapper = styled.div`
  display: flex;
  padding-top: 50px;
  @media only screen and (max-width: 1540px) {
    margin-left: 20px;
  }
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const WrapperTop = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 100px;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 64px;
  height: 10px;
`;