import React, { useContext, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { OverlayContext } from "../../../layout-and-styling/context-hooks/OverlayContext";
import botImage from '../assets/botImage.png';
import reversedBotImage from '../assets/reversedBotImage.svg';
import ChatbotLoading from "../assets/ChatbotLoading.gif";

export default function ComponentMessage(props) {
  const color = props.owner_is_bot ? "#F7C9D9" : "#D0E1F1";
  const currentUser = JSON.parse(localStorage.getItem("current_user_data"))[0];
  const context = useContext(OverlayContext);
  const isPortraitMode = window.matchMedia("(orientation: portrait)").matches;

  // Typewriter effect for bot message typing
  function TypeWriterEffect(text, typingSpeed = 10) {
    const [displayedText, setDisplayedText] = useState('');

    useEffect(() => {
      if (text.length > displayedText.length) {
        props.setBotWriting(true);
        const timeoutId = setTimeout(() => {
          setDisplayedText(text.substring(0, displayedText.length + 1));
        }, typingSpeed);

        return () => clearTimeout(timeoutId);
      } else {
        props.setBotWriting(false);
      }
    }, [text, displayedText, typingSpeed]);

    // Scroll down after typing
    props.scrollDown();

    return displayedText;
  }

  return (                             
    <Container isBot={props.owner_is_bot}>
      {/* Bot's profile picture */}
      {props.owner_is_bot && !props.previousOwnerIsBot && (   // Show only one bot profile picture per new bot messages
        <ProfilePicture>
          <img src={reversedBotImage} alt="Bot" />
        </ProfilePicture>
      )}
      
      <MessageContainer isBot={props.owner_is_bot} previousOwnerIsBot={props.previousOwnerIsBot}>
        {/* Message and triangle */}
        {props.owner_is_bot ? (
          <>
            {!isPortraitMode &&(
              <Triangle Left="6px"  previousOwnerIsBot={props.previousOwnerIsBot}>
                <svg width="14" height="12">
                  <polygon points="14,0 0,0 14,12" style={{ fill: color }} />
                </svg>
              </Triangle>
            )}
            <Message color={color} isBot={props.owner_is_bot} previousOwnerIsBot={props.previousOwnerIsBot}>
              {props.thinking ? (
                <img src={ChatbotLoading} alt="Loading..." style={{ width: '20px', height: '20px' }} />
              ) : (
                <>{TypeWriterEffect(props.message)}</>
              )}
            </Message>
          </>
        ) : (
          <>
            <Message key={props.message} color={color} isBot={props.owner_is_bot} >
              {props.message}
            </Message>
            {!isPortraitMode && (
              <Triangle Left="-6px">
                <svg width="14" height="12">
                  <polygon points="0,0 0,12 14,0" style={{ fill: color }} />
                </svg>
              </Triangle>
            )}
          </>
        )}
      </MessageContainer>
      
      {/* User's profile picture */}
      {!isPortraitMode && !props.owner_is_bot && (
        <ProfilePicture>
          {context.setFirstName(currentUser.first_name, 0).charAt(0) +
            context.setFirstName(currentUser.last_name, 0).split(" ").filter((str) => str !== '').pop().charAt(0)}
        </ProfilePicture>
      )}
    </Container>
  );
}

// Styled Components

const Container = styled.div`
  display: flex;
  @media (orientation: portrait) {
    align-items: ${(props) => (props.isBot ? "flex-start" : "flex-end")};
  }
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px 24px 8px 16px;
  width: fit-content;
  max-width: 700px;
  margin-left: ${(props) => (props.previousOwnerIsBot ? "64px" : "16px")};

  @media (orientation: portrait) {
    max-width: 800px;
    width: auto;
    margin-bottom: ${(props) => (props.isBot ? "0" : "0px")};
    margin-right: 0px;
    margin-left: ${(props) => (props.previousOwnerIsBot ? "116px" : "16px")};
    margin-top: ${(props) => (props.previousOwnerIsBot ? "0px" : "50px")};
  }
`;

const Message = styled.div`
  display: flex;
  border-radius: 8px;
  padding: 12px;
  font-family: 'Overpass';
  font-size: 18px;
  line-height: 27px;
  
  /* Prevent breaking on spaces */
  white-space: pre-wrap; /* Allow wrapping but respect spaces */
  word-break: break-word; /* Default word breaking */

  background-color: ${({ color }) => color};

  @media (orientation: portrait) {
    font-size: 34px;
    line-height: 51px;
    padding: 30px;
    border-radius: ${(props) => (props.isBot && props.previousOwnerIsBot ? "40px" : props.isBot ? "0 40px 40px 40px" : "40px 40px 0px 40px")};
    background-color: ${(props) => (props.isBot ? "#F7C9D9" : "#D0E1F1")} !important;
  }
`;

const ProfilePicture = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: #D0E1F1;
  border-radius: 100%;
  font-family: 'Overpass';
  font-size: 20px;
  color: #000000;

  img {
    width: 100%;
    height: 100%; // increase the size of the image if border around the icon needs to be removed
  }

  @media (orientation: portrait) {
    width: 100px;
    height: 100px;
    font-size: 40px;
    font-weight: 700;
    background-color: ${(props) => (props.isBot ? "D0E1F1" : "transparent")};
  }
`;

const Triangle = styled.div`
  position: relative;
  display: flex;
  opacity: ${(props) => (props.previousOwnerIsBot ? "0" : "1")}; // hide triangle if its not the first message from bot
  left: ${(props) => props.Left || "0px"};
  height: 12px;
  width: 14px;
`;