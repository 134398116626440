import styled from 'styled-components';
import { StyledDivRegular } from './Styling-TextInput';


export const DashedArrow = ( componentprops ) => {
    return(
        <StyledDivRegular MarginTop={componentprops.MarginTop} MarginLeft={componentprops.MarginLeft} 
        Height='14px' Display='flex' JustifyContent='flex-start'>

            <Dot MarginLeft='0px'/>
            <DashedLine/>
            <ArrowHead MarginLeft='756px'/>

        </StyledDivRegular>
        
    );
};


export const DashedLine = styled.div`
    position: ${props => props.Position || 'fixed'};
    margin-top:  ${props => props.MarginTop || "1px"};
    margin-left:  ${props => props.MarginLeft || "0px"};
    width: ${props => props.Width || "756px"};
    height: ${props => props.Height || "0px"};
    border: none;
    border-top: ${props => props.BorderTop || "3px dashed #271B36"};
    color: transparent;
    background-color: transparent;
`;

export const ArrowHead = styled.div`
    position: ${props => props.Position || 'fixed'};
    margin-top:  ${props => props.MarginTop || "0px"};
    margin-left:  ${props => props.MarginLeft || "0px"};
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 12px solid #271B36;
`;  

const Dot = styled.div`
    position: ${props => props.Position || 'fixed'};
    margin-top:  ${props => props.MarginTop || "0px"};
    margin-left:  ${props => props.MarginLeft || "0px"};
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background-color: #271B36;
`;

export const StartArrowWrapper = styled.div`
    margin-right:  ${props => props.MarginRight || "144px"}; /** process arrow gap size 1*/
    margin-top: -4px;

`;

export const StartArrow1 = styled.div`
    position: ${props => props.Position || "fixed"};;
    margin-top:  ${props => props.MarginTop || ""};
    margin-right:  ${props => props.MarginRight || ""};
    width: 117px;
    // height: 56px;
    height: 65px;

    background: ${props => props.Background || "#271B36"};
    border-radius: 2px;
    color: #FCFCFC;
    font-family: 'Overpass';
    font-size: 15px;
    font-weight: 400;
    display:flex;
    align-items: center;
    justify-content: center;
`;

export const StartArrow2 = styled.div`
  position: ${props => props.Position || "fixed"};;
    margin-top:  ${props => props.MarginTop || ""};
    margin-left:  ${props => props.MarginLeft || "116px"}; 
    border-top:  ${props => props.BorderTop || "28px solid transparent"};
    border-bottom:  ${props => props.BorderBottom || "28px solid transparent;"};
    border-left:  ${props => props.BorderLeft || "28px solid #271B36"};
    border-radius: 2px;
    height: 0px;
`;

export const MidArrowWrapper = styled.div`
    margin-top:  ${props => props.MarginTop || ""};
    margin-left:  ${props => props.MarginLeft || "-16px"}; /** process arrow gap size 2*/
    display:flex;
    justify-content: center;
    align-items: center;
`;

export const MidArrow1 = styled.div`
    display:flex;
    justify-content: center;
    margin-top:  ${props => props.MarginTop || ""};
    margin-left:  ${props => props.MarginLeft || "-1px"};
    width: 0px;
    height: 0px;

    border-top:  ${props => props.BorderTop || "28px solid #271B36"};
    border-right:  ${props => props.BorderRight || "28px solid #271B36"};
    border-bottom:  ${props => props.BorderBottom || "28px solid #271B36"};
    border-left:  ${props => props.BorderLeft || "28px solid transparent"};
`;
export const MidArrow2 = styled.div`
    margin-top:  ${props => props.MarginTop || ""};
    margin-left:  ${props => props.MarginLeft || "-23px"};
    width: 88px;
    // height: 56px;
    height: 66px;

    background:  ${props => props.Background || "#271B36"};
    border-radius: 2px;
    color: #FCFCFC;
    font-family: 'Overpass';
    font-size: 15px;
    font-weight: 400;
    display:flex;
    align-items: center;
    // justify-content: flex-start;
    justify-content: center;
`;
export const MidArrow3 = styled.div`
    margin-top:  ${props => props.MarginTop || ""};
    margin-left:  ${props => props.MarginLeft || "-1px"};
    border-top:  ${props => props.BorderTop || "28px solid transparent"};
    border-bottom:  ${props => props.BorderBottom || "28px solid transparent"};
    border-left:  ${props => props.BorderLeft || "28px solid #271B36"};
    border-radius: 2px;
    height: 0px;

`;

export const EndArrowWrapper = styled.div`
    margin-left:  ${props => props.MarginLeft || "-16px"};
    margin-top: -4px;
`;
export const EndArrow1 = styled.div`
    position: fixed;
    margin-top:  ${props => props.MarginTop || ""};
    margin-left:  ${props => props.MarginLeft || ""};
    border-top:  ${props => props.BorderTop || "28px solid #271B36"};
    border-right:  ${props => props.BorderRight || "28px solid #271B36"};
    border-bottom:  ${props => props.BorderBottom || "28px solid #271B36"};
    border-left:  ${props => props.BorderLeft || "28px solid transparent"};
    height: 0px;

`;
export const EndArrow2 = styled.div`
    position: fixed;
    margin-top:  ${props => props.MarginTop || ""};
    margin-left:  ${props => props.MarginLeft || "28px"};
    width: 88px;
    // height: 56px;
    height: 66px;
    background:  ${props => props.Background || "#271B36"};
    border-radius: 2px;
    color: #FCFCFC;
    font-family: 'Overpass';
    font-size: 15px;
    font-weight: 400;
    display:flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

export const SubProcessBlock = styled.div`
  position: relative;
  flex-direction: column;
  margin-top: ${props => props.MarginTop || "8px"};
  margin-bottom: ${props => props.MarginBottom || "0px"};
  margin-left: ${props => props.MarginLeft || "0px"};
  margin-right: ${props => props.MarginRight || "12px"};
  width: ${props => props.Width || "125px"};
  height: ${props => props.Height || "56px"};
  background: ${props => props.Background || "#E3E5E4"};
  opacity: ${props => props.Opacity || "1"};
  color: ${props => props.Color || "#5F6366"};
  border-radius: 5px;
  font-family: 'Overpass';
  font-weight: ${props => props.FontWeight || "400"};
  font-size: ${props => props.FontSize || "14px"};
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: ${props => props.Cursor || "default"};
  z-index: 1;
`;
