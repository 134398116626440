import * as d3 from 'd3';
import './D3Components.css';
import * as Gradient from "javascript-color-gradient";


/**  Teams based graph component */
export class D3Class {

    // Constructor variable for the class
    containerEl; // the container that will hold the visualization
    props;  // the json data, width and height
    svg;  // variable to hold the svg elements(canvas)

    /** Constructor function to render the graph, click and hover functions
     * hover shows name and team affiliation
     */

    constructor(containerEl, props) {
        // Initiliazing the variables
        // The this variables are visible from SocialNetwork-render-component
        this.containerEl = containerEl;
        this.props = props;
        const {width, height, context, demo_mode, language, selected_survey} = props;
        const nodeData = props.data.nodes; // store the node data
        const edgeData = props.data.edges; // store edge data
        const num_employees = 41; // store edge data

        // Div Container size
        const windowWidth = width;
        const windowHeight = height;
        
        // Graph Width
        const xpositions = nodeData.map(node => node.xpos)
        const minX = Math.min(...xpositions)
        const maxX = Math.max(...xpositions)
        var graphWidth = (maxX - minX)
        
        // Graph center X
        const sortedXpositions = xpositions.sort((a,b) => a - b)
        const medianXIndex = Math.floor(sortedXpositions.length / 2)
        const medianX = sortedXpositions.length % 2 !== 0 ? sortedXpositions[medianXIndex] : (sortedXpositions[medianXIndex - 1] + sortedXpositions[medianXIndex]) / 2;
        
        // Graph Height
        const ypositions = nodeData.map(node => node.ypos)
        const minY = Math.min(...ypositions)
        const maxY = Math.max(...ypositions)
        var graphHeight = (maxY - minY)

        // Graph center Y
        const sortedYpositions = ypositions.sort((a,b) => a - b)
        const medianYIndex = Math.floor(sortedYpositions.length / 2)
        const medianY = sortedYpositions.length % 2 !== 0 ? sortedYpositions[medianYIndex] : (sortedYpositions[medianYIndex - 1] + sortedYpositions[medianYIndex]) / 2;

        // Scaling factor (modifies nodes and edges size depending on the size of the graph)
        const initialZoom = 1  // >1 = zoom out  |  <1 = zoom in 
        const widthScalingFactor = windowWidth / (graphWidth * initialZoom)
        const heightScalingFactor = windowHeight / (graphHeight * initialZoom)
        var scalingFactor;
        if (widthScalingFactor > heightScalingFactor)
            scalingFactor = widthScalingFactor
        else
            scalingFactor = heightScalingFactor

        // Box containing all the elements in the graph
        var containingBoxX = ( graphWidth + graphHeight ) * scalingFactor
        var containingBoxY = ( graphHeight + graphWidth ) * scalingFactor

        // Passing variables to the SocialNetwork-render-component
        this.containingBoxX = containingBoxX
        this.containingBoxY = containingBoxY
        // Calculate offsets so the graph can be aligned at a certain distance form the edge of the canvas top and left alignment
        const offsetX = ( minX - graphHeight / 2 ) * scalingFactor
        const offsetY = ( minY - graphWidth / 2 ) * scalingFactor
        this.offsetX = offsetX
        this.offsetY = offsetY
        // Passing variables to the SocialNetwork-render-component
        this.medianX = medianX * scalingFactor
        this.medianY = medianY * scalingFactor

        // Animation duration for the effects 
        const transitionDuration = 200;        

        // Node drawing parameters
        const nodeBaseSize = 150;
        const normalOpacity = 1
        const hiddenOpacity = 0.2
        const activeNodeColor = "#E2336B";

        // Edge drawing parameters
        const edgeColor = "#271B36";

        // Font parameters
        const fontColor = "#271B36";
        const fontWeight = 200;
        const fontFamiliy = "Overpass";
        var nodesFontSize = 42;

        // Define the arrowhead marker variables
        const markerBoxWidth = 8; // width of the box where the arrow's triangle is
        const markerBoxHeight = 8; // height of the box where the arrow's triangle is
        const refX = markerBoxWidth / 2; 
        const refY = markerBoxHeight  / 2;
        const arrowPoints = [[0, 0], [0, 8], [8, 4]]; // points of the box


        // Using 2 colors and 10 midpoints to generate an array of hex color values
        const root_cause_gradient = new Gradient()
            .setColorGradient("#A79090", "#D90808")
            .setMidpoint(10)
            .getColors();

        const symptom_gradient = new Gradient()
            .setColorGradient("#A79090", "#4183BD")
            .setMidpoint(10)
            .getColors();

        // Identifies the maximum and minimum root cause scores
        const root_causes = nodeData.filter((node) => node.name !== "I don't know").map(node => node.root_cause_score)
        
        const max_root_cause_score = Math.max(...root_causes)
        const max_symptom_score = Math.min(...root_causes)

        // selected symptoms/causes, at the start is the symptoms
        const symptoms = nodeData.filter((node) => node.root_cause_score < 0).map(node => node.name)
        var visible_nodes = new Set(symptoms)

        var selected_element = "node"

        // list with the active nodes (the active one is the last element, similar functionality as a stack)
        var selected_nodes = [""]

        // selected edge
        var selected_edge = [String, String]

        // gets the node color of a problem depending if its a root cause or not
        function get_problem_color(node){ 
            if(node.name === "I don't know")
                return "#A79090"
            // root cause
            else if(node.root_cause_score > 0){
                return root_cause_gradient[Math.round((node.root_cause_score / max_root_cause_score) * 9)]
            }
            // symptom
            else if(node.root_cause_score < 0){
                return symptom_gradient[Math.round((node.root_cause_score / max_symptom_score) * 9)]
            }
            else
                return "#A79090"
        }

        // get the initials of a tag name
        function get_tag_initials(tag_name){ 
            let initials = ""
            let words = tag_name.split(' ')
            for (let i = 0; i < words.length; i++) {
                initials = initials.concat(words[i][0])
            }
            return initials
        }

        // Selected nodes at the moment
        var all_tags = []; 
        for(var i = 0; i<nodeData.length; ++i){
            all_tags.push(nodeData[i].name);
        }

        // Create the tooltip
        var tooltip = d3.select("body")
            .append("div") 
            .attr("class", "tooltip") // setting class of the div
            // css styling
            .style("position", "absolute")
            .style("z-index", "9999")
            .style("visibility", "hidden")
            .style("background", "#D0E1F1");

        // SVG canvas creation (where the nodes and edges are drawn)
        this.svg = d3.select(containerEl)
            .append('svg')
            .attr('width', function () {
                if(containingBoxX > windowWidth) return containingBoxX
                else return windowWidth
            })
            .attr('height', function () {
                if(containingBoxY > windowHeight) return containingBoxY
                else return windowHeight
            })

        // Edges in the graph + edge comon parameters
        var edges = this.svg.selectAll(".path")
            .attr("className", "edge")
            .data(edgeData) 
            .enter() 
            .append("g") // place each edge in a group
            .attr('stroke', function(edge) {
                if(selected_element == "edge" && edge.source == selected_edge[0] && edge.target == selected_edge[1])
                    return activeNodeColor
                else
                    return edgeColor
            })
            .style("cursor", "pointer")
            .style("padding", 4)
            .attr("display", function (edge) {
                if(visible_nodes.has(edge.source) && visible_nodes.has(edge.target))
                    return "block"
                else
                    return "none"
            })
            .attr("opacity", function (edge) {
                if(visible_nodes.has(edge.source) && visible_nodes.has(edge.target)){
                    if(edge.target == selected_nodes[selected_nodes.length-1]) {
                        return normalOpacity
                    }
                    else return hiddenOpacity
                }
                else
                    return 0
            })
            .on('mouseover', function (event, edge) {
                if(visible_nodes.has(edge.source) && visible_nodes.has(edge.target)) {
                    mouseOverEdge(edge);
                    return tooltip.style("top", (event.pageY - 10) + "px").style("left", (event.pageX + 10) + "px");    
                }
            })
            .on("mouseout", function () {
                mouseOutEdge();
            })
            .on("click", function (event, edge) {
                selected_edge[0] = edge.source
                selected_edge[1] = edge.target
                selected_element = "edge"
                let source = nodeData.find((node) => node.name === edge.source)
                let target = nodeData.find((node) => node.name === edge.target)
                updateSelection()
                localStorage.setItem('selected_element', JSON.stringify([source, target]))
                window.dispatchEvent(new Event('selected_element_changed'))
            });

        // arrows line
        edges.append('path')
            .attr("class", "path")
            .attr('d', function(edge){
                let source = nodeData.find((node) => node.name === edge.source)
                let target = nodeData.find((node) => node.name === edge.target)
                return d3.linkVertical()
                    .x(d => d.x)
                    .y(d => d.y)({
                    source: { x: source.xpos * scalingFactor - offsetX, y: source.ypos * scalingFactor - offsetY - nodeBaseSize + Math.sqrt(source.conversations.length) },
                    target: { x: target.xpos * scalingFactor - offsetX, y: target.ypos * scalingFactor - offsetY + nodeBaseSize + 40 + Math.sqrt(target.conversations.length) }
                    })
            })
            .attr('marker-end', 'url(#arrow)')
            .attr('stroke-width', '3')
            .attr('fill', 'none');

        // invisible arrows thicker line for the clicking
        edges.append('path')
            .attr("class", "path")
            .attr('d', function(edge){
                let source = nodeData.find((node) => node.name === edge.source)
                let target = nodeData.find((node) => node.name === edge.target)
                return d3.linkVertical()
                    .x(d => d.x)
                    .y(d => d.y)({
                    source: { x: source.xpos * scalingFactor - offsetX, y: source.ypos * scalingFactor - offsetY - nodeBaseSize + Math.sqrt(source.conversations.length) },
                    target: { x: target.xpos * scalingFactor - offsetX, y: target.ypos * scalingFactor - offsetY + nodeBaseSize + 20 + Math.sqrt(target.conversations.length) }
                    })
            })
            .attr('stroke-width', '32')
            .style("stroke", "transparent")
            .attr('fill', 'none');


        // Node image (not used)
        edges.append("image")
            .attr("class", "image")
            // uncomment this to show images
            .attr("xlink:href",  function (edge) {
                if(selected_element == "edge" && edge.source == selected_edge[0] && edge.target == selected_edge[1])
                    return "/icons/CommentsIconClicked.svg"
                else
                    return "/icons/CommentsIcon.svg"
            })
            .attr("x", function (edge) {
                let source = nodeData.find((node) => node.name === edge.source)
                let target = nodeData.find((node) => node.name === edge.target)
                return ((source.xpos * scalingFactor - offsetX) + (target.xpos * scalingFactor - offsetX)) / 2;
            })    
            .attr("y", function (edge) {
                let source = nodeData.find((node) => node.name === edge.source)
                let target = nodeData.find((node) => node.name === edge.target)
                return ((source.ypos * scalingFactor - offsetX) + (target.ypos * scalingFactor - offsetX)) / 2;
            })
            .attr("width", 64)
            .attr("height", 64)
            .attr("opacity", 1);            
            

        // Add the arrowhead marker definition to the svg element
        edges.append('defs')
            .append('marker')
            .attr("class", "triangle")
            .attr('id', 'arrow')
            .attr('viewBox', [0, 0, markerBoxWidth, markerBoxHeight]) // box where the arrow's triangle is
            .attr('refX', refX)
            .attr('refY', refY)
            .attr('markerWidth', markerBoxWidth)
            .attr('markerHeight', markerBoxHeight)
            .attr('orient', 'auto-start-reverse')
            .append('path')
            .attr('d', d3.line()(arrowPoints))


        // Nodes in the graph + node common parameters
        var nodes = this.svg.selectAll(".node")
            .attr("className", "nodeBorder")
            .data(nodeData)
            .enter()
            .append("g")
            .style("font-weight", fontWeight)
            .style("font-family", fontFamiliy)
            .attr("font-color", fontColor)
            .style("font-size", function (node) {
                return nodesFontSize
            })
            .attr("text-anchor", "middle")
            .style("cursor", function(node) {
                if(visible_nodes.has(node.name))
                    return "pointer"
            })
            .attr("opacity", function (node) {
                if(visible_nodes.has(node.name)){
                    let parent_edge = edgeData.filter((edge) => edge.source == node.name && edge.target == selected_nodes[selected_nodes.length-1])
                    if(node.name == selected_nodes[selected_nodes.length-1] || parent_edge.length > 0) {
                        return normalOpacity
                    }
                    else return hiddenOpacity
                }
                else
                    return 0
            })
            .on('mouseover', function (event, node) {
                if(visible_nodes.has(node.name)) {
                    node_tooltip(node)
                    return tooltip.style("top", (event.pageY - 10) + "px").style("left", (event.pageX + 10) + "px");
                }
            })
            .on("mouseout", function () {
                tooltip.style("visibility", "hidden");
            })
            .on("click", function (event, node) {
                if(visible_nodes.has(node.name)){
                    clicked_node(node.name)
                    updateSelection()
                    localStorage.setItem('selected_element', JSON.stringify([node]))
                    window.dispatchEvent(new Event('selected_element_changed'))
                }
            });


        // Node circle
        nodes.append("circle")
            .attr("class", "problem")
            .attr("r", function (node) { // radius of the node, bigger for the customer and supplier
                return nodeBaseSize + Math.sqrt(node.conversations.length)
            })
            .attr('stroke', function(node) {
                if(selected_element == "node" && selected_nodes[selected_nodes.length-1] == node.name)
                    return activeNodeColor
                else
                    return fontColor
            })  // outside edge of the node
            .attr('stroke-width', 2)  // border of the node
            .attr('fill', function (node) {
                // return get_problem_color(node)
                return "#FCFCFC"
            })
            // Position of the node
            .attr('cx', function (node) {
                return node.xpos * scalingFactor - offsetX;
            })
            .attr('cy', function (node) {
                return node.ypos * scalingFactor - offsetY;
            })
            .style("z-index", 2)


        // Node Tag name
        nodes.append('text')
            .attr('class', 'name')
            // position
            .attr("textLength", function (node) {
                return (nodeBaseSize + Math.sqrt(node.conversations.length)) * 1.8
            })
            .attr("lengthAdjust", "spacingAndGlyphs")
            .attr("x", function (node) {
                return node.xpos * scalingFactor - offsetX
            })   
            .attr("y", function (node) {
                return node.ypos * scalingFactor - offsetY 
            })  
            .text(function (node) {
                return node.name
                // return get_tag_initials(node.name)
            })


        // Node percentage
        nodes.append('text')
            .attr('class', 'percentage')
            .style("font-size", function (node) {
                return nodesFontSize - 8
            })
            // position
            .attr("x", function (node) {
                return node.xpos * scalingFactor - offsetX
            })   
            .attr("y", function (node) {
                return node.ypos * scalingFactor - offsetY
            })  
            .attr("dy", containingBoxX /  (nodeData.length * 5)) // you can vary how far apart it shows up
            .text(function (node) {
                if(symptoms.includes(node.name)) // for the symptoms we show the absolute percentage of the company
                    return Math.ceil(node.conversations.length/num_employees*100) + "% company"
                else if(selected_element == "node") {
                    let parent_edge = edgeData.filter((edge) => edge.source == node.name && edge.target == selected_nodes[selected_nodes.length-1])
                    if(parent_edge.length > 0) {
                        let parent = nodeData.filter((node) => node.name == selected_nodes[selected_nodes.length-1])[0]
                        return Math.ceil(parent_edge[0].weight/parent.messages.length*100) + "%"
                    }
                }
                else
                    return ""
            });
        
        // when a node is clicked, we update the selected nodes list adding, removing or updating the clicked node
        function clicked_node(node) {
            let index = selected_nodes.indexOf(node)

            if(index == -1){ // node not in the selected list
                selected_nodes.push(node)
            }
            else if(index == selected_nodes.length-1){ // node is the last selected one
                selected_nodes.pop(node)
                let descendents = bfs(node)
                for(let i = 0; i < selected_nodes.length; i++){
                    if(descendents.has(selected_nodes[i]))
                        selected_nodes.pop(selected_nodes[i])
                }
            }
            else{ // node was selected but not the last one
                selected_nodes.splice(index, 1)
                selected_nodes.push(node)
            }
            selected_element = "node"
        }

        // bfs to get all the descendents of the node in the network
        function bfs(node) {

            const queue = [node]
            const visited = new Set()

            while(queue.length > 0) {
                const target = queue.shift()

                if(!visited.has(target)) {
                    visited.add(target)

                    let connectedEdges = edgeData.filter((edge) => target == edge.target);
                    let connectedSources = new Set(connectedEdges.map(edge => edge.source));
                    for(const source of connectedSources) {
                        queue.push(source)
                    }
                }
            }

            return visited
        }

        // function to reRender the nodes when one has been clicked
        function updateSelection() { 
            let connectedEdges = edgeData.filter((edge) => selected_nodes.includes(edge.target));
            let connectedSources = new Set(connectedEdges.map(edge => edge.source));

            // getting the new visible nodes
            visible_nodes = new Set(symptoms)
            for (let i of connectedSources) {
                visible_nodes.add(i)
            }
            
            // sets the opacity of the selected nodes to normal (1) and the other ones to hidden (0.05)
            nodes.transition().duration(transitionDuration)
            .attr("opacity", function (node) {
                if(visible_nodes.has(node.name)){
                    let parent_edge = edgeData.filter((edge) => edge.source == node.name && edge.target == selected_nodes[selected_nodes.length-1])
                    if(node.name == selected_nodes[selected_nodes.length-1] || parent_edge.length > 0) {
                        return normalOpacity
                    }
                    else return hiddenOpacity
                }
                else
                    return 0
            })
            .style("cursor", function(node) {
                if(visible_nodes.has(node.name))
                    return "pointer"
            });

            // sets the stroke color of the clicked node
            nodes.selectAll(".problem").transition().duration(transitionDuration)
            .attr("stroke-width", function (node) {
                if(selected_element == "node" && selected_nodes[selected_nodes.length-1] == node.name)
                    return 6
                else
                    return 2
            })
            .attr("stroke", function (node) {
                if(selected_element == "node" && selected_nodes[selected_nodes.length-1] == node.name)
                    return activeNodeColor
                else
                    return fontColor
            });

            // sets the percentage of the node depending on its parent  
            nodes.selectAll(".percentage").transition().duration(transitionDuration)
            .text( function (node) {
                if(symptoms.includes(node.name)) // for the symptoms we show the absolute percentage of the company
                    return Math.ceil(node.conversations.length/num_employees*100) + "% company"
                else if(selected_element == "node") {
                    let parent_edge = edgeData.filter((edge) => edge.source == node.name && edge.target == selected_nodes[selected_nodes.length-1])
                    if(parent_edge.length > 0) {
                        let parent = nodeData.filter((node) => node.name == selected_nodes[selected_nodes.length-1])[0]
                        return Math.ceil(parent_edge[0].weight/parent.messages.length*100) + "%"
                    }
                }
                else
                    return ""
            });

            // sets the opacity of the edges between the selected nodes to normal (1) and the other ones to hidden (0.05)
            edges.transition().duration(transitionDuration)
            .attr("display", function (edge) {
                if (connectedEdges.includes(edge)) return "block"
                else return "none"
            })
            .attr("opacity", function (edge) {
                if(visible_nodes.has(edge.source) && visible_nodes.has(edge.target)){
                    if(edge.target == selected_nodes[selected_nodes.length-1]) {
                        return normalOpacity
                    }
                    else return hiddenOpacity
                }
                else
                    return 0
            })
            .attr('stroke', function(edge) {
                if(selected_element == "edge" && edge.source == selected_edge[0] && edge.target == selected_edge[1])
                    return activeNodeColor
                else
                    return edgeColor
            });

            edges.selectAll(".image").transition().duration(transitionDuration)
            .attr("xlink:href",  function (edge) {
                if(selected_element == "edge" && edge.source == selected_edge[0] && edge.target == selected_edge[1])
                    return "/icons/CommentsIconClicked.svg"
                else
                    return "/icons/CommentsIcon.svg"
            });
        }


        // Tooltip that appears when a node is hovered
        function node_tooltip(node) {
            tooltip.style("visibility", "visible");
            tooltip.html(`${node.name}`)
        }


        // Node hover functionality stops
        function mouseOverEdge(edge) {
            tooltip.style("visibility", "visible");
            tooltip.html(`${edge.weight}`)
        }

        // Node hover functionality stops
        function mouseOutEdge() {
            tooltip.style("visibility", "hidden");
        }
        
    }

}

export default D3Class;
