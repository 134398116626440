import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {ReactComponent as SurveyFooterBackButton} from "../common-assets/BackButton_Survey_56px.svg";
import { UserIntroToColleagueFilterPromises, PostSignificantInteractions, UpdateSpontaneousHours, 
    UpdateMeetingHours, UpdateCollaborationQuality, UpdateProactiveness, UpdateCulture, UpdateCulturePeer } from './api-calls/survey-social-network-api-calls';
import ProgressBar from "@ramonak/react-progress-bar";
import { sendPageTime } from '../../layout-and-styling/API-calls/common-api-calls';
import { StyledDivRegular } from '../../layout-and-styling/standardized-components-library/Styling-TextInput';
import {useTranslation} from "react-i18next";
import logo from "../../assets/dashboard/load.gif";


function SurveySocialNetworkFooter(props) {
    /** Component to build footer buttons, with the corresponding API calls.
     * First section you find all the API get, post and update request functions
     * Second section you find the if statements for specific pages, to load in specific styling and API requests
     * Third section you find the styled div css styling
     */

    /** Go from one directory to another via useHistory */
    let history = useHistory();
    const {t} = useTranslation();
    const [advance, setAdvance] = useState(false);

    const CurrentUserData = JSON.parse(localStorage.getItem('current_user_data'));
    
    const [buttonEnabled, setButtonEnabled] = useState(false);
    const [buttonWidth, setButtonWidth] = useState(0);
    const [buttonTimeout, setButtonTimeout] = useState(CurrentUserData[0].is_developer ? 0 : 5000);

    useEffect(() => {
        if (props.page != "PageBenefits") {
            enableButton()
        }
    }, [props.identifier]);

    const enableButton = () => {
        const buttonEnabledBefore = localStorage.getItem(`buttonEnabled${props.identifier}`);

        if (!buttonEnabledBefore) {
            setButtonWidth('100%')
    
            setTimeout(() => {
                localStorage.setItem(`buttonEnabled${props.identifier}`, 'true');
                setButtonEnabled(true);
            }, buttonTimeout);
        } else {
            setButtonEnabled(true);
        }
    }

    useEffect(() => {
        if (CurrentUserData[0].is_developer) {
            enableButton()
        } else if(props.componentClicked) {
            enableButton()
        }
    }, [props.componentClicked]);

    /** Workaround for API and rendering issues, with timeouts and forced re-renders
     * TODO: async await API handshakes
     * TODO: proper first render of mapped localStorage data, without the need of page refresh
     */
    /** If else statement, to only push ArrayCloseColleagues for now at "PageCommFreqMeasFilter */
    

    /** User input, then use api/employee api/mydetails */
    if (props.page === "IntroVerificationButton") {
        const checked = props.checked_flag;
        return (

            <>
                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.MarginLeft} Width={props.Width} Height={props.Height}
                                   BorderRadius={props.BorderRadius} BackgroundColor={checked === true ? "#e2336B" : "#F7C9D9"} Cursor={checked === true ? "pointer" : "default"}>

                    <button
                        disabled={!checked}
                        onClick={() => {
                            history.push(props.nextdirectory);
                        }}
                    >
                        {props.ButtonText}
                    </button>
                </ButtonWrapperNext>
            </>

        );
    }

    else if (props.page === "PageWelcome") {
        return (
            <IntroductionContainer>

                {buttonEnabled ? (
                    <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.MarginLeft} PointerEvents={"all"}
                                Width={props.Width} Height={props.Height}>
                        <button
                            onClick={() => {
                                sendPageTime("user-intro", props.nextdirectory);
                            }}
                        >
                            {t('Component-NextButton')}
                        </button>
                    </ButtonWrapperNext>
                ) : (
                    <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.MarginLeft}
                                            Width={props.Width} Height={props.Height}>
                        <ButtonWrapperLoading>
                            <button disabled={true}>
                                {t('Component-NextButton')}
                            </button>

                            <ButtonWrapperLoadingOverlay Width={buttonWidth} Transition={`${buttonTimeout}ms`}>
                                <button disabled={true}>
                                    {t('Component-NextButton')}
                                </button>
                            </ButtonWrapperLoadingOverlay>
                        </ButtonWrapperLoading>
                    </ButtonWrapperNext>
                )}

            </IntroductionContainer>
        );
    }

    /** User input GPDR, then get data for next page */
    else if (props.page === "PageBenefits") {
        return (
            <IntroductionContainer>

                <ButtonWrapperBack Position={props.Position} MarginTop={props.MarginTop} MarginLeft={props.BackMarginLeft}
                                   Width={props.BackWidth} Height={props.BackHeight}>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.prevdirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>
                
                {buttonEnabled ? (
                    <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.MarginLeft} PointerEvents={"all"}
                                       Width={props.Width} Height={props.Height}>
                        <button
                            onClick={() => {
                                sendPageTime("page-user-benefits", props.nextdirectory)
                            }}
                        >
                            {t('Component-NextButton')}
                        </button>
                    </ButtonWrapperNext>
                ) : (
                    <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.MarginLeft}
                                            Width={props.Width} Height={props.Height}>
                        <ButtonWrapperLoading>
                            <button disabled={true}>
                                {t('Component-NextButton')}
                            </button>

                            <ButtonWrapperLoadingOverlay Width={buttonWidth} Transition={`${buttonTimeout}ms`}>
                                <button disabled={true}>
                                    {t('Component-NextButton')}
                                </button>
                            </ButtonWrapperLoadingOverlay>
                        </ButtonWrapperLoading>
                    </ButtonWrapperNext>
                )}
                
            </IntroductionContainer>
        );
    }

    /** User input GPDR, then get data for next page */
    else if (props.page === "PageInstructions") {
        return (
            <IntroductionContainer>

                <ButtonWrapperBack Position={props.Position} MarginTop={props.MarginTop} MarginLeft={props.BackMarginLeft}
                                   Width={props.BackWidth} Height={props.BackHeight}>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.prevdirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                {buttonEnabled ? (
                    !props.nextButtonClicked ? (
                        <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.MarginLeft} PointerEvents={"all"}
                                           Width={props.Width} Height={props.Height}>
                            <button
                                onClick={() => {
                                    props.setNextButtonClicked(true)
                                    UserIntroToColleagueFilterPromises(props.hasBeenRequested, props.setHasBeenRequested, props.setNextButtonClicked)
                                }}>
                                {t('Component-GetStartedButton')}
                            </button>
                        </ButtonWrapperNext>
                    ) : ( 
                        <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.MarginLeft}
                                           Width={props.Width} Height={props.Height}>
                            <ButtonWrapperLogo>
                                <button disabled={true}/>
                                <img src={logo} alt="loading..."/>   
                            </ButtonWrapperLogo>
                        </ButtonWrapperNext>
                    )
                ) : (
                    <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.MarginLeft}
                                            Width={props.Width} Height={props.Height}>
                        <ButtonWrapperLoading>
                            <button disabled={true}>
                                {t('Component-GetStartedButton')}
                            </button>

                            <ButtonWrapperLoadingOverlay Width={buttonWidth} Transition={`${buttonTimeout}ms`}>
                                <button disabled={true}>
                                    {t('Component-GetStartedButton')}
                                </button>
                            </ButtonWrapperLoadingOverlay>
                        </ButtonWrapperLoading>
                    </ButtonWrapperNext>
                )}

            </IntroductionContainer>
        );
    }

    else if (props.page === "PageCloseColleagueFilter") {
        /** Built in check whether anything is filled in */
        // const input_checked_process = props.input_check_process;
        const input_checked_people = props.inputCheckPeople;
        const customer_check_hours = props.customerAndSupplier.customer.hours;
        const supplier_check_hours = props.customerAndSupplier.supplier.hours;
        const customer_check_significant = props.customerAndSupplier.customer.interaction;
        const supplier_check_significant = props.customerAndSupplier.supplier.interaction;
        

        return (
            <Wrapper MarginTop={props.FooterMarginTop} MarginLeft={props.FooterMarginLeft}
                     Width={props.FooterWidth} Height={props.FooterHeight}>

                <StyledDivRegular MarginTop='28px' MarginLeft={(100 * 220 / 1920).toString() + "%"}>
                    <StyledDivRegular Width='100px'> 1 of 5 </StyledDivRegular>
                    <StyledDivRegular MarginTop='30px'>
                        <ProgressBar completed={100 * 1 / 5} customLabel=" " height='16px' width='280px' bgColor="#e2336b" baseBgColor="#E3E5E4"/>
                    </StyledDivRegular>
                </StyledDivRegular>

                <ButtonWrapperBack MarginTop={props.MarginTop} MarginLeft={props.BackButtonMarginLeft} Height='56px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.NextButtonMarginLeft}
                                PointerEvents={advance ? "none" : "all"} Height='56px' Width='160px' BackgroundColor={advance === true ? "#F7C9D9" : "#e2336B"}  Cursor={advance === true ? "default" : "pointer"}>
                    <button
                        onClick={() => {
                            props.setNextButtonClicked(true)
                            if(input_checked_people && (customer_check_hours > 0 || customer_check_significant === false) && (supplier_check_hours > 0 || supplier_check_significant === false)){
                                setAdvance(true)
                                PostSignificantInteractions(props.setNextButtonClicked)
                            }
                        }}
                    >
                        {advance && (
                            <LoadLogo MarginTop={"-12px"}>
                                <img src={logo} alt="loading..."/>
                            </LoadLogo>
                        )}
                        {t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    }

    else if (props.page === "PagePlannedMeetingsQuestion") {

        return (
            <Wrapper MarginTop={props.FooterMarginTop} MarginLeft={props.FooterMarginLeft}
                     Width={props.FooterWidth} Height={props.FooterHeight}>

                <ButtonWrapperBack MarginTop={props.MarginTop} MarginLeft={props.BackButtonMarginLeft} Height='56px' Width='56px'>
                    <button id="backbtn">
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

            </Wrapper>
        );
    }

    else if (props.page === "PagePlannedMeetings") {

        function checkMeetings() {
            let meetingsNoPeople = []
            let meetingsNoTime = []
            for (let i = 0; i < props.MeetingsDays.length; i++) {
                for (let j = 0; j < props.MeetingsDays[i].meetings.length; j++) {
                    if (props.MeetingsDays[i].meetings[j]["counter_people"] === 0) meetingsNoPeople.push(props.MeetingsDays[i].meetings[j]["name"])
                    if (props.MeetingsDays[i].meetings[j]["duration"] === 0) meetingsNoTime.push(props.MeetingsDays[i].meetings[j]["name"]) 
                }
            }
            return [meetingsNoPeople, meetingsNoTime]
        }

        return (
            <Wrapper MarginTop={props.FooterMarginTop} MarginLeft={props.FooterMarginLeft}
                     Width={props.FooterWidth} Height={props.FooterHeight}>

                <StyledDivRegular MarginTop='28px' MarginLeft={(100 * 220 / 1920).toString() + "%"}>
                    <StyledDivRegular Width='100px'> 2 of 5 </StyledDivRegular>
                    <StyledDivRegular MarginTop='30px'>
                        <ProgressBar completed={100 * 2 / 5} customLabel=" " height='16px' width='280px' bgColor="#e2336b" baseBgColor="#E3E5E4"/>
                    </StyledDivRegular>
                </StyledDivRegular>

                <ButtonWrapperBack MarginTop={props.MarginTop} MarginLeft={props.BackButtonMarginLeft} Height='56px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.NextButtonMarginLeft} PointerEvents={advance ? "none" : "all"} Height='56px' Width='160px'
                                BackgroundColor={advance === true ? "#F7C9D9" : "#e2336B"} Cursor={advance === true ? "default" : "pointer"}>
                    <button
                        onClick={() => {
                            props.setNextButtonClicked(true)
                            let checker = checkMeetings()
                            props.setCheckMeetingMembers(checker[0].length == 0)
                            props.setCheckMeetingTime(checker[1].length == 0)
                            if(checker[0].length == 0 && checker[1].length == 0) {
                                setAdvance(true)
                                UpdateMeetingHours(props.setNextButtonClicked)
                            } 
                        }}
                    >
                        {advance && (
                            <LoadLogo MarginTop={"-12px"}>
                                <img src={logo} alt="loading..."/>
                            </LoadLogo>
                        )}
                       {t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    }

    else if (props.page === "PageSpontaneousCollaboration") {
        /** Built in check whether anything is filled in */
        const inputCheckPeople = props.inputCheckPeople;

        return (
            <Wrapper MarginTop={props.FooterMarginTop} MarginLeft={props.FooterMarginLeft}
                     Width={props.FooterWidth} Height={props.FooterHeight}>

                <StyledDivRegular MarginTop='28px' MarginLeft={(100 * 220 / 1920).toString() + "%"}>
                    <StyledDivRegular Width='100px'> 3 of 5 </StyledDivRegular>
                    <StyledDivRegular MarginTop='30px'>
                        <ProgressBar completed={100 * 3 / 5} customLabel=" " height='16px' width='280px' bgColor="#e2336b" baseBgColor="#E3E5E4"/>
                    </StyledDivRegular>
                </StyledDivRegular>

                <ButtonWrapperBack MarginTop={props.MarginTop} MarginLeft={props.BackButtonMarginLeft} Height='56px' Width='56px'>
                    <button id="backbtn">
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.NextButtonMarginLeft} PointerEvents={advance ? "none" : "all"} Height='56px' Width='160px'
                                BackgroundColor={advance === true ? "#F7C9D9" : "#e2336B"} Cursor={advance === true ? "default" : "pointer"}>
                    <button
                        onClick={() => {
                            props.setNextButtonClicked(true)
                            if(inputCheckPeople){
                                setAdvance(true)
                                UpdateSpontaneousHours(props.setNextButtonClicked)
                            }
                        }}
                    >
                        {advance && (
                            <LoadLogo MarginTop={"-12px"}>
                                <img src={logo} alt="loading..."/>
                            </LoadLogo>
                        )}
                       {t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    }

    else if (props.page === "PageCollaborationMeasurement") {
        /** Built in check whether anything is filled in */
        const inputCheckPeople = props.inputCheckPeople;

        return (
            <Wrapper MarginTop={props.FooterMarginTop} MarginLeft={props.FooterMarginLeft}
                     Width={props.FooterWidth} Height={props.FooterHeight}>

                <StyledDivRegular MarginTop='28px' MarginLeft={(100 * 220 / 1920).toString() + "%"}>
                    <StyledDivRegular Width='100px'> 4 of 5 </StyledDivRegular>
                    <StyledDivRegular MarginTop='30px'>
                        <ProgressBar completed={100 * 4 / 5} customLabel=" " height='16px' width='280px' bgColor="#e2336b" baseBgColor="#E3E5E4"/>
                    </StyledDivRegular>
                </StyledDivRegular>

                <ButtonWrapperBack MarginTop={props.MarginTop} MarginLeft={props.BackButtonMarginLeft} Height='56px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.NextButtonMarginLeft} PointerEvents={"all"} Height='56px' Width='160px'
                                   BackgroundColor={"#e2336B"} Cursor={"pointer"}>
                    <button
                        onClick={() => {
                            props.setNextButtonClicked(true)
                            if(inputCheckPeople){
                                setAdvance(true)
                                UpdateCollaborationQuality(props.setNextButtonClicked)
                            }
                        }}
                    >
                        {advance && (
                        <LoadLogo MarginTop={"-13px"}>
                            <img src={logo} alt="loading..."/>
                        </LoadLogo>
                        )}
                        {t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    }

    else if (props.page === "PageProactiveness") {
        /** Built in check whether anything is filled in */
        const inputCheckPeople = props.inputCheckPeople;

        return (
            <Wrapper MarginTop={props.FooterMarginTop} MarginLeft={props.FooterMarginLeft}
                     Width={props.FooterWidth} Height={props.FooterHeight}>

                <StyledDivRegular MarginTop='28px' MarginLeft={(100 * 220 / 1920).toString() + "%"}>
                    <StyledDivRegular Width='100px'> 5 of 5</StyledDivRegular>
                    <StyledDivRegular MarginTop='30px'>
                        <ProgressBar completed={100 * 5 / 5} customLabel=" " height='16px' width='280px' bgColor="#e2336b" baseBgColor="#E3E5E4"/>
                    </StyledDivRegular>
                </StyledDivRegular>

                <ButtonWrapperBack MarginTop={props.MarginTop} MarginLeft={props.BackButtonMarginLeft} Height='56px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.NextButtonMarginLeft} PointerEvents={"all"} Height='56px' Width='160px'
                                   BackgroundColor={"#e2336B"} Cursor={"pointer"}>
                    <button
                        onClick={() => {
                            props.setNextButtonClicked(true)
                            if(inputCheckPeople){
                                setAdvance(true)
                                UpdateProactiveness(props.setNextButtonClicked)
                            }
                        }}
                    >
                        {advance && (
                        <LoadLogo MarginTop={"-13px"}>
                            <img src={logo} alt="loading..."/>
                        </LoadLogo>
                        )}
                        {t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>                        
            </Wrapper>
        );
    }

    else if (props.page === "PageCulture") {
        
        return (
            <Wrapper MarginTop={props.FooterMarginTop} MarginLeft={props.FooterMarginLeft}
                     Width={props.FooterWidth} Height={props.FooterHeight}>

                <StyledDivRegular MarginTop='28px' MarginLeft={(100 * 220 / 1920).toString() + "%"}>
                    <StyledDivRegular Width='100px'> 7 of 10 </StyledDivRegular>
                    <StyledDivRegular MarginTop='30px'>
                        <ProgressBar completed={100 * 7 / 10} customLabel=" " height='16px' width='280px' bgColor="#e2336b" baseBgColor="#E3E5E4"/>
                    </StyledDivRegular>
                </StyledDivRegular>

                <ButtonWrapperBack MarginTop={props.MarginTop} MarginLeft={props.BackButtonMarginLeft} Height='56px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.NextButtonMarginLeft} PointerEvents={advance ? "none" : "all"} Height='56px' Width='160px'
                                BackgroundColor={advance === true ? "#F7C9D9" : "#e2336B"} Cursor={advance === true ? "default" : "pointer"}>
                    <button
                        onClick={() => {
                            props.setNextButtonClicked(true)
                            const userOwnCulture = localStorage.getItem("userOwnCulture") ? JSON.parse(localStorage.getItem("userOwnCulture")) : {};
                            const ArrayCultureApi = localStorage.getItem("ArrayCultureApi") ? JSON.parse(localStorage.getItem("ArrayCultureApi")) : [];
                            setAdvance(true)
                            if(!ArrayCultureApi[0]["communication"] || !ArrayCultureApi[0]["trust"] || !ArrayCultureApi[0]["scheduling"] || !ArrayCultureApi[0]["deciding"] || !ArrayCultureApi[0]["leading"] || !ArrayCultureApi[0]["evaluating"] || !ArrayCultureApi[0]["disagreeing"] && JSON.parse(localStorage.getItem("cultureSelectedPeople")) == null){
                                setAdvance(false)
                            }
                            else if(JSON.parse(localStorage.getItem("cultureSelectedPeople")) == null){
                                UpdateCulture(props.setNextButtonClicked)
                            }
                            else if(
                            userOwnCulture?.["communication"] &&
                            userOwnCulture?.["trust"] &&
                            userOwnCulture?.["scheduling"] &&
                            userOwnCulture?.["deciding"] &&
                            userOwnCulture?.["leading"] &&
                            userOwnCulture?.["evaluating"] &&
                            userOwnCulture?.["disagreeing"]){
                                UpdateCulture(props.setNextButtonClicked)
                            }
                            else {
                                setAdvance(false) 
                            }
                        }}
                    >
                        {advance && (
                            <LoadLogo MarginTop={"-12px"}>
                                <img src={logo} alt="loading..."/>
                            </LoadLogo>
                        )}
                       {t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    }

    else if (props.page === "PageCulturePeer") {

        return (
            <Wrapper MarginTop={props.FooterMarginTop} MarginLeft={props.FooterMarginLeft}
                     Width={props.FooterWidth} Height={props.FooterHeight}>

                <StyledDivRegular MarginTop='28px' MarginLeft={(100 * 220 / 1920).toString() + "%"}>
                    <StyledDivRegular Width='100px'> 7 of 10 </StyledDivRegular>
                    <StyledDivRegular MarginTop='30px'>
                        <ProgressBar completed={100 * 7 / 10} customLabel=" " height='16px' width='280px' bgColor="#e2336b" baseBgColor="#E3E5E4"/>
                    </StyledDivRegular>
                </StyledDivRegular>

                <ButtonWrapperBack MarginTop={props.MarginTop} MarginLeft={props.BackButtonMarginLeft} Height='56px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.NextButtonMarginLeft} PointerEvents={advance ? "none" : "all"} Height='56px' Width='160px'
                                BackgroundColor={advance === true ? "#F7C9D9" : "#e2336B"} Cursor={advance === true ? "default" : "pointer"}>
                    <button
                        onClick={() => {
                            props.setNextButtonClicked(true)
                            const ArrayCultureApi = localStorage.getItem("ArrayCultureApi") ? JSON.parse(localStorage.getItem("ArrayCultureApi")) : [];
                            const newArr = ArrayCultureApi.slice(1)
                            
                            let result = newArr.filter(user => user.completed)
                            let compare = (result.length == newArr.length)
                            let checkAllTrue = false
                            if (localStorage.getItem("CheckedEmployeesCulturePeer") != null){
                                checkAllTrue = (JSON.parse(localStorage.getItem("cultureSelectedPeople")).length == JSON.parse(localStorage.getItem("CheckedEmployeesCulturePeer")).length)
                            }
                            if(checkAllTrue && compare){
                                setAdvance(true)
                                UpdateCulturePeer(props.setNextButtonClicked)
                            }
                            else{
                                setAdvance(false)
                            }
                        }}
                    >
                        {advance && (
                            <LoadLogo MarginTop={"-12px"}>
                                <img src={logo} alt="loading..."/>
                            </LoadLogo>
                        )}
                       {t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    }
    
    else {
        return (
            <Wrapper Height='104px'>
                <ButtonWrapperBack MarginTop='853px' MarginLeft='1464px' Height='56px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop='853px' MarginLeft='1540px' Height='56px' Width='160px'>
                    <button
                        onClick={() => {
                            history.push(props.nextdirectory);
                        }}
                    >{t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    };
};
export default SurveySocialNetworkFooter

const Wrapper = styled.div`
  position: fixed;
  margin-left: ${props => props.MarginLeft || ""};
  height: ${props => props.Height || "104px"};
  width: ${props => props.Width || "100%"};
  background-color: ${props => props.BackgroundColor || "#FCFCFC"};
  border-top: 1px solid #E3E5E4;
  z-index: 1;
  display: ${props => props.Display || ""};
  bottom: 0; //fixes the footer to the bottom of the page

  img {
    -drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`;

const LoadLogo = styled.div`
  display: flex;
  justify-content: flex-start;
  position: absolute;

  img {
    width: 50px;
    margin-left: ${props => props.MarginLeft || "55px"};
    margin-top: ${props => props.MarginTop || "3px"};
  }

  button {
    opacity: 0.2;
    position: absolute;
    cursor: unset;
  }
`;

const IntroductionContainer = styled.div`
  position: absolute;
  bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  max-width: 1030px;
  padding: 0 220px 0 170px;

  img {
    -drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  @media (max-width: 2420px) {
    margin-left: max(400px, 26%);
  }

  @media (max-width: 1920px) {
    right: 0;
  }

  @media (max-width: 1420px) {
    left: 50%;
    transform: translateX(-50%);
    padding: 0 60px;
    margin: auto;
  }

  @media (max-width: 1150px) {
    right: 0;
    left: auto;
    transform: translateX(0);
  }
`;

const ButtonWrapperBack = styled.div`
  position: ${props => props.Position || "fixed"};
  margin-bottom: ${props => props.MarginBottom || ""};
  margin-top: ${props => props.MarginTop || "39.4%"};
  margin-left: ${props => props.MarginLeft || "76.5%"};

  @media only screen and (max-width: 1700px) {
    margin-left: ${props => props.MarginLeft || "75.5%"};
  }

  z-index: 4;
  width: ${props => props.Width || "39.4%"};
  height: ${props => props.Height || "39.4%"};

  button {
    border: none;
    padding: 0;
    background: none;
    cursor: ${props => props.Cursor || "pointer"};
  }
`;

const ButtonWrapperNext = styled.div`
  justify-content: center;
  margin-top: ${props => props.MarginTop || "22px"};
  margin-left: ${props => props.MarginLeft || "80.1%"};
  z-index: 4;

  button {
    width: ${props => props.Width || "136px"};
    height: ${props => props.Height || "48px"};
    border: ${props => props.Border || "none"};
    border-radius: ${props => props.BorderRadius || "8px"};
    background-color: ${props => props.BackgroundColor || "#e2336B"};
    color: ${props => props.Color || "#FCFCFC"};
    font-size: ${props => props.FontSize || "18px"};
    font-weight: ${props => props.FontWeight || "400"};
    font-family: ${props => props.FontFamily || "Overpass"};
    opacity: ${props => props.isDisabled ? '0.5' : props.Opacity || '1'};
    cursor: ${props => props.isDisabled ? '' : props.Cursor || 'pointer'};
    transition: all 0.2s ease-in;
    align-items: flex-start;

    pointer-events: ${props => props.PointerEvents || "none"};

    :hover {
        background-color: ${props => props.isDisabled ? '' : '#B3194A'};
    }
  }
`;

const ButtonWrapperLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    position: absolute;
    width: 40px;
  }

  button {
    cursor: unset;
    background: #E3E5E4;
  }
`;

const ButtonWrapperLoading = styled.div`
  position: relative;
  display: flex;

  button {
    cursor: unset;
    background-color: #E3E5E4;
    color: #929699;
  }
`;

const ButtonWrapperLoadingOverlay = styled.div`
  position: absolute;
  width: ${props => props.Width || '0'};
  transition: width ${props => props.Transition || '0'} linear;
  overflow: hidden;

  button {
    cursor: unset;
    background-color: #C6CBCB;
    color: #FCFCFC;
  }
`;
