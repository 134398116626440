import React, {useContext, useState, useEffect, useRef, Component} from "react";
import styled from "styled-components";
import { OverlayContext } from "../../layout-and-styling/context-hooks/OverlayContext";
import {StyledDivButton, StyledDivRegular} from "../../layout-and-styling/standardized-components-library/Styling-TextInput";
import {useTranslation} from 'react-i18next';
import Xarrow from "react-xarrows";
import ErrorPage from "../../layout-and-styling/common-pages/ErrorPage";
import load from "../../assets/dashboard/load.gif";


const ProblemDeepDive = (props) => {
  const context = useContext(OverlayContext);
  const {t, i18n} = useTranslation();
  /** The useRef Hook creates a variable that "holds on" to a value across rendering passes.
   * In this case it will hold our component's SVG DOM element.
   * It's initialized null and React will assign it later (see the return statement) */

  const [Data, setData] = useState(null);
  /*
    {
      <process_name> : {
        causesVisible: <causes_visible : boolean>,
        branchesVisible: {
          <branch : string>: <boolean>
        }
      }
    }
    */
  var [cardsVisible, setCardsVisible] = useState({})

  /**
   * useEffect executed every time the page renders and the variable selectedTab has a different value than before
   */
  useEffect(()=> {
    // we switch with the selected tab
    try {
      // importing and setting the page to render
      const root_cause_avocado3 = require('./root_cause_avocado3.json');
      setData(root_cause_avocado3["Processes"])
      setCardsVisible(root_cause_avocado3["Processes"].map((process) => process.process).reduce((acc,el) => ({...acc, [el]:{causesVisible: false, branchesVisible: {}}}), {}))
    } catch (error) {
      // Error page in case the page is not accessible
      setData([])
    }

  }, []);
  
  const totalVotes = 37

  const categories = ["Problem", "Cause", "Solution", "Barrier"]
  const [selectedCategory, setSelectedCategory] = useState("Problem");
  const [selectedItem, setSelectedItem] = useState(0);
  const [selectedProcess, setSelectedProcess] = useState("Quotation / 报价");
  const [selectedProblem, setSelectedProblem] = useState(0);
  const [showText, setShowText] = useState(false);

  const commom_processes = ["Sample production / 样品制作", "Volume production / 生产量", "Volume quality & shipping / 批量质量和运输"]
  const disabled_processes = ["After sales / 售后服务", "Complaint & customer support / 投诉和客户支持"]

  const handleChangeProcess = (process) => {
    if(commom_processes.includes(process))
      setSelectedProcess(commom_processes[0]);
    else if(!disabled_processes.includes(process)){
      setSelectedProcess(process)
    }
    setSelectedItem(0)
  };

  function handleClickCard(category, item, item_index){
    if (category == "Problem"){
      setShowText(true);
      setSelectedCategory(category);
      setSelectedItem(item_index);
    }
    else if (category == "Cause")
      if(!cardsVisible[selectedProcess].causesVisible){
        setCardsVisible({
          ...cardsVisible,
          [selectedProcess]:{
            causesVisible: true,
            branchesVisible: cardsVisible[selectedProcess].branchesVisible
          }
        });
      }
      else{
        setShowText(true);
        setSelectedCategory(category);
        setSelectedItem(item_index);
      }
    else if (cardsVisible[selectedProcess].causesVisible && (category == "Solution" || category == "Barrier")){
      if (!cardsVisible[selectedProcess].branchesVisible[item.branch.slice(0,3).toString()]){
        setCardsVisible({
          ...cardsVisible,
          [selectedProcess]: {
            causesVisible: cardsVisible[selectedProcess].causesVisible,
            branchesVisible: {
              ...cardsVisible[selectedProcess].branchesVisible,
              [item.branch.slice(0,3).toString()]: true
            }
          }
        })
      }
      else {
        setShowText(true);
        setSelectedCategory(category);
        setSelectedItem(item_index);
      }
    }
  }

  if (!Data) {
    return (
      <Container>
        <LoadLogo>
          {" "} 
          <img src={load} alt="loading..."/>
        </LoadLogo>
      </Container>
    )
  } else if(Data.length == 0) {
    return(
      <ErrorPage type="Access"/>
    )
  }else {
    return (
      <Container>
        {/* Overlay background */}
        <OverlayBackground Display={showText ? "block" : "none"}
                            onClick={() => setShowText(false)}/>

        {/* Overlay */}
        <ExpandedBox Display={showText ? "block" : "none"}>
          <TitleButton JustifyContent="flex-start">
            <StyledDivRegular Position="relative" MarginLeft="0px" Width="fit-content" MarginBottom="0px" FontSize="24px" FontWeight="700">
              {selectedCategory}{selectedCategory != "Problem" ? "s" : ""}
            </StyledDivRegular>
          </TitleButton>
          
          <FeedbackBox>

            <FeedbackTopic>
              <StyledDivRegular Position="relative" MarginLeft="0px" Width="fit-content" Height="fit-content" MarginBottom="0px" FontSize="24px" LineHeight="32px" FontWeight="400">
                {Data.filter((process) => process.process === selectedProcess)[0]["problems"][selectedProblem][selectedCategory][selectedItem]["summary"]}
              </StyledDivRegular>
              <FeedbackWrapper>
                {Data.filter((process) => process.process === selectedProcess)[0]["problems"][selectedProblem][selectedCategory][selectedItem]["feedback"].map((feedback, feedback_item) => {
                  return(
                    <Feedback>

                      <StyledDivRegular Position="relative" Width="fit-content" Height="fit-content" FontWeight="400" FontSize="20px" LineHeight="24px" Color="#271B36">
                        {feedback}
                      </StyledDivRegular>
                      
                    </Feedback>
                  )
                })}
              </FeedbackWrapper>
            </FeedbackTopic>

          </FeedbackBox>
        </ExpandedBox>

        {/* Top Container */}
        <TopContainer>

          {/* Processes */}
          <ProcessComponentWrapper>
            {Data.map((process, index) => 
              <StyledDivButton Position="relative" Display="flex" Width="100%" Height="fit-content" MarginTop="0px" MarginLeft="0px" BackgroundColor='#FCFCFC' ZIndex="1"
                                JustifyContent="space-between" BorderRadius="4px" Cursor={disabled_processes.includes(process.process) ? "default" : "pointer"} >
                <button onClick={() => {handleChangeProcess(process.process)}}>
                  {index === 0 ? (
                    <ArrowProcess
                      RightBorderTop={"40px solid transparent"}
                      RightBorderBottom={"40px solid transparent"}
                      RightBorderLeft={disabled_processes.includes(process.process) ? "40px solid #C6CBCB" : selectedProcess == process.process || commom_processes.includes(selectedProcess) && commom_processes.includes(process.process) ? "40px solid #E2336B" : "40px solid #271B36"}
                      RightBorderRight={"none"}
                      Background={disabled_processes.includes(process.process) ? "#C6CBCB" : selectedProcess == process.process || commom_processes.includes(selectedProcess) && commom_processes.includes(process.process) ? "#E2336B" : "#271B36"}>
                      <ArrowTitle>
                          {context.setProcess(process.process, i18n.language)}
                      </ArrowTitle>
                    </ArrowProcess>
                  ) : index === Data.length - 1 ? (
                    <ArrowProcess3
                          LeftBorderTop={"40px solid transparent"}
                          LeftBorderBottom={"40px solid transparent"}
                          LeftBorderLeft={"40px solid #FCFCFC"}
                          LeftBorderRight={"none"}
                          Background={disabled_processes.includes(process.process) ? "#C6CBCB" : selectedProcess == process.process || commom_processes.includes(selectedProcess) && commom_processes.includes(process.process) ? "#E2336B" : "#271B36"}>
                          <ArrowTitle>
                              {context.setProcess(process.process, i18n.language)}
                          </ArrowTitle>
                      </ArrowProcess3>
                  ) : (
                    <ArrowProcess2
                          LeftBorderTop={"40px solid transparent"}
                          LeftBorderBottom={"40px solid transparent"}
                          LeftBorderLeft={"40px solid #FCFCFC"}
                          LeftBorderRight={"none"}
                          RightBorderTop={"40px solid transparent"}
                          RightBorderBottom={"40px solid transparent"}
                          RightBorderLeft={disabled_processes.includes(process.process) ? "40px solid #C6CBCB" : selectedProcess == process.process || commom_processes.includes(selectedProcess) && commom_processes.includes(process.process) ? "40px solid #E2336B" : "40px solid #271B36"}
                          RightBorderRight={"none"}
                          Background={disabled_processes.includes(process.process) ? "#C6CBCB" : selectedProcess == process.process || commom_processes.includes(selectedProcess) && commom_processes.includes(process.process) ? "#E2336B" : "#271B36"}>
                          <ArrowTitle>
                              {context.setProcess(process.process, i18n.language)}
                          </ArrowTitle>
                      </ArrowProcess2>
                  )}
                </button>
                {index != 1 && index != 3 ? (
                    <ProcessPercentage>
                      {process.votes} / {totalVotes} {"(" + Math.ceil(process.votes/totalVotes * 100) + "%)"}
                    </ProcessPercentage>
                ) : (
                  ""
                )}
                
              </StyledDivButton>
            )}
          </ProcessComponentWrapper>
        </TopContainer>
            
        {/* Bottom Container */}
        <BottomContainer>
          
          <TitleContainer>
            {categories.map((category, category_index) => {
              return(
                <ProblemBox>
                  <TitleButton JustifyContent="center">
                    <StyledDivRegular Position="relative" MarginLeft="0px" Width="fit-content" MarginBottom="0px" FontSize="32px" FontWeight="700">
                      {category}{category != "Problem" ? "s" : ""}
                    </StyledDivRegular>
                  </TitleButton>
                </ProblemBox>
              )
            })}
          </TitleContainer>

          <GraphContainerScroll>
            <GraphContainer>
            {/* Feedback */}
            {categories.map((category, category_index) => 
              <ProblemBox>
                {Data.filter((process) => process.process === selectedProcess)[0]["problems"][selectedProblem][category].map((item, item_index) => 
                  <>
                    <TextBox
                      id={selectedProcess + item.branch.toString()}
                      Opacity={
                        category != "Problem" &&
                        !(category == "Cause" && cardsVisible[selectedProcess].causesVisible) &&
                        !((category == "Solution" || category == "Barrier") &&
                          cardsVisible[selectedProcess].causesVisible &&
                          cardsVisible[selectedProcess].branchesVisible[item.branch.slice(0,3).toString()]) &&
                        0.05}
                    >
                      <button
                        onClick={() => handleClickCard(category, item, item_index)}
                      >
                        {item["summary"]}
                      </button>
                      {category != "Problem" &&
                        <Xarrow
                          start={selectedProcess + item.branch.slice(0,-1)}
                          end={selectedProcess + item.branch.toString()}
                          color="gray"
                          strokeWidth={1.5}
                          curveness={0.6}
                          headSize={8}
                          dashness={{ strokeLen: 10, nonStrokeLen: 10 }}
                        />
                      }
                    </TextBox>
                  </>
                )}
              </ProblemBox>
            )}
            </GraphContainer>
          </GraphContainerScroll>

        </BottomContainer>
      </Container>
    );
  } 
};

export default ProblemDeepDive;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 32px 42px;
  background-color: #F1F3F4;
  gap: 24px;
  @media only screen and (min-width: 1980px) {
    zoom: 1;
  }
`;

const LoadLogo = styled.div`
  img {
    width: 50px;
  }

  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
`;

const OverlayBackground = styled.div`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  position: fixed;
  display: ${props => props.Display || ""};
  background: rgba(107, 107, 107, 0.46);
  cursor: pointer;
`;

const TopContainer = styled.div`
  display: flex;
  width: calc(100% - 192px);
  height: fit-content;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 24px 24px 12px 24px;
  gap: 24px;
  background-color: #FCFCFC;
`;

const BottomContainer = styled.div`
  width: calc(100% - 144px);
  height: calc(80% - 48px);
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 24px;
`;

const GraphContainerScroll = styled.div`
  height: calc(100% - 84px);
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #929699;
  }

`;

const GraphContainer = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
  gap: 24px;
`;

const ProblemBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: calc(25% - 66px);
  gap: 30px;
  padding: 24px;
`;

const TitleButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const TextBox = styled.div`
  background-color: #FCFCFC;
  border-radius: "5px";
  z-index: 1;
  opacity: ${props => props.Opacity || 1};

  @media screen and (min-width: 1980px) {
    button {
      font-size: 17;
    }
  }

  button {
    border: none;
    border-radius: 5px;
    background-color: #FCFCFC;
    font-size: 20px;
    font-weight: 400;
    font-family: "Overpass";
    text-align: left;
    cursor: ${props => props.Opacity && "default" || "pointer"};
    padding: 20px;
    user-select: none;
  }
`;


const ProcessComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 34px;
  height: fit-content;
`;


const ArrowProcess = styled.div`
  width: 165px;
  height: 80px;
  background: ${
          props => props.Background || "#271B36"
  };
  border-radius: 2px;
  color: #FCFCFC;
  font-family: 'Overpass';
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: " ";
    z-index: 2;
    position: absolute;
    margin-left: 205px;
    border-right: ${props => props.RightBorderRight || "28px solid #271B36"};
    border-top: ${props => props.RightBorderTop || "28px solid transparent"};
    border-bottom: ${props => props.RightBorderBottom || "28px solid transparent;"};
    border-left: ${props => props.RightBorderLeft || "28px solid #271B36"};
  }
`;

const ArrowProcess2 = styled.div`
  width: 165px;
  height: 80px;

  background: ${
          props => props.Background || "#271B36"
  };
  border-radius: 2px;
  color: #FCFCFC;
  font-family: 'Overpass';
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: " ";
    position: absolute;
    margin-right: 125px;
    border-top: ${props => props.LeftBorderTop || "28px solid #271B36"};
    border-right: ${props => props.LeftBorderRight || "28px solid #271B36"};
    border-bottom: ${props => props.LeftBorderBottom || "28px solid #271B36"};
    border-left: ${props => props.LeftBorderLeft || "28px solid transparent"};
  }

  &:after {
    content: " ";
    z-index: 2;
    position: absolute;
    margin-left: 205px;
    border-right: ${props => props.RightBorderRight || "28px solid #271B36"};
    border-top: ${props => props.RightBorderTop || "28px solid transparent"};
    border-bottom: ${props => props.RightBorderBottom || "28px solid transparent;"};
    border-left: ${props => props.RightBorderLeft || "28px solid #271B36"};
  }
`;

const ArrowProcess3 = styled.div`
  width: 165px;
  height: 80px;
  background: ${
          props => props.Background || "#271B36"
  };
  border-radius: 2px;
  color: #FCFCFC;
  font-family: 'Overpass';
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: " ";
    position: absolute;
    margin-right: 125px;
    border-top: ${props => props.LeftBorderTop || "28px solid #271B36"};
    border-right: ${props => props.LeftBorderRight || "28px solid #271B36"};
    border-bottom: ${props => props.LeftBorderBottom || "28px solid #271B36"};
    border-left: ${props => props.LeftBorderLeft || "28px solid transparent"};
  }
`;

const ArrowTitle = styled.div`
  font-size: 18px;
  margin-left: 40px;
  text-align: center;
  width: 124px;
`;

const ProcessPercentage = styled.div`
  font-family: "Overpass";
  font-size: 18px;
  text-align: center;
  width: 165px;
  margin-top: 8px;
  margin-left: 6px;
  background-color: ${props => props.BackgroundColor || "#FCFCFC"};;
  border-radius: 5px;
`;

const FeedbackWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #FCFCFC;
  height: calc(100%);
  width: calc(100% - 16px);
  margin-left: 16px;
  gap: 16px;
`

const ExpandedBox = styled.div`
  position: absolute;
  display: ${props => props.Display || "none"};
  flex-direction: column;
  background-color: #FCFCFC;
  height: calc(75% - 64px);
  width: calc(75% - 64px);
  left: 7.5%;
  top: 7.5%;
  padding: 32px;
  gap: 16px;
  z-index: 4;
`

const FeedbackBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #FCFCFC;
  height: calc(100% - 120px);
  width: calc(100% - 64px);
  padding: 32px;
  gap: 32px;

  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #929699;
  }

`

const FeedbackTopic = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;

`

const Feedback = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #F1F3F4;
  height: fit-content;
  gap: 4px;
  padding: 12px;
  border-radius: 4px;
`