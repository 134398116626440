import styled from 'styled-components';
import i18next from "i18next";

function hoursStringToDecimalSpontaneous(totalHours) {
    const hours = Math.floor((totalHours / 60));
    const minutes = (totalHours % 60) / 60;
    return Number(hours) + Number(minutes);
}

/**  function to convert type Number to type String, into String format that resembles Type time'xx:xx' */
function decimalHoursToString(hoursDecimal) {
    let hours = (hoursDecimal / 60);
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);

    if (rminutes >= 0) {
        return `${rhours < 10 ? "0" : ""}${rhours}:${rminutes < 10 ? "0" : ""}${rminutes}`
    } else {
        return `${rhours < 10 ? "0" : ""}${rhours}:{"0" : "0"}`
    }
}

const ComponentTimeDay = (props) => {

    return (
        /** Add ZIndex for toggling the component to highlight in the two container */

        <CategoryButtonWrapper onClick={() => props.changeDaySelection(props.id)} Background={props.id === props.selectedDay ? '#B3194A' : '#E3E5E4'} MarginBottom='33px'>
            <TopWrapper Color={props.id === props.selectedDay ? '#FCFCFC' : '##271B36'}>
                <TextContainer>
                    <div style={{fontWeight:'bold'}}>
                    {props.id > 4 
                        ? (i18next.language === "en-US" ? props.day.name : i18next.language === "nl-NL" ? props.day.name_nl : props.day.name_ch) + " " + (props.id - 4)
                        : (i18next.language === "en-US" ? props.day.name : i18next.language === "nl-NL" ? props.day.name_nl : props.day.name_ch)
                    }
                    &nbsp;
                    </div>
                    {decimalHoursToString(props.day.meetingsDuration)} h
                </TextContainer>
            </TopWrapper> 
        </CategoryButtonWrapper>                

    )
}
export default ComponentTimeDay

const CategoryButtonWrapper = styled.div`
  height: 64px;
  width: 100%;
  padding-bottom: 12px;
  background: ${props => props.Background || '#C6CBCB'};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 410px;
`;

const TopWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  color: ${props => props.Color || '#271B36'}
`;

const TextContainer = styled.div`
display: flex;
height: 100%;
width: 100%;
align-items: center;
justify-content: center;
padding-top: 5px;
`