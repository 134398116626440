import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import styled from "styled-components";

import SurveyTextProcessingHeader from "../standardized-components/Survey-text-processing-header";
import SurveyTextProcessingFooter from "../standardized-components/Survey-text-processing-Footer";
import {useWindowSize} from "../../layout-and-styling/standardized-components-library/Component-Responsive";

import ComponentFeedbackInputGood from "./page-specific-components/Component-Feedback-Input-Good";
import ComponentFeedbackTrackerGood from "./page-specific-components/Component-Feedback-Tracker-Good";
import {useTranslation} from "react-i18next";
import ComponentFeedbackTextGood from "./page-specific-components/Component-Feedback-Text-Good";

/** 
 * Good feedback survey page used to receive information about which processes the user thinks are going best in their company.
 * The information is stored in optimalFeedbackSelectedSubprocesses in localStorage.
 */
const PageProcessGoodFeedback = () => {
    /**
     * Component that allows the user to provide their opinion on which processes that are optimal in the company
     * 1) Load data from localStorage and create skeleton to be filled in
     * 2) Component Header, with corresponding Survey styling
     * 3) Tracker component where you can see the current process and subprocess selected
     * 4) Feedback input where the user can select tags related, teams affected and give a proposal
     * 5) Component Footer, specifically for this page where POST request is sent. Also if you return from the next page
     */
    const isFirstRender = useRef(true);
    const {t} = useTranslation();

    function getInitialFeedbackSubprocesses(selectedProcesses) {
        let InitialFeedback = []
        for (let i = 0; i < selectedProcesses.length; i++) {
            InitialFeedback.push({
                'process': selectedProcessInfo[i].process,
                'subprocess': selectedProcessInfo[i].subprocess,
                'tags': [],
                'teams': [],
                'proposal': "",
                'cause': "",
                'justification': ""
            })
        }
        return InitialFeedback
    }

    /** 
     * !!!! IMPORTANT: tags is what we use on selection and translatedTags are only for display. If a translated tag is 
     * sent to the back end, the user will be stuck 
     * */
    const translatedTags = [t('PrePageGoodFeedback.Sales&Marketing'), t('PrePageGoodFeedback.ProductTesting'), t('PrePageGoodFeedback.Standardization&Automation'), t('PrePageGoodFeedback.Specification&Design'), t('PrePageGoodFeedback.Proiritization&Planning'), t('PrePageGoodFeedback.Responsibility&RoleClarity'), t('PrePageGoodFeedback.Alignment&Meetings'), t('PrePageGoodFeedback.CompetenceLevel'), t('PrePageGoodFeedback.SupplyChain&Distribution'), t('PrePageGoodFeedback.CustomerFeedback&Support'), t('PrePageGoodFeedback.Facilities&Tooling'), t('PrePageGoodFeedback.TeamDevelopment&HR'), t('PrePageGoodFeedback.Finance&Legal'), t('PrePageGoodFeedback.Retrospective'), t('PrePageGoodFeedback.Documentation&ERP'), t('PrePageGoodFeedback.Language&Culture'), t('PrePageGoodFeedback.Strategy')];
    const tags = ["Sales & Marketing", "Product Testing", "Standardization & Automation", "Specification & Design", "Prioritization & Planning", "Responsibility & Role Clarity", "Alignment & Meetings", "Competence level", "Supply Chain & Distribution", "Customer feedback & support", "Facilities & Tooling", "Team Development & HR", "Finance & Legal", "Retrospective & lessons learned", "Documentation & ERP", "Language & culture", "Strategy"];

    /** Check if already authenticated, and bring to dashboard if possible.*/
    useEffect(() => {
        const axiosInstance = axios.create({withCredentials: true})
        // Perform GET request to get a CSRFToken from the API and store it in sessionStorage
        axiosInstance.get(process.env.REACT_APP_APIURL + '/api/auth/validatesession/')
            .catch((error) => {
                window.location.replace(process.env.REACT_APP_APPURL + '/login');
            })
    }, []);

    const selectedProcesses = JSON.parse(localStorage.getItem("optimalFeedbackSelectedProcesses"));
    const employees = JSON.parse(localStorage.getItem("AllEmployeeData"));

    const ProcessData = JSON.parse(localStorage.getItem("business_process_data"));
    const [selectedProcessInfo, setSelectedProcessInfo] = useState(calculateProcessesSelected());
    const [fullFeedbackContainer, setFullFeedbackContainer] = useState(getInitialFeedbackSubprocesses(selectedProcessInfo));

    /** States to check if page is properly filled in, before going to next page */
    const [InputCheck, setInputCheck] = useState(false);
    const [render, setRender] = useState(false);
    const [currentProcessPointer, setCurrentProcessPointer] = useState(0);
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [writtenProposal, setWrittenProposal] = useState("");
    const [writtenJustification, setWrittenJustification] = useState("");
    const [writtenCause, setWrittenCause] = useState("");
    const companyTeams = getCompanyTeams();
    const [nextButtonClicked, setNextButtonClicked] = useState(false);

    useEffect(() => {
        //Load feedback container for page, and all feedback given (in case of switched subprocesses)
        let newFeedbackContainer = fullFeedbackContainer;
        let oldFeedbackContainers = JSON.parse(localStorage.getItem("optimalFeedbackAllAnswers")) ? JSON.parse(localStorage.getItem("optimalFeedbackAllAnswers")) : [];
        for(let i=0; i<selectedProcessInfo.length; i++){
            let updated = false;
            for(let j=0; j<oldFeedbackContainers.length; j++){
                if(selectedProcessInfo[i].process === oldFeedbackContainers[j].process && selectedProcessInfo[i].subprocess === oldFeedbackContainers[j].subprocess){
                    newFeedbackContainer[i] = oldFeedbackContainers[j];
                    updated = true;
                }
            }
            //If the subprocess has never been selected before, empty the entries.
            if(!updated){
                newFeedbackContainer[i].tags = [];
                newFeedbackContainer[i].teams = [];
                newFeedbackContainer[i].proposal = "";
                newFeedbackContainer[i].cause = "";
                newFeedbackContainer[i].justification = "";
            }
        }
        setFullFeedbackContainer(newFeedbackContainer);
        localStorage.setItem("optimalFeedbackAllAnswers", JSON.stringify(newFeedbackContainer));
    }, [])

    function calculateProcessesSelected() {
        // Method used to get data from the initial process. Used only on load.
        let procAndSubprocData = []
        let counter = 0;
        for (let i = 0; i < ProcessData.length; i++) {
            for (let j = 0; j < ProcessData[i].children.length; j++) {
                if (selectedProcesses.includes(counter)) {
                    procAndSubprocData.push({'process': ProcessData[i].name, 'subprocess': ProcessData[i].children[j]})
                }

                counter++;
            }
        }

        return procAndSubprocData;
    }


    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        var tempCopy = fullFeedbackContainer;

        tempCopy[currentProcessPointer].tags = selectedTags;
        tempCopy[currentProcessPointer].teams = selectedTeams;
        tempCopy[currentProcessPointer].proposal = writtenProposal;
        tempCopy[currentProcessPointer].justification = writtenJustification;
        tempCopy[currentProcessPointer].cause = writtenCause;

        setFullFeedbackContainer(tempCopy);
        localStorage.setItem("optimalFeedbackAllAnswers", JSON.stringify(tempCopy));

        checkIfPageComplete();
    }, [selectedTags, selectedTeams, writtenProposal, writtenJustification, writtenCause])

    useEffect(() => {
        /** 
         * Makes sure that on re-render the values are correct for the selected user. The first load is avoided because the 
         * list will be empty and will therefore crash if removed.
        */
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        setSelectedTags(fullFeedbackContainer[currentProcessPointer].tags);
        setSelectedTeams(fullFeedbackContainer[currentProcessPointer].teams);
        setWrittenProposal(fullFeedbackContainer[currentProcessPointer].proposal);
        setWrittenJustification(fullFeedbackContainer[currentProcessPointer].justification);
        setWrittenCause(fullFeedbackContainer[currentProcessPointer].cause);

        checkIfPageComplete();
    }, [currentProcessPointer])

    const reRender = () => {
        setRender(!render)
    };

    function getCompanyTeams() {
        let teams = []
        for (let i = 0; i < employees.length; i++) {
            teams.push((employees[i].team))
        }
        return teams;
    }

    function checkProcessComplete(number) {
        /** Checks if process number has its feedback filled in, tags selected, and teams selected */
        return (fullFeedbackContainer[number].teams.length > 0 && fullFeedbackContainer[number].tags.length > 0 && fullFeedbackContainer[number].proposal !== "" && fullFeedbackContainer[number].justification !== "" && fullFeedbackContainer[number].cause !== "")
    }

    function checkIfPageComplete() {

        if (checkProcessComplete(0) && checkProcessComplete(1) && checkProcessComplete(2)) {
            setInputCheck(true);
            return true;
        }
        setInputCheck(false);
        return false;
    }

    return (
        <Container>
            <ComponentContainer>
                <TopComponentContainer>
                    <HeaderAndOverlayWrapper>
                        <SurveyTextProcessingHeader page="Survey" reRender={reRender} directory="/survey-business-process-selection-feedback-optimal" />
                    </HeaderAndOverlayWrapper>
                    {/* Left component, assists keeping track of the subprocess being graded, and which process it belongs to */}
                    <ComponentFeedbackTrackerGood checkProcessComplete={checkProcessComplete} InputCheck={InputCheck} selectedProcessInfo={selectedProcessInfo} currentProcessPointer={currentProcessPointer}
                                                setCurrentProcessPointer={setCurrentProcessPointer} />

                    {/* Right component, used to actually give input and navigate through the pages */}
                    <ComponentFeedbackInputGood tags={tags} translatedTags={translatedTags} reRender={reRender} setFullFeedbackContainer={setFullFeedbackContainer} fullFeedbackContainer={fullFeedbackContainer}
                                                setCurrentProcessPointer={setCurrentProcessPointer} currentProcessPointer={currentProcessPointer} companyTeams={companyTeams} setSelectedTags={setSelectedTags} 
                                                selectedTags={selectedTags} selectedTeams={selectedTeams} setSelectedTeams={setSelectedTeams} checkProcessComplete={checkProcessComplete}/>
                </TopComponentContainer>
                <BottomComponentContainer>
                    <ComponentFeedbackTextGood tags={tags} translatedTags={translatedTags} reRender={reRender} setFullFeedbackContainer={setFullFeedbackContainer} fullFeedbackContainer={fullFeedbackContainer}
                                                setCurrentProcessPointer={setCurrentProcessPointer} currentProcessPointer={currentProcessPointer} companyTeams={companyTeams} setSelectedTags={setSelectedTags} checkIfPageComplete={checkIfPageComplete}
                                                selectedTags={selectedTags} selectedTeams={selectedTeams} setSelectedTeams={setSelectedTeams} writtenProposal={writtenProposal} setWrittenProposal={setWrittenProposal} nextButtonClicked={nextButtonClicked}
                                                writtenJustification={writtenJustification} setWrittenJustification={setWrittenJustification} writtenCause={writtenCause} setWrittenCause={setWrittenCause} checkProcessComplete={checkProcessComplete}/>
                </BottomComponentContainer>
            </ComponentContainer>
            <SurveyTextProcessingFooter key="1" page="PageProcessGoodFeedback" backDirectory="/survey-business-process-selection-feedback-optimal" FooterMarginTop={(useWindowSize()[1] - 104).toString() + "px"}
                            FooterMarginLeft="0px" FooterHeight="104px" FooterWidth="100%" MarginTop="25px" BackButtonMarginLeft="65%" NextButtonMarginLeft="calc(65% + 66px)"
                            input_check={InputCheck} checkIfPageComplete={checkIfPageComplete} nextButtonClicked={nextButtonClicked} setNextButtonClicked={setNextButtonClicked}  />
        </Container>
    );
};
export default PageProcessGoodFeedback;

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: calc(100% - 168px);
  top: 65px;
  left: 0;
  bottom: 0;
  right: 0;
  background: #f1f3f4;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: calc(1 / 1.25);
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: calc(1 / 1.5);
  }

`;

const HeaderAndOverlayWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
`;
const ComponentContainer = styled.div`
  float: left;
  height:100%
`
const TopComponentContainer = styled.div`
  display: flex;
  height: 316px;
  margin: 1% 0 0 0;
`
const BottomComponentContainer = styled.div`
  display: flex;
  height: 343px;
`