import React, {useContext, useEffect, useRef, useState} from "react";
import styled from 'styled-components';
import ComponentDay from "./Component-day";
import ComponentAddDay from "./Component-add-day";
import ComponentTimeWeek from "./Component-time-week";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import Delete from "../../common-assets/delete.png";
import { TooltipMeetingsPage } from "../../standardized-components/Survey-social-network-tooltips";


const ComponentWeek = (props) => {
    /**
     * Component that allows the user to fill in the communication quality based on the colleagues they selected
     * 1) Load Data (selected colleagues) via API GET request and localStorage (from previous page Footer Next button)
     * 2) Component Header, with corresponding Survey styling
     * 3) Day selection component, with buttons that will select which day the time should be registered
     * 4) Colleagues and the user's interaction time registration, for the selected day
     */

    /** Context to load in variables for responsive intro overlay  */
    const { t } = useTranslation();
    
    const [hover, setHover] = useState(false);
    const myElementRef = useRef(null);
    const [scrollLeft, setScrollLeft] = useState(0);
  
    useEffect(() => {
      
      const handleScroll = () => {
        const el = myElementRef.current;
        setScrollLeft(el.scrollLeft);
      };
    
      const element = myElementRef.current;
      element.addEventListener("scroll", handleScroll);
      localStorage.setItem("MeetingsDays", JSON.stringify(props.MeetingsDays))
      return () => {
        element.removeEventListener("scroll", handleScroll);
      };
    }, []);

    function addDay() {

      props.MeetingsDays.push({ id: props.MeetingsDays.length, name: "Extra", name_nl: "Extra", name_ch: "额外的", selected: true, meetings: [], meetingsDuration: 0 })
      props.setSelectedDay(props.MeetingsDays.length-1);
      for (let i = 0; i < props.meetingsTimes.length; i++) props.meetingsTimes[i]["days"].push(0)
      props.setMeetingsDays(props.MeetingsDays)
      props.setMeetingsTimes(props.meetingsTimes)
      localStorage.setItem("MeetingsDays", JSON.stringify(props.MeetingsDays))
      localStorage.setItem("meetingsTimes", JSON.stringify(props.meetingsTimes))

      props.SpontaneousDays.push({ id: props.SpontaneousDays.length, name: "Extra", name_nl: "Extra", name_ch: "额外的"})
      for (let i = 0; i < props.spontaneousTimes.length; i++) props.spontaneousTimes[i]["days"].push(0)
      props.setSpontaneousDays(props.SpontaneousDays)
      props.setSpontaneousTimes(props.spontaneousTimes)
      localStorage.setItem("SpontaneousDays", JSON.stringify(props.SpontaneousDays))
      localStorage.setItem("spontaneousTimes", JSON.stringify(props.spontaneousTimes))

      props.reRender()

    }


    function addMeeting() {  
      let meetingName = props.MeetingsDays[props.selectedDay].name.concat(" ").concat(props.MeetingsDays[props.selectedDay].meetings.length+1)
      if(i18next.language === "nl-NL") meetingName = props.MeetingsDays[props.selectedDay].name_nl.concat(" ").concat(props.MeetingsDays[props.selectedDay].meetings.length+1)
      else if(i18next.language === "ch-CH") meetingName = props.MeetingsDays[props.selectedDay].name_ch.concat(" ").concat(props.MeetingsDays[props.selectedDay].meetings.length+1)
      props.MeetingsDays[props.selectedDay].meetings.push({
          name: meetingName,
          selected: false,
          duration: 0,
          Teams: JSON.parse(localStorage.getItem("SignificantColleaguesPerTeam")),
          counter_people: 0,
          allColleagues: false
      });
      props.setSelectedMeeting(props.MeetingsDays[props.selectedDay].meetings.length - 1);
      localStorage.setItem('MeetingsDays', JSON.stringify(props.MeetingsDays))
      props.reRender();
    }

    function removeMeeting() {
      if(props.MeetingsDays[props.selectedDay].meetings.length > props.selectedMeeting){
        props.MeetingsDays[props.selectedDay].meetingsDuration -= props.MeetingsDays[props.selectedDay].meetings[props.selectedMeeting].duration;
        props.MeetingsDays[props.selectedDay].meetings.splice(props.selectedMeeting, 1);
        if(props.selectedMeeting > 0){
          props.MeetingsDays[props.selectedDay].meetings[props.selectedMeeting-1].selected = true;
          props.setSelectedMeeting(props.selectedMeeting-1)
        } 
    
        props.setMeetingsDays(props.MeetingsDays);
        localStorage.setItem('MeetingsDays', JSON.stringify(props.MeetingsDays))
        props.reRender();
      }  
    }
    

    /** For and if statements to select the right day in ArrayHrs, so this specific selections only alters the right day and colleague interaction */
    return (
      <Container outline={!props.checkMeetingTime & props.nextButtonClicked ? "2px solid #F72B2B" : ""}>
        <IncompleteContentText display={ !props.checkMeetingTime && props.nextButtonClicked ? "flex" : "none"}>
          {t("PagePlannedMeetings.MeetingTimeError")}
        </IncompleteContentText>
        <TopBarWrapper>
          <TextWrapper>
          {t("PagePlannedMeetings.TitleSelector")}
          </TextWrapper>
          <TopButtonsWrapper>
            <ButtonWrapper>
                <button id="meetingbtn" onClick={addMeeting} >+ {t("PagePlannedMeetings.AddMeeting")}</button>
            </ButtonWrapper>

            <ButtonWrapper Opacity={props.selectedMeeting < props.MeetingsDays[props.selectedDay].meetings.length ? '1' : '0.5'} 
              Cursor={props.selectedMeeting < props.MeetingsDays[props.selectedDay].meetings.length ? "pointer" : "normal"}>
                <button onClick={removeMeeting}>
                  <img width='20px' src={Delete}/>
                  {t("PagePlannedMeetings.RemoveMeeting")}
                </button>
            </ButtonWrapper>
            <InfoHover onMouseOver={() => setHover(true)}
                        onMouseOut={() => setHover(false)}>?
                {hover && (<TooltipMeetingsPage whichTooltip={"Meetings"}/>
                )}
            </InfoHover>
          </TopButtonsWrapper>
        </TopBarWrapper>

        <DaysWrapper ref={myElementRef}>
            {props.MeetingsDays.map((day, day_id) => (
                <ComponentDay day={day} id={day_id} MeetingsDays={props.MeetingsDays} setMeetingsDays={props.setMeetingsDays} meetingsTimes={props.meetingsTimes} setMeetingsTimes={props.setMeetingsTimes} selectedDay={props.selectedDay} 
                              setSelectedDay={props.setSelectedDay} SpontaneousDays={props.SpontaneousDays} setSpontaneousDays={props.setSpontaneousDays} spontaneousTimes={props.spontaneousTimes} setSpontaneousTimes={props.setSpontaneousTimes} 
                              selectedMeeting={props.selectedMeeting} setSelectedMeeting={props.setSelectedMeeting} reRender={props.reRender} setInteractionTime={props.setInteractionTime} scrollLeft={scrollLeft}/>
              
            ))}

            <ComponentAddDay addDay={addDay} />
            
        </DaysWrapper>

        <ComponentTimeWeek MeetingsDays={props.MeetingsDays}/>
        
      </Container>     
    )

}
export default ComponentWeek

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 32px;
  margin-right: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #FCFCFC;
  border-radius: 10px;
  outline: ${props => props.outline || "none"};
  outline-offset: -2px;
`;

const TopBarWrapper = styled.div`
display: flex;
flex-direction: row;
width: 100%;
`

const TextWrapper = styled.div`
display: flex;
padding-top: 46px;
width: 50%;
height: 100%;
font-family: 'Overpass';
font-size: 16px;
height: 0px;
font-weight: bold;
align-items: center;
`

const IncompleteContentText = styled.div`
  position: absolute;
  display: ${(props) => props.display || ""};
  border-radius: ${(props) => props.BorderRadius || "5px"};
  color: #F72B2B;
  margin-top: -532px;
  margin-left: 250px;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  z-index: 1;
`;

const TopButtonsWrapper = styled.div`
  width: 100%;
  height: 10%;
  padding: 24px 24px 24px 0px;
  display: flex;
  flex-direction: row;
  position: relative;
  flex-flow: nowrap;
  justify-content: right;
`;

const DaysWrapper = styled.div`
  width: calc(100% - 24px);
  max-width: 1180px;
  height: 80%;
  padding: 2px 0 2px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  flex-flow: nowrap;
  background: #FCFCFC;
  gap: 24px;
  /* border: solid 0.25px #271B36; */
  border-radius: 10px;

  overflow-y: hidden;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    height: 12px;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #929699;
    border-radius: 5px;
  }
`;

const ButtonWrapper = styled.div`
  button {
    display: flex;
    vertical-align: middle;
    flex-direction: row;
    justify-content: right;
    margin-left: 8px;
    align-items: center;
    width: fit-content;
    height: fit-content;
    padding: 8px 12px 8px 12px;
    background: #FCFCFC;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 27px;
    font-size: 20px;
    float: right;
    font-family: "Overpass";
    cursor: ${props => props.Cursor || "pointer"};
    gap: 8px;
    opacity: ${props => props.Opacity || "1"};
  }
`;

const InfoHover = styled.div`
  float: right;
  font-size: 18px;
  font-family: 'Overpass', sans-serif;
  font-weight: 700;
  position: relative;
  color: #FCFCFC;
  background: #E2336B;
  margin-left: 16px;
  border: 2px solid #E2336B;
  border-radius: 500px;
  width: 20px;
  text-align: center;
  height: 20px;
  cursor: pointer;
`;