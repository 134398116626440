import React, {useContext, useEffect, useRef, useState} from "react";
import styled from 'styled-components';
import { OverlayContext } from "../../../layout-and-styling/context-hooks/OverlayContext";
import {ReactComponent as PrevButton} from "../../common-assets/initiative_prev_button.svg";
import {ReactComponent as NextButton} from "../../common-assets/initiative_next_button.svg";
import {ReactComponent as NextButtonNL} from "../../common-assets/initiative_next_buttonNL.svg";
import {ReactComponent as NextButtonCN} from "../../common-assets/initiative_next_buttonCN.svg";
import {ReactComponent as NextButtonGray} from "../../common-assets/initiative_next_button_gray.svg";
import {ReactComponent as NextButtonGrayNL} from "../../common-assets/initiative_next_button_grayNL.svg";
import {ReactComponent as NextButtonGrayCN} from "../../common-assets/initiative_next_button_grayCN.svg";
import {ReactComponent as PrevButtonGray} from "../../common-assets/initiative_prev_button_gray.svg";
import { TooltipCollaborationMeasurement } from "../../standardized-components/Survey-social-network-tooltips";
import ComponentStyledSlider from "../../../layout-and-styling/standardized-components-library/ComponentStyledSlider";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import {Slider} from "@mui/material";

const ComponentCollaborationSlider = ({   ArrayQuality, ArrayInputCheck, updateArrayQuality,
                                          updateArrayInputCheck, selectedColleague, setSelectedColleague,
                                          SignificantColleagues, setInputChecker, reRender, employeeRef
                                      }) => {
    /**
     * Component that allows the user to fill in the communication quality based on the colleagues they selected
     * 1) Load Data (selected colleagues) via API GET request and localStorage (from previous page Footer Next button)
     * 2) Component Header, with corresponding Survey styling
     * 3) Day selection component, with buttons that will select which day the time should be registered
     * 4) Colleagues and the user's interaction time registration, for the selected day
     */

    /** Context to load in variables for responsive intro overlay  */
    const context = useContext(OverlayContext);
    const [hover3, setHover3] = useState(false);

    const {t, i18n} = useTranslation();

    SignificantColleagues = JSON.parse(localStorage.getItem("SignificantColleagues")) ? JSON.parse(localStorage.getItem('SignificantColleagues')) : [];
    let SignificantColleaguesPerTeam = JSON.parse(localStorage.getItem("SignificantColleaguesPerTeam")) ? JSON.parse(localStorage.getItem('SignificantColleaguesPerTeam')) : [];

    /** create an array that contains the people selected independent
     * of teams for easier checking*/
    let significantColleaguesListWithoutTeams = [];

    SignificantColleaguesPerTeam.map((team) => {
        team.team_members.map((employee) => {
                significantColleaguesListWithoutTeams.push(employee)
            }
        )
    });


    const getColleagueByUserId = (id) => {
        return ArrayQuality.find(e => e.target === id)
    };


    const selectedColleagueArray = getColleagueByUserId(selectedColleague);
    const index = ArrayQuality.indexOf(selectedColleagueArray);


    for (let i = 0; i < ArrayQuality.length; i++) {
        if (ArrayQuality[i]['impact_activated'] === true &&
            ArrayQuality[i]['pleasantness_activated'] === true &&
            !ArrayInputCheck.includes(ArrayQuality[i].target)) {
            ArrayInputCheck.push(ArrayQuality[i].target);
        }
    }

    if (ArrayInputCheck.length) {
        updateArrayInputCheck(ArrayInputCheck)
        localStorage.setItem('CheckedEmployeesCollaborationMeasurement', JSON.stringify(ArrayInputCheck))
    }

    setInputChecker(ArrayQuality.length === ArrayInputCheck.length)

    /** When Pleasantness input is given, use function updateArray to set ArrayQuality */
    const updateArray = (value, factor, click) => {
        for (let i = 0; i < ArrayQuality.length; i++) {

            if(!click) ArrayQuality[index][factor] = value; //.target.value

            /**  Set the activated flag of this "first" Colleague to true*/
            if(factor == 'impact'){
                updateArrayQuality(prevArray => prevArray.map((val, i) => i === index ? {
                    ...val,
                    impact_activated: true
                } : val))
            } else if(factor == 'pleasantness'){
                updateArrayQuality(prevArray => prevArray.map((val, i) => i === index ? {
                    ...val,
                    pleasantness_activated: true
                } : val))
            }            
            /** Update variable in local storage */
            localStorage.setItem('ArrayQuality', JSON.stringify(ArrayQuality))
        }
    };

    const previousColleague = () => {
        for (let i = 0; i < significantColleaguesListWithoutTeams.length; i++) {
            if (significantColleaguesListWithoutTeams[i].id === selectedColleague && i - 1 >= 0) {
                setSelectedColleague(significantColleaguesListWithoutTeams[i - 1].id);
                localStorage.setItem("SelectedColleague", significantColleaguesListWithoutTeams[i - 1].id)
                break;
            }
        }
        
        /** 
         * This is for the screen to automatically scroll to the user that is selected. The try/catch is to mitigate an error that does not affect the functionality
         * caused by a desync between the data and the ref.
         */
        try {
            employeeRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'nearest',
            });
        } catch (error) {
            console.log(error);
        }  
    }

    const nextColleague = () => {
        for (let i = 0; i < significantColleaguesListWithoutTeams.length; i++) {
            if (significantColleaguesListWithoutTeams[i].id === selectedColleague && i + 1 < significantColleaguesListWithoutTeams.length) {
                setSelectedColleague(significantColleaguesListWithoutTeams[i + 1].id);
                localStorage.setItem("SelectedColleague", significantColleaguesListWithoutTeams[i + 1].id)
                break;
            }
        }

        try {
            employeeRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        } catch (error) {
            console.log(error)
        }
    }

    const marksImpact = [
        {
            value: 1,
            label: t('PageCollaborationMeasurement.NoImpact'),
            labelDescription: t('PageCollaborationMeasurement.ImpactLabelDisplay1'),
        },
        {
            value: 2,
            label: "",
            labelDescription: t('PageCollaborationMeasurement.ImpactLabelDisplay2'),
        },
        {
            value: 3,
            label: t('PageCollaborationMeasurement.NormalImpact'),
            labelDescription: t('PageCollaborationMeasurement.ImpactLabelDisplay3'),
        },
        {
            value: 4,
            label: "",
            labelDescription: t('PageCollaborationMeasurement.ImpactLabelDisplay4'),
        },
        {
            value: 5,
            label: t('PageCollaborationMeasurement.VeryImpactful'),
            labelDescription: t('PageCollaborationMeasurement.ImpactLabelDisplay5'),
        },
    ]

    const marksPleasantness = [
        {
            value: 1,
            label: t('PageCollaborationMeasurement.Unpleasant'),
            labelDescription: t('PageCollaborationMeasurement.PleasantnessLabelDisplay1'),
        },
        {
            value: 2,
            label: "",
            labelDescription: t('PageCollaborationMeasurement.PleasantnessLabelDisplay2'),
        },
        {
            value: 3,
            label: t('PageCollaborationMeasurement.NormalPleasantness'),
            labelDescription: t('PageCollaborationMeasurement.PleasantnessLabelDisplay3'),
        },
        {
            value: 4,
            label: "",
            labelDescription: t('PageCollaborationMeasurement.PleasantnessLabelDisplay4'),
        },
        {
            value: 5,
            label: t('PageCollaborationMeasurement.VeryPleasant'),
            labelDescription: t('PageCollaborationMeasurement.PleasantnessLabelDisplay5'),
        },
    ]

    function checkNextColleague() {
        for (let i = 0; i < significantColleaguesListWithoutTeams.length; i++) {
            if (significantColleaguesListWithoutTeams[i].id === selectedColleague && i + 1 < significantColleaguesListWithoutTeams.length) {
                return true;
            }
        }
    }

    function checkPreviousColleague() {
        for (let i = 0; i < significantColleaguesListWithoutTeams.length; i++) {
            if (significantColleaguesListWithoutTeams[i].id === selectedColleague && i - 1 >= 0) {
                return true;
            }
        }
    }

    /** For and if statements to select the right day in ArrayHrs, so this specific selections only alters the right day and colleague interaction */
    return (
        <Container id='collaborationsliders'>
            <ImpactPleasantWrapper>
                <TopHeader>
                    <NamePicture>
                        <PersonPic>
                            {ArrayQuality[index].first_name.charAt(0) +
                                ArrayQuality[index].last_name.charAt(0)}
                        </PersonPic>
                        {context.setFirstName(ArrayQuality[index].first_name, i18next.language)}
                    </NamePicture>
                    <InfoHover onMouseOver={() => setHover3(true)}
                                onMouseOut={() => setHover3(false)}>?
                        {hover3 && (<TooltipCollaborationMeasurement whichTooltip={"ImpactPleasantness"}/>
                        )}
                    </InfoHover>
                </TopHeader>
                    
                <SlidersContainer>
                    <ComponentStyledSlider updateArray={updateArray} index={index} ArrayQuality={ArrayQuality} factor={'impact'}
                                        thumbColorBool={ArrayQuality[index].impact_activated} min={1} max={5} marks={marksImpact}
                                        title={t('PageCollaborationMeasurement.ImpactText1') + context.setFirstName(ArrayQuality[index].first_name, i18next.language) + t('PageCollaborationMeasurement.ImpactText2')}/>
                    <ComponentStyledSlider updateArray={updateArray} MarginLeft="80px" index={index} ArrayQuality={ArrayQuality} factor={'pleasantness'}
                                        thumbColorBool={ArrayQuality[index].pleasantness_activated} min={1} max={5} marks={marksPleasantness}
                                        title={t('PageCollaborationMeasurement.PleasantText1') + context.setFirstName(ArrayQuality[index].first_name, i18next.language) + t('PageCollaborationMeasurement.PleasantText2')}/>
                </SlidersContainer>
            </ImpactPleasantWrapper>
                                
            <NextPreviousButtons>
                <button>{!checkPreviousColleague() ? <PrevButtonGray/> :
                    <PrevButton onClick={() => previousColleague()}/>}</button>
                {i18n.language == 'en-US' &&
                <button>{!checkNextColleague() ? <NextButtonGray/> :
                    <NextButton onClick={() => nextColleague()}/>}</button>
                            }
                {i18n.language == 'nl-NL' &&
                <button>{!checkNextColleague() ? <NextButtonGrayNL/> :
                    <NextButtonNL onClick={() => nextColleague()}/>}</button>
                }
                {i18n.language == 'ch-CH' &&
                <button>{!checkNextColleague() ? <NextButtonGrayCN/> :
                    <NextButtonCN onClick={() => nextColleague()}/>}</button>
                }
            </NextPreviousButtons>

        </Container>
        
    )

}
export default ComponentCollaborationSlider

const Container = styled.div`
  height: calc(100% - 64px);
  max-height: 600px;
  width: 100%;
  max-width: 850px;
  background-color: #FCFCFC;
  display: flex;
  flex-direction: column;
  margin: 0 20px 0 12px;
  border-radius: 10px;
`;

const ImpactPleasantWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  align-items: center;
`;

const TopHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: calc(100% - 32px);
  padding: 16px 16px 0 16px;
  justify-content: space-between;
`;

const NamePicture = styled.div`
  display: flex;
  padding: 4px;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  width: fit-content;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const PersonPic = styled.div`
  margin-left: 8px;
  margin-right: 12px;
  align-items: center;
  width: 42px;
  height: 42px;
  font-size: 20px;
  border-radius: 50%;
  background: #d0e1f1;
  color: #271b36;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SlidersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 92px;
  width: 90%;
  gap: 64px;
`;

const SliderImpactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const SliderPleasantnessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const NextPreviousButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 24px;

  button {
    background: none;
    border: none;
    cursor: pointer;
  }
`;

const InfoHover = styled.div`
  float: right;
  font-size: 18px;
  font-family: 'Overpass', sans-serif;
  font-weight: 300;
  position: relative;
  color: #FCFCFC;
  background-color: #E2336B;
  border: 2px solid #E2336B;
  border-radius: 500px;
  width: 20px;
  text-align: center;
  height: 20px;
  cursor: pointer;
`;

const StyledSlider = styled(Slider)`
  position: ${props => props.Position || 'relative'};
  margin-top: ${props => props.MarginTop || ""};
  width: 100%;
  height: ${props => props.Height || "8px"};
  font-family: 'Overpass';
  font-size: 16px;
  color: #271B36;
  z-index: 1;

  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: 1.25;
  }

  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: 1.5;
  }

  @media (-webkit-device-pixel-ratio: 1.75) {
    zoom: 1.75;
  }

  &:focus {
    outline: none !important;
    border: 1px solid #e2336b;
  }
`;
