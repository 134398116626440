import React, {useState, useEffect} from 'react';
import axios from 'axios';
import styled from "styled-components";
import logo from '../assets/login/Logo.svg';
import IconEmail from '../assets/login/IconMail.svg';
import lock_icon from "../assets/lock_open.png";
import lock_icon_shadow from "../assets/lock_open_shadow.png";
import {StyledInput, StyledDivRegular, StyledDivBold} from '../layout-and-styling/standardized-components-library/Styling-TextInput';
import {Checkbox} from '@mui/material';
import getCookieValue from "../utils";
import ComponentLanguageSwitcher from "../layout-and-styling/standardized-components-library/Component-language-switcher";
import ComponentLanguageSwitcherPhone from "../layout-and-styling/standardized-components-library/Component-language-switcher-phone";
import {useTranslation} from 'react-i18next';
import loadlogo from "../assets/dashboard/load.gif";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

/** Login page and auth API */
const loginURL = process.env.REACT_APP_APIURL + '/api/auth/login/'

/** define axiosInstance for API requests */
const axiosInstance = axios.create({withCredentials: true})

// This file contains the login page where the user has to enter his/her credentials and accept the terms of service and privacy policy
const Login = () => {
    const [checked, setChecked] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState(false);
    const [loginClicked, setLoginClicked] = useState(false);
    const [isPortraitMode, setIsPortraitMode] = useState(window.matchMedia("(orientation: portrait)").matches);
    const [paddingBottom, setPaddingBottom] = useState('0px');
    const [topContainerMinHeight, setTopContainerMinHeight] = useState('35vh');

    const {t} = useTranslation();

    const handleCheckboxChange = () => {
        setChecked(!checked);
    };

    /** This function is called every time the log in page is opened/refreshed to check if the user is logged in already */
    const LoginCheck = async () => {
        try {
            const promise1 = await new Promise((resolve, reject) => {
                axiosInstance.get(process.env.REACT_APP_APIURL + '/api/auth/validatesession/')
                    .then((res) => {
                        resolve('resolved promise1')
                    })
                    .catch((error) => {
                        console.log('error, you are not logged in yet!')
                    })
            })
            if (promise1) {
                LoginCommonPromises()
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        async function checkIfBrave() {
            if (navigator.brave && (await navigator.brave.isBrave())) {
                setPaddingBottom('60px');  // Apply padding for Brave
                setTopContainerMinHeight('550px');
            } else {
                setPaddingBottom('0px');  // Default padding for other browsers
                setTopContainerMinHeight('35vh');
            }
        }

        checkIfBrave();
    }, []);

    /** This function is called when the Log In button is clicked. */
    async function LoginPromises() {
        /** Load in login form input */
        var bodyFormData = new FormData();
        bodyFormData.append("username", username)
        bodyFormData.append("password", password)
        bodyFormData.append("test", "test")

        /** Start Promise async sequence */
        try {

            /** Get CSRF and Session token via loginURL */
            const promise1 = await new Promise((resolve, reject) => {
                axiosInstance.get(loginURL)
                    .then((res) => {
                        resolve('promise1')
                    })
                    .catch(error => {
                        setLoginClicked(false)
                        console.log(error)
                    })
            });

            /** If CSRF and Session token request is resolved,
             * use form input and tokens to login via loginURL */
            if (promise1) {
                const promise2 = await new Promise((resolve, reject) => {
                    axiosInstance.post(loginURL, bodyFormData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            "X-CSRFToken": getCookieValue("csrftoken"),
                        }
                    })
                        .then((res) => {
                            resolve('promise2')
                        })
                        .catch(error => {
                            setLoginClicked(false)
                            setErrors(true)
                            console.log(error)
                        })
                });

                /** If login request is resolved,
                 * GET request to get CurrentUserData.json via /api/mydetails/ */
                if (promise2) {
                    LoginCommonPromises()
                }
            }
        } catch (err) {
            setLoginClicked(false)
            console.log(err)
        }
    }

    /** This function does: 1) login authentication sequence, 2) necessary api calls and 3) re-direct to next page  */
    async function LoginCommonPromises() {

        /** Start Promise async sequence */
        try {
            const promise1 = await new Promise((resolve, reject) => {
                axiosInstance.get(process.env.REACT_APP_APIURL + '/api/mydetails/')
                    .then(response => {
                        // TODO: Filter by survey and if survey isn't same as current wipe storage.
                        localStorage.clear()
                        sessionStorage.clear()
                        localStorage.setItem('current_user_data', JSON.stringify(response.data)) //store Employee API result
                    })
                    .then((res) => {
                        resolve('promise1')
                    })
                    .catch(error => {
                        setLoginClicked(false)
                        setErrors(true)
                        console.log(error)
                    })
            });

            /** If CurrentUserData.json request is resolved, re-direct to next page */
            if (promise1) {
                /** Perform TermsCheck PUT request */
                const TermsCheck = {accepted_tos: checked}

                const CurrentUserData = JSON.parse(localStorage.getItem('current_user_data'));
                const promise2 = await new Promise((resolve, reject) => {
                    axiosInstance.put(process.env.REACT_APP_APIURL + '/api/myterms/' + CurrentUserData[0]['user'] + '/', TermsCheck, {
                        headers: {
                            'Content-Type': 'application/json',
                            "X-CSRFToken": getCookieValue("csrftoken"),
                        }
                    })
                        .then(response => {
                            resolve('resolved promise2')
                            let currentUser = JSON.parse(localStorage.getItem('current_user_data'));
                            currentUser[0].is_developer = response.data.is_developer;
                            localStorage.setItem('current_user_data', JSON.stringify(currentUser))
                        })
                        .catch(error => {
                            setLoginClicked(false)
                            console.log(error)
                        });
                });
                if (promise2) {
                    /** Get Companies via API /api/companies/ */
                    let currentUser = JSON.parse(localStorage.getItem('current_user_data'));
                    const promise3 = await new Promise((resolve, reject) => {
                        axiosInstance.get(process.env.REACT_APP_APIURL + '/api/myintakeprocesses/')
                            .then(response => {
                                if (JSON.stringify(response.data) != '[]'){
                                    localStorage.setItem('intakeData', JSON.stringify(response.data))
                                    resolve('promise3')
                                }
                                else{
                                    resolve('promise3')
                                }
                            }
                            )
                        }
                    )
                    if (promise3){
                        const promise4 = await new Promise((resolve, reject) => {
                            axiosInstance
                                .get(
                                    process.env.REACT_APP_APIURL +
                                    "/api/sections_available/"
                                )
                                .then((res) => {
                                    localStorage.setItem("allow_survey", res.data["survey"]);
                                    localStorage.setItem("allow_chatbot", res.data["chatbot"]);
                                    localStorage.setItem("allow_dashboard", res.data["dashboard"]);
                                    resolve('promise4')
                                })
                                .catch((error) => {
                                    console.log("ERROR: Could not fetch /api/dashboard_availability/");
                                });
                            })
                    
                        if (promise4) {
                            if (currentUser[0].is_developer) {
                                const promise4 = await new Promise((resolve, reject) => {
                                    axiosInstance.get(process.env.REACT_APP_APIURL + '/api/companies/')
                                        .then(response => {
                                            localStorage.setItem('companies_data', JSON.stringify(response.data))
                                            localStorage.setItem('demo_mode', JSON.stringify(true))
                                            resolve('promise1')
                                        })
                                        .catch(error => {
                                            console.log(error)
                                        })
                                });
                                if (promise4) {
                                    window.location.replace(process.env.REACT_APP_APPURL + '/home')
                                }
                            } else {
                                window.location.replace(process.env.REACT_APP_APPURL + '/survey-rootcause-chatbot')
                            }
                        }
                    }
                }
            }
        } catch (err) {
            setLoginClicked(false)
            console.log(err)
        }
    }

    /** Built-in check if already authenticated, and bring to dashboard if possible.*/
    useEffect(() => {
        /** Set document zoom at 1.0, just to be sure */
        document.body.style.zoom = 1.0;

        const mediaQuery = window.matchMedia("(orientation: portrait)");

        // Event listener callback for orientation change
        const handleOrientationChange = (event) => {
            setIsPortraitMode(event.matches);
        }

        // Add the event listener
        mediaQuery.addEventListener('change', handleOrientationChange);
        
        
        /** call async function inside UseEffect */
        LoginCheck()

        // Clean yp the event listener on component unmount
        return () => {
            mediaQuery.removeEventListener('change', handleOrientationChange);
        };

    }, []);


    /** Initial Log-in and if authentication OK, set auth token and re-direct to the below directory and run API get calls for Survey*/
    const onSubmit = e => {
        e.preventDefault();
        
        LoginPromises();
    };

    return (
        <>
        {!isPortraitMode ?
        <Container>

            <LogoWrapper> <img src={logo} alt='Logo'/> </LogoWrapper>
            <ComponentLanguageSwitcher Top="24px" />

            <HalfContainer BackgroundColor="#271B36">
                <ContentWrapper MarginBottom="160px">

                    <LogoHeaderWrapper>
                        <Email_icon_wrapper>
                            <img src={IconEmail} alt=""/>
                        </Email_icon_wrapper>

                        <StyledDivBold Display="flex" Position="relative" MarginTop='32px' Color='#FCFCFC' Height='fit-content' Width='fit-content' FontSize='40px' LineHeight='52px'>
                            {t('Login.TextHeader1')}
                        </StyledDivBold>
                    </LogoHeaderWrapper>

                    <StyledDivRegular Display="flex" Position="relative" MarginTop='32px' Color='#FCFCFC' Height='fit-content' Width='fit-content' FontSize='18px' LineHeight='28px' >
                        {t('Login.TextWrapper1')}
                        <br/>
                        <br/>
                        {t('Login.TextWrapper2')}
                        <br/>
                        <br/>
                        {t('Login.TextWrapper3')}
                    </StyledDivRegular>

                </ContentWrapper>
            </HalfContainer>

            <HalfContainer>
                
                <ContentWrapper>
                    <LogoHeaderWrapper>
                        <WholeLockIconWrapper>
                            <LockIconWrapper MarginTop='0px'>
                                <img src={lock_icon} height="60%" width="60%" alt=""/>
                            </LockIconWrapper>

                            <LockIconWrapper2 MarginTop='8px'>
                                <img src={lock_icon_shadow} height="60%" width="60%" alt=""/>
                            </LockIconWrapper2>
                        </WholeLockIconWrapper>

                        <Heading MarginTop='0px' MarginLeft='0px'>
                            {t('Login.TextHeader2')}
                        </Heading>
                    </LogoHeaderWrapper>

                    <FormContainer MarginTop='20px'>
                        <form onSubmit={onSubmit}>
                            <StyledDivRegular MarginTop='0px' MarginLeft='0px' Width='fit-content' Height='20px' FontSize='18px' LineHeight='28px' Position='relative'>
                                {t('Login.Email')}
                            </StyledDivRegular>

                            <StyledInput style={{marginTop: '0px', marginLeft: '0px'}} MarginLeft='' Height='40px' Position='relative' Width='380px'
                            ActiveBackground='#FCFCFC' FontSize='18px' LineHeight='28px' PlaceholderFontSize='18px' PlaceholderLineHeight='28px'
                                name="email"
                                type="email"
                                placeholder="name@example.com"
                                value={username}
                                onChange={e => setUsername(e.target.value)}
                            />
                            <StyledDivRegular MarginTop='0px' MarginLeft='0px' FontSize='18px' LineHeight='28px' Position='relative' Width="fit-content">
                                {t('Login.Password')}
                            </StyledDivRegular>

                            <StyledInput style={{marginTop: '0px', marginLeft: '0px'}} Height='40px' Width='380px' Position='relative'
                            ActiveBackground='#FCFCFC' FontSize='18px' LineHeight='28px' PlaceholderFontSize='18px' PlaceholderLineHeight='28px'
                                name="password"
                                type="password"
                                placeholder={t('Login.Password')}
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                            <StyledDivRegular MarginTop='0px' MarginLeft='0px' Color='#F72B2B' Width='fit-content' Position='relative' Height='20px'
                                Display={errors === true ? '' : 'none'}>
                                {t('Login.ErrorText')}
                            </StyledDivRegular>

                            <TermsCheckboxWrapper>
                                <CheckboxWrapper id="termsbtn" MarginTop='0px' MarginLeft='0px'>
                                    <Checkbox size="large" sx={{'& .MuiSvgIcon-root': {fontSize: 28},}} style={{color: "#e2336B", margin: "-12px",}}
                                            value={checked} onChange={handleCheckboxChange}/>
                                </CheckboxWrapper>

                            <Terms MarginTop='0px' MarginLeft='20px' FontSize='18px' LineHeight='28px'>
                                {t('Login.TextWrapper4')}<a href="https://elephantsintheroom.co/terms-of-service/"
                                                            target="_blank" style={{textDecoration: "none"}} rel="noreferrer">
                                <span>{t('Login.TermsOfServiceText')}</span></a>
                                {t('Login.AndText')}<a href="https://elephantsintheroom.co/privacy-policy/"
                                                        target="_blank" style={{textDecoration: "none"}} rel="noreferrer">
                                <span> {t('Login.PrivacyPolicyText')}</span></a>
                            </Terms>
                            </TermsCheckboxWrapper>

                            {!loginClicked ?
                                <ButtonWrapper MarginTop='0px' MarginLeft='0px' Height='56px' Width='160px'
                                            PointerEvents={checked ? "all" : "none"} 
                                            BackgroundColor={checked ? "#e2336B" : "#F7C9D9"}
                                            Cursor={checked ? "pointer" : "default"}>
                                <button onClick={() => {
                                    setLoginClicked(true)
                                    LoginPromises();
                                    }} type='submit' value='Login' disabled={!checked}>
                                    {t('Login.ButtonText')}
                                </button>
                            </ButtonWrapper>
                            :
                            <ButtonWrapper MarginTop='0px' MarginLeft='0px' Height='56px' Width='160px'
                                            PointerEvents={checked ? "all" : "none"}
                                            BackgroundColor={checked ? "#e2336B" : "#F7C9D9"}
                                            Cursor={checked ? "pointer" : "default"}>
                                <LoadLogo>
                                    <button disabled={!checked}>
                                        {t('Login.ButtonText')}
                                    </button>
                                    <img src={loadlogo} alt="loading..."/>
                                    
                                </LoadLogo>
                            </ButtonWrapper>
                            }
                        </form>
                    </FormContainer>
                </ContentWrapper>

            </HalfContainer>

        </Container>
        :
        // Phone version
        <Container>
            <PhoneHalfContainer PaddingBottom={paddingBottom} MinHeight={topContainerMinHeight} Height="auto" MaxHeight="50vh" BackgroundColor="#271B36" Gap="40px">
                <Header>
                    <LogoWrapper> <img src={logo} alt='Logo'/> </LogoWrapper>
                    <ComponentLanguageSwitcherPhone/>
                </Header>

                <ContentWrapper>
                    <LogoHeaderWrapper MarginTop='40px'>
                        <Email_icon_wrapper>
                            <img src={IconEmail} alt=""/>
                        </Email_icon_wrapper>

                        <StyledDivBold Color='#FCFCFC' PhoneFontSize='60px' PhoneLineHeight='68px'>
                            {t('Login.TextHeader1')}
                        </StyledDivBold>
                    </LogoHeaderWrapper>

                    <StyledDivRegular Color='#FCFCFC' PhoneWidth='100%' PhoneMinWidth='320px' PhoneFontSize='40px' PhoneLineHeight='56px' PhonePosition='relative'>
                        {t('Login.TextWrapper1')}
                        <br/>
                        {t('Login.TextWrapper3')}
                    </StyledDivRegular>
                </ContentWrapper>
            </PhoneHalfContainer>

            <PhoneHalfContainer MinHeight="65vh" Height="auto" MaxHeight="80vh" BackgroundColor="#FFFFFF">
                <ContentWrapper>
                    <LogoHeaderWrapper>
                        <WholeLockIconWrapper>
                            <LockIconWrapper>
                                <img src={lock_icon} height="60%" width="60%" alt=""/>
                            </LockIconWrapper>

                            <LockIconWrapper2>
                                <img src={lock_icon_shadow} height="60%" width="60%" alt=""/>
                            </LockIconWrapper2>
                        </WholeLockIconWrapper>

                        <Heading>
                            {t('Login.TextHeader2')}
                        </Heading>
                    </LogoHeaderWrapper>
                    <FormContainer>
                        <form onSubmit={onSubmit}>
                            <StyledDivRegular PhoneWidth='fit-content'  PhoneFontSize='44px' PhoneLineHeight='54px'>
                                {t('Login.Email')}
                            </StyledDivRegular>

                            <StyledInput ActiveBackground='#FCFCFC'
                                name="email"
                                type="email"
                                placeholder={t('Login.Email')}
                                value={username}
                                onChange={e => setUsername(e.target.value)} />
                            <StyledDivRegular PhoneWidth='fit-content'  PhoneFontSize='44px' PhoneLineHeight='54px'>
                                {t('Login.Password')}
                            </StyledDivRegular>

                            <StyledInput ActiveBackground='#FCFCFC'
                                name="password"
                                type="password"
                                placeholder={t('Login.Password')}
                                value={password}
                                onChange={e => setPassword(e.target.value)}/>
                            <StyledDivRegular  Color='#F72B2B' PhoneWidth='100%' PhoneHeight='100px' PhoneFontSize='34px' PhoneLineHeight='50px' 
                                PhoneBackgroundColor='#FFEAEA' PhoneBorderRadius='25px' PhoneAlignItems="center" PhoneJustifyContent="start"
                                PhoneGap='30px' PhonePaddingLeft='20px' PhoneBoxSizing='border-box' PhoneDisplay={errors === true ? 'flex' : 'none'}>
                                <FontAwesomeIcon icon={ faCircleExclamation } style={{ fontSize: '40px' }}/>
                                {t('Login.PhoneErrorText')}
                            </StyledDivRegular>

                            <TermsCheckboxWrapper>
                                <CheckboxWrapper id="termsbtn">
                                    <Checkbox
                                        size="large"
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 56, // Set the icon size
                                            },
                                            '&.Mui-checked .MuiSvgIcon-root': {
                                                color: '#E2336B', // Change color when checked
                                            },
                                            color: "#E3E5E4", // Default color for the checkbox (unchecked state)
                                            margin: '-12px', // Apply margin if needed
                                    }}
                                        value={checked}
                                        onChange={handleCheckboxChange}
                                    />
                                </CheckboxWrapper>

                                <Terms>
                                    {t('Login.TextWrapper4')}
                                    <a href="https://elephantsintheroom.co/terms-of-service/" target="_blank" style={{textDecoration: "none"}} rel="noreferrer">
                                    <span>{t('Login.TermsOfServiceText')}</span></a>
                                    {t('Login.AndText')}
                                    <a href="https://elephantsintheroom.co/privacy-policy/" target="_blank" style={{textDecoration: "none"}} rel="noreferrer">
                                    <span> {t('Login.PrivacyPolicyText')}</span></a>
                                </Terms>
                            </TermsCheckboxWrapper>
                            {!loginClicked ?
                                <ButtonWrapper  PhoneBackgroundColor={checked ? "#e2336B" : "#F7C9D9"}
                                                PointerEvents={checked ? "all" : "none"} 
                                                Cursor={checked ? "pointer" : "default"}>
                                <button onClick={() => {
                                    setLoginClicked(true)
                                    LoginPromises();
                                    }} type='submit' value='Login' disabled={!checked}>
                                    {t('Login.ButtonText')}
                                </button>
                            </ButtonWrapper>
                            :
                            <ButtonWrapper  PhoneBackgroundColor={checked ? "#e2336B" : "#F7C9D9"}
                                            PointerEvents={checked ? "all" : "none"}
                                            Cursor={checked ? "pointer" : "default"}>
                                <LoadLogo>
                                    <button disabled={!checked}>
                                        {t('Login.ButtonText')}
                                    </button>
                                    <img src={loadlogo} alt="loading..."/>
                                    
                                </LoadLogo>
                            </ButtonWrapper>
                            }
                            <StyledDivRegular PhoneWidth='fit-content' PhoneFontSize='40px' PhoneLineHeight='50px' PhoneAlignSelf='center' PhoneTextAlign='center' PhoneLinkTextDecoration='none'>
                                {t('Login.PhoneBottomText')} <a href='https://elephantsintheroom.co/contact/'><b>{t('Login.PhoneBottomLink')}</b></a>
                            </StyledDivRegular>
                        </form>
                    </FormContainer>
                </ContentWrapper>
            </PhoneHalfContainer>
        </Container>
        }
        </>
    );
};


export default Login;

const PhoneHalfContainer = styled.div`
    width: 100vw;
    padding-left: 5%;
    padding-right: 5%;

    display: flex;
    flex-direction: column;
    gap: ${props => props.Gap || ""};
    position: relative;
    padding-bottom: ${props => props.PaddingBottom || ""};

    background-color: ${props => props.BackgroundColor || "#FFFFFF"};
    // Height is specified in vh (viewport height) to adjust dynamically to the screen size of different phones
    min-height: ${props => props.MinHeight || ""};
    height: ${props => props.Height || ""};
    max-height: ${props => props.MaxHeight || ""};
`;

const Header = styled.div`
    margin-top: 4vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  background: #FCFCFC;

  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: calc(1 / 1.25);
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: calc(1 / 1.5);
  }

  @media screen only (min-device-width: 4000px) {
    flex-direction: column;
  }

  @media (orientation: portrait) {
    flex-direction: column;
  }
`;

const HalfContainer = styled.div`
    width: 100vw;
    background-color: ${props => props.BackgroundColor || "#FCFCFC"};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden; // added to fix logo going beyond the box
`;

const ContentWrapper = styled.div`
    position: relative;
    display: flex;
    background-color: none;
    width: 75%;
    flex-direction: column;
    margin-left: 12.5%;
    height: fit-content;
    margin-bottom: ${props => props.MarginBottom || ""};

    @media (orientation: portrait) {
        margin: 0;
        width: 100%;
        gap: 40px;
        height: 100%;
        box-sizing: border-box;

    }
`;

const LogoHeaderWrapper = styled.div`
    display: flex;
    align-items: start;
    flex-direction: column;
    max-width: 100%; // added to fix logo going beyond the box
    margin-top: ${props => props.MarginTop || "0px"};
    margin-bottom: ${props => props.MarginBottom || "0px"};
    overflow: hidden; // added to fix logo going beyond the box
    
    @media (orientation: portrait) {
        flex-direction: row;
        align-items: center;
        gap: 44px;
        margin-bottom: 0px;
        margin-top: ${props => props.MarginTop || "60px"};
        min-height: 150px;
    }
`;

const LogoWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  z-index: 1;
  margin-top: ${props => props.MarginTop || "40px"};
  margin-left: ${props => props.MarginLeft || "70px"};

  @media (orientation: portrait) {
    position: relative;
    margin: 0px;
    margin-left: -10px;

    img {
        width: 400px;
        height: auto;
    }
  }
`;

const Email_icon_wrapper = styled.div`
  position: relative;
  display: flex;
  img {
        width: 78px
    }
  @media (orientation: portrait) {
        img {
            width: 120px
        }
    }
`;


export const LanguageSwitcher = styled.div`
  margin-left: 75%;
  margin-top: 15px;
  position: relative;
  height: 50px;
`;

const WholeLockIconWrapper = styled.div`
    display: flex;
    align-items: start;
    width: 100%;
    margin-left: -160px;
    margin-bottom: 20px;
    
    @media (orientation: portrait) {
        width: fit-content;
        display: block;
        margin-left: -20px;
        margin-bottom: 0px;
        img {
            width: 140px;
        }
    }
`;

const LockIconWrapper = styled.div`
  position: relative;
  margin-top: ${props => props.MarginTop || "160px"};
  margin-left: ${props => props.MarginLeft || "150px"};
  z-index: 2;

  @media (orientation: portrait) {
    margin: 0px;
}
`;

const LockIconWrapper2 = styled.div`
  position: absolute;
  margin-top: ${props => props.MarginTop || "168px"};
  margin-left: ${props => props.MarginLeft || "158px"};
  z-index: 1;

  @media (orientation: portrait) {
    position: relative;
    margin-top: -132px;
    margin-left: 14px;
}
`;

const Heading = styled.div`
  position: relative;
  margin-top: ${props => props.MarginTop || "282px"};
  margin-left: ${props => props.MarginLeft || "160px"};
  font-family: 'Montserrat', sans-serif;
  font-size: 40px;
  font-weight: 700;
  color: #271B36;
  line-height: 52px;

  @media (orientation: portrait) {
    margin: 0px;
    font-size: 60px;
    line-height: 70px;
}
`;

const FormContainer = styled.div`
  position: relative;
  margin-top: ${props => props.MarginTop || "374px"};
  margin-left: ${props => props.MarginLeft || "160px"};
  width: 420px;
  margin-left: 0px;
  height: fit-content;

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  @media (orientation: portrait) {
    margin: 0;
    width: 100%;

    form {
      gap: 60px;
    }
  }
`;

const Terms = styled.p`
  font-family: 'Overpass';
  font-size: 18px;
  color: #271B36;
  font-weight: 400;
  position: relative;
  margin-top: ${props => props.MarginTop || "0%"};
  margin-left: ${props => props.MarginLeft || "0%"};
  text-align: left;
  width: fit-content;

  span {
    font-family: 'Overpass';
    font-size: 18px;
    color: #e3316b;
    font-weight: 400;
  }

  @media (orientation: portrait) {
    font-size: 36px;
    line-height: 56px;
    margin: 0;

    span {
        font-size: 36px;
        line-height: 56px;
    }
  }
`;

const CheckboxWrapper = styled.div`
  position: relative;
  margin-top: ${props => props.MarginTop || "0%"};
  margin-left: ${props => props.MarginLeft || "0%"};
`;

const TermsCheckboxWrapper = styled.div`
  width: fit-content;
  display: flex;
  align-items: start;
  margin-top: 20px;

  @media (orientation: portrait) {
    margin: 0px;
    gap: 40px;
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
  margin-top: ${props => props.MarginTop || "0%"};
  margin-left: ${props => props.MarginLeft || "0%"};
  z-index: 3;

  button {
    width: ${props => props.Width || "160px"};
    height: ${props => props.Height || "48px"};
    border: ${props => props.Border || "none"};
    border-radius: ${props => props.BorderRadius || "8px"};
    background-color: ${props => props.BackgroundColor || "#e2336B"};
    color: ${props => props.Color || "#FCFCFC"};
    font-size: ${props => props.FontSize || "18px"};
    line-height: ${props => props.LineHeight || "28px"};
    font-weight: ${props => props.FontWeight || "400"};
    font-family: ${props => props.FontFamily || "Overpass"};
    cursor: ${props => props.Cursor || "pointer"};
    transition: all 0.2s ease-in;
    align-items: start;

    pointer-events: ${props => props.PointerEvents || "none"};

    :hover {
        background-color: #B3194A;
    }
  }

  @media (orientation: portrait) {
    button {
      width: 100%;
      font-size: 44px;
      height: 120px;
      border-radius: 25px;
      background-color: ${props => props.PhoneBackgroundColor || "#e2336B"};
    }
  }
`;

const LoadLogo = styled.div`
  img {
    width: 50px;
    margin-left: 55px;
    margin-top: 3px;
  }
  button {
    opacity: 0.2;
    position: absolute;
    cursor: unset;
  }
  display: flex;
  justify-content: flex-start;
  position: relative;

  @media (orientation: portrait) {
    justify-content: center;
    align-items: center;

    img {
      margin: 0px;
      width: 80px;
      height: auto;
    }
  }
`;
