import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import axios from "axios";

import SurveySocialNetworkHeader from "../standardized-components/Survey-social-network-header";
import SurveySocialNetworkFooter from "../standardized-components/Survey-social-network-Footer";
import { useWindowSize } from "../../layout-and-styling/standardized-components-library/Component-Responsive";
import ComponentFrequencyEmployees from "./page-specific-components/Component-frequency-employees";
import ComponentWeek from "./page-specific-components/Component-week";
import { Trans, useTranslation } from "react-i18next";


const PagePlannedMeetings = () => {
  /**
   * Component that allows the user to fill in how much they communicate per week, based on the colleagues they selected
   * 1) Load Data (selected colleagues) via API GET request and localStorage (from previous page Footer Next button)
   * 2) Component Header, with corresponding Survey styling
   * 3) Day selection component, with buttons that will select which day the time should registered
   * 4) Colleagues and the user's interaction time registration, for the selected day
   * 5) Component Footer, specifically for this page with if statement will then do an update via PUT API request. Also if you return from the next page
   */

  /** Check if already authenticated, and bring to dashboard if possible.*/
  const isFirstRender = useRef(true);

  useEffect(() => {
    const axiosInstance = axios.create({ withCredentials: true });
    // Perform GET request to get a CSRFToken from the API and store it in sessionStorage
    axiosInstance
      .get(process.env.REACT_APP_APIURL + "/api/auth/validatesession/")
      .catch((error) => {
        console.log("error, you are not logged in yet!");
        window.location.replace(process.env.REACT_APP_APPURL + "/login");
      });
  }, []);

  const { t } = useTranslation();

  const [render, setRender] = useState(false);
  const reRender = () => {
    setRender(!render);
  };

  /** meetingsTimes = interacted time every day from the planned meetings page, if no meetings -> [0, 0, 0, 0, 0] */
  const [meetingsTimes, setMeetingsTimes] = useState(JSON.parse(localStorage.getItem("meetingsTimes")));

  /** MeetingsDays = starts with just the five days and more will be added if days are added to the week */
  const [MeetingsDays, setMeetingsDays] = useState(JSON.parse(localStorage.getItem("MeetingsDays")));

  /** spontaneousTimes = starts the same as the meetingsTimes but will changhe with the sliders */
  const [spontaneousTimes, setSpontaneousTimes] = useState( JSON.parse(localStorage.getItem("spontaneousTimes")));

  /** SpontaneousDays = starts with just the five days and more will be added if days are added to the week */
  const [SpontaneousDays, setSpontaneousDays] = useState( JSON.parse(localStorage.getItem("SpontaneousDays")));

  const [selectedDay, setSelectedDay] = useState(
    JSON.parse(localStorage.getItem("selectedDay"))
      ? JSON.parse(localStorage.getItem("selectedDay"))
      : 0
  );

  const [selectedMeeting, setSelectedMeeting] = useState(0);
  const [interactionTime, setInteractionTime] = useState(0);
  const [totalHours, setTotalHours] = useState(0);

  const [checkMeetingTime, setCheckMeetingTime] = useState(false);
  const [checkMeetingMembers, setCheckMeetingMembers] = useState(false);
  const [nextButtonClicked, setNextButtonClicked] = useState(false);

  /** From InteractionData.json, store the selected colleagues' names into an array */
  /** Initiate Array to store Hrs Data for each category, to start with an empty array */
  return (
    <>
      <Container>
        <HeaderAndOverlayWrapper>
          <SurveySocialNetworkHeader page="PagePlannedMeetings" directory="/survey-planned-meetings" reRender={reRender} />
        </HeaderAndOverlayWrapper>

        <CenterContainer>
          <PageWrapper>
            <WrapperBodyHeading MarginTop="104px">
              <Trans i18nKey="PagePlannedMeetings.Title"></Trans>
            </WrapperBodyHeading>

            <HrsInputWrapper MarginTop="184px" MarginLeft="70px">

              <ComponentWeek MeetingsDays={MeetingsDays} setMeetingsDays={setMeetingsDays} meetingsTimes={meetingsTimes} setMeetingsTimes={setMeetingsTimes} SpontaneousDays={SpontaneousDays} setSpontaneousDays={setSpontaneousDays} 
                              spontaneousTimes={spontaneousTimes} setSpontaneousTimes={setSpontaneousTimes} selectedDay={selectedDay} setSelectedDay={setSelectedDay} selectedMeeting={selectedMeeting} setSelectedMeeting={setSelectedMeeting} 
                              reRender={reRender} nextButtonClicked={nextButtonClicked} checkMeetingTime={checkMeetingTime} setCheckMeetingTime={setCheckMeetingTime}/>              

              <ComponentFrequencyEmployees MeetingsDays={MeetingsDays} setMeetingsDays={setMeetingsDays} selectedDay={selectedDay} selectedMeeting={selectedMeeting} reRender={reRender}
                                            interactionTime={interactionTime} setInteractionTime={setInteractionTime} totalHours={totalHours} setTotalHours={setTotalHours}
                                            checkMeetingMembers={checkMeetingMembers} setCheckMeetingMembers={setCheckMeetingMembers} nextButtonClicked={nextButtonClicked}/>
            </HrsInputWrapper>
          </PageWrapper>
        </CenterContainer>

        <SurveySocialNetworkFooter page="PagePlannedMeetings" backDirectory="/survey-close-colleagues" MeetingsDays={MeetingsDays} FooterMarginTop={(useWindowSize()[1] - 104).toString() + "px"}
                          FooterMarginLeft="0px" FooterHeight="104px" FooterWidth="100%" MarginTop="25px" BackButtonMarginLeft="65%" NextButtonMarginLeft="calc(65% + 66px)" nextButtonClicked={nextButtonClicked} 
                          setNextButtonClicked={setNextButtonClicked} checkMeetingTime={checkMeetingTime} setCheckMeetingTime={setCheckMeetingTime} checkMeetingMembers={checkMeetingMembers} setCheckMeetingMembers={setCheckMeetingMembers}/>
      </Container>
    </>
  );
};
export default PagePlannedMeetings;

const Container = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #f1f3f4;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: calc(1 / 1.25);
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: calc(1 / 1.5);
  }
  @media (-webkit-device-pixel-ratio: 1.75) {
    zoom: calc(1 / 1.75);
  }
`;

const CenterContainer = styled.div`
  width: 1920px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-flow: nowrap;
`;

const HeaderAndOverlayWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
`;

const PageWrapper = styled.div`
  background: #f1f3f4;
  z-index: 1;
`;

const WrapperBodyHeading = styled.div`
  margin-top: ${(props) => props.MarginTop || "12.0%"};
  margin-bottom: 40px;
  font-family: 'Montserrat', sans-serif;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0em;
  color: #271b36;
  white-space: nowrap;
  background: ${(props) => props.Backgroundcolor || "#F1F3F4"};
  display: flex;
  justify-content: center;

  span {
    font-family: "Montserrat", sans-serif;
    font-size: 40px;
    font-weight: 700;
    color: #e3316b;
    white-space: nowrap;
  }
`;

const HrsInputWrapper = styled.div`
  margin-left: ${(props) => props.MarginLeft || "0%"};
  margin-right: 70px;
  height: 572px;
  display: flex;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: scroll;
  max-width: 1780px;

  ::-webkit-scrollbar {
    display: flex;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100px;
    width: 8px;
    border-left: 0.1px #fcfcfc;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #929699;
    border-radius: 5px;
    height: 120px;
    width: 5px;
  }
`;