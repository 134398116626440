import React, { useEffect} from 'react';
import axios from 'axios';
import styled from "styled-components";
import logo from '../assets/login/Logo.svg';
import {ReactComponent as FrameTopWiggle} from '../assets/userintro/FrameTopWiggle.svg';
import { StyledDivBold, StyledDivRegular } from '../layout-and-styling/standardized-components-library/Styling-TextInput';
import CommonFooter from '../layout-and-styling/standardized-components-library/Common-Footer';
import {HeightFloatToString} from '../layout-and-styling/standardized-components-library/Component-Responsive';
import {Trans, useTranslation} from "react-i18next";
import i18n from "../i18n";

const CurrentUserData = JSON.parse(localStorage.getItem('current_user_data'));

// This file contains the logout page that the user goes once he/she have log out
/** Page to thank the user for filling in the survey and what kind of results to expect */
const Logout = () => {
    const {t} = useTranslation();

    /** Check if already authenticated, and bring to dashboard if possible.*/
    useEffect(() => {
        const axiosInstance = axios.create({withCredentials: true})
        // Perform GET request to get a CSRFToken from the API and store it in sessionStorage
        axiosInstance.get(process.env.REACT_APP_APIURL + '/api/auth/validatesession/')
        .catch((error) => {
        window.location.replace(process.env.REACT_APP_APPURL+'/login');
        })
    }, []);

    return (
        <Container> 
            <LeftContainer>

                <FrameWrapper>
                    <StyledDivRegular MarginTop='0px' MarginLeft='367px' Width='200px' FontSize='18px'
                                    LineHeight='28px'>
                      <StyledTopWiggle>
                        <FrameTopWiggle />
                      </StyledTopWiggle>
                    </StyledDivRegular>

                    <BottomWiggle MarginLeft='24px'/>

                    <LogoWrapper MarginLeft='65px'>
                      <img src={logo} alt='Logo' />
                    </LogoWrapper>

                    <LeftTextWrapper MarginTop={HeightFloatToString(322 / 852)} MarginLeft='65px'>
                        <Trans i18nKey="PageSurveyCompleted.SidebarText">
                            Together we take your organization to <span>the next level</span>.
                        </Trans>
                    </LeftTextWrapper>

                    <IntroFrameBackground Height="100%"/>

                </FrameWrapper>

            </LeftContainer>
    
            <RightContainer>
                <StyledDivBold MarginTop='286px' MarginLeft='170px' Width='1080px' FontSize='42px' LineHeight='52px' PhoneWidth='100%' PhoneFontSize='54px' PhoneLineHeight='64px' PhoneTextAlign='center' PhoneMarginTop='120px'>
                    <Trans i18nKey="Logout.Thanks">
                    Thank you for your time, 
                    </Trans> {CurrentUserData[0]['first_name']}!
                </StyledDivBold> 
                
                <StyledDivRegular MarginTop='378px' MarginLeft='170px' Width='950px' FontSize='24px' LineHeight='36px' PhoneWidth='100%' PhoneFontSize='42px' PhoneLineHeight='54px' PhoneTextAlign='center' PhoneMarginBottom='80px'>
                    <Trans i18nKey="Logout.Sure">
                    Are you sure you want to logout?
                    </Trans>
                </StyledDivRegular>

                <CommonFooter page = 'Logout' nextdirectory = '/dashboard' ButtonText='Logout'
                    MarginTop='480px' MarginLeft='170px' Width='200px' Height='56px' BorderRadius='8px' PhoneWidth='440px' PhoneFontSize='42px' PhoneHeight='150px'/>
            </RightContainer>
                
       </Container> 
 
     );
 };
 export default Logout;

const Container = styled.div`
  background: #FCFCFC;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;

  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: calc(1 / 1.25);
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: calc(1 / 1.5);
  }
  @media (-webkit-device-pixel-ratio: 1.75) {
    zoom: calc(1 / 1.75);
  }

  @media (orientation: portrait) {
    flex-direction: column;
}
`;

const LeftContainer = styled.div`
    width: 500px;
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    background: #FCFCFC;

    @media (orientation: portrait) {
        width: 100%;
        justify-content: center;
        align-items: start;
    }
`;

const FrameWrapper = styled.div`
  position: fixed;
  z-index: 1;
  height: 94.666667%;
  margin: 0 25px;

  @media (orientation: portrait) {
  position: relative;
  margin: 0px;
  height: 100%;
  width: 100%;
}
`;

const StyledTopWiggle = styled.div`
 @media (orientation: portrait) {
  position: absolute;
  margin-left: 873px;
}
`;

const BottomWiggle = styled.div`
  position: ${props => props.Position || 'fixed'};
  bottom: 2.66667%;
  margin-left: ${props => props.MarginLeft || "0%"};
  width: 100px;
  height: 100px;
  background: #e2336B;
  border-radius: 0 100px 0 0;
  -moz-border-radius: 0 100px 0 0;
  -webkit-border-radius: 0 100px 0 20px;
  flex: 1;
  vertical-align: text-bottom;

  @media (orientation: portrait) {
    position: absolute;
    bottom: 0px;
    margin-left: 0px;
    -webkit-border-radius: 0 100px 0 0;
}
`;

const IntroFrameBackground = styled.div`
  margin-left: 24px;
  width: ${props => props.Width || "450px"};
  height: ${props => props.Height || "852px"};
  background: #271B36;
  border-radius: 20px;

  @media (orientation: portrait) {
    margin: 0px;
    width: 100%;
    height: 100%;
    border-radius: 0px;
}
`;

const LogoWrapper = styled.div`
  position: absolute;
  margin-top: 40px;
  margin-left: 40px;
  z-index: 2;

  @media (orientation: portrait) {
    img {
      margin-top: 60px;
      width: 400px
    }
  }
`;

const LeftTextWrapper = styled.div`
  position: absolute;
  margin-top: ${props => props.MarginTop || "0%"};
  margin-left: ${props => props.MarginLeft || "40px"};
  width: 360px;
  z-index: 2;
  font-family: 'Montserrat';
  font-size: 42px;
  line-height: 52px;
  font-weight: 700;
  color: #FCFCFC;

  span {
    color: #e2336B
  }

  @media (orientation: portrait) {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    font-size: 48px;
    line-height: 74px;
    margin-top: 50%;
  }
`;

const RightContainer = styled.div`
  width: 100%;
  background-color: #FCFCFC;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (orientation: portrait) {
    gap: 80px;
    justify-content: start;
    align-items: center;
  }
`;