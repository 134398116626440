import {useContext, useEffect, useState} from "react";
import { StyledDivRegular } from "../../../layout-and-styling/standardized-components-library/Styling-TextInput";
import ProgressBar from "@ramonak/react-progress-bar";

import styled from 'styled-components';
import {useTranslation} from "react-i18next";

function hoursStringToDecimalSpontaneous(totalHours) {
    const hours = Math.floor((totalHours / 60));
    const minutes = (totalHours % 60) / 60;
    return Number(hours) + Number(minutes);
}

/**  function to convert type Number to type String, into String format that resembles Type time'xx:xx' */
function decimalHoursToString(hoursDecimal) {
    let hours = (hoursDecimal / 60);
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);

    if (rminutes >= 0) {
        return `${rhours < 10 ? "0" : ""}${rhours}:${rminutes < 10 ? "0" : ""}${rminutes}`
    } else {
        return `${rhours < 10 ? "0" : ""}${rhours}:{"0" : "0"}`
    }
}

const ComponentTimeWeek = (props) => {
    const {t, i18n} = useTranslation();

    
    function sumMeetingsWeek() {
        var weekMeetingTime = 0
        props.MeetingsDays.map((day, index) => {
            weekMeetingTime = weekMeetingTime + day.meetingsDuration
        });
        return weekMeetingTime
    }

    return (

        <CategoryButtonWrapper>
            <StyledDivRegular Height="auto" Width="fit-content" Color={"#271B36"} MarginTop='0px' MarginRight='8px' TextAlign="center" Position='relative'>
            {t('PagePlannedMeetings.WeeklyMeetingHours')}
            </StyledDivRegular>
                            
            <ProgressBar MarginLeft='8px'
                completed={(hoursStringToDecimalSpontaneous(sumMeetingsWeek())/50)*100}
                customLabel=" " height='16px' width='957px' bgColor={"#E2336B"} baseBgColor={"#F1F3F4"} />   

                <StyledDivRegular Color={ "#271B36"} MarginTop='4px' MarginLeft='16px' Width='70px' Position='relative'>
                    {decimalHoursToString(sumMeetingsWeek())} h
                </StyledDivRegular>
        </CategoryButtonWrapper>                

    )
}
export default ComponentTimeWeek

const CategoryButtonWrapper = styled.div`
  height: 24px;
  width: 100%;
  margin-top: 16px;
  margin-right: 26px;
  padding: 8px 0px 8px 0px;
  padding-bottom: 12px;
  background: #FCFCFC;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
