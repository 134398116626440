// This page contains the component that displays all the employees of the company grouped by teams so that the user can select them
// It is used in many pages of the survey
// The code is quite a mess and should be cleaned and organized

import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import Checkbox from "@mui/material/Checkbox";
import { OverlayContext } from "../../../layout-and-styling/context-hooks/OverlayContext";

import CheckboxFilled, {ReactComponent as Checkbox_Filled} from "../assets/CircleChecked_dropdown.svg";
import CheckboxFilledSelected, {ReactComponent as Checkbox_FilledSelected} from "../assets/CircleChecked_dropdown_selected.svg";
import Checkbox_unchecked, {ReactComponent as Checkbox_Unfilled,} from "../assets/CircleUnchecked_dropdown.svg";
import {ReactComponent as IconTeamArrowOpen} from "../assets/IconTeamArrowClosed.svg";
import {ReactComponent as IconTeamArrowClosed} from "../assets/IconTeamArrowClosed.svg";
import { StyledDivRegular } from "../../../layout-and-styling/standardized-components-library/Styling-TextInput";
import { TooltipCloseColleagueFilter } from "../../standardized-components/Survey-social-network-tooltips";
import {useTranslation} from "react-i18next";
import i18n from "../../../i18n";

/** Get Employee and CurrentUser datasets from API stored data from localStorage */
const EmployeeData = JSON.parse(localStorage.getItem("employee_data"));
const CurrentUserData = JSON.parse(localStorage.getItem("current_user_data"));
const InteractionData = JSON.parse(localStorage.getItem("SignificantInteractions"));
const IntakeEmployeeData = JSON.parse(localStorage.getItem("intake_employee_data"))
var EmployeeCount = 0;
var TeamCount = 0;

/** Function to calculate difference between two arrays object/value pairs, with user_id knowledge as parameter */
function getSelectedPeopleDifference(array1, array2) {
    return array1.filter((object1) => {
        return !array2.some((object2) => {
            return object1.id === object2.id;
        });
    });
}

/** Function to calculate intersection between two arrays object/value pairs, with user_id knowledge as parameter */
function getSelectedPeopleIntersection(array1, array2) {
    return array1.filter((object1) => {
        return array2.some((object2) => {
            return object1.id === object2.id;
        });
    });
}

const ComponentTeamPersonFilter = (componentprops) => {
    const {t} = useTranslation();

    /** State to keep track of total selected persons */
    const [checkedCount, setCheckedCount] = useState(0);
    const context = useContext(OverlayContext);
    const categoriesArray = JSON.parse(localStorage.getItem("MeetingsDays"))
        ? JSON.parse(localStorage.getItem("MeetingsDays"))
        : [];
    const [hover, setHover] = useState(false);

    /** Load and share state from this child component to parent via */
    const childToParent = (childdata) => {
        setCheckedCount(childdata);
    };

    

    /** Simple check to ensure someone started filling in, and trigger next page button enabled
     * But outside changeChecked function, to check when navigating from different pages */

    if (componentprops.whichFilter === "PageCloseColleagueFilter") {
        let counter = 0;

        for (let i = 0; i < Object(AllEmployeeData).length; i++) {
            /** for all unique teams and it's members and if checked == true then increase checked_count */
            for (
                let j = 0;
                j < Object(AllEmployeeData[i]["team_members"]).length;
                j++
            ) {
                if (AllEmployeeData[i]["team_members"][j]["checked"] === true) {
                    counter++;
                } else {
                }
            }
        }

        if (counter === 0) {
            componentprops.set_inputCheckPeople(false);
        }
        if (counter > 0) {
            componentprops.set_inputCheckPeople(true);
        }

        /** Component to generate the entire colleague list of the logged in user, and give a dropdown list filtered per team */
        return (
            <ContainerColleagues outline={!componentprops.inputCheckPeople & componentprops.nextButtonClicked ? "2px solid #F72B2B" : ""}>
                <IncompleteContentText display={ !componentprops.inputCheckPeople & componentprops.nextButtonClicked ? "flex" : "none"}>
                    {t("PageCloseColleagueFilter.ErrorColleagues")}
                </IncompleteContentText>
                <TopHeader>
                    <TotalSelectedText>
                        {t("PagePlannedMeetings.HoursPageTitle")} {counter}
                    </TotalSelectedText>
                    <InfoHover onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>?
                        {hover && (
                            <TooltipCloseColleagueFilter whichTooltip={"CloseColleagues"}/>
                        )}
                    </InfoHover>
                </TopHeader>

                <ComponentWrapper>
                    {AllEmployeeData &&
                        AllEmployeeData.map(
                            (current_team, team_index, employee_data_set) => {
                                return (
                                    <EachTeamComponent key={current_team.team} current_team={current_team} team_index={team_index} childToParent={childToParent} set_input_checked_people={ componentprops.set_inputCheckPeople} />
                                );
                            }
                        )}
                </ComponentWrapper>
            </ContainerColleagues>
        );
    }
    else if (componentprops.whichFilter === "PagePlannedMeetings") {
        /** if people have already been selected for a meeting check whether
         * the user went back and modified the close colleagues selected*/
        componentprops.MeetingsDays.map((day) => {
            day.meetings.map((meeting) => {
                    /** create an array that contains the people selected independent
                     * of teams for easier checking*/
                    let previousPeopleListWithoutTeams = [];
                    let counter = 0;
                    meeting.Teams.map((team) => {
                        team.team_members.map((employee) => {
                            if (employee.checked) counter++;
                            previousPeopleListWithoutTeams.push(employee);
                        });
                        meeting.counter_people = counter;
                    });
                
                /** outputs the people that have been newly added, the difference between the new and the
                 * previous array.*/
                const SelectedPeopleDifference = [
                    ...getSelectedPeopleDifference(
                        JSON.parse(localStorage.getItem("SignificantColleagues")), previousPeopleListWithoutTeams),
                ];

                /** takes the people that are in both arrays. we check based on the previous array
                 * so that we can keep the people selected*/
                const SelectedPeopleIntersection = [
                    ...getSelectedPeopleIntersection(
                        previousPeopleListWithoutTeams,
                        JSON.parse(localStorage.getItem("SignificantColleagues"))
                    ),
                ];

                /** if an intersection was found, repopulate the array in order
                 * to get rid of the people that have been unselected*/
                previousPeopleListWithoutTeams = [];

                if (SelectedPeopleIntersection.length > 0) {
                    SelectedPeopleIntersection.map((selectedPerson) =>
                        previousPeopleListWithoutTeams.push(selectedPerson)
                    );
                }

                /** if a difference was found, push the new employees*/
                if (SelectedPeopleDifference.length > 0) {
                    SelectedPeopleDifference.map((selectedPerson) =>
                        previousPeopleListWithoutTeams.push(selectedPerson)
                    );
                }

                    /** if a difference or an intersection was found, rebuild the initial peopleSelectedMeeting
                     * so that we have the employees divided by teams */
                    if (SelectedPeopleIntersection.length > 0 || SelectedPeopleDifference.length > 0) {
                        meeting.Teams = [];
                        if (previousPeopleListWithoutTeams.length) {
                            meeting.Teams.push({
                                team: previousPeopleListWithoutTeams[0].team,
                                team_members: [],
                            });
                            for (let i = 1; i < previousPeopleListWithoutTeams.length; i++) {
                                if (meeting.Teams.filter((e) => e.team === previousPeopleListWithoutTeams[i].team).length === 0) {
                                    meeting.Teams.push({
                                        team: previousPeopleListWithoutTeams[i].team,
                                        team_members: [],
                                    });
                                }
                            }

                            for (let i = 0; i < meeting.Teams.length; i++) {
                                for (let j = 0; j < previousPeopleListWithoutTeams.length; j++) {
                                    if (previousPeopleListWithoutTeams[j].team === meeting.Teams[i].team) {
                                        meeting.Teams[i].team_members.push(
                                            previousPeopleListWithoutTeams[j]
                                        );
                                    }
                                }
                            }
                        }
                    }
                }
            )
        });

        /** if people have already been selected for a meeting pass them to the peopleSelectedMeeting variable.*/
        if (componentprops.MeetingsDays[componentprops.selectedDay].meetings.length > 0 &&
            componentprops.MeetingsDays[componentprops.selectedDay].meetings[componentprops.selectedMeeting]) {
            peopleSelectedMeeting = componentprops.MeetingsDays[componentprops.selectedDay].meetings[componentprops.selectedMeeting].Teams;
        } else peopleSelectedMeeting = [];

        componentprops.setMeetingsDays(componentprops.MeetingsDays);
        localStorage.setItem("MeetingsDays", JSON.stringify(componentprops.MeetingsDays));
        /** Component to generate the entire colleague list of the logged-in user, and give a dropdown list filtered per team */
        return (
            <ComponentSignificantWrapper Height="calc(100% - 100px)" Width="calc(100% - 59px)">
                {peopleSelectedMeeting &&
                    peopleSelectedMeeting.map((team, team_index) => {
                        return (
                            <TeamFilterComponent Opacity={peopleSelectedMeeting.length > 0 ? 1 : 0.5}
                                whichFilter={componentprops.whichFilter}
                                key={team.team}
                                team={team}
                                team_index={team_index}
                                childToParent={childToParent}
                                MeetingsDays={componentprops.MeetingsDays}
                                setMeetingsDays={componentprops.setMeetingsDays}
                                selectedMeeting={componentprops.selectedMeeting}
                                selectedDay={componentprops.selectedDay}
                                reRender={componentprops.reRender}
                                setGradedSomething={componentprops.setGradedSomething}
                                gradedSomething={componentprops.gradedSomething}
                            />
                        );
                    })}
            </ComponentSignificantWrapper>
        );
    }
    else if (componentprops.whichFilter === "PageSpontaneousCollaboration") {

        for (let i = 0; i < SignificantColleaguesPerTeam.length; i++) {
            for (let j = 0; j < SignificantColleaguesPerTeam[i].team_members.length; j++) {
                if (componentprops.selectedColleague === SignificantColleaguesPerTeam[i].team_members[j].id) {
                    SignificantColleaguesPerTeam[i].team_members[j]["checked"] = true;
                } else
                    SignificantColleaguesPerTeam[i].team_members[j]["checked"] = false;
            }
        }
        /** Component to generate the entire colleague list of the logged-in user, and give a dropdown list filtered per team */
        return (
            <ContainerCollaboration>
                <ComponentWrapperCollaboration>
                    {SignificantColleaguesPerTeam &&
                        SignificantColleaguesPerTeam.map((current_team, team_index) => {
                            return (
                                <TeamFilterComponentCollaboration
                                    meetingsTimes={componentprops.meetingsTimes}
                                    spontaneousTimes={componentprops.spontaneousTimes}
                                    ArrayInteractionAPI={componentprops.ArrayInteractionAPI}
                                    ArrayInputCheck={componentprops.ArrayInputCheck}
                                    selectedColleague={componentprops.selectedColleague}
                                    setSelectedColleague={componentprops.setSelectedColleague}
                                    whichFilter={componentprops.whichFilter}
                                    current_team={current_team}
                                    reRender={componentprops.reRender}
                                    employeeRef={componentprops.employeeRef}
                                />
                            );
                        })}
                </ComponentWrapperCollaboration>
            </ContainerCollaboration>
        );
    }
    else if (componentprops.whichFilter === "PageCollaborationMeasurement") {
        for (let i = 0; i < SignificantColleaguesPerTeam.length; i++) {
            for (let j = 0; j < SignificantColleaguesPerTeam[i].team_members.length; j++) {
                if (componentprops.selectedColleague === SignificantColleaguesPerTeam[i].team_members[j].id) {
                    SignificantColleaguesPerTeam[i].team_members[j]["checked"] = true;
                } else
                    SignificantColleaguesPerTeam[i].team_members[j]["checked"] = false;
            }
        }
        /** Component to generate the entire colleague list of the logged-in user, and give a dropdown list filtered per team */
        return (
            <ContainerCollaboration>
                <ComponentWrapperCollaboration>
                    {SignificantColleaguesPerTeam &&
                        SignificantColleaguesPerTeam.map((current_team, team_index) => {
                            return (
                                <TeamFilterComponentCollaboration
                                    ArrayQuality={componentprops.ArrayQuality}
                                    updateArrayQuality={componentprops.updateArrayQuality}
                                    ArrayInteractionAPI={componentprops.ArrayInteractionAPI}
                                    ArrayInputCheck={componentprops.ArrayInputCheck}
                                    selectedColleague={componentprops.selectedColleague}
                                    setSelectedColleague={componentprops.setSelectedColleague}
                                    whichFilter={componentprops.whichFilter}
                                    current_team={current_team}
                                    reRender={componentprops.reRender}
                                    employeeRef={componentprops.employeeRef}
                                />
                            );
                        })}
                </ComponentWrapperCollaboration>
            </ContainerCollaboration>
        );
    }
    else if (componentprops.whichFilter === "PageCulturePeer") {
        for (let i = 0; i < filteredPeopleCulturePerTeam.length; i++) {
            for (let j = 0; j < filteredPeopleCulturePerTeam[i].team_members.length; j++) {
                if (componentprops.selectedColleague === filteredPeopleCulturePerTeam[i].team_members[j].id) {
                    filteredPeopleCulturePerTeam[i].team_members[j]["checked"] = true;
                } else
                filteredPeopleCulturePerTeam[i].team_members[j]["checked"] = false;
            }
        }
        /** Component to generate the entire colleague list of the logged-in user, and give a dropdown list filtered per team */
        return (
            <ContainerCollaboration>
                <ComponentWrapperCollaboration>
                    {filteredPeopleCulturePerTeam &&
                        filteredPeopleCulturePerTeam.map((current_team, team_index) => {
                            return (
                                <TeamFilterComponentCollaboration
                                    checkColleagueCompleted={componentprops.checkColleagueCompleted}
                                    ArrayQuality={componentprops.ArrayQuality}
                                    updateArrayQuality={componentprops.updateArrayQuality}
                                    ArrayInteractionAPI={componentprops.ArrayInteractionAPI}
                                    ArrayInputCheck={componentprops.ArrayInputCheck}
                                    selectedColleague={componentprops.selectedColleague}
                                    setSelectedColleague={componentprops.setSelectedColleague}
                                    whichFilter={componentprops.whichFilter}
                                    current_team={current_team}
                                    reRender={componentprops.reRender}
                                    employeeRef={componentprops.employeeRef}
                                />
                            );
                        })}
                </ComponentWrapperCollaboration>
            </ContainerCollaboration>
        );
    }
    else if (componentprops.whichFilter === "PageProactiveness") {

        for (let i = 0; i < SignificantColleaguesPerTeam.length; i++) {
            for (let j = 0; j < SignificantColleaguesPerTeam[i].team_members.length; j++) {
                if (componentprops.selectedColleague === SignificantColleaguesPerTeam[i].team_members[j].id) {
                    SignificantColleaguesPerTeam[i].team_members[j]["checked"] = true;
                } else
                    SignificantColleaguesPerTeam[i].team_members[j]["checked"] = false;
            }
        }
        return (
            <ContainerInitiative>
                <TotalSelectedText>
                    {t("PageProactiveness.PeopleSelected")}
                </TotalSelectedText>

                <ComponentSignificantWrapperInitiative>
                    {SignificantColleaguesPerTeam &&
                        SignificantColleaguesPerTeam.map((current_team, team_index) => {
                            TeamCount++;
                            return (
                                <TeamFilterComponentCollaboration
                                    ArrayQuality={componentprops.ArrayQuality}
                                    updateArrayQuality={componentprops.updateArrayQuality}
                                    ArrayInteractionAPI={componentprops.ArrayInteractionAPI}
                                    ArrayInputCheck={componentprops.ArrayInputCheck}
                                    selectedColleague={componentprops.selectedColleague}
                                    setSelectedColleague={componentprops.setSelectedColleague}
                                    whichFilter={componentprops.whichFilter}
                                    current_team={current_team}
                                    reRender={componentprops.reRender}
                                    employeeRef={componentprops.employeeRef}
                                />
                            );
                        })}
                </ComponentSignificantWrapperInitiative>
            </ContainerInitiative>
        );
    }
};
export default ComponentTeamPersonFilter;

const EachTeamComponent = ({
                               whichFilter,
                               current_team,
                               team_index,
                               employee_data_set,
                               childToParent,
                               set_input_checked_people,
                               reRender,
                               currentProcessName,
                               setChosenReps,
                               chosenReps,
                           }) => {
    /** Boolean state teamnav, to keep track which team dropdown should be hidden or shown */
    const [teamNav, setTeamNav] = React.useState(true);
    const showTeamNav = () => setTeamNav(!teamNav);
    const context = useContext(OverlayContext);

    /** setting div color when checkbox is changed */
    const [divcolor, setDivcolor] = useState("#F1F3F4");
    const handleDivcolor = () =>
        setDivcolor(divcolor === "#F1F3F4" ? "#B3194A" : "#F1F3F4");

    /** used to constantly re-render after onChange, else the checkbox state is updated too late. */
    const [selected, setSelected] = useState(false);
    const changeSelected = () => {
        setSelected(!selected);
    };
    

    /** set checked item in Data[index] to the reverse value of true/false, activated through changedChecked */
    const changeChecked = (employee_id, team_index, whichFilter) => {
        let checked_count = 0;
        const checked_team_members = [];
        const UpdatedRepresentative = [];
        if(whichFilter == "IntakeRepresentatives"){
            AllIntakeEmployeeData[team_index]["team_members"][employee_id]["checked"] =
            !AllIntakeEmployeeData[team_index]["team_members"][employee_id]["checked"];
            
            const selectedEmployee = AllIntakeEmployeeData[team_index]["team_members"][employee_id]["id"]
            /** for loop to count number of selected close colleagues via checked boolean and keep count with the var checked_count */
            for (let i = 0; i < Object(AllIntakeEmployeeData).length; i++) {
                for (let j = 0; j < Object(AllIntakeEmployeeData[i]["team_members"]).length; j++) {
                    if (AllIntakeEmployeeData[i]["team_members"][j]["checked"] === true) {
                        checked_count++
                        if (selectedEmployee !== AllIntakeEmployeeData[i]["team_members"][j]['id']) {
                            AllIntakeEmployeeData[i]["team_members"][j]['checked'] = false
                        }
                    if(AllIntakeEmployeeData[i]["team_members"][j]["checked"] === true) {
                        UpdatedRepresentative.push({
                            employee_id: AllIntakeEmployeeData[i]["team_members"][j]['id'],
                            employee_name: AllIntakeEmployeeData[i]["team_members"][j]['full_name']
                        })}
                    }
                }
            }
            const repData = JSON.parse(localStorage.getItem('repByProcess'))
            repData.map((process, index) => {
                if(process.process === currentProcessName && UpdatedRepresentative[0] !== undefined){
                    process.employee = UpdatedRepresentative[0].employee_id
                    process.employee_name = UpdatedRepresentative[0].employee_name
                    let repsData = chosenReps
                    repsData[index].employee_name = UpdatedRepresentative[0].employee_name
                    setChosenReps = repsData
                    localStorage.setItem('repByProcess', JSON.stringify(repData))
                }
                if(process.process === currentProcessName && UpdatedRepresentative[0] == undefined ){
                    process.employee = null
                    process.employee_name = null
                    let repsData = chosenReps
                    repsData[index].employee_name = null
                    setChosenReps = repsData
                    localStorage.setItem('repByProcess', JSON.stringify(repData))
                }
            })
            childToParent(checked_count);
            changeSelected();

            reRender()
        }
        else{
            /** Check or uncheck OnClick of each Div, according to associated styling changes */
            AllEmployeeData[team_index]["team_members"][employee_id]["checked"] =
                !AllEmployeeData[team_index]["team_members"][employee_id]["checked"];
            changeSelected();

             /** for loop to count number of selected close colleagues via checked boolean and keep count with the var checked_count */
            for (let i = 0; i < Object(AllEmployeeData).length; i++) {
                /** for all unique teams and it's members and if checked == true then increase checked_count */
                for (let j = 0; j < Object(AllEmployeeData[i]["team_members"]).length; j++) {
                    if (AllEmployeeData[i]["team_members"][j]["checked"] === true) {
                        checked_count++;
                        checked_team_members.push(
                            AllEmployeeData[i]["team_members"][j]["id"]
                        );
                    }
                }
            }

            childToParent(checked_count);
            localStorage.setItem("AllEmployeeData", JSON.stringify(AllEmployeeData));

            /** For loop to create the JSON object SignificantInteractions required for the api/myinteractions POST request */
            const UpdatedInteractions = [];
            for (let i = 0; i < Object(AllEmployeeData).length; i++) {
                /** for all unique teams and it's members */
                for (let j = 0; j < Object(AllEmployeeData[i]["team_members"]).length; j++) {
                    if (AllEmployeeData[i]["team_members"][j]["checked"]) {
                        UpdatedInteractions.push({
                            significant_interaction: true,
                            source: CurrentUserData[0].id,
                            target: AllEmployeeData[i]["team_members"][j]["id"],
                        });
                    } else {
                        UpdatedInteractions.push({
                            significant_interaction: false,
                            source: CurrentUserData[0].id,
                            target: AllEmployeeData[i]["team_members"][j]["id"],
                        });
                    }
                }
                
            }
            localStorage.setItem("UpdatedInteractions", JSON.stringify(UpdatedInteractions));
        }
        if(whichFilter == "IntakeRepresentatives"){
            const representativeData = JSON.parse(localStorage.getItem('repByProcess'))
            let allowNext = true
            representativeData.map((process, index) => {
                if(process.employee === null){
                    allowNext =  false
                }
            })
            if(allowNext === true){
                set_input_checked_people(true);
            }
            else{
                set_input_checked_people(false);
            }
        }
        else{
            /** Simple check to ensure someone started filling in, and trigger next page button enabled. Inside changeChecked function*/
            if (checked_count === 0) {
                set_input_checked_people(false);
            }
            if (checked_count > 1) {
                set_input_checked_people(true);
            }
        }
        
    };

    /**checks whether people exist in the team list before displaying the team. this is to avoid empty teams being shown **/
    if (
        JSON.parse(localStorage.getItem("employee_data")) &&
        current_team.team_members.length
    ) {
        EmployeeCount = 0;
        return (
            <SubComponentWrapper>
                <TeamFilterWrapper>
                    <TeamFilterLabelWrapper onClick={current_team.team_members && showTeamNav}>
                        {context.setTeams(current_team.team, i18n.language)}
                        {/*{current_team.team}*/}
                        <StyledDivRegular MarginTop="-3px" MarginLeft="20px" Width="24x" Height="24px"
                                          Position="absolute">
                            {teamNav === true ? (
                                <IconTeamArrowOpen/>
                            ) : (
                                <IconTeamArrowClosed/>
                            )}
                        </StyledDivRegular>
                    </TeamFilterLabelWrapper>
                </TeamFilterWrapper>
                
                {teamNav &&
                    current_team.team_members &&
                    current_team.team_members.map(
                        (employee, employee_index, current_team_members) => {
                            EmployeeCount++;
                            return (
                                <PersonWrapper
                                    key={employee.id}
                                    onClick={() => changeChecked(employee_index, team_index, whichFilter)}
                                    style={{
                                        background:
                                            employee.checked === true ? "#B3194A" : "#FCFCFC",
                                    }}
                                    Width={whichFilter === "PagePlannedMeetings" ? "400px" : "calc(100% - 20px)"}
                                    MarginLeft="20px"
                                    MarginRight="20px"
                                >
                                    {/* <PersonPic> {employee.avatar} </PersonPic> */}
                                    <PersonPic id={EmployeeCount + TeamCount}>
                                        <StyledDivRegular
                                            Position="absolute"
                                            BorderRadius="50%"
                                            Width="36px"
                                            Height="36px"
                                            Background="#D0E1F1"
                                            Color="#271B36"
                                            Display="flex"
                                            AlignItems="center"
                                            JustifyContent="center"
                                        >
                                            {context.setFirstName(employee.first_name, 0).charAt(0)
                                                + context.setFirstName(employee.last_name, 0).split(" ").filter((str) => str !== '').pop().charAt(0)}
                                        </StyledDivRegular>
                                    </PersonPic>

                                    <PersonName style={{color: employee.checked === true ? "#FCFCFC" : "#271B36"}}>
                                        {context.setNames(employee.first_name, employee.last_name, i18n.language)}
                                    </PersonName>
                                    <PersonRole
                                        style={{
                                            color: employee.checked === true ? "#FCFCFC" : "#271B36",
                                        }}
                                    >
                                        {employee.role}{" "}
                                    </PersonRole>
                                    <PersonCheckbox>
                                        <Checkbox
                                            icon={<img src={Checkbox_unchecked}></img>}
                                            checkedIcon={<img src={CheckboxFilled}></img>}
                                            checked={employee.checked}
                                        />
                                    </PersonCheckbox>
                                </PersonWrapper>
                            );
                        }
                    )}
            </SubComponentWrapper>
        );
    }
    if (JSON.parse(localStorage.getItem("intake_employee_data")) &&
    current_team.team_members.length){
        EmployeeCount = 0;
        return (
            <SubComponentWrapper>
                <TeamFilterWrapper>
                    <TeamFilterLabelWrapper onClick={current_team.team_members && showTeamNav}>
                        {context.setTeams(current_team.team, i18n.language)}
                        {/*{current_team.team}*/}
                        <StyledDivRegular MarginTop="-3px" MarginLeft="20px" Width="24x" Height="24px"
                                          Position="absolute">
                            {teamNav === true ? (
                                <IconTeamArrowOpen/>
                            ) : (
                                <IconTeamArrowClosed/>
                            )}
                        </StyledDivRegular>
                    </TeamFilterLabelWrapper>
                </TeamFilterWrapper>
                
                {teamNav &&
                    current_team.team_members &&
                    current_team.team_members.map(
                        (employee, employee_index, current_team_members) => {
                            EmployeeCount++;
                            return (
                                <PersonWrapper
                                    id={"pk" + employee.id}
                                    key={employee.id}
                                    onClick={() => changeChecked(employee_index, team_index, whichFilter)}
                                    style={{
                                        background:
                                            employee.checked === true ? "#B3194A" : "#FCFCFC",
                                    }}
                                    Width={whichFilter === "PagePlannedMeetings" ? "400px" : "calc(100% - 20px)"}
                                    MarginLeft="20px"
                                    MarginRight="20px"
                                >
                                    {/* <PersonPic> {employee.avatar} </PersonPic> */}
                                    <PersonPic id={EmployeeCount + TeamCount}>
                                        <StyledDivRegular
                                            Position="absolute"
                                            BorderRadius="50%"
                                            Width="36px"
                                            Height="36px"
                                            Background="#D0E1F1"
                                            Color="#271B36"
                                            Display="flex"
                                            AlignItems="center"
                                            JustifyContent="center"
                                        >
                                            {context.setFirstName(employee.first_name, 0).charAt(0)
                                                + context.setFirstName(employee.last_name, 0).split(" ").filter((str) => str !== '').pop().charAt(0)}
                                        </StyledDivRegular>
                                    </PersonPic>

                                    <PersonName  style={{color: employee.checked === true ? "#FCFCFC" : "#271B36"}}>
                                        {context.setNames(employee.first_name, employee.last_name, i18n.language)}
                                    </PersonName>
                                    <PersonRole
                                        style={{
                                            color: employee.checked === true ? "#FCFCFC" : "#271B36",
                                        }}
                                    >
                                        {employee.role}{" "}
                                    </PersonRole>
                                    <PersonCheckbox>
                                        <Checkbox
                                            icon={<img src={Checkbox_unchecked}></img>}
                                            checkedIcon={<img src={CheckboxFilled}></img>}
                                            checked={employee.checked}
                                        />
                                    </PersonCheckbox>
                                </PersonWrapper>
                            );
                        }
                    )}
            </SubComponentWrapper>
        );
    }
};

const TeamFilterComponent = ({ whichFilter, team, team_index, childToParent, MeetingsDays, setMeetingsDays, 
                                selectedMeeting, selectedDay, reRender, }) => {
    /** Boolean state teamnav, to keep track which team dropdown should be hidden or shown */
    const [teamnav, setTeamnav] = React.useState(true);
    const showTeamnav = () => setTeamnav(!teamnav);
    const context = useContext(OverlayContext);

    /** set checked item in Data[index] to the reverse value of true/false, activated through changedChecked */
    const changeChecked = (employee_index, team_index) => {
        /** Check or uncheck OnClick of each Div, according to associated styling changes */
        MeetingsDays[selectedDay].meetings[selectedMeeting].Teams[team_index]["team_members"][employee_index].checked =
            !MeetingsDays[selectedDay].meetings[selectedMeeting].Teams[team_index]["team_members"][employee_index].checked;

        /** for loop to count number of selected close colleagues via checked boolean and keep count with the var checked_count */
        let checked_count = 0;

        for (let i = 0; i < Object(peopleSelectedMeeting).length; i++) {
            /** for all unique teams and it's members and if checked == true then increase checked_count */
            for (let j = 0; j < Object(MeetingsDays[selectedDay].meetings[selectedMeeting].Teams[i]["team_members"]).length; j++) {
                if (MeetingsDays[selectedDay].meetings[selectedMeeting].Teams[i]["team_members"][j]["checked"] === true) {
                    checked_count++;
                }
            }
        }
        childToParent(checked_count);
        MeetingsDays[selectedDay].meetings[selectedMeeting].allColleagues = false
        setMeetingsDays(MeetingsDays);
        localStorage.setItem("MeetingsDays", JSON.stringify(MeetingsDays));
        reRender();
    };

    if (JSON.parse(localStorage.getItem("employee_data"))) {
        return (
            <SubComponentWrapper>
                <TeamFilterWrapper>
                    <TeamFilterLabelWrapper onClick={team.team_members && showTeamnav} >
                        {context.setTeams(team.team, i18n.language)}
                        <StyledDivRegular MarginTop="-3px" MarginLeft="20px" Width="24x"
                                          Height="24px" Position="absolute"
                        >
                            {teamnav === true ? (<IconTeamArrowOpen/>) : (<IconTeamArrowClosed/>)}
                        </StyledDivRegular>
                    </TeamFilterLabelWrapper>

                    <TeamFilterSubArrowWrapper>
                        {team.team_members && teamnav ? team.iconOpened : team.team_members ? team.iconClosed : null}
                    </TeamFilterSubArrowWrapper>
                </TeamFilterWrapper>

                {teamnav && team.team_members && team.team_members.map(
                    (employee, employee_index) => {
                        return (
                            <PersonWrapper key={employee.id} onClick={() => changeChecked(employee_index, team_index)}
                                           style={{background: employee.checked === true ? "#B3194A" : "#FCFCFC"}}
                                           Width={whichFilter === "PagePlannedMeetings" ? "400px" : "820px"}
                                           MarginLeft="20px" MarginRight="20px" >
                                {/* <PersonPic> {employee.avatar} </PersonPic> */}
                                <PersonPic>
                                    <StyledDivRegular Position="absolute" BorderRadius="50%" Width="36px" Height="36px" Background="#D0E1F1"
                                        Color="#271B36" Display="flex" AlignItems="center" JustifyContent="center"
                                    >
                                        {context.setFirstName(employee.first_name, 0).charAt(0)
                                            + context.setFirstName(employee.last_name, 0).split(" ").filter((str) => str !== '').pop().charAt(0)}
                                    </StyledDivRegular>
                                </PersonPic>

                                <PersonName style={{color: employee.checked === true ? "#FCFCFC" : "#271B36"}}>
                                    {context.setNames(employee.first_name, employee.last_name, i18n.language)}
                                </PersonName>
                                <PersonRole
                                    style={{
                                        color: employee.checked === true ? "#FCFCFC" : "#271B36",
                                    }}
                                >
                                    {employee.role}{" "}
                                </PersonRole>
                                <PersonCheckbox>
                                    {employee.checked ? (
                                        <Checkbox_Filled/>
                                    ) : (
                                        <Checkbox_Unfilled/>
                                    )}
                                </PersonCheckbox>
                            </PersonWrapper>
                        );
                    }
                )}
            </SubComponentWrapper>
        );
    }
};

const TeamFilterComponentCollaboration = ({ArrayInputCheck, reRender, setSelectedColleague, whichFilter, 
                                            current_team, employeeRef, meetingsTimes, spontaneousTimes, checkColleagueCompleted}) => {
    /** Boolean state teamnav, to keep track which team dropdown should be hidden or shown */
    const [teamnav, setTeamnav] = React.useState(true);
    const showTeamNav = () => setTeamnav(!teamnav);
    const context = useContext(OverlayContext);
    /** setting div color when checkbox is changed */
    const [divcolor, setDivcolor] = useState("#F1F3F4");
    const handleDivcolor = () =>
        setDivcolor(divcolor === "#F1F3F4" ? "#B3194A" : "#F1F3F4");

    useEffect(() => {
        let arrayofcolleagues = JSON.parse(localStorage.getItem("SignificantColleaguesPerTeam"));
        const arrayofcolleaguesCulture =
        localStorage.getItem("filteredPeopleCulturePerTeam")
        ? JSON.parse(localStorage.getItem("filteredPeopleCulturePerTeam"))
        : []


        changeChecked(arrayofcolleagues[0].team_members[0].id)
        if(arrayofcolleaguesCulture.length > 0){
        changeChecked(arrayofcolleaguesCulture[0].team_members[0].id)}
    }, [])



    /** set checked item in Data[index] to the reverse value of true/false, activated through changedChecked */
    const changeChecked = (employee_userId) => {
        const selectedColleague = JSON.parse(localStorage.getItem("SelectedColleague"));
        if(checkColleagueCompleted){checkColleagueCompleted(selectedColleague)}
      
        setSelectedColleague(employee_userId);
        localStorage.setItem("SelectedColleague", JSON.stringify(employee_userId));

        for (let i = 0; i < SignificantColleaguesPerTeam.length; i++) {
            for (let j = 0; j < SignificantColleaguesPerTeam[i].team_members.length; j++) {
                SignificantColleaguesPerTeam[i].team_members[j]['checked'] = SignificantColleaguesPerTeam[i].team_members[j].id === employee_userId;
            }
        }
        reRender();
    };

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    function toHHMMSS(totalMinutes) {
        const minutes = totalMinutes % 60;
        const hours = Math.floor(totalMinutes / 60);
        if (!hours) {
            return `00:${padTo2Digits(Math.floor(minutes))}`;

        } else {
            return `${padTo2Digits(hours)}:${padTo2Digits(Math.floor(minutes))}`;
        }
    }

    function addTimesDay(days) {
        let total = 0
        for (let i = 0; i < days.length; i++) total += days[i]
        return total
    }

    if (JSON.parse(localStorage.getItem("employee_data"))) {
        
        return (
            <SubComponentWrapper>
                <TeamFilterWrapper>
                    <TeamFilterLabelWrapper onClick={current_team.team_members && showTeamNav} >
                        {context.setTeams(current_team.team, i18n.language)}

                        <StyledDivRegular MarginTop="-3px" MarginLeft="20px" Width="24x" Height="24px" Position="absolute" >
                            {teamnav === true ? (
                                <IconTeamArrowOpen/>
                            ) : (
                                <IconTeamArrowClosed/>
                            )}
                        </StyledDivRegular>
                    </TeamFilterLabelWrapper>
                </TeamFilterWrapper>
                                  
                {teamnav &&
                    
                    current_team.team_members &&
                    current_team.team_members.map(
                        (employee, employee_index, current_team_members) => {
                            return (
                                <PersonWrapper MarginLeft="20px" MarginRight="20px" key={employee.id} onClick={() => changeChecked(employee.id)}
                                    style={{
                                        background:
                                            employee.checked === true ? "#B3194A" : "#FCFCFC",
                                    }}
                                    Width={whichFilter === "PagePlannedMeetings" ? "400px" : "820px"}
                                    ref={employee.checked === true ? employeeRef : null} >
                                    {/* <PersonPic> {employee.avatar} </PersonPic> */}
                                    <PersonPic>
                                        <StyledDivRegular Position="absolute" BorderRadius="50%" Width="36px" Height="36px" Background="#D0E1F1"
                                            Color="#271B36" Display="flex" AlignItems="center" JustifyContent="center" >
                                            {context.setFirstName(employee.first_name, 0).charAt(0)
                                                + context.setFirstName(employee.last_name, 0).split(" ").filter((str) => str !== '').pop().charAt(0)}
                                        </StyledDivRegular>
                                    </PersonPic>

                                    <PersonName
                                        style={{
                                            color: employee.checked === true ? "#FCFCFC" : "#271B36",
                                        }} >
                                        {context.setNames(employee.first_name, employee.last_name, i18n.language)}
                                    </PersonName>
                                    { whichFilter === "PageSpontaneousCollaboration" &&
                                        <WeekTime>
                                            <WeekTime style={{color: employee.checked === true ? "#FCFCFC" : "#271B36"}}>
                                                {toHHMMSS(addTimesDay(meetingsTimes.filter((e) => e["employee"] === employee.id)[0]["days"]) + addTimesDay(spontaneousTimes.filter((e) => e["employee"] === employee.id)[0]["days"]))}
                                            </WeekTime>
                                            <WeekTime style={{color: employee.checked === true ? "#FCFCFC" : "#271B36"}}>
                                                {"h"}
                                            </WeekTime>
                                        </WeekTime>
                                    }
                                    <PersonCheckbox>
                                        {employee.checked ?
                                            <Checkbox icon={<img src={Checkbox_unchecked} alt="unchecked_icon"/>}
                                                      checkedIcon={<img src={CheckboxFilled} alt="checked_icon"/>}
                                                      checked={ArrayInputCheck.includes(employee.id)} />

                                            :
                                            <Checkbox icon={<img src={Checkbox_unchecked} alt="unchecked_icon"/>}
                                                      checkedIcon={<img src={CheckboxFilledSelected}
                                                                        alt="checked_icon"/>}
                                                      checked={ArrayInputCheck.includes(employee.id)} />
                                            }
                                    </PersonCheckbox>
                                </PersonWrapper>
                            );
                        }
                    )}
            </SubComponentWrapper>
        );
    }
};


/** Data filtering after get request from API, to get unique teams of object EmployeeData.json.js --> output AllEmployeeData */
const AllEmployeeData = [];
const AllEmployeeDataUnchecked = [];

/** TODO create a more elegant await authentication, now a simple if statement to prevent .map(null) errors */
if (EmployeeData !== null) {
    const uniqueTeamsStartArray = [
        ...new Set(EmployeeData.map((obj) => obj.team)),
    ];

    /** for loop to determine unique teams in the current EmployeeData.json.js */
    let uniqueTeamsLength = Object.keys(uniqueTeamsStartArray).length;
    let UniqueTeamsRawArray = [];
    for (let i = 0; i < uniqueTeamsLength; i++) {
        UniqueTeamsRawArray.push(uniqueTeamsStartArray[i]);
    }
    /** Create a object based on unique teams of EmployeeData.json.js, then nesting this with users of each team.
     *  To be later combined to be used for React component Component-team-person-filter **/
    const UniqueTeamsFilteredArray = UniqueTeamsRawArray.filter(
        (x, i, a) => a.indexOf(x) === i
    );

    for (let i = 0; i < Object(UniqueTeamsFilteredArray).length; i++) {
        /** Create unique team objects */
        AllEmployeeData.push({team: UniqueTeamsFilteredArray[i]});
        AllEmployeeDataUnchecked.push({team: UniqueTeamsFilteredArray[i]});
        /** Create the team_members object of each unique team */
        AllEmployeeData[i].team_members = [];
        AllEmployeeDataUnchecked[i].team_members = [];

        /** Append the React team teamnav open & close Objects */
        AllEmployeeData[i].iconClosed = <IconTeamArrowClosed/>;
        AllEmployeeData[i].iconOpened = <IconTeamArrowOpen/>;
    }

    /** for loop to append AllEmployeeData team_members fields from relevant EmployeeData.json.js fields. */
    for (let i = 0; i < Object(EmployeeData).length; i++) {
        /** for all unique teams, loop through if statement if Employee is in the same team then append the AllEmployeeData with UserData fields. */
        for (let j = 0; j < Object(UniqueTeamsFilteredArray).length; j++) {

            if (EmployeeData[i]["team"].toString() === UniqueTeamsFilteredArray[j]) {
                /** TODO API still includes current logged-in user in api/myemployees, until that is fixed. Filter out in front-end here */
                /** Removes logged-in user from available employee list*/
                if (EmployeeData[i]["id"] !== CurrentUserData[0].id) {
                    AllEmployeeData[j]["team_members"].push({
                        id: EmployeeData[i]["id"],
                        full_name: EmployeeData[i]["first_name"].concat(
                            " ",
                            EmployeeData[i]["last_name"]
                        ),
                        avatar: (
                            <img
                                src={EmployeeData[i]["avatar"]}
                                alt="avatar"
                                height="32px"
                                width="32px"
                            />
                        ),
                        role: EmployeeData[i]["role"],
                        checked: false,
                        first_name: EmployeeData[i]["first_name"],
                        last_name: EmployeeData[i]["last_name"],
                    });

                }
            }
        }
    }

    /** Store AllEmployee in localStorage*/
    localStorage.setItem("AllEmployeeData", JSON.stringify(AllEmployeeData));
}
export const AllIntakeEmployeeData = []
const AllIntakeEmployeeDataUnchecked = [];
if(IntakeEmployeeData !== null){
    const uniqueTeamsStartArray = [
        ...new Set(IntakeEmployeeData.map((obj) => obj.team)),
    ];

    /** for loop to determine unique teams in the current EmployeeData.json.js */
    let uniqueTeamsLength = Object.keys(uniqueTeamsStartArray).length;
    let UniqueTeamsRawArray = [];
    for (let i = 0; i < uniqueTeamsLength; i++) {
        UniqueTeamsRawArray.push(uniqueTeamsStartArray[i]);
    }
    /** Create a object based on unique teams of EmployeeData.json.js, then nesting this with users of each team.
     *  To be later combined to be used for React component Component-team-person-filter **/
    const UniqueTeamsFilteredArray = UniqueTeamsRawArray.filter(
        (x, i, a) => a.indexOf(x) === i
    );

    for (let i = 0; i < Object(UniqueTeamsFilteredArray).length; i++) {
        /** Create unique team objects */
        AllIntakeEmployeeData.push({team: UniqueTeamsFilteredArray[i]});
        AllIntakeEmployeeDataUnchecked.push({team: UniqueTeamsFilteredArray[i]});
        /** Create the team_members object of each unique team */
        AllIntakeEmployeeData[i].team_members = [];
        AllIntakeEmployeeDataUnchecked[i].team_members = [];

        /** Append the React team teamnav open & close Objects */
        AllIntakeEmployeeData[i].iconClosed = <IconTeamArrowClosed/>;
        AllIntakeEmployeeData[i].iconOpened = <IconTeamArrowOpen/>;
    }

    /** for loop to append AllIntakeEmployeeData team_members fields from relevant EmployeeData.json.js fields. */
    for (let i = 0; i < Object(IntakeEmployeeData).length; i++) {
        /** for all unique teams, loop through if statement if Employee is in the same team then append the AllIntakeEmployeeData with UserData fields. */
        for (let j = 0; j < Object(UniqueTeamsFilteredArray).length; j++) {

            if (IntakeEmployeeData[i]["team"].toString() === UniqueTeamsFilteredArray[j]) {
                /** TODO API still includes current logged-in user in api/myemployees, until that is fixed. Filter out in front-end here */
                /** Removes logged-in user from available employee list*/
                if (IntakeEmployeeData[i]["id"] !== CurrentUserData[0].id) {
                    AllIntakeEmployeeData[j]["team_members"].push({
                        id: IntakeEmployeeData[i]["id"],
                        full_name: IntakeEmployeeData[i]["first_name"].concat(
                            " ",
                            IntakeEmployeeData[i]["last_name"]
                        ),
                        avatar: (
                            <img
                                src={IntakeEmployeeData[i]["avatar"]}
                                alt="avatar"
                                height="32px"
                                width="32px"
                            />
                        ),
                        role: IntakeEmployeeData[i]["role"],
                        checked: false,
                        first_name: IntakeEmployeeData[i]["first_name"],
                        last_name: IntakeEmployeeData[i]["last_name"],
                    });

                }
            }
        }
    }

    /** Store AllEmployee in localStorage*/
    localStorage.setItem("AllIntakeEmployeeData", JSON.stringify(AllIntakeEmployeeData));
}

// if(componentprops.whichFilter === "IntakeRepresentatives") {
//     const chosenRepresentative = JSON.parse(localStorage.getItem("ChosenRepresentatives"));

//     if (!chosenRepresentative) {
//         localStorage.setItem("ChosenRepresentatives", [])
//     }
    
// }
/** For the case that the user presses back-button or re-do survey, but don't get undefined errors via null check with ? ternary operator */
/** (Data persistence) */
// const GetInteractions = JSON.parse(localStorage.getItem('UpdatedInteractions'));
const GetInteractions = JSON.parse(localStorage.getItem("UpdatedInteractions"))
    ? JSON.parse(localStorage.getItem("UpdatedInteractions"))
    : {};

/** for all unique teams, loop through if statement if Employee is in the same team then append the AllEmployeeData with UserData fields. */
for (let i = 0; i < Object(AllEmployeeData).length; i++) {
    for (let j = 0; j < Object(AllEmployeeData[i]["team_members"]).length; j++) {
        for (let k = 0; k < Object(GetInteractions).length; k++) {
            if (
                AllEmployeeData[i]["team_members"][j]["id"] ===
                GetInteractions[k]["target"] &&
                GetInteractions[k]["significant_interaction"] === true
            ) {
                AllEmployeeData[i]["team_members"][j]["checked"] = true;
            } else {
            }
        }
    }
}
/** Store AllEmployee in localStorage*/
localStorage.setItem("AllEmployeeData", JSON.stringify(AllEmployeeData));

/** Creating array of significant colleagues for the hours page **/
let peopleSelectedMeeting = [];

const SignificantColleagues = [];
for (let i = 0; i < Object(InteractionData).length; i++) {
    for (let j = 0; j < Object(EmployeeData).length; j++) {
        if (EmployeeData[j]["id"] === InteractionData[i]["target_survey"]) {
            SignificantColleagues.push({
                id: EmployeeData[j]["id"],
                full_name: EmployeeData[j]["first_name"].concat(" ", EmployeeData[j]["last_name"]),
                avatar: (
                    <img
                        alt="avatar"
                        src={EmployeeData[j]["avatar"]}
                        height="32px"
                        width="32px"
                    />
                ),
                first_name: EmployeeData[j]["first_name"],
                last_name: EmployeeData[j]["last_name"],
                team: EmployeeData[j]["team"],
                checked: false,
            });
        }
    }
}

localStorage.setItem("SignificantColleagues", JSON.stringify(SignificantColleagues));

let SignificantColleaguesPerTeam = [];
if (SignificantColleagues.length) {
    SignificantColleaguesPerTeam.push({
        team: SignificantColleagues[0].team,
        team_members: [],
    });
    for (let i = 1; i < SignificantColleagues.length; i++) {
        if (
            SignificantColleaguesPerTeam.filter(
                (e) => e.team === SignificantColleagues[i].team
            ).length === 0
        ) {
            SignificantColleaguesPerTeam.push({
                team: SignificantColleagues[i].team,
                team_members: [],
                iconClosed: <IconTeamArrowClosed/>,
                iconOpened: <IconTeamArrowOpen/>,
            });
        }
    }

    for (let i = 0; i < SignificantColleaguesPerTeam.length; i++)
        for (let j = 0; j < SignificantColleagues.length; j++) {
            if (
                SignificantColleagues[j].team ===
                SignificantColleaguesPerTeam[i].team
            ) {
                SignificantColleaguesPerTeam[i].team_members.push(
                    SignificantColleagues[j],
                );
            }
        }
    localStorage.setItem("SignificantColleaguesPerTeam", JSON.stringify(SignificantColleaguesPerTeam));
}

//Getting data to filter new employees on time and impact and pleasentness
let meetingsTimes = localStorage.getItem("meetingsTimes")
? JSON.parse(localStorage.getItem("meetingsTimes"))
: []
let spontaneousTimes = localStorage.getItem("spontaneousTimes")
? JSON.parse(localStorage.getItem("spontaneousTimes"))
: []

let impactAndPleasantness = localStorage.getItem("ArrayQuality")
? JSON.parse(localStorage.getItem("ArrayQuality"))
: []

//New variables where I will be saving the filtered data
let closeColleaguesCultureTime = []
let lowRatedEmployees = [];
let finalArr = [];

for(let i = 0; i < meetingsTimes.length; i++){
  for(let j = 0; j < spontaneousTimes.length; j++){
  if(meetingsTimes[i].employee === spontaneousTimes[j].employee){
    let eachEmployeeTotalMeetings = meetingsTimes[i]["days"].reduce((sum, num) => sum + num)
    let eachEmployeeTotalSpontaneous = spontaneousTimes[j]["days"].reduce((sum, num) => sum + num)

    closeColleaguesCultureTime.push({
      target: meetingsTimes[i].employee,
      totalTime: eachEmployeeTotalMeetings + eachEmployeeTotalSpontaneous
      })

    }
  }
}

let closeColleaguesAboveTimeLimit = closeColleaguesCultureTime.filter(time => time.totalTime >= 75)

for(let i = 0; i < impactAndPleasantness.length; i++){
  if(impactAndPleasantness[i]["impact"] + impactAndPleasantness[i]["pleasantness"] <= 5){
    lowRatedEmployees.push({
      target: impactAndPleasantness[i]["target"],
      impact: impactAndPleasantness[i]["impact"],
      pleasantness: impactAndPleasantness[i]["pleasantness"]
    })
  }
}


for(let i = 0; i < closeColleaguesAboveTimeLimit.length; i++){
  for(let j = 0; j < lowRatedEmployees.length; j++){
    if(closeColleaguesAboveTimeLimit[i]["target"] === lowRatedEmployees[j]["target"]){
      for(let k = 0; k < SignificantColleagues.length; k++){
        if(closeColleaguesAboveTimeLimit[i]["target"] === SignificantColleagues[k]["id"]){
          finalArr.push({
            id: closeColleaguesAboveTimeLimit[i]["target"],
            full_name: SignificantColleagues[k]["full_name"],
            first_name: SignificantColleagues[k]["first_name"],
            last_name: SignificantColleagues[k]["last_name"],
            team: SignificantColleagues[k]["team"],
            impact: lowRatedEmployees[j]["impact"],
            impactAndPleasantness: lowRatedEmployees[j]["impact"] + lowRatedEmployees[j]["pleasantness"],
            pleasantness: lowRatedEmployees[j]["pleasantness"],
            time: closeColleaguesAboveTimeLimit[i]["totalTime"],
            checked: false
          })
        }
      }
    }
  }
}

let filteredPeopleCulture = finalArr.sort(function(a, b){return b.time - a.time}).splice(0,3)
let filteredPeopleCulturePerTeam = [];


if (filteredPeopleCulture.length) {
    filteredPeopleCulturePerTeam.push({
        team: filteredPeopleCulture[0].team,
        team_members: [],
    });
    for (let i = 1; i < filteredPeopleCulture.length; i++) {
        if (
            filteredPeopleCulturePerTeam.filter(
                (e) => e.team === filteredPeopleCulture[i].team
            ).length === 0
        ) {
            filteredPeopleCulturePerTeam.push({
                team: filteredPeopleCulture[i].team,
                team_members: [],
                iconClosed: <IconTeamArrowClosed/>,
                iconOpened: <IconTeamArrowOpen/>,
            });
        }
    }

    for (let i = 0; i < filteredPeopleCulturePerTeam.length; i++)
        for (let j = 0; j < filteredPeopleCulture.length; j++) {
            if (
                filteredPeopleCulture[j].team ===
                filteredPeopleCulturePerTeam[i].team
            ) {
                filteredPeopleCulturePerTeam[i].team_members.push(
                    filteredPeopleCulture[j],
                );
            }
        }
    localStorage.setItem("filteredPeopleCulturePerTeam", JSON.stringify(filteredPeopleCulturePerTeam));
    localStorage.setItem("cultureSelectedPeople", JSON.stringify(filteredPeopleCulture));
}

const ContainerInitiative = styled.div`
  position: relative;
  width: calc(100% - 40px);
  height: calc(100% - 90px);
  background: #fcfcfc;
  border-radius: ${(props) => props.BorderRadius || "5px"};
`;

const ContainerColleagues = styled.div`
  display: flex;
  width: 100%;
  min-width: ${props => props.MinWidth || '1000px'};
  max-width: ${props => props.MaxWidth || '2400px'};
  height: ${props => props.Height || 'calc(100% - 90px)'};
  max-height: ${props => props.MaxHeight || '1000px'};
  margin: ${props => props.Margin || '0 10px 70px 20px'};
  flex-direction: column;
  align-items: center;
  border-radius: ${props => props.BorderRadius || '10px'};
  outline: ${props => props.outline || ''};
  background-color: #FCFCFC;
  box-shadow: ${props => props.BoxShadow || ''};
  z-index: ${props => props.ZIndex || '1'};

  outline-offset: -2px;
  @media only screen and (max-width: 2000px) {
    max-height: ${props => props.MaxHeightMedia || '650px'};
  }
`;

const IncompleteContentText = styled.div`
  position: absolute;
  display: ${(props) => props.display || ""};
  margin-top: 8px;
  border-radius: ${(props) => props.BorderRadius || "5px"};
  color: #F72B2B;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
`;

const ContainerCollaboration = styled.div`
  position: relative;
  background: #fcfcfc;
  width: calc(100% - 40px);
  height: calc(100% - 100px);
  border-radius: ${(props) => props.BorderRadius || "5px"};
`;

const TopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
`;

const TotalSelectedText = styled.div`
  width: ${(props) => props.Width || "fit-content"};
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-left: 20px;
  color: #271B36;
`;

const ComponentSignificantWrapper = styled.div`
  position: ${props => props.Position || "absolute"};
  width: ${props => props.Width || "450px"};
  height: ${props => props.Height || "490px"};
  overflow-x: hidden;
  overflow-y: scroll;
  border-right: 8px #fcfcfc;
  cursor: pointer;
  margin-top: 20px;
  padding-left: 32px;
  padding-right: 4px;
  opacity: ${props => props.Opacity || 1};

  overflow-x: hidden;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    height: 120px;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #929699;
    border-radius: 5px;
  }

  @media only screen and (max-width: 1900px) and (-webkit-device-pixel-ratio: 1) {
    width: 440px;
  }
  @media only screen and (max-width: 1850px) and (-webkit-device-pixel-ratio: 1) {
    width: 420px;
  }
  @media only screen and (max-width: 1700px) and (-webkit-device-pixel-ratio: 1) {
    width: 331px;
  }

  @media only screen and (max-width: 1600px) and (-webkit-device-pixel-ratio: 1) {
    width: 331px;
  }

  @media only screen and (max-width: 1500px) and (-webkit-device-pixel-ratio: 1) {
    width: 330px;
  }

  @media only screen and (max-width: 1400px) and (-webkit-device-pixel-ratio: 1) {
    width: 318px;
  }

  @media only screen and (max-width: 1500px) and (-webkit-device-pixel-ratio: 1.25) {
    width: 408px;
  }

  @media only screen and (max-width: 1380px) and (-webkit-device-pixel-ratio: 1.25) {
    width: 500px;
  }

  @media only screen and (max-width: 1380px) and (-webkit-device-pixel-ratio: 1.5) {
    width: 800px;
  }

  ::-webkit-scrollbar {
    height: 120px;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #929699;
    border-radius: 5px;
  }
`;

const ComponentSignificantWrapperInitiative = styled.div`
  position: relative;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  margin: 8px 8px 8px 8px;
  overflow-x: hidden;
  overflow-y: scroll;
  border-right: 8px #fcfcfc;
  cursor: pointer;

  ::-webkit-scrollbar {
    height: 120px;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #929699;
    border-radius: 5px;
  }
`;

const ComponentWrapperCollaboration = styled.div`
  position: relative;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  margin: 8px 8px 8px 8px;
  overflow-x: hidden;
  overflow-y: scroll;
  border-right: 8px #fcfcfc;
  cursor: pointer;

  ::-webkit-scrollbar {
    height: 120px;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #929699;
    border-radius: 5px;
  }
`;

const ComponentWrapper = styled.div`
  position: relative;
  height: calc(100% - 100px);
  width: calc(100% - 48px);
  margin-top: 20px;
  overflow-x: hidden;
  overflow-y: scroll;
  border-right: 8px #fcfcfc;
  cursor: pointer;
  padding: 0 0 0 10px;
  box-shadow: 0px -20px 20px -20px rgba(0, 0, 0, 0.1);

  ::-webkit-scrollbar {
    height: 120px;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #929699;
    border-radius: 5px;
  }
`;

const SubComponentWrapper = styled.div`
  margin-bottom: 8px;
  background: #e3e5e4;
  border-radius: 5px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const TeamFilterWrapper = styled.div`
  margin-top: 60px;
  margin-left: 20px;
  width: ${(props) => props.Width || "calc(100% - 20px)"};
  height: 10px;
  color: #271b36;
  display: flex;
  justify-content: flex-start;
  font-family: "Overpass", sans-serif;
  font-size: 16px;
  font-weight: 700;
`;

const TeamFilterLabelWrapper = styled.div`
  margin-top: -30px; // margin top of team titles
  margin-left: 0;
  font-family: "Overpass", sans-serif;
  width: 100%;
`;

const TeamFilterSubArrowWrapper = styled.div`
  margin-top: -32px; // margin top of dropdown arrow
  margin-left: 10px;
  height: 24px;
`;

const PersonWrapper = styled.div`
  width: ${(props) => props.Width || "820px"};
  border: 1px solid #fcfcfc;
  border-radius: 10px;
  background: ${(props) => props.Backgroundcolor || "#F1F3F4"};
  color: #271b36;
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  justify-content: space-between;
  font-family: ${(props) => props.FontFamily || "Overpass"};
  font-size: ${(props) => props.FontSize || "16px"};
  line-height: ${(props) => props.LineHeight || "20px"};
  font-weight: ${(props) => props.FontWeight || "400"};
  cursor: pointer;
  opacity: ${(props) => props.Opacity || "1"};
  margin-left: ${(props) => props.MarginLeft || ""};
  margin-right: ${(props) => props.MarginRight || ""};
  margin-bottom: ${(props) => props.MarginBottom || ""};
  margin-top: 4px;
  padding: 10px;

  &:hover {
    border: 1px solid #b3194a;
  }

  @media only screen and(max-width: 1900px) and (-webkit-device-pixel-ratio: 1) {
    margin-right: 10px;
    margin-left: 10px;
  }

  @media only screen and (max-width: 1500px) and (-webkit-device-pixel-ratio: 1.25) {
    margin: 4px 20px;
  }

  @media only screen and (max-width: 1360px) and (-webkit-device-pixel-ratio: 1.25) {
    margin: 4px 15px;
  }

  @media only screen and (max-width: 1380px) and (-webkit-device-pixel-ratio: 1.5) {
    margin: 4px 15px;
  }
`;

const PersonPic = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;
`;
const PersonName = styled.div`
  width: 40%;
`;
const PersonRole = styled.div`
  width: 20%;
`;

const WeekTime = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

const PersonCheckbox = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  margin-right: 20px;
`;

const InfoHover = styled.div`
  font-size: 18px;
  font-family: 'Overpass', sans-serif;
  font-weight: 300;
  position: relative;
  color: #FCFCFC;
  background-color: #E2336B;
  margin-right: 20px;
  border: 2px solid #E2336B;
  border-radius: 500px;
  width: 20px;
  text-align: center;
  height: 20px;
  cursor: pointer;
`;
