import styled from 'styled-components';
import {useEffect, useState} from 'react';
import axios from 'axios';

import {useWindowSize} from '../layout-and-styling/standardized-components-library/Component-Responsive';
import {useTranslation, Trans} from 'react-i18next';
import GifLoading from '../assets/intake/GifLoading.gif';
import ImageNotFound from '../assets/intake/ImageNotFound.png';
import {ReactComponent as IconCheckCircleFill} from '../assets/intake/IconCheckCircleFill.svg';
import CommonHeader from '../layout-and-styling/standardized-components-library/Common-header';
import {StyledDivBold, StyledDivRegular} from '../layout-and-styling/standardized-components-library/Styling-TextInput';
import ComponentTeamPersonFilter from './intake-builder/page-specific-components/Component-team-person-filter';
import {SendRepresentativeIntake} from "./api-calls/api-calls";
import { ReactComponent as NextPinkButtonEN } from "../assets/intake/NextPinkButtonEN.svg";
import { ReactComponent as NextPinkButtonNL } from "../assets/intake/NextPinkButtonNL.svg";
import { ReactComponent as NextPinkButtonCH } from "../assets/intake/NextPinkButtonCH.svg";
import { ReactComponent as NextGreyButtonEN } from "../assets/intake/NextGreyButtonEN.svg";
import { ReactComponent as BackPinkButtonSimple } from "../assets/intake/BackPinkButtonSimple.svg";
import { ReactComponent as BackGreyButtonSimple } from "../assets/intake/BackGreyButtonSimple.svg";
import { ReactComponent as Startblock } from "../assets/intake/Startblock.svg";
import { ReactComponent as Middleblock } from "../assets/intake/Middleblock.svg";
import { ReactComponent as Endblock } from "../assets/intake/Endblock.svg";
import { AllIntakeEmployeeData } from './intake-builder/page-specific-components/Component-team-person-filter';
import CommonFooter from '../layout-and-styling/standardized-components-library/Common-Footer';


const PageIntakeRepresentative = () => {
    const {t} = useTranslation();

    const [identifier, setIdentifier] = useState('');
    const [allowAdvance, setAllowAdvance] = useState(false)
    const mainProcesses = JSON.parse(localStorage.getItem("businessProcessIntake"))
    const startRepData = []
    const [inputCheckPeople, setInputCheckPeople] = useState(false);
    const [publishButtonClicked, setPublishButtonClicked] = useState(false);
    const [currentProcess, setCurrentProcess] = useState(0);
    const [chosenReps, setChosenReps] = useState({'process': null,'employee': null,'employee_name': "None Selected"},
    {'process': null,'employee': null,'employee_name': "None Selected"},
    {'process': null,'employee': null,'employee_name': "None Selected"},
    {'process': null,'employee': null,'employee_name': "None Selected"},
    {'process': null,'employee': null,'employee_name': "None Selected"},
    {'process': null,'employee': null,'employee_name': "None Selected"})
    const [currentProcessName, setCurrentProcessName] = useState(mainProcesses[currentProcess].name)

    useEffect(() => {
        const axiosInstance = axios.create({withCredentials: true});
        setIdentifier('IntakeLoading');

        axiosInstance.get(process.env.REACT_APP_APIURL + '/api/auth/validatesession/')
            .catch(() => {
                console.log('error, you are not logged in yet!')
                window.location.replace(process.env.REACT_APP_APPURL + '/login');
            })
        
        axiosInstance.get(process.env.REACT_APP_APIURL + '/api/myintakeprocesses/compare_intakes')
            .then((res) => {
                if (JSON.parse(localStorage.getItem('intakeData'))[0].is_main && !res.data) {
                    axiosInstance.get(process.env.REACT_APP_APIURL + '/api/myintakeprocesses/check_own_intake')
                        .then((res) => {
                            if (res.data) {
                                const businessProcess = JSON.parse(localStorage.getItem(`businessProcessIntake`));

                                if (businessProcess !== 0 && businessProcess.length !== 0) {
                                    setIdentifier('IntakeBuilderLeader');
                                } else {
                                    setIdentifier('IntakeNotFound');
                                }
                            } else {
                                setIdentifier('IntakeBuilderLeaderPublished');
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                } else {
                    setIdentifier('IntakeNotFound');
                }
            })
            .catch((error) => {
                console.log(error);
            })
        if (!localStorage.getItem('repByProcess')){
            mainProcesses.map((process, index) => {
                startRepData.push({'process': process.name,
                                   'employee': null,
                                   'employee_name': null})
            })
            setChosenReps(startRepData)
            localStorage.setItem('repByProcess', JSON.stringify(startRepData))

        }
        else{
            const legacyData = JSON.parse(localStorage.getItem('repByProcess'))
            for (let i = 0; i < Object(AllIntakeEmployeeData).length; i++) {
                for (let j = 0; j < Object(AllIntakeEmployeeData[i]["team_members"]).length; j++) {
                    if(AllIntakeEmployeeData[i]["team_members"][j]["id"] === legacyData[0].employee){
                        AllIntakeEmployeeData[i]["team_members"][j]["checked"] = true
                    }
                }
            }
            setChosenReps(legacyData)
            let allowNext = true
            legacyData.map((process, index) => {
                if(process.employee === null){
                    allowNext =  false
                }
            })
            if(allowNext === true){
                setInputCheckPeople(true);
            }
            else{
                setInputCheckPeople(false);
            }
        }
    }, []);

    

    useWindowSize();

    const [render, setRender] = useState(false);

    const reRender = () => {
        setRender(!render);
    };

    useEffect(() => {
        if(!localStorage.getItem("i18nextLng")) {
            localStorage.setItem("i18nextLng", "en-US");
        }
    }, [])

    const [localStorageLanguage, setSelectedLanguage] = useState(JSON.parse(localStorage.getItem('Language')) ? JSON.parse(localStorage.getItem('Language')) : 0);

    const backToHome = () => {
        window.location.replace(process.env.REACT_APP_APPURL + '/home');
    }

    function goNextProcess(){
        if (currentProcess != mainProcesses.length - 1){
            let checkedEmployee = 0
            for (let i = 0; i < Object(AllIntakeEmployeeData).length; i++) {
                for (let j = 0; j < Object(AllIntakeEmployeeData[i]["team_members"]).length; j++) {
                    const repData = JSON.parse(localStorage.getItem('repByProcess'))
                    let allowedEntry = false
                    repData.map((process, index) => {
                        if(process.employee === AllIntakeEmployeeData[i]["team_members"][j]["id"] && process.process === mainProcesses[currentProcess+1].name){
                            AllIntakeEmployeeData[i]["team_members"][j]["checked"] = true
                            allowedEntry = true
                            checkedEmployee = process.employee
                        }
                        if(process.employee === null && process.process !== mainProcesses[currentProcess+1].name && !allowedEntry){
                            AllIntakeEmployeeData[i]["team_members"][j]["checked"] = false
                        }
                        if(!allowedEntry){
                            AllIntakeEmployeeData[i]["team_members"][j]["checked"] = false
                        }
                    })
                }
            }
            setCurrentProcess(currentProcess + 1)
            setCurrentProcessName(mainProcesses[currentProcess+1].name)
            var scrollElement = document.getElementById('pk' + checkedEmployee)
            if(checkedEmployee !== 0){
                scrollElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center', offset: 20 })
            }
        }
    }

    function goPreviousProcess(){
        if (currentProcess != 0){
            let checkedEmployee = 0
            for (let i = 0; i < Object(AllIntakeEmployeeData).length; i++) {
                for (let j = 0; j < Object(AllIntakeEmployeeData[i]["team_members"]).length; j++) {
                    const repData = JSON.parse(localStorage.getItem('repByProcess'))
                    let allowedEntry = false   
                    repData.map((process, index) => {     
                        if(process.employee === AllIntakeEmployeeData[i]["team_members"][j]["id"] && process.process === mainProcesses[currentProcess-1].name){
                            AllIntakeEmployeeData[i]["team_members"][j]["checked"] = true
                            allowedEntry = true
                            checkedEmployee = process.employee
                        }
                        if(process.employee === null && process.process !== mainProcesses[currentProcess-1].name && !allowedEntry){
                            AllIntakeEmployeeData[i]["team_members"][j]["checked"] = false
                        }
                        if(!allowedEntry){
                            AllIntakeEmployeeData[i]["team_members"][j]["checked"] = false
                        }
                    })
                }
            }
            setCurrentProcess(currentProcess - 1)
            setCurrentProcessName(mainProcesses[currentProcess-1].name)
            
            var scrollElement = document.getElementById('pk' + checkedEmployee)
            if(checkedEmployee !== 0){
                scrollElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center', offset: 20 })
            }
        }
    }


    
    return(
        <Container>
            {(identifier === 'IntakeLoading') ? (
                <OverlayWrapper>
                    <GifWrapper>
                        <img src={GifLoading} style={{width: '426.67px', height: '320px'}} alt=''/>
                    </GifWrapper>

                    <StyledDivBold Position='static' Width='auto' Height='auto' MarginTop='40px' MarginBottom='40px' FontSize='42px' LineHeight='52px'>
                        {t('PageIntakeBuilder.LoadingTextHeader')}
                    </StyledDivBold>
                </OverlayWrapper>
            ) : (identifier === 'IntakeNotFound') ? (
                <OverlayWrapper>
                    <img src={ImageNotFound} style={{width: 'calc(50% - 20px)', height: 'auto'}} alt=''/>  

                    <StyledDivBold Position='static' Width='auto' Height='auto' MarginTop='40px' MarginBottom='40px' FontSize='42px' LineHeight='52px'>
                        {t('PageIntakeBuilder.NotFoundTextHeader')}
                    </StyledDivBold>

                    <ButtonWrapper onClick={backToHome} Width='auto'>
                        <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' Color='#FCFCFC' FontWeight='700' LetterSpacing='0.3px'>
                            {t('PageIntakeBuilder.NotFoundOrPublishedTextButton')}
                        </StyledDivRegular>
                    </ButtonWrapper>
                </OverlayWrapper>
            ) : (identifier === 'IntakeBuilderLeaderPublished') ? (
                <OverlayWrapper>
                    <IconCheckCircleFill/>

                    <StyledDivBold Position='static' Width='auto' Height='auto' MarginTop='40px' MarginBottom='16px' FontSize='42px' LineHeight='52px'>
                        {t('PageIntakeBuilder.PublishedTextHeader')}
                    </StyledDivBold>

                    <StyledDivRegular Position='static' Width='auto' Height='auto' MarginBottom='40px' FontSize='18px' LineHeight='28px' TextAlign='center'>
                        {t('PageIntakeBuilder.PublishedBuilderLeaderTextBody')}
                    </StyledDivRegular>

                    <ButtonWrapper onClick={backToHome} Width='auto'>
                        <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' Color='#FCFCFC' FontWeight='700' LetterSpacing='0.3px'>
                            {t('PageIntakeBuilder.NotFoundOrPublishedTextButton')}
                        </StyledDivRegular>
                    </ButtonWrapper>
                </OverlayWrapper>
            ) : (
                <>
                    <HeaderWrapper>
                        <CommonHeader page='IntakeRepresentative' /* isDisabled={!inputCheckPeople} handleClick={publishRepresentative} */ set_selected_language={setSelectedLanguage} reRender={reRender}/>
                    </HeaderWrapper>

                    <Wrapper>
                        <StyledDivBold Position='static' Width='auto' Height='auto' MarginBottom='8px' FontSize='42px' LineHeight='52px'>
                            {t('PageIntakeRepresentative.TextHeader')}
                        </StyledDivBold>

                        <StyledDivBold Position='static' Width='auto' Height='auto' MarginBottom='40px' FontSize='20px' LineHeight='20px' FontWeight='700'>
                            <Trans i18nKey="PageIntakeRepresentative.TextSubHeader">
                                SELECT <span style={{color: "#E2336B"}}>ONE REPRESENTATIVE</span> PER BUSINESS PROCESS BY HOW MUCH <span style={{color: "#E2336B"}}>KNOWLEDGE</span> THEY HAVE OF THE PROCESS
                            </Trans>
                        </StyledDivBold>
                        <ProcessContainer>
                            <MainProcessContainer>
                                    {mainProcesses.map((process, index) => (
                                        index === 0 ? (
                                            <>
                                                <ProcessWrapper>
                                                    <Startblock fill={currentProcess === index ? "#E2336B" : "#271B36"}/>
                                                        <ArrowTitle>
                                                            {process.name}
                                                        </ArrowTitle>
                                                        <SelectedText>
                                                            {chosenReps[index] !== undefined || NaN ? chosenReps[index].employee_name : "None Selected"}
                                                        </SelectedText>
                                                </ProcessWrapper>
                                                
                                            </>
                                        ) : 
                                            index === mainProcesses.length - 1 ? (
                                                <ProcessWrapper>
                                                    <Endblock fill={currentProcess === index ? "#E2336B" : "#271B36"}/>
                                                        <ArrowTitle>
                                                            {process.name}
                                                        </ArrowTitle>
                                                        <SelectedText>
                                                            {chosenReps[index] !== undefined ? chosenReps[index].employee_name : "None Selected"}
                                                        </SelectedText>
                                                </ProcessWrapper>
                                            )
                                        : (
                                            <ProcessWrapper>
                                                <Middleblock fill={currentProcess === index ? "#E2336B" : "#271B36"}/>
                                                    <ArrowTitle>
                                                        {process.name}
                                                    </ArrowTitle>
                                                    <SelectedText>
                                                            {chosenReps[index] !== undefined ? chosenReps[index].employee_name : "None Selected"}
                                                    </SelectedText>
                                            </ProcessWrapper>
                                        )
                                    ))}
                                    
                            </MainProcessContainer>
                        </ProcessContainer>
                        <PersonContainer>
                            <ComponentTeamPersonFilter whichFilter="IntakeRepresentatives" MinWidth='840px' MaxWidth='100%' Height='100%' MaxHeight='100%' Margin='0' BorderRadius='8px'
                                                       BoxShadow='0px 0px 60px 0px rgba(0, 0, 0, 0.05)' ZIndex='0' MaxHeightMedia='100%' inputMarginTop="16px" inputMarginLeft="30px" 
                                                       inputCheckPeople={inputCheckPeople} nextButtonClicked={publishButtonClicked} set_inputCheckPeople={setInputCheckPeople} setChosenReps={setChosenReps} chosenReps={chosenReps}
                                                       incompleteText={t('PageIntakeRepresentative.TextError')} set_selected_language={setSelectedLanguage} reRender={reRender} currentProcessName={currentProcessName}/>
                        </PersonContainer>
                        <ButtonsWrapper>
                            <BackButtonWrapper onClick={goPreviousProcess} tempProcesses={0} onFirstProcess={currentProcess == 0}>
                                {currentProcess == 0 ? (
                                    <BackGreyButtonSimple/>
                                ) : (
                                    <BackPinkButtonSimple/>
                                )
                            }
                            </BackButtonWrapper>
                            <NextButtonWrapper onClick={goNextProcess} onLastProcess={currentProcess == mainProcesses.length  - 1}>
                                {currentProcess == mainProcesses.length  - 1 ? (
                                    <NextGreyButtonEN/>
                                ) :(
                                    <NextPinkButtonEN/>
                                )}
                            </NextButtonWrapper>
                        </ButtonsWrapper>
                    </Wrapper>

                    <CommonFooter page='PageIntakeRepresentative' prevdirectory='/intake-builder' isDisabled={!inputCheckPeople} handleClick={SendRepresentativeIntake} setNextButtonClicked={setPublishButtonClicked} Position='static' 
                                     MarginTop='0' BackMarginLeft='0' BackWidth="56px" BackHeight="56px" MarginLeft='0' Width='160px' Height='56px'/>
                </>
            )}
        </Container>
    )
}

export default PageIntakeRepresentative;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f6f7fa;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    pointer-events: none;
    -drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: calc(1 / 1.25);
  }

  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: calc(1 / 1.5);
  }

  @media (-webkit-device-pixel-ratio: 1.75) {
    zoom: calc(1 / 1.75);
  }
`;

const OverlayWrapper = styled.div`
  display: flex;
  width: calc(100% - 80px);
  max-width: 1278px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
`;

const GifWrapper = styled.div`
    display: flex;
    width: 250px;
    height: 125px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: ${props => props.Width || 'calc(100% - 20px)'};
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${props => props.Color || '#E2336B'};
  transition: filter 0.15s;
  cursor: pointer;
  user-select: none;

  &:hover {
    filter: brightness(90%);
  }
`;

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1290px;
  flex-direction: column;
  align-items: flex-start;
  padding: 108px 40px 145px 40px;
`;

const MainProcessContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

const ProcessContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 10vh;
  margin-bottom: 50px;
  margin-left: -6px;
`;

const PersonContainer = styled.div`
  display: flex;
  height: calc(100vh - 316px);
  max-height: 754px;
  width: 100%;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 24px;
  button {
    background: none;
    border: none;
  }
`;

const BackButtonWrapper = styled.button`
    cursor: ${props => props.onFirstProcess ? 'default': 'pointer'};
`;

const NextButtonWrapper = styled.button`
    cursor: ${props => props.onLastProcess ? 'default': 'pointer'};    
`;

const ArrowTitle = styled.div`
    margin-left: 39px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 115px;
    height: 80px;
    max-width: 138px;
    max-height: 80px;
    color: var(--primary-color-white, #FCFCFC);
    font-family: Overpass;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    overflow: hidden;
    position: absolute;
`;

const ProcessWrapper = styled.div`
    display: flex;
`;

const SelectedText = styled.p`
    position: absolute;
    margin-left: 10px;
    margin-top: 92px;
    font-family: Overpass;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;