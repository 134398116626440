import React, {useContext, useState, useEffect,useRef} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {OverlayContext} from "../../../layout-and-styling/context-hooks/OverlayContext";
import Toolbox from './Toolbox';
import SocialNetworkRenderComponent from './RenderComponent';
import load from "../../../assets/dashboard/load.gif";
import { StyledCheckbox, StyledDivButton, StyledDivRegular } from '../../../layout-and-styling/standardized-components-library/Styling-TextInput';
import { DeveloperComparisonPage } from '../../api-calls/api-calls';
import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';

export var ComparisonVis = [];

const ComparisonPage = () => {
    const context = useContext(OverlayContext);
    const {t, i18n} = useTranslation();

    const currentUser = JSON.parse(localStorage.getItem("current_user_data"))[0];
    const [selectedCompany, setSelectedCompany] = useState(JSON.parse(localStorage.getItem("selected_company")));
    const [selectedSurvey, setSelectedSurvey] = useState(JSON.parse(localStorage.getItem("selected_survey")));  

    const [comparisonData, setComparisonData] = useState(JSON.parse(localStorage.getItem("comparison_data")) && JSON.parse(localStorage.getItem("comparison_data")).latest_survey_data.survey == selectedSurvey ? JSON.parse(localStorage.getItem("comparison_data")) : null);
    
    // default sizes and zooms of the networks of the different surveys, after render they are recalculated automatically
    // every social network has different dimensions, which are calculated in the file SocialNetwork-render-component in the useEffect when props.graphData changes
    // these are the dimensions obtained there. On reload of the page these sizes are automatically assigned but at the first time it does not, that why we have to add these values here
    // TODO: make it automated also for the first load of the page 
    // Zoom of the 2 surveys
    const [zoom1, setZoom1] = useState(
      selectedSurvey == 2 ? 0.56
      : selectedSurvey == 3 ? 0.55
      : selectedSurvey == 5 ? 0.55
      : selectedSurvey == 8 ? 0.52
      : selectedSurvey == 9 ? 0.56
      : 1);
    const [zoom2, setZoom2] = useState(
      selectedSurvey == 2 ? 0.62
      : selectedSurvey == 3 ? 0.40
      : selectedSurvey == 5 ? 0.40
      : selectedSurvey == 8 ? 0.52
      : selectedSurvey == 9 ? 0.62
      : 1);

    // size of the survey 1
    const [graphWidth1, setGraphWidth1] = useState(
      selectedSurvey == 2 ? 3286
      : selectedSurvey == 3 ? 2782
      : selectedSurvey == 5 ? 2782
      : selectedSurvey == 8 ? 2916
      : selectedSurvey == 9 ? 3286
      : 0);
    const [graphHeight1, setGraphHeight1] = useState(
      selectedSurvey == 2 ? 3286
      : selectedSurvey == 3 ? 2782
      : selectedSurvey == 5 ? 2782
      : selectedSurvey == 8 ? 2916
      : selectedSurvey == 9 ? 3286 
      : 0);

    // size of the survey 2
    const [graphWidth2, setGraphWidth2] = useState(
      selectedSurvey == 2 ? 2465
      : selectedSurvey == 3 ? 3770
      : selectedSurvey == 5 ? 3770 
      : selectedSurvey == 8 ? 2916
      : selectedSurvey == 9 ? 2465
      : 0);
    const [graphHeight2, setGraphHeight2] = useState(
      selectedSurvey == 2 ? 2465
      : selectedSurvey == 3 ? 3770
      : selectedSurvey == 5 ? 3770
      : selectedSurvey == 8 ? 2916
      : selectedSurvey == 9 ? 2465
      : 0);


    const [legendExpanded, expandLegend] = React.useState(false);
    const [allTeams, setAllTeams] = useState(JSON.parse(localStorage.getItem("all_teams")) ? JSON.parse(localStorage.getItem("all_teams")) : []);
    const [checkedTeams, setCheckedTeams] = useState(JSON.parse(localStorage.getItem("checked_teams")) ? JSON.parse(localStorage.getItem("checked_teams")) : []);
    const [render, setRender] = useState(true);
    const reRender = () => {
      setRender(!render);
    };

    // Function that make the api call to get the data if necessary
    async function loadData() {
      if (currentUser.is_developer) {
        if(! comparisonData) await DeveloperComparisonPage(selectedCompany.id, selectedSurvey);
      }
    }

    /** Adding a listener that stores the data in the local storage if its not there before */
    useEffect(() => {

      loadData()

      window.addEventListener('comparison_data_event', () => {
        setComparisonData(JSON.parse(localStorage.getItem("comparison_data")));
        let all_teams = JSON.parse(localStorage.getItem("comparison_data")).teams
        let teams = []
        for (const [team, team_ids] of Object.entries(all_teams)) {
          teams.push(context.setTeams(team, i18n.language))
        }
        setCheckedTeams(teams)
        setAllTeams(teams)
        localStorage.setItem('all_teams', JSON.stringify(teams))
        localStorage.setItem('checked_teams', JSON.stringify(teams))
      })

    }, [])

    // function to manage the selected teams (actioned when checkbox is clicked)
    const handleNewCheckboxChange = (team) => {  
      let new_teams
      
      // different options
      if(team == "all") {
        if(checkedTeams.length == allTeams.length) {
          new_teams = []
        }   
        else{
          new_teams = allTeams
        }
      } else {
        new_teams = checkedTeams 
        if(new_teams.includes(team))
          new_teams.splice(new_teams.indexOf(team), 1)
        else
          new_teams.push(team)
      }

      // storing new checked teams
      setCheckedTeams(new_teams)
      localStorage.setItem('checked_teams', JSON.stringify(new_teams))
      reRender()

      let ids = []
      for (const [team, team_ids] of Object.entries(comparisonData.teams)) {
        if(new_teams.includes(context.setTeams(team, i18n.language)))
          ids.push.apply(ids, team_ids)
      }

      // customer and supplier (in this case avocado 2 ones)
      if(new_teams.length == allTeams.length) {
        ids.push.apply(ids, comparisonData.outside_teams)
      }

      // calling the functions in the SocialNetwork.js to highlight the nodes
      ComparisonVis[0].highlightSelectedTeams(ids)
      ComparisonVis[1].highlightSelectedTeams(ids)
  }   

  // handler to control the legend accordion
  const handleAccordions = (panel) => (event, isExpanded) => {
    if(panel === 'panel1') expandLegend(isExpanded ? panel : false);
  };

  return (
    <Container>

      <Toolbox Page="Comparison" JustifyContent={"center"} LeftScore="Collaboration previous survey" RightScore="Collaboration actual survey"/> 

      <TopWrapper>
        {!comparisonData ? (
          <LoadLogo>
            {" "}
            <img src={load} alt="loading..."/>
          </LoadLogo>
        ) : (
          // Left social network
          <NetworkWrapper>
              <SocialNetworkRenderComponent id="SocialNetworkRenderComponent" graphData={comparisonData.previous_survey_data} Zoom={zoom1} setZoom={setZoom1} graphWidth={graphWidth1} setGraphWidth={setGraphWidth1}
                        graphHeight={graphHeight1} setGraphHeight={setGraphHeight1} Page="Comparison" Iteration="before" language={i18n.language} />
          </NetworkWrapper>
        )}

        <MiddleLine/>
        
        {!comparisonData ? (
          <LoadLogo>
            {" "}
            <img src={load} alt="loading..."/>
          </LoadLogo>
        ) : (
          // Right social network
          <NetworkWrapper>
            <SocialNetworkRenderComponent id="SocialNetworkRenderComponent" graphData={comparisonData.latest_survey_data} Zoom={zoom2} setZoom={setZoom2} graphWidth={graphWidth2} setGraphWidth={setGraphWidth2}
                        graphHeight={graphHeight2} setGraphHeight={setGraphHeight2} Page="Comparison" Iteration="after" language={i18n.language} />
          </NetworkWrapper> 
        )}

      </TopWrapper>

      {!comparisonData ? (
          <LoadLogo>
            {" "}
            <img src={load} alt="loading..."/>
          </LoadLogo>
      ) : (
        <>
        <LegendZoomWrapper>
            <ScaleColorWrapper>
                {t('DashboardSocialNetwork.Low')}
                <Color MarginLeft="12px" Color="#B3194A"/>
                <Color Color="#F72B2B"/>
                <Color Color="#FFBC1F"/>
                <Color Color="#89F066"/>
                <Color MarginRight="12px" Color="#559B3E"/>
                {t('DashboardSocialNetwork.High')}
            </ScaleColorWrapper>

          </LegendZoomWrapper>
      
          {/* // Team legend */}
          <TeamsLegendWrapper Height="fit-content" Width="fit-content">
            <Accordion expanded={legendExpanded === 'panel1'} onChange={handleAccordions('panel1')}
                    sx={{ width: "fit-content", borderTopRightRadius: 5, borderTopLeftRadius: 5, justifyContent: 'center' }}>
                <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header"
                    sx={{ color: '#292929', fontSize: 16, fontFamily: 'Overpass', fontStyle: 'normal', fontWeight: 700,
                        '& .MuiAccordionSummary-content': {
                            justifyContent: 'center',
                        },
                        '& .MuiAccordionSummary-root.Mui-expanded': {
                            height: '32px',
                        }
                    }}>
                    {t('DashboardSocialNetwork.TeamsLegend')}
                </AccordionSummary>
                <AccordionDetails sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} >      
                  <TeamFiltersWrapper Language= {i18n.language} reRender={reRender}>
                    {Object.entries(comparisonData.teams).map(([team, ids]) => {
                      return(
                        <StyledDivRegular Position="relative" Display="flex" MarginLeft="0px" Width="fit-content" MarginBottom="0px" Gap="4px" FontFamily="Overpass" FontSize="14px" FontWeight="400">
                          <StyledCheckbox value={team} type="checkbox" FontFamily="Overpass" FontSize="14px" FontWeight="400" 
                            checked={checkedTeams.includes(context.setTeams(team, i18n.language))} 
                            onChange={() => handleNewCheckboxChange(context.setTeams(team, i18n.language))}/>
                          <StyledDivRegular Position="relative" Display="flex" Width="fit-content" Height="fit-content" FontFamily="Overpass" FontSize="14px" FontWeight="400">{context.setTeams(team, i18n.language)}</StyledDivRegular>
                        </StyledDivRegular>
                      )
                    })}
                    {/* select all button */}
                    <StyledDivButton MarginTop='0px' MarginLeft='0px' Width='82px' Height='32px' BoxShadow='0px 2px 4px rgba(0, 0, 0, 0.25)' 
                                    Padding='8px 4px 8px 4px' FontSize='16px' Display='flex' JustifyContent='center' Position="relative" BackgroundColor={'#FCFCFC'}
                                    Border={'0px solid #271B36'} Color={'#271B36'}>

                        <button onClick={() => handleNewCheckboxChange("all")}>
                            {"Select all"}
                        </button>
                    </StyledDivButton>
                  </TeamFiltersWrapper>
                </AccordionDetails>
            </Accordion>
          </TeamsLegendWrapper>
        </>
      )}
        
    </Container>

  )
}

export default ComparisonPage

const Container = styled.div`
  font-family: 'Overpass', sans-serif;
  width: calc(100%);
  height: calc(100%);
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

const TopWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  height: calc(100%);
  width: calc(100%);
`;

const MiddleLine = styled.div`
  position: relative;
  display: flex;
  margin-top: calc(2%);
  height: calc(80%);
  border-left: 2px solid #C6CBCB;
  z-index: 2;
`;

const NetworkWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100%);
  width: calc(100%);
  z-index: 1;
`;

const LoadLogo = styled.div`
  img {
    width: 50px;
  }

  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
`;


const LegendZoomWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  bottom: 0;
  right: 0;
  margin-bottom: 20px;
  margin-right: 20px;
  align-items: flex-end;
  gap: 16px;
`;

const ScaleColorWrapper = styled.div.attrs({
    className: "ColorScale",
})`
  display: none;
  background: #FCFCFC;
  flex-direction: row;
  font-family: "Overpass";
  color: #271B36;
  width: 224px;
  height: 24px;
  align-items: center;
  justify-content: center;
  z-index: 3;
  padding: 8px 4px;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;

const Color = styled.div`
  display: flex;
  flex-direction: row;
  width: 24px;
  height: 24px;
  margin-left: ${props => props.MarginLeft || "0px"};
  margin-right: ${props => props.MarginRight || "0px"};
  align-items: center;
  justify-content: center;
  background-color: ${props => props.Color};
  opacity: 0.6;
  z-index: 4;
`;

const TeamsLegendWrapper = styled.div`
  display: flex;
  z-index: 1;
  width: ${(props) => props.Width || "1199px"};
  height: ${(props) => props.Height || "126px"};
  position: fixed;
  justify-content: center;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #FCFCFC;
`;

const TeamFiltersWrapper = styled.div`
  position: relative;
  width: fit-content;
  height: 54px;
  /* max-width: 1024px; */
  margin-top: 0px;
  margin-left: 0px;
  background-color: #FCFCFC;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  language: ${(props) => props.Language || "20px"};
  overflow-y: hidden;
  overflow-x: auto;

  ::-webkit-scrollbar {
    height: 8px;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #929699;
    border-radius: 5px;
  }
`;