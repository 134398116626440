import styled from 'styled-components';
import { StyledDivRegular } from './Styling-TextInput';
import { Trans, useTranslation } from "react-i18next";

// This file contains all the explanatory tooltips used in the intake pages and its introductions

export const ComponentTooltipProcessTime = (props) => {

    if (props.whichTooltip === "Tooltip1") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-615px" MarginTop="-98px">
                <ExplanationBoxContainer Width='565px' Height="fit-content" ZIndex='4'
                    Position="absolute">

                    <IntroExplanationBox Width='565px' Height="fit-content"
                        Position="static">
                        <StyledDivRegular Position="static" MarginTop='24px' MarginBottom='24px' MarginLeft='24px' MarginRight='24px'
                            Width='517px' Height="fit-content" FontSize='18px' LineHeight='28px'
                            TextAlign="left">
                            <Trans i18nKey="IntakeProcessTime.TooltipProcessTime1">
                                Judge the total amount of <b>time spent starting from first task to last task</b> carried out in this process. You can view the steps when hovering over the process block.
                            </Trans>
                            <br></br> 
                            <br></br>
                            <Trans i18nKey="IntakeProcessTime.TooltipProcessTime2">
                                Do not<b>Do not</b> evaluate your personal time spent, but that of the entire company! E.g. it could take around 2 months for your company to complete the process “Market research” for a project, starting from the first person beginning to work on this until the last person finishing their task in this process.
                            </Trans>
                            <br></br> 
                            <br></br>
                            <Trans i18nKey="IntakeProcessTime.TooltipProcessTime3">
                                SHORT<b>SHORT</b> = Working through the processes sub-steps is quick and without delays. This could happen when either the workload for this run-through is low and/or no problems occurred during execution.
                            </Trans>
                            <br></br>
                            <br></br>
                            <Trans i18nKey="IntakeProcessTime.TooltipProcessTime4">
                                LONG<b>LONG</b> = Working through the processes sub-steps is more time consuming. This could happen when either the workload is particular high for this run-through and/or problems and repeats of sub-steps were necessary causing a delay.
                            </Trans>
                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='60px' Left='569px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='60px' Left='566px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />

                </ExplanationBoxContainer>

            </IntroOverlayBoxWrapper>
        )
    }
}

export const ComponentTooltipRepresentativeChoice = (props) => {
    const { i18n } = useTranslation();

    if (props.whichTooltip === "RepresentativeChooser") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-620px" MarginTop="-90px">
                <ExplanationBoxContainer Width='565px' Height="fit-content" ZIndex='4'
                    Position="absolute">

                    <IntroExplanationBox Width='565px' Height="fit-content"
                        Position="static">
                        <StyledDivRegular Position="static" MarginTop='24px' MarginBottom='24px' MarginLeft='24px' MarginRight='24px'
                            Width='517px' Height="fit-content" FontSize='18px' LineHeight='28px'
                            TextAlign="left">
                            <Trans i18nKey="IntakeRepresentativeChoice.ExplanationBoxChooser1">
                                Now that you've created the first version of your business' process structure, we'd like some opinions from other people in the company about what you've created. They can make changes as they see fit and once all of them are done, one final meeting is held where the <b>final version</b> is made.
                            </Trans>
                            <br></br>
                            <br></br>
                            <Trans i18nKey="IntakeRepresentativeChoice.ExplanationBoxChooser2">
                                Please select one person per team <b>(if applicable)</b> to fill in the business' process structure. Consider who you think would know the most about the company and its processes. Once you've made your selection, press the <b>'Next'</b> button.
                            </Trans>
                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='60px' Left='569px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='60px' Left='566px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />

                </ExplanationBoxContainer>

            </IntroOverlayBoxWrapper>
        )
    }
}

const IntroOverlayBoxWrapper = styled.div`
  position: ${props => props.Position || "fixed"};
  margin-top: ${props => props.MarginTop || "0px"};
  margin-right: ${props => props.MarginRight || "0px"};
  margin-left: ${props => props.MarginLeft || "0px"};
  margin-bottom: ${props => props.MarginBottom || "0px"};
  transition: 2s ease-out 100ms;
  left: 50%;
  top: 100%;
  z-index: 4;
  transform: translate(-50%, 0.75rem);
`;

const ExplanationBoxContainer = styled.div`
  position: ${props => props.Position || "fixed"};
  top: ${props => props.Top || "0px"};
  left: ${props => props.Left || "0px"};
  min-width: ${props => props.Width || "550px"};
  height: ${props => props.Height || "300px"};
  display: ${props => props.Display || ""};
  z-index: ${props => props.ZIndex || "3"};
`;

const IntroExplanationBox = styled.div`
  position: ${props => props.Position || "absolute"};
  top: ${props => props.Top || "258px"};
  left: ${props => props.Left || "1000px"};
  width: ${props => props.Width || "540px"};
  height: ${props => props.Height || "250px"};
  background: #FCFCFC;
  z-index: ${props => props.ZIndex || "3"};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: ${props => props.FontFamily || 'Overpass'};
  font-size: ${props => props.FontSize || '18px'};
  line-height: ${props => props.LineHeight || '28px'};
  font-weight: ${props => props.FontWeight || '400'};
  border: 2px solid #E2336B;
  border-radius: 10px;
`;

const IntroExplanationOuterTriangle = styled.div`
  position: absolute;
  top: ${props => props.Top || "288px"};
  left: ${props => props.Left || "970px"};
  width: 0;
  height: 0;
  border-top: ${props => props.BorderTop || "20px solid transparent"};
  border-right: ${props => props.BorderRight || "30px solid #E2336B"};
  border-bottom: ${props => props.BorderBottom || "20px solid transparent"};
  border-left: ${props => props.BorderLeft || ""};
  z-index: ${props => props.ZIndex || "3"};
  transform: ${props => props.Rotate || ""};
`;

const IntroExplanationInnerTriangle = styled.div`
  position: absolute;
  top: ${props => props.Top || "288px"};
  left: ${props => props.Left || "973px"};
  width: 0;
  height: 0;
  border-top: ${props => props.BorderTop || "20px solid transparent"};
  border-right: ${props => props.BorderRight || "30px solid #FCFCFC"};
  border-bottom: ${props => props.BorderBottom || "20px solid transparent"};
  border-left: ${props => props.BorderLeft || ""};
  z-index: ${props => props.ZIndex || "3"};
  transform: ${props => props.Rotate || ""};
`;
