import React, { useEffect, useState, useContext} from 'react';
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import Fade from '@mui/material/Fade';
import {SubProcessBlock} from '../layout-and-styling/standardized-components-library/Styling-ProcessBlocks';
import { ReactComponent as Startblock } from "../assets/intake/Startblock.svg";
import { ReactComponent as Middleblock } from "../assets/intake/Middleblock.svg";
import { ReactComponent as Endblock } from "../assets/intake/Endblock.svg";


const ProcessData = JSON.parse(localStorage.getItem('businessProcessIntake'));

const MappedSubProcesses = ({ 
    current_process_object,
    current_process_id,
    containerRef
}) => {
const [hover, setHover] = useState(false)
const {t, i18n} = useTranslation();

return (
    <>
        {current_process_id === 0 &&
            <>
                <ProcessBlockWrapper>
                    <Startblock onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)} fill={hover ? "#E2336B" : "#271B36"} ref={containerRef}/>

                        <ArrowTitle id={current_process_object.name} onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
                            {current_process_object.name}
                        </ArrowTitle>
                           
                    <Fade in={hover} timeout={600} container={containerRef.current}>
                        <HoverTooltip>
                            {current_process_object.subProcesses.map((subprocess, subprocess_index) => {
                                if (subprocess_index === current_process_object.subProcesses.length - 1) {
                                    return (
                                        <>
                                            <SubProcessColumnWrapper>
                                                <SubProcessBlock MarginRight='16px'>
                                                    <SubProcessText>
                                                        {subprocess.name}
                                                    </SubProcessText>
                                                </SubProcessBlock>
                                            </SubProcessColumnWrapper>
                                            
                                            <BreakColumn/>
                                        </>
                                    )
                                } else {
                                    return (
                                        <SubProcessColumnWrapper>
                                            <SubProcessBlock MarginRight='16px'>
                                                <SubProcessText>
                                                    {subprocess.name} 
                                                </SubProcessText>
                                            </SubProcessBlock>
                                        </SubProcessColumnWrapper>
                                    )
                                }
                            })}
                        </HoverTooltip>
                    </Fade>
                </ProcessBlockWrapper>
            </>
        }

        {current_process_id === ProcessData.length - 1 &&
            <>
                <ProcessBlockWrapper>
                    <Endblock onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)} fill={hover ? "#E2336B" : "#271B36"}  ref={containerRef}/>

                    <ArrowTitle id={current_process_object.name} onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
                        {current_process_object.name} 
                    </ArrowTitle>
                   
                    <Fade in={hover} timeout={600} container={containerRef.current}>
                        <HoverTooltip>
                            {current_process_object.subProcesses.map((subprocess, subprocess_index) => {
                                if (subprocess_index === current_process_object.subProcesses.length - 1) {
                                    return (
                                        <>
                                            <SubProcessColumnWrapper>
                                                <SubProcessBlock MarginRight='16px'>
                                                    <SubProcessText>
                                                        {subprocess.name} 
                                                    </SubProcessText>
                                                </SubProcessBlock>
                                            </SubProcessColumnWrapper>

                                            <BreakColumn/>
                                        </>
                                    )
                                } else {
                                    return (
                                        <SubProcessColumnWrapper>
                                            <SubProcessBlock MarginRight='16px'>
                                                <SubProcessText>
                                                    {subprocess.name} 
                                                </SubProcessText>
                                            </SubProcessBlock>
                                        </SubProcessColumnWrapper>
                                    )
                                }})
                            }
                        </HoverTooltip>
                    </Fade>
                </ProcessBlockWrapper>
            </>
        }

        {current_process_id !== 0 && current_process_id !== ProcessData.length - 1 &&
            <>  
                <ProcessBlockWrapper>
                    <Middleblock onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)} fill={hover ? "#E2336B" : "#271B36"}  ref={containerRef}/>

                        <ArrowTitle id={current_process_object.name} onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
                            {current_process_object.name} 
                        </ArrowTitle>

                    <Fade in={hover} timeout={600} container={containerRef.current}>
                        <HoverTooltip>
                            <SubProcessWrapper>
                                {current_process_object.subProcesses.map((subprocess, subprocess_index) => {
                                    if (subprocess_index === current_process_object.subProcesses.length - 1) {
                                        return (
                                            <>
                                                <SubProcessColumnWrapper>
                                                    <SubProcessBlock MarginRight='16px'>
                                                        <SubProcessText>
                                                            {subprocess.name} 
                                                        </SubProcessText>
                                                    </SubProcessBlock>
                                                </SubProcessColumnWrapper>

                                                <BreakColumn/>
                                            </>
                                        )
                                    } else {
                                        return (
                                            <SubProcessColumnWrapper>
                                                <SubProcessBlock MarginRight='16px'>
                                                    <SubProcessText>
                                                        {subprocess.name}
                                                    </SubProcessText>
                                                </SubProcessBlock>
                                            </SubProcessColumnWrapper>
                                        )
                                    }
                                })}
                            </SubProcessWrapper>
                        </HoverTooltip>
                    </Fade>
                </ProcessBlockWrapper>
            </>
        }
    </>
)}

export default MappedSubProcesses;

const ArrowTitle = styled.div`
  margin-left: 40px;
  margin-top: -80px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 115px;
  height: 80px;
  max-width: 138px;
  max-height: 80px;
  color: var(--primary-color-white, #FCFCFC);
  font-family: Overpass;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  overflow: hidden;
  position: absolute;
`;

const HoverTooltip = styled.div`
  position: absolute;
  padding: 0 0 10px 20px;
  top: 312px;
  z-index: 3;
  background: #FCFCFC;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

const SubProcessColumnWrapper = styled.div`
`;

const SubProcessWrapper = styled.div`
`;
const SubProcessText = styled.p`
  margin: 0;
  padding: 3px;
  width: 115px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-family: 'Overpass';
  font-weight: ${props => props.FontWeight || "400"};
  font-size: ${props => props.FontSize || "14px"};
  line-height: 18px;
  overflow: hidden;
  color: var(--primary-color-dark-purple, #271B36);
  text-align: center;
  font-family: Overpass;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
`;
const BreakColumn = styled.div`
  flex-basis: ${props => props.FlexBasis || "100%"};
`;

const ProcessBlockWrapper = styled.div`
    flex-direction: row;
    width: 215px;
    height: 80px;
    cursor: pointer;
`;


