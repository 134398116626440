import React, {useEffect, useState, useContext, useRef} from "react";
import styled from 'styled-components';

import {useTranslation} from 'react-i18next';
import {ReactComponent as EITRLogoPink} from "../../assets/header/Icon_eitr2_logo.svg";
import {Checkbox} from '@mui/material';
import {ReactComponent as HeaderIconSettings} from "../common-assets/Icon_settings.svg";
import {ReactComponent as HeaderIconSettingsActive} from "../common-assets/Icon_settings_active.svg";
import ComponentLanguageSwitcher from "../../layout-and-styling/standardized-components-library/Component-language-switcher";
import {ReactComponent as Logout} from "../../assets/dashboard/logout.svg";
import { StyledDivRegular } from "../../layout-and-styling/standardized-components-library/Styling-TextInput";
import SurveySocialNetworkOverlay from "./Survey-social-network-overlay";
import { OverlayContext } from "../../layout-and-styling/context-hooks/OverlayContext";


function SurveySocialNetworkHeader(props) {
    const { t } = useTranslation();

    /** Component to load in different header styling, combined with an introduction overlay */

    /** Overlay dock state, with big picture introduction */
    const [dockstatus, dockVisible] = useState(true);
    const localStorageLanguage = JSON.parse(localStorage.getItem("Language")) ? JSON.parse(localStorage.getItem('Language')) : 'en-US';
    const [currentLanguage, setCurrentLanguage] = useState('en-US');
    const [isOpen, setIsOpen] = useState(false);
    const currentUser = JSON.parse(localStorage.getItem("current_user_data"))[0];
    const context = useContext(OverlayContext);
    

    useEffect(() => {
        if (props.hasOwnProperty('set_selected_language')) {
            setCurrentLanguage(props.set_selected_language(localStorageLanguage));
        }
    }, [currentLanguage]);

    const [isHowToUseOpen, setIsHowToUseOpen] = useState(false);
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const howToUseRef = useRef(null);
    const profileRef = useRef(null);

    useEffect(() => {
        const closeDropdowns = (e) => {
            if (howToUseRef.current && !howToUseRef.current.contains(e.target) && profileRef.current && !profileRef.current.contains(e.target)) {
                setIsHowToUseOpen(false);
                setIsProfileOpen(false);
            } else if (howToUseRef.current && !howToUseRef.current.contains(e.target)) {
                setIsHowToUseOpen(false);
            } else if (profileRef.current && !profileRef.current.contains(e.target)) {
                setIsProfileOpen(false);
            }
        };

        document.addEventListener('click', closeDropdowns);
        
        return () => {
          document.removeEventListener('click', closeDropdowns);
        };
        
    }, []);


    return (
        <HeaderContainer>

            {/* <SurveyHeaderIconHelp MarginLeft={MarginLeftHelpIcon}>
                <button onClick={() => handleDock()}>
                    <HeaderIconHelp2/>
                </button>
            </SurveyHeaderIconHelp> */}

            <HeaderLogoWrapper
                onClick={() => window.location.replace(process.env.REACT_APP_APPURL + '/home')}>
                <EITRLogoPink/>
            </HeaderLogoWrapper>

            <CheckboxWrapper MarginTop='20px' MarginLeft='90%' Width="fit-content">

                <ComponentLanguageSwitcher reRender={props.reRender} MarginLeft="0%" MarginRight="24px"
                                        set_language_switcher={setCurrentLanguage}/>

                <Checkbox style={{fontSize: 35}}
                        icon={<HeaderIconSettings/>}
                        checkedIcon={<HeaderIconSettingsActive/>}
                        onClick={() => setIsOpen(!isOpen)}/>

                {isOpen && 
                (<DropdownContent>
                    <button onClick={() => window.location.replace(process.env.REACT_APP_APPURL + '/logout')}>

                        <Logout/>
                        <p>
                            {t('Logout.LogoutButton')}
                        </p>

                    </button>
                </DropdownContent>)}

            </CheckboxWrapper>
            
            <CheckboxWrapper MarginTop='6px' MarginLeft='93.100%' Height='50px' Width='50px'>
                <PersonPic>
                    <StyledDivRegular Position="absolute" BorderRadius="50%" Width="36px" Height="36px" Background="#D0E1F1"
                        Color="#271B36" Display="flex" AlignItems="center" JustifyContent="center"
                    >
                        {context.setFirstName(currentUser.first_name, 0).charAt(0)
                            + context.setFirstName(currentUser.last_name, 0).split(" ").filter((str) => str !== '').pop().charAt(0)}
                    </StyledDivRegular>
                </PersonPic>
            </CheckboxWrapper>

            <SurveyDockWrapper>
                <SurveySocialNetworkOverlay dock={dockstatus} setDock={dockVisible} page={props.page} />
            </SurveyDockWrapper>

        </HeaderContainer>
    );

};
export default SurveySocialNetworkHeader

const HeaderContainer = styled.div`
  position: fixed;
  display: flex;
  width: ${props => props.Width || "100%"};
  height: ${props => props.Height || "64px"};
  padding: ${props => props.Padding || ''};
  justify-content: ${props => props.JustifyContent || ''};
  align-items: ${props => props.AlignItems || ''};
  border-bottom: ${props => props.BorderBottom || "1px solid #E3E5E4"};
  background-color: #FCFCFC;
  box-shadow: ${props => props.BoxShadow || ''};
  user-select: none;
  z-index: 3;
`;

export const HeaderLogoWrapper = styled.div`
  position: ${props => props.Position || 'fixed'};
  display: ${props => props.Display || ''};
  margin-top: ${props => props.MarginTop || '8px'};
  margin-left: ${props => props.MarginLeft || '3.6458%'};
  cursor: pointer;
`;

const CheckboxWrapper = styled.div`
  position: fixed;
  cursor: pointer;
  margin-top: ${props => props.MarginTop || "0%"};
  margin-left: ${props => props.MarginLeft || "0%"};
  width: ${props => props.Width || "24px"};
  height: ${props => props.Height || "24px"};
  color: ${props => props.inputColor || 'transparent'};
  background-color: #fcfcfc;
  border-color: ${props => props.BorderColor || 'transparent'}; //1px solid #E3E5E4 
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
`;

const DropdownContent = styled.div`
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  top: 45px;

  button {
    padding-left: 10px;
    height: 48px;
    border: none;
    background: #f9f9f9;
    color: ${props => props.Color || '#5F6366'};
    border-radius: 10px;
    cursor: pointer;
    font-family: ${props => props.FontFamily || 'Overpass'};
    font-size: ${props => props.FontSize || '16px'};
    line-height: ${props => props.LineHeight || '20px'};
    font-weight: ${props => props.FontWeight || '400'};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: -webkit-fill-available;
    column-gap: 15px;
  }
`;

export const LanguageSwitcher = styled.div`
  margin-left: 100%;
  position: relative;
  height: 50px;
`;

export const SurveyHeaderText = styled.div`
  position: fixed;
  margin-top: ${props => props.MarginTop || "28px"};
  margin-left: ${props => props.MarginLeft || "890px"};
  font-family: 'Overpass', sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #271B36;
  line-height: 36px;
  z-index: 2;
`;

export const SurveyHeaderIconHelp = styled.div`
  position: fixed;
  margin-top: ${props => props.MarginTop || "18px "};
  margin-left: 87.4%;
  transform: scale(1.5);
  z-index: 9;

  button {
    border: none;
    background-color: transparent;
    border-radius: 50%;
    height: 28px;
    width: 28px;
    cursor: ${props => props.Cursor || "pointer"};
    display: flex;
    align-content: center;
    justify-content: center;
  }
`;

export const SurveyHeaderTextHelp = styled.div`
  position: absolute;
  margin-top: ${props => props.MarginTop || "22px"};
  margin-left: 89%;
  display: flex;
  align-content: center;
  justify-content: center;
  font-family: 'Overpass', sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  z-index: 9;

  button {
    border: none;
    background-color: transparent;
    color: #e2336B;
    height: 24px;
    width: 86px;
    cursor: ${props => props.Cursor || "pointer"};
    text-decoration: underline;
    display: flex;
    align-content: center;
    justify-content: center;
  }

`;

const SurveyDockWrapper = styled.div`
  background-color: #FCFCFC;
  font-family: 'Overpass', sans-serif;
  font-size: 16px; /* 42px --> 38px smaller size 1920x900px */
  font-weight: 400;
  line-height: 24px; /* 68px --> 61px smaller size 1920x900px */
  color: #C6CBCB;
  position: fixed;
  z-index: 12;
`;


const PersonPic = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;
`;
