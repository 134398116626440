import React, {useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from "react-i18next";
import { StyledIndividualPerformanceSliders } from '../../../layout-and-styling/standardized-components-library/Styling-TextInput';
import { TooltipKnowledgePage } from '../../standardized-components/Survey-text-processing-tooltips';

const ComponentIndividualImpact = (props) => {
    /** Component to select how much knowledge an employee has of the business processes */
    const [selectedScore, setSelectedScore] = useState(JSON.parse(localStorage.getItem('individual_impact_score')) ? JSON.parse(localStorage.getItem('individual_impact_score')) : 0)
    const [checked, setChecked] = useState(JSON.parse(localStorage.getItem("individual_impact_check")) ? JSON.parse(localStorage.getItem('individual_impact_check')) : false)
    const [hover, setHover] = useState(false);
    const {t, i18n} = useTranslation();

    if (checked === true)
        props.set_input_check_individual_impact(true)

    const updateImpact = (individual_impact_score) => {
        setChecked(true);
        setSelectedScore(individual_impact_score);
        localStorage.setItem("individual_impact_score", JSON.stringify(individual_impact_score));
        localStorage.setItem("individual_impact_check", JSON.stringify(checked));
    }

    const marksPleasantness = [
        {
            value: 0,
            label: <p> {t('PageKnowledge.NoImpact')}</p>
        },
        {
            value: 1,
            label: <p> {t('PageKnowledge.SomeImpact')}</p>
        },
        {
            value: 2,
            label: <p> {t('PageKnowledge.Impactful')}</p>
        },
        {
            value: 3,
            label: <p> {t('PageKnowledge.VeryImpactful')}</p>
        },
        {
            value: 4,
            label: <p> {t('PageKnowledge.ExceptionallyImpactful')}</p>
        },
    ]
    return (
        <Container outline={!checked && props.nextButtonClicked ? "2px solid #F72B2B" : ""}>
            <IncompleteContentText display={!checked && props.nextButtonClicked ?  "flex" : "none"}>
                {t('PageKnowledge.SliderError')}
            </IncompleteContentText>
            <TopHeader>
                <ImpactHeaderText>
                    {t('PageKnowledge.IndividualImpactHeaderText')}
                </ImpactHeaderText>

                <InfoHover onMouseOver={() => setHover(true)}
                           onMouseOut={() => setHover(false)}>?
                    {hover && (<TooltipKnowledgePage whichTooltip={"IndividualImpact"}/>
                    )}
                </InfoHover>
            </TopHeader>

            <StyledIndividualPerformanceSliders id="slippyslider"
                                                MarginTop="20px"
                                                MarginLeft='64px'
                                                Padding='8px'
                                                onChange={(event, individual_impact) => updateImpact(individual_impact)}
                                                value={selectedScore} valueLabelDisplay="off"
                                                size="small" step={1} min={0} max={4}
                                                marks={marksPleasantness}
                                                sx={{
                                                    width: "calc(100% - 150px)",
                                                    height: 10,
                                                    '@media screen and (max-width: 1280px)': {
                                                        width: 32,
                                                        '& .MuiSlider-markLabel': {fontSize: "10px"},
                                                        MarginTop: 40,
                                                    },

                                                    color: '#C6CBCB',
                                                    '& .MuiSlider-thumb': {
                                                        color: checked ? "#B3194A" : "#5F6366",
                                                        width: '15px',
                                                        height: '15px',
                                                        '&:focus, &:hover, &.Mui-active': {
                                                            color: "#D13C70",
                                                            boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
                                                            '@media (hover: none)': {boxShadow: 'none',}
                                                        },
                                                    },
                                                    '& .MuiSlider-rail': {
                                                        opacity: 1,
                                                        width: 1,
                                                        backgroundColor: '#F1F3F4',
                                                    },
                                                    '& .MuiSlider-root': {
                                                        opacity: 1,
                                                        width: 1,
                                                        backgroundColor: '#F1F3F4',
                                                    },
                                                    // '& .MuiSlider-mark': {
                                                    //     background: '#C6CBCB', height: '1px', width: '3px',
                                                    //     '&.MuiSlider-markActive': {backgroundColor: '#C6CBCB',},
                                                    // },
                                                    '& .MuiSlider-track': {
                                                        width: "100%",
                                                        backgroundColor: '#B3194A',
                                                    },
                                                    '& .MuiSlider-markLabel': {
                                                        fontSize: "14px",
                                                        FontFamily: "Overpass",
                                                        color: '#5F6366',
                                                        // whiteSpace: "normal",
                                                        textAlign: "center",
                                                    },

                                                }} 
             />
        </Container>
    )
}

export default ComponentIndividualImpact

const Container = styled.div`
  background: #FCFCFC;
  min-width: 1044px;
  height: 178px;
  border-radius: ${props => props.BorderRadius || '5px'};
  position: relative;
  margin-right: 20px;
  outline: ${props => props.outline || ''};
  outline-offset: -2px;
`;

const IncompleteContentText = styled.div`
  position: absolute;
  display: ${(props) => props.display || ""};
  margin-left: 416px;
  margin-top: 4px;
  border-radius: ${(props) => props.BorderRadius || "5px"};
  color: #F72B2B;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
`;

const ImpactHeaderText = styled.div`
  margin-top: 20px;
  margin-left: 20px;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
`;

const InfoHover = styled.div`
  float: right;
  font-size: 18px;
  font-family: 'Overpass', sans-serif;
  font-weight: 300;
  position: relative;
  color: #FCFCFC;
  background-color: #E2336B;
  margin-right: 16px;
  margin-top: 16px;
  border: 2px solid #E2336B;
  border-radius: 500px;
  width: 20px;
  text-align: center;
  height: 20px;
  cursor: pointer;
`;

const TopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;