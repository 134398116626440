import React, {useContext, useEffect, useState, useCallback} from 'react';
import axios from 'axios';
import styled from "styled-components";
import getCookieValue from "../../utils";

import {useWindowSize} from '../../layout-and-styling/standardized-components-library/Component-Responsive';
import {useTranslation, Trans} from 'react-i18next';
import GifLoading from '../../assets/intake/GifLoading.gif';
import ImageNotFound from '../../assets/intake/ImageNotFound.png';
import {ReactComponent as IconCheckCircleFill} from '../../assets/intake/IconCheckCircleFill.svg';
import CommonHeader from '../../layout-and-styling/standardized-components-library/Common-header';
import {StyledDivBold, StyledDivRegular} from '../../layout-and-styling/standardized-components-library/Styling-TextInput';
import IntroductionComponent from './page-specific-components/IntroductionComponent';
import SidebarComponent from './page-specific-components/SidebarComponent';
import {ReactComponent as IconAlert} from '../../assets/intake/IconAlert.svg';
import MainProcessComponent from './page-specific-components/MainProcessComponent';
import ButtonMainProcess from '../../assets/intake/ButtonMainProcess.png';
import SubProcessComponent from './page-specific-components/SubProcessComponent';
import ButtonSubProcess from '../../assets/intake/ButtonSubProcess.png';
import {ReactComponent as IconPlusBig} from '../../assets/intake/IconPlusBig.svg';
import { FollowUpProcessTimePage, FollowUpRepresentativePage } from '../api-calls/api-calls';
import { handleLogout } from '../../layout-and-styling/API-calls/common-api-calls';


const PageIntakeBuilder = () => {
    const { t } = useTranslation();

    const [identifier, setIdentifier] = useState(''); // the phase of the Intake
    const [organizationName, setOrganizationName] = useState(''); // the name of the current company

    useEffect(() => {
        const axiosInstance = axios.create({withCredentials: true});
        setIdentifier('IntakeLoading');

        // Check if the current user is logged in
        axiosInstance.get(process.env.REACT_APP_APIURL + '/api/auth/validatesession/')
            .catch(() => {
                console.log('error, you are not logged in yet!')
                window.location.replace(process.env.REACT_APP_APPURL + '/login');
            })
        
        // Set identifier based on the current phase of the Intake
        if (JSON.parse(localStorage.getItem('intakeData'))[0].is_main) { // the current user is Leader
            axiosInstance.get(process.env.REACT_APP_APIURL + '/api/myintakeprocesses/compare_intakes') // check if all Representatives published their business processes
                .then((res) => {
                    const intakeData = JSON.parse(localStorage.getItem('intakeData')); // store the data of the intake

                    if (intakeData !== null && intakeData !== 0) {
                        if (res.data) { // all Representatives published 
                            setIdentifier('IntakeComparison');
                        } else { 
                            axiosInstance.get(process.env.REACT_APP_APIURL + '/api/myintakeprocesses/check_own_intake') // check if the current user published their business processes
                                .then((res) => {
                                    if (res.data) { // the current user DID NOT publish 
                                        setIdentifier('IntakeBuilderLeader');
                                    } else {
                                        setIdentifier('IntakeBuilderLeaderPublished');
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                })
                        }
                    } else {
                        setIdentifier('IntakeNotFound');
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }

        // Set identifier based on the current phase of the Intake
        axiosInstance.get(process.env.REACT_APP_APIURL + '/api/myintakeprocesses/check_own_intake') // check if the current user published their business process
            .then((res) => {
                const intakeData = JSON.parse(localStorage.getItem('intakeData')); // store the data of the intake

                if (intakeData !== null && intakeData !== 0) {
                    if (!JSON.parse(localStorage.getItem('intakeData'))[0].is_main) { // the current user is a Representative
                        if (res.data) { // the current user DID NOT publish
                            setIdentifier('IntakeBuilderRepresentative');
                        } else {
                            setIdentifier('IntakeBuilderRepresentativePublished');
                        }
                    }
                } else {
                    setIdentifier('IntakeNotFound');
                }
            })
            .catch((error) => {
                console.log(error);
            })
        
        // Set businessProcesses based on the business processes from all Representatives
        axiosInstance.get(process.env.REACT_APP_APIURL +  '/api/getallintakeprocesses')
            .then((res) => {
                setBusinessProcesses(formatBusinessProcesses(res.data));
            })
            .catch((error) => {
                console.log(error);
            })
        
        // Set organizationName based on the company's name of the current user
        axiosInstance.get(process.env.REACT_APP_APIURL + '/api/mycompanyname/get_company_name')
            .then((res) => {
                let companyName = res.data
                if (companyName.length > 20){
                    companyName = companyName.substring(0,21) + "..."
                    setOrganizationName(companyName);
                }
                else{
                    setOrganizationName(companyName);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, []);

    useWindowSize();

    const [render, setRender] = useState(false);

    const reRender = () => {
        setRender(!render);
    };

    useEffect(() => {
        if(!localStorage.getItem("i18nextLng")) {
            localStorage.setItem("i18nextLng", "en-US");
        }
    }, [])

    const [localStorageLanguage, setSelectedLanguage] = useState(JSON.parse(localStorage.getItem('Language')) ? JSON.parse(localStorage.getItem('Language')) : 0);

    const backToHome = () => {
        window.location.replace(process.env.REACT_APP_APPURL + '/home');
    }

    const [mainProcesses, setMainProcesses] = useState([]); // the business process of the current user
    const [businessProcesses, setBusinessProcesses] = useState([]); // the business processes of all Representatives
    const [isShowingIntroduction, setIsShowingIntroduction] = useState(false);

    // const [selectedTemplate, setSelectedTemplate] = useState('');

    // useEffect(() => {
    //     if (selectedTemplate === 'StandardProcess') {
    //         const data = [
    //             {name: 'Sales & Marketing', subProcesses: [{name: 'Lead Generation & Qualification'}, {name: 'Sales Proposal'}, {name: 'Deal Closure'}]},
    //             {name: 'Product Development', subProcesses: [{name: 'Idea Generation'}, {name: 'Concept Development'}, {name: 'Design & Development'}, {name: 'Product Release'}]},
    //             {name: 'Manufacturing & Delivery', subProcesses: [{name: 'Raw Material Procurement'}, {name: 'Production'}, {name: 'Logistics & Distribution'}]},
    //             {name: 'Customer Support', subProcesses: [{name: 'Inquiry Handling'}, {name: 'Issue Resolution'}]}
    //         ];

    //         setMainProcesses(data);
    //     }
    // }, [selectedTemplate]);

    // Convert the business process from the database format to Intake format
    const formatMainProcesses = (mainProcesses) => {
        const sortedMainProcesses = mainProcesses.sort((a, b) => a.order_id - b.order_id);

        // Create an array of main processes with nested sub-processes
        const formattedMainProcesses = sortedMainProcesses.map(mainProcess => ({
            name: mainProcess.name,
            subProcesses: mainProcess.children.map(subProcessName => ({
                name: subProcessName
            }))
        }));

        return formattedMainProcesses;
    };

    // Convert the business process from the Intake format to the database format
    const reverseFormatMainProcesses = (formattedMainProcesses) => {
        const reverseFormattedMainProcesses = []; // store 2 arrays of main processes and sub-processes

        // Create an array of main processes
        const mainProcessesData = formattedMainProcesses.map((mainProcess, mainProcessIndex) => ({ 
            name: mainProcess.name, 
            order_id: mainProcessIndex
        }));

        reverseFormattedMainProcesses.push(mainProcessesData); // add the array of main processes to the reverseFormattedMainProcesses

        let subProcessesData = [];

        // Create an array of sub-processes
        formattedMainProcesses.forEach((mainProcess) => {
            if (mainProcess.subProcesses) {
                mainProcess.subProcesses.forEach((subProcess, subIndex) => {
                    subProcessesData.push({
                        name: subProcess.name,
                        order_id: subIndex,
                        parent: mainProcess.name
                    });
                });
            }
        });

        reverseFormattedMainProcesses.push(subProcessesData); // add the array of sub-processes to the reverseFormattedMainProcesses
        return reverseFormattedMainProcesses;
    };

    // Convert the business processes from the database format to the Intake sidebar format
    const formatBusinessProcesses = (businessProcesses) => {

        // Group an array of main processes by a user's name
        const groupedBusinessProcesses = businessProcesses.reduce((result, process) => {
            const key = process.intake;

            if (!result[key]) {
                result[key] = [];
            }

            result[key].push(process);
            return result;
        }, {});
        
        for (const key in groupedBusinessProcesses) {
            groupedBusinessProcesses[key].sort((a, b) => a.order_id - b.order_id);
        }
    
        // Create an array of users' names with nested main processes which have nested sub-processes
        const formattedBusinessProcesses = Object.values(groupedBusinessProcesses).map((mainProcesses) => ({
            userName: mainProcesses[0].intake,
            mainProcesses: mainProcesses.map((mainProcess) => ({
                    name: mainProcess.name,
                    subProcesses: mainProcess.children.map(subProcessName => ({
                        name: subProcessName
                    }))
                }))
        }));
    
        return formattedBusinessProcesses;
    }

    const showIntroduction = () => {
        const isShowingIntroductionBefore = localStorage.getItem(`isShowingIntroduction`); // check if the introduction was shown before

        if (!isShowingIntroductionBefore) {
            setTimeout(() => {
                setIsShowingIntroduction(true);
            }, 1000);
        } else {
            setIsShowingIntroduction(false);
        }
    }

    const hideIntroduction = () => {
        localStorage.setItem(`isShowingIntroduction`, 'true'); // set any value if the introduction was shown before
        setIsShowingIntroduction(false);
    }

    // Set up the business process and the introduction to start the Intake
    useEffect(() => {
        const businessProcess = JSON.parse(localStorage.getItem(`businessProcessIntake`)); // check if the current user started the Intake before
                    
        if (businessProcess === null || businessProcess.length === 0) { // the current user DID NOT start
            const axiosInstance = axios.create({withCredentials: true});
        
            axiosInstance
                .get(
                    process.env.REACT_APP_APIURL +
                    "/api/mymainprocesses/",                
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "X-CSRFToken": getCookieValue("csrftoken"),
                        },
                    }
                ) // check if the current phase is IntakeComparison and get the business process of Leader
                .then ((res) => {
                    if(res.data.length !== 0) { // the current phase is IntakeComparison
                        setMainProcesses(formatMainProcesses(res.data));
                    } else {
                        const data = [
                            {name: 'Main Process 1', subProcesses: []}
                        ]; // create a blank business process

                        setMainProcesses(data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setMainProcesses(businessProcess);
        }

        showIntroduction();
    }, []);

    useEffect(() => {
        if (mainProcesses.length !== 0) {
            localStorage.setItem(`businessProcessIntake`, JSON.stringify(mainProcesses));
        }
    }, [mainProcesses]);

    const [isShowingAlert, setIsShowingAlert] = useState(false);
    const [alertIdentifier, setAlertIdentifier] = useState(''); // the type of the alert
    const [businessProcess, setBusinessProcess] = useState({ mainProcessIndex: null, subProcessIndex: null }); // the main or sub- process being modified

    const showAlert = (alertIdentifier, mainProcessIndex, subProcessIndex) => {
        if (alertIdentifier === 'AlertRenamingMainProcess' && mainProcessIndex !== null) {
            setAlertIdentifier('AlertRenamingMainProcess');
            setBusinessProcess({ mainProcessIndex: mainProcessIndex });
            setIsShowingAlert(true);
        }

        if (alertIdentifier === 'AlertRenamingSubProcess' && mainProcessIndex !== null && subProcessIndex !== null) {
            setAlertIdentifier('AlertRenamingSubProcess');
            setBusinessProcess({ mainProcessIndex: mainProcessIndex, subProcessIndex: subProcessIndex });
            setIsShowingAlert(true);
        }

        if (alertIdentifier === 'AlertDeletingMainProcess' && mainProcessIndex !== null) {
            setAlertIdentifier('AlertDeletingMainProcess');
            setBusinessProcess({ mainProcessIndex: mainProcessIndex });
            setIsShowingAlert(true);
        }
    };

    const closeAlert = () => {
        if (isShowingAlert) {
            setIsShowingAlert(false);
            setBusinessProcess({ mainProcessIndex: null });
        }
    };

    const continueAlert = () => {
        if (businessProcess.mainProcessIndex !== null) {
            deleteMainProcess(businessProcess.mainProcessIndex);
            closeAlert();
        }
    };

    const [processBeingRenamed, setProcessBeingRenamed] = useState({ mainProcessIndex: null, subProcessIndex: null });

    const addMainProcess = () => {
        if (processBeingRenamed.mainProcessIndex === null) {
            if (mainProcesses.length < 6) {
                const newMainProcesses = [...mainProcesses];
                newMainProcesses.push({ name: `Main Process ${newMainProcesses.length + 1}`, subProcesses: [] });
                setMainProcesses(newMainProcesses);
            }
        }
    };

    const renameMainProcess = (mainProcessIndex, newName) => {
        if (mainProcessIndex !== null && newName !== null) {
            const duplicateIndex = mainProcesses.findIndex((mainProcess, index) => {
                return index !== mainProcessIndex && mainProcess.name === newName;
            }); // check if the new name of the main process is unique
            
            if (duplicateIndex !== -1) { // the new name of the main process IS NOT unique
                showAlert('AlertRenamingMainProcess', duplicateIndex);
            } else {
                const newMainProcesses = [...mainProcesses];
                newMainProcesses[mainProcessIndex].name = newName;
                setMainProcesses(newMainProcesses);
            }
        }
    };    

    const moveMainProcessRight = (mainProcessIndex) => {
        if (mainProcessIndex !== null) {
            if (processBeingRenamed.mainProcessIndex === null) {
                if (mainProcessIndex < mainProcesses.length - 1) {
                    const newMainProcesses = [...mainProcesses];
                    const tempMainProcess = newMainProcesses[mainProcessIndex];
                    newMainProcesses[mainProcessIndex] = newMainProcesses[mainProcessIndex + 1];
                    newMainProcesses[mainProcessIndex + 1] = tempMainProcess;
                    setMainProcesses(newMainProcesses);
                }
            }
        }
    };

    const moveMainProcessLeft = (mainProcessIndex) => {
        if (mainProcessIndex !== null) {
            if (processBeingRenamed.mainProcessIndex === null) {
                if (mainProcessIndex > 0) {
                    const newMainProcesses = [...mainProcesses];
                    const tempMainProcess = newMainProcesses[mainProcessIndex];
                    newMainProcesses[mainProcessIndex] = newMainProcesses[mainProcessIndex - 1];
                    newMainProcesses[mainProcessIndex - 1] = tempMainProcess;
                    setMainProcesses(newMainProcesses);
                }
            }
        }
    };

    const deleteMainProcess = (mainProcessIndex) => {
        if (mainProcessIndex !== null) {
            if (processBeingRenamed.mainProcessIndex === null) {
                if (mainProcesses.length > 1) {
                    const newMainProcesses = [...mainProcesses];
                    newMainProcesses.splice(mainProcessIndex, 1);
                    setMainProcesses(newMainProcesses);
                }
            }
        }
    };

    const addSubProcess = (mainProcessIndex) => {
        if (mainProcessIndex !== null) {
            if (processBeingRenamed.mainProcessIndex === null) {
                if (mainProcesses[mainProcessIndex].subProcesses.length < 6) {
                    const newMainProcesses = [...mainProcesses];
                    newMainProcesses[mainProcessIndex].subProcesses.push({ name: `Sub-Process ${newMainProcesses[mainProcessIndex].subProcesses.length + 1}`});
                    setMainProcesses(newMainProcesses);
                }
            }
        }
    };

    const renameSubProcess = (mainProcessIndex, subProcessIndex, newName) => {
        if (mainProcessIndex !== null && subProcessIndex !== null && newName !== null) {
            const duplicateIndex = mainProcesses[mainProcessIndex].subProcesses.findIndex((subProcess, index) => {
                return index !== subProcessIndex && subProcess.name === newName;
            }); // check if the new name of the sub-process is unique within the main process
            
            if (duplicateIndex !== -1) { // the new name of the sub-process IS NOT unique
                showAlert('AlertRenamingSubProcess', mainProcessIndex, duplicateIndex);
            } else {
                const newMainProcesses = [...mainProcesses];
                newMainProcesses[mainProcessIndex].subProcesses[subProcessIndex].name = newName;
                setMainProcesses(newMainProcesses);
            }
        }
    };

    const moveSubProcessUp = (mainProcessIndex, subProcessIndex) => {
        if (mainProcessIndex !== null && subProcessIndex !== null) {
            if (processBeingRenamed.mainProcessIndex === null) {
                if (subProcessIndex > 0) {
                    const newMainProcesses = [...mainProcesses];
                    const tempSubProcess = newMainProcesses[mainProcessIndex].subProcesses[subProcessIndex];
                    newMainProcesses[mainProcessIndex].subProcesses[subProcessIndex] = newMainProcesses[mainProcessIndex].subProcesses[subProcessIndex - 1];
                    newMainProcesses[mainProcessIndex].subProcesses[subProcessIndex - 1] = tempSubProcess;
                    setMainProcesses(newMainProcesses);
                }
            }
        }
    };

    const moveSubProcessDown = (mainProcessIndex, subProcessIndex) => {
        if (mainProcessIndex !== null && subProcessIndex !== null) {
            if (processBeingRenamed.mainProcessIndex === null) {
                if (subProcessIndex < mainProcesses[mainProcessIndex].subProcesses.length - 1) {
                    const newMainProcesses = [...mainProcesses];
                    const tempSubProcess = newMainProcesses[mainProcessIndex].subProcesses[subProcessIndex];
                    newMainProcesses[mainProcessIndex].subProcesses[subProcessIndex] = newMainProcesses[mainProcessIndex].subProcesses[subProcessIndex + 1];
                    newMainProcesses[mainProcessIndex].subProcesses[subProcessIndex + 1] = tempSubProcess;
                    setMainProcesses(newMainProcesses);
                }
            }
        }
    };

    const deleteSubProcess = (mainProcessIndex, subProcessIndex) => {
        if (mainProcessIndex !== null && subProcessIndex !== null) {
            if (processBeingRenamed.mainProcessIndex === null) {
                const newMainProcesses = [...mainProcesses];
                newMainProcesses[mainProcessIndex].subProcesses.splice(subProcessIndex, 1);
                setMainProcesses(newMainProcesses);
            }
        }
    };

    const publishMainProcesses = () => {
        const businessProcess = JSON.parse(localStorage.getItem(`businessProcessIntake`));

        if (businessProcess !== null && businessProcess.length !== 0) {
            const data = [
                {name: 'Main Process 1', subProcesses: []}
            ]; // create a blank business process

            if (JSON.stringify(businessProcess) !== JSON.stringify(data)) { // the business process IS NOT blank
                const reverseFormattedData = JSON.stringify(reverseFormatMainProcesses(businessProcess));
                const axiosInstance = axios.create({withCredentials: true});

                if (identifier === 'IntakeBuilderLeader') {
                    FollowUpRepresentativePage(); // redirect to the Representative page
                } else if (identifier === 'IntakeComparison') {
                    FollowUpProcessTimePage(); // redirect to the Process Time page
                } else {
                    axiosInstance
                        .post(
                            process.env.REACT_APP_APIURL +
                            '/api/myintakeprocesses/update_batch/',
                            reverseFormattedData,
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    "X-CSRFToken": getCookieValue("csrftoken"),
                                },
                            }
                        )
                        .then (() => {
                            axiosInstance.get(process.env.REACT_APP_APIURL + '/api/myintakeprocesses/compare_intakes')
                                .catch((error) => {
                                    console.log(error);
                                });

                            setIdentifier('IntakeBuilderRepresentativePublished');
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            }
        }
    }

    return (
        <Container>
            {(identifier === 'IntakeLoading') ? (
                <OverlayWrapper>
                    <GifWrapper>
                        <img src={GifLoading} style={{width: '426.67px', height: '320px'}} alt=''/>
                    </GifWrapper>

                    <StyledDivBold Position='static' Width='auto' Height='auto' MarginTop='40px' MarginBottom='40px' FontSize='42px' LineHeight='52px'>
                        {t('PageIntakeBuilder.LoadingTextHeader')}
                    </StyledDivBold>
                </OverlayWrapper>
            ) : (identifier === 'IntakeNotFound') ? (
                <OverlayWrapper>
                    <img src={ImageNotFound} style={{width: 'calc(50% - 20px)', height: 'auto'}} alt=''/>  

                    <StyledDivBold Position='static' Width='auto' Height='auto' MarginTop='40px' MarginBottom='40px' FontSize='42px' LineHeight='52px'>
                        {t('PageIntakeBuilder.NotFoundTextHeader')}
                    </StyledDivBold>

                    <ButtonWrapper onClick={backToHome} Width='auto'>
                        <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' Color='#FCFCFC' FontWeight='700' LetterSpacing='0.3px'>
                            {t('PageIntakeBuilder.NotFoundOrPublishedTextButton')}
                        </StyledDivRegular>
                    </ButtonWrapper>
                </OverlayWrapper>
            ) : (identifier === 'IntakeBuilderLeaderPublished' || identifier === 'IntakeBuilderRepresentativePublished' || identifier === 'IntakeComparisonPublished') ? (
                <OverlayWrapper>
                    <IconCheckCircleFill/>

                    <StyledDivBold Position='static' Width='auto' Height='auto' MarginTop='40px' MarginBottom='16px' FontSize='42px' LineHeight='52px'>
                        {t('PageIntakeBuilder.PublishedTextHeader')}
                    </StyledDivBold>

                    <StyledDivRegular Position='static' Width='auto' Height='auto' MarginBottom='40px' FontSize='18px' LineHeight='28px' TextAlign='center'>
                        {(identifier === 'IntakeBuilderLeaderPublished') ? (
                            <>
                                {t('PageIntakeBuilder.PublishedBuilderLeaderTextBody')}
                            </>
                        ) : (identifier === 'IntakeComparisonPublished') ? (
                            <>
                                {t('PageIntakeBuilder.PublishedComparisonTextBody')}
                            </>
                        ) : (
                            <>
                                {t('PageIntakeBuilder.PublishedBuilderRepresentativeTextBody')}
                            </>
                        )}
                    </StyledDivRegular>
                    <ButtonWrapper onClick={handleLogout} Width='auto'>
                        <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' Color='#FCFCFC' FontWeight='700' LetterSpacing='0.3px'>
                            {t('PageIntakeBuilder.ComparisonFinishedButton')}
                        </StyledDivRegular>
                    </ButtonWrapper>
                        

                </OverlayWrapper>
            ) : (
                <>
                    <IntroductionComponent reRender={reRender} isShowingIntroduction={isShowingIntroduction} identifier={identifier} /* setSelectedTemplate={setSelectedTemplate} */ hideIntroduction={hideIntroduction}/>

                    {isShowingAlert && (
                        <AlertContainer>
                            <AlertWrapper>
                                <IconAlert/>

                                {(alertIdentifier === 'AlertRenamingMainProcess') && (
                                    <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' TextAlign='center'>
                                        <Trans i18nKey="PageIntakeBuilder.TextAlertRenamingMainProcess">
                                            The <b>{{mainProcessName: mainProcesses[businessProcess.mainProcessIndex].name}}</b> Process <b>already exists</b>. Please enter a different name.
                                        </Trans>
                                    </StyledDivRegular>
                                )}

                                {(alertIdentifier === 'AlertRenamingSubProcess') && (
                                    <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' TextAlign='center'>
                                         <Trans i18nKey="PageIntakeBuilder.TextAlertRenamingSubProcess">
                                            The <b>{{subProcessName: mainProcesses[businessProcess.mainProcessIndex].subProcesses[businessProcess.subProcessIndex].name}}</b> Sub-Process <b>already exists</b>. Please enter a different name.
                                        </Trans>
                                    </StyledDivRegular>
                                )}

                                {(alertIdentifier === 'AlertDeletingMainProcess') && (
                                    <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' TextAlign='center'>
                                        <>
                                            {(mainProcesses[businessProcess.mainProcessIndex].subProcesses.length === 0) ? (
                                                <Trans i18nKey="PageIntakeBuilder.TextAlertDeletingNone">
                                                    The <b>{{mainProcessName: mainProcesses[businessProcess.mainProcessIndex].name}}</b> and its related Sub-Processes <b>will be deleted immediately</b>. Are you sure you want to continue?
                                                </Trans>
                                            ) : (mainProcesses[businessProcess.mainProcessIndex].subProcesses.length === 1) ? (
                                                <Trans i18nKey="PageIntakeBuilder.TextAlertDeletingOne">
                                                    The <b>{{mainProcessName: mainProcesses[businessProcess.mainProcessIndex].name}}</b> and its related Sub-Processes <b>will be deleted immediately</b>. Are you sure you want to continue?
                                                </Trans>
                                            ) : (
                                                <Trans i18nKey="PageIntakeBuilder.TextAlertDeletingMany">
                                                    The <b>{{mainProcessName: mainProcesses[businessProcess.mainProcessIndex].name}}</b> and its related Sub-Processes <b>will be deleted immediately</b>. Are you sure you want to continue?
                                                </Trans>
                                            )}
                                        </>
                                    </StyledDivRegular>
                                )}

                                {(alertIdentifier === 'AlertDeletingMainProcess') ? (
                                    <AlertButtonContainer>
                                        <ButtonWrapper onClick={closeAlert} Color={'#E3E5E4'}>
                                            <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' Color='#5F6366' FontWeight='700' LetterSpacing='0.3px'>
                                                {t('PageIntakeBuilder.TextButtonCancel')}
                                            </StyledDivRegular>
                                        </ButtonWrapper>

                                        <ButtonWrapper onClick={continueAlert}>
                                            <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' Color='#FCFCFC' FontWeight='700' LetterSpacing='0.3px'>
                                                {t('PageIntakeBuilder.TextButtonContinue')}
                                            </StyledDivRegular>
                                        </ButtonWrapper>
                                    </AlertButtonContainer>
                                ) : (
                                    <AlertButtonContainer>
                                       <ButtonWrapper onClick={closeAlert}>
                                            <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' Color='#FCFCFC' FontWeight='700' LetterSpacing='0.3px'>
                                                {t('PageIntakeBuilder.TextButtonGotIt')}
                                            </StyledDivRegular>
                                        </ButtonWrapper>
                                    </AlertButtonContainer>
                                )}
                            </AlertWrapper>
                        </AlertContainer>
                    )}

                    <HeaderWrapper isShowingIntroduction={!localStorage.getItem(`isShowingIntroduction`)}>
                        <CommonHeader page="IntakeBuilder" identifier={identifier} isDisabled={JSON.stringify(mainProcesses) === JSON.stringify([{name: 'Main Process 1', subProcesses: []}])} publishMainProcesses={publishMainProcesses} set_selected_language={setSelectedLanguage} reRender={reRender}/>
                    </HeaderWrapper>

                    {(identifier === 'IntakeComparison') && (
                        <SidebarComponent businessProcesses={businessProcesses} isShowingIntroduction={!localStorage.getItem(`isShowingIntroduction`)}/>
                    )}

                    <Wrapper identifier={identifier} isShowingIntroduction={!localStorage.getItem(`isShowingIntroduction`)}>
                        <StyledDivBold Position='static' Width='auto' Height='auto' FontSize='42px' LineHeight='52px'>
                            {t('PageIntakeBuilder.TextHeader', {organizationName: organizationName})}
                        </StyledDivBold>

                        <ProcessWrapper>
                            {mainProcesses.map((mainProcess, mainProcessIndex) => (
                                <MainProcessWrapper>
                                    <MainProcessComponent mainProcess={mainProcess} mainProcessIndex={mainProcessIndex}
                                                          isOverlayEnabled={processBeingRenamed.mainProcessIndex === null}
                                                          isShowingTextInput={processBeingRenamed.mainProcessIndex === mainProcessIndex && processBeingRenamed.subProcessIndex === null}
                                                          showTextInput={() => setProcessBeingRenamed({mainProcessIndex: mainProcessIndex, subProcessIndex: null})}
                                                          hideTextInput={() => setProcessBeingRenamed({mainProcessIndex: null, subProcessIndex: null})}
                                                          isMoveLeftDisabled={mainProcessIndex === 0}
                                                          isMoveRightDisabled={mainProcessIndex === (mainProcesses.length - 1)}
                                                          isDeleteDisabled={mainProcesses.length === 1}
                                                          showAlert={() => showAlert('AlertDeletingMainProcess', mainProcessIndex)}
                                                          renameMainProcess={(newName) => renameMainProcess(mainProcessIndex, newName)}
                                                          moveMainProcessLeft={() => moveMainProcessLeft(mainProcessIndex)}
                                                          moveMainProcessRight={() => moveMainProcessRight(mainProcessIndex)}
                                    />

                                    {mainProcess.subProcesses.map((subProcess, subProcessIndex) => (
                                        <SubProcessComponent subProcess={subProcess} subProcessIndex={subProcessIndex}
                                                             isOverlayEnabled={processBeingRenamed.mainProcessIndex === null}
                                                             isShowingTextInput={processBeingRenamed.mainProcessIndex === mainProcessIndex && processBeingRenamed.subProcessIndex === subProcessIndex}
                                                             showTextInput={() => setProcessBeingRenamed({mainProcessIndex: mainProcessIndex, subProcessIndex: subProcessIndex})}
                                                             hideTextInput={() => setProcessBeingRenamed({mainProcessIndex: null, subProcessIndex: null})}
                                                             isMoveUpDisabled={subProcessIndex === 0}
                                                             isMoveDownDisabled={subProcessIndex === (mainProcess.subProcesses.length - 1)}
                                                             renameSubProcess={(newName) => renameSubProcess(mainProcessIndex, subProcessIndex, newName)}
                                                             moveSubProcessUp={() => moveSubProcessUp(mainProcessIndex, subProcessIndex)}
                                                             moveSubProcessDown={() => moveSubProcessDown(mainProcessIndex, subProcessIndex)}
                                                             deleteSubProcess={() => deleteSubProcess(mainProcessIndex, subProcessIndex)}
                                        />
                                    ))}

                                    {(mainProcess.subProcesses.length < 6) && (
                                        <AddButtonWrapper onClick={() => addSubProcess(mainProcessIndex)} Disabled={processBeingRenamed.mainProcessIndex !== null}>
                                            <ImageWrapper>
                                                <IconPlusBig/>
                                            </ImageWrapper>
                        
                                            <BackgroundWrapper>
                                                <img src={ButtonSubProcess} alt=''/>  
                                            </BackgroundWrapper>
                                        </AddButtonWrapper>
                                    )}
                                </MainProcessWrapper>
                            ))}

                            {(mainProcesses.length < 6) && (
                                <AddButtonWrapper onClick={addMainProcess} isButtonMainProcess={true} Disabled={processBeingRenamed.mainProcessIndex !== null}>
                                    <ImageWrapper>
                                        <IconPlusBig/>
                                    </ImageWrapper>

                                    <BackgroundWrapper>
                                        <img src={ButtonMainProcess} alt=''/>  
                                    </BackgroundWrapper>
                                </AddButtonWrapper>
                            )}
                        </ProcessWrapper>
                    </Wrapper>
                </>
            )}
        </Container>
    )
};

export default PageIntakeBuilder;

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f6f7fa;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
        pointer-events: none;
        -drag: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }

    @media (-webkit-device-pixel-ratio: 1.25) {
        zoom: calc(1 / 1.25);
    }

    @media (-webkit-device-pixel-ratio: 1.5) {
        zoom: calc(1 / 1.5);
    }

    @media (-webkit-device-pixel-ratio: 1.75) {
        zoom: calc(1 / 1.75);
    }
`;

const OverlayWrapper = styled.div`
    display: flex;
    width: calc(100% - 80px);
    max-width: 1290px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
`;

const GifWrapper = styled.div`
    display: flex;
    width: 250px;
    height: 125px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

const AlertContainer = styled.div`
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(39, 27, 54, 0.75);
    backdrop-filter: blur(12.5px);
    z-index: 49;
`;

const AlertWrapper = styled.div`
    display: flex;
    width: calc(100% - 40px);
    max-width: 360px;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-radius: 8px;
    background: #FCFCFC;
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.05);
`;

const AlertButtonContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    width: ${props => props.Width || 'calc(100% - 20px)'};
    padding: 15px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: ${props => props.Color || '#E2336B'};
    transition: filter 0.15s;
    cursor: pointer;
    user-select: none;

    &:hover {
        filter: brightness(90%);
    }
`;

const HeaderWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    pointer-events: ${props => props.isShowingIntroduction ? 'none' : 'auto'};
    z-index: 4;
`;

const Wrapper = styled.div`
    display: flex;
    min-width: 1290px;
    min-height: 782px;
    flex-direction: column;
    align-items: flex-start;
    padding: ${props => (props.identifier === 'IntakeComparison') ? '108px 40px 40px 257px' : '68px 40px 40px 40px'};
    gap: 50px;
    pointer-events: ${props => props.isShowingIntroduction ? 'none' : 'auto'};

    @media (max-width: 1370px) or (max-height: 900px) {
        min-width: 1215px;
        min-height: 717px;
        gap: 45px;
    }
`;

const ProcessWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 60px;

    @media (max-width: 1370px) or (max-height: 900px) {
        gap: 45px;
    }
`;

const MainProcessWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    @media (max-width: 1370px) or (max-height: 900px) {
        gap: 10px;
    }
`;

const AddButtonWrapper = styled.div`
    position: relative;
    display: flex;
    width: ${props => props.isButtonMainProcess ? '117px' : '145px'};
    height: 60px;
    padding: 10px 10px 10px ${props => props.isButtonMainProcess ? '38px' : '10px'};
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    opacity: ${props => props.Disabled ? '0.5' : ''};
    transition: opacity 0.3s, filter 0.15s;
    cursor: ${props => props.Disabled ? '' : 'pointer'};

    &:hover {
        filter: ${props => props.Disabled ? '' : 'brightness(90%)'};
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    z-index: 1;
`;

const BackgroundWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    z-index: 0;
`;


//Archive

// const businessProcesses1 = [
//     { id: 0, children: ['Sub-Process 1', 'Sub-Process 2', 'Sub-Process 3'], name: 'Main Process 1', description: '', order_id: 2, company: 3, survey: 6, intake: 1 },
//     { id: 1, children: ['Sub-Process 1', 'Sub-Process 2', 'Sub-Process 3'], name: 'Main Process 2', description: '', order_id: 1, company: 3, survey: 6, intake: 2 },
//     { id: 2, children: ['Sub-Process 1', 'Sub-Process 2'], name: 'Main Process 1', description: '', order_id: 1, company: 3, survey: 6, intake: 1 },
//     { id: 3, children: ['Sub-Process 1', 'Sub-Process 2'], name: 'Main Process 3', description: '', order_id: 2, company: 3, survey: 6, intake: 3 },
//     { id: 4, children: ['Sub-Process 1', 'Sub-Process 2', 'Sub-Process 3'], name: 'Main Process 3', description: '', order_id: 0, company: 3, survey: 6, intake: 3 },
//     { id: 5, children: ['Sub-Process 1', 'Sub-Process 2'], name: 'Main Process 2', description: '', order_id: 0, company: 3, survey: 6, intake: 2 },
//     { id: 6, children: ['Sub-Process 1'], name: 'Main Process 3', description: '', order_id: 1, company: 3, survey: 6, intake: 3 },
//     { id: 7, children: ['Sub-Process 1'], name: 'Main Process 1', description: '', order_id: 0, company: 3, survey: 6, intake: 1 },
//     { id: 8, children: ['Sub-Process 1'], name: 'Main Process 2', description: '', order_id: 2, company: 3, survey: 6, intake: 2 },
// ]

// const businessProcesses2 = [
//     [
//         { name: 'Main Process 1', subProcesses: ['Sub-Process 1'] },
//         { name: 'Main Process 1', subProcesses: ['Sub-Process 1', 'Sub-Process 2'] },
//         { name: 'Main Process 1', subProcesses:  ['Sub-Process 1', 'Sub-Process 2', 'Sub-Process 3'] }
//     ],
//     [
//         { name: 'Main Process 2', subProcesses: ['Sub-Process 1', 'Sub-Process 2'] },
//         { name: 'Main Process 2', subProcesses: ['Sub-Process 1', 'Sub-Process 2', 'Sub-Process 3'] },
//         { name: 'Main Process 2', subProcesses: ['Sub-Process 1'] }
//     ],
//     [
//         { name: 'Main Process 3', subProcesses: ['Sub-Process 1', 'Sub-Process 2', 'Sub-Process 3'] },
//         { name: 'Main Process 3', subProcesses: ['Sub-Process 1'] },
//         { name: 'Main Process 3', subProcesses: ['Sub-Process 1', 'Sub-Process 2'] }
//     ]
// ]