import {createContext, useState} from "react";

export const OverlayContext = createContext();

export function OverlayContextProvider(props) {
    const [header, setHeader] = useState(false);
    const [footer, setFooter] = useState(false);

    const setNames = (first_name, last_name, language) => {
        let first = "";
        let last = "";

        if (first_name.includes("/")) {
            if (language === "ch-CH") first = first_name.split('/')[1]
            else first = first_name.substring(0, first_name.indexOf('/') - 1)
        } else return first_name + " " + last_name;

        if (last_name.includes("/")) {
            if (language === "ch-CH") last = last_name.split('/')[1]
            else last = last_name.substring(0, last_name.indexOf('/') - 1)
        } else return first_name + " " + last_name;

        if (language === "ch-CH") return last + first;
        else return first + " " + last;
    }

    const setFirstName = (value, language) => {
        if (value.includes("/")) {
            if (language === "ch-CH") return value.split('/')[1]
            else return value.substring(0, value.indexOf('/') - 1)
        } else return value;
    }

    const setTeams = (value, language) => {
        if (value.includes("/")) {
            if (language === "ch-CH") return value.split('/')[1]
            else return value.substring(0, value.indexOf('/'))
        } else return value;
    }

    const setProcess = (value, language) => {
        if (value.includes("/")) {
            if (language === "ch-CH") return value.split('/')[1]
            else return value.substring(0, value.indexOf('/'))
        } else return value;
    }

    const toggleOverlay = (e) => {
        setHeader(!header);
        setFooter(!footer);
    }


    return (
        <OverlayContext.Provider
            value={{
                header,
                footer,
                toggleOverlay,
                setNames,
                setTeams,
                setProcess,
                setFirstName,
            }}>
            {props.children}
        </OverlayContext.Provider>
    );
};