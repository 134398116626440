import React, {useContext, useState, useEffect, useRef} from "react";
import styled from "styled-components";
import { StyledDivRegular } from "../../layout-and-styling/standardized-components-library/Styling-TextInput";
import SocialNetworkPage from "./components/SocialNetworkPage";
import ComparisonPage from "./components/ComparisonPage";
import { Switch } from "@mui/material";
    
const SocialNetwork = () => {
  
  const [render, setRender] = useState(true);
  const reRender = () => {
    setRender(!render);
  };

  const [comparison, setComparison] = useState(false);

  function handleSwitch(){
    setComparison(!comparison)
  }

  return (
      <>
        <PresentationSwitch>
            <Switch disableRipple onChange={handleSwitch} sx={{
              color: '#271B36', height: '38px', padding: '3px', 
              '& .MuiSwitch-switchBase': {
                padding: 1, transitionDuration: '300ms',
              },
              '& .MuiSwitch-thumb': {
                width: '24px', height: '24px', backgroundSize: '24px', color: '#271B36',
              },
              '& .MuiSwitch-track': {
                backgroundColor: '#C6CBCB', borderRadius: 13,
                '&:before, &:after': {
                  transform: 'translateX(40)',
                }
              },
              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
              backgroundColor: '#F7C9D9'
              },
            }}/>

            <StyledDivRegular Position="relative" MarginLeft="0px" Width="fit-content" MarginBottom="0px" FontSize="18px" FontWeight="400" Padding="12px 0px 0px 0px">
              Comparison
            </StyledDivRegular>
                
          </PresentationSwitch>

        {comparison ? (
          <ComparisonPage/>
        ) : (
          <NetworkContainer>

            <SocialNetworkPage Page="SocialNetwork"/>

          </NetworkContainer>
        )}
        
      </>
  );
};

export default SocialNetwork;


const NetworkContainer = styled.div`
  display: flex;
  background: ${
          props => props.Background || ""
  };
  min-width: fit-content;
  flex-direction: column;
  position: relative;
`;

const PresentationSwitch = styled.div`
  position: absolute;
  left: 64px;
  top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.JustifyContent || "center"};
  padding: 12px 12px;
  border-radius: 5px;
  background-color: #FCFCFC;
  z-index: 3;
  gap: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
`;
