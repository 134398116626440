import React, {useRef, useEffect, useState} from 'react';
import styled from "styled-components";

import {StyledDivRegular} from '../../../layout-and-styling/standardized-components-library/Styling-TextInput';
import {ReactComponent as ButtonArrowUp} from '../../../assets/intake/ButtonArrowUp.svg';
import {ReactComponent as ButtonArrowDown} from '../../../assets/intake/ButtonArrowDown.svg';
import {ReactComponent as ButtonPencil} from '../../../assets/intake/ButtonPencil.svg';
import {ReactComponent as ButtonTrash} from '../../../assets/intake/ButtonTrash.svg';
import {ReactComponent as ButtonX} from '../../../assets/intake/ButtonX.svg';
import {ReactComponent as ButtonCheck} from '../../../assets/intake/ButtonCheck.svg';

const SubProcessComponent = (props) => {
    const subProcessRef = useRef(null);
    const textInputRef = useRef(null);

    useEffect(() => {
        if (props.isShowingTextInput) {
            textInputRef.current.focus();
            textInputRef.current.select();

            const handleKeyDown = (e) => {
                if (e.key === 'Escape') {
                    hideTextInput();
                }
            };

            window.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [props.isShowingTextInput]);

    const [isShowingOverlay, setIsShowingOverlay] = useState(false);
    const [newName, setNewName] = useState('');
    const [characterLength, setCharacterLength] = useState(0);

    const showTextInput = () => {
        if(props.isOverlayEnabled) {
            if(!props.isShowingTextInput) {
                setNewName(props.subProcess.name);
                setCharacterLength(props.subProcess.name.length);
                setIsShowingOverlay(false);
                props.showTextInput(props.subProcessIndex);
            }
        }
    }

    const hideTextInput = () => {
        if(!props.isOverlayEnabled) {
            if(props.isShowingTextInput) {
                props.hideTextInput();
            }
        }
    }

    const renameSubProcess = () => {
        if(props.isShowingTextInput) {
            if (newName.trim().length !== 0) {
                props.renameSubProcess(newName);
            } else {
                props.renameSubProcess('Empty');
            }
        }

        hideTextInput();
    }

    const handleMouseEnter = () => {
        if (props.isOverlayEnabled) {
            setIsShowingOverlay(true);
            subProcessRef.current.focus();
        }
    }

    const handleMouseLeave = () => {
        if (props.isOverlayEnabled) {
            setIsShowingOverlay(false);
            subProcessRef.current.blur();
        }
    }

    const handleChange = (e) => {
        if (e.key !== 'Enter') {
            const { selectionStart, value } = e.target;
            const formattedValue = e.target.value.replace(/\s+/g, ' ');
            setNewName(formattedValue);
            const cursorPosition = selectionStart + (formattedValue.length - value.length);
            textInputRef.current.setSelectionRange(cursorPosition, cursorPosition);
            setCharacterLength(formattedValue.length);

            setTimeout(() => {
                e.target.setSelectionRange(cursorPosition, cursorPosition);
            });
        }
    };

    const handleKeyDown = (e) => {
        if (props.isShowingTextInput) {
            if (e.key === 'Enter') {
                renameSubProcess();
            }
        } else {
            if (e.key === 'ArrowUp') {
                props.moveSubProcessUp();
            }

            if (e.key === 'ArrowDown') {
                props.moveSubProcessDown();
            }

            if (e.key === 'Backspace' || e.key === 'Delete') {
                props.deleteSubProcess();
            }
        }
    };

    return (
        <Container key={props.subProcessIndex} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {props.isShowingTextInput ? (
                <>
                    <OverlayWrapper ShowTextInput={props.isShowingTextInput}>
                        <OverlaySectionWrapper>
                            <OverlayButtonWrapper onClick={hideTextInput}> 
                                <ButtonX/>
                            </OverlayButtonWrapper>

                            <OverlayButtonWrapper onClick={renameSubProcess}>
                                <ButtonCheck/>
                            </OverlayButtonWrapper>
                        </OverlaySectionWrapper>
                    </OverlayWrapper>

                    <TextWrapper IsShowingTextInput={props.isShowingTextInput}>
                        <TextInput ref={textInputRef} maxLength={40} type="text" value={newName} onChange={handleChange} onKeyDown={handleKeyDown} placeholder='Edit text...'/>
                        
                        <StyledDivRegular Position='absolute' Width='auto' Height='auto' FontSize='12px' LineHeight='16px' FontWeight='400'
                                          Color='#929699' TextAlign='right' ZIndex='1' Right='10px' Bottom='10px'>
                            {characterLength}/40
                        </StyledDivRegular>
                    </TextWrapper>
                </>
            ) : (
                <>
                    {isShowingOverlay && (
                        <OverlayWrapper onMouseEnter={handleMouseEnter}>
                            <OverlaySectionWrapper>
                                <OverlayButtonWrapper onClick={props.moveSubProcessUp} Disabled={props.isMoveUpDisabled}>
                                    <ButtonArrowUp/>
                                </OverlayButtonWrapper>
        
                                <OverlayButtonWrapper onClick={props.moveSubProcessDown} Disabled={props.isMoveDownDisabled}>
                                    <ButtonArrowDown/>
                                </OverlayButtonWrapper>
                            </OverlaySectionWrapper>
        
                            <OverlaySectionWrapper>
                                <OverlayButtonWrapper onClick={showTextInput}> 
                                    <ButtonPencil/>
                                </OverlayButtonWrapper>
        
                                <OverlayButtonWrapper onClick={props.deleteSubProcess}>
                                    <ButtonTrash/>
                                </OverlayButtonWrapper>
                            </OverlaySectionWrapper>
                        </OverlayWrapper>
                    )}
                    
                    <TextWrapper ref={subProcessRef} tabIndex='0' onKeyDown={handleKeyDown} onDoubleClick={showTextInput} IsShowingTextInput={props.isShowingTextInput}>
                        <StyledDivRegular Position='static' Width='100%' Height='auto' FontSize='16px' LineHeight='20px' FontWeight='400' 
                                          OverflowWrap='break-word' Hyphens='auto' Color='#271B36' TextAlign='center' ZIndex='1'>
                            {props.subProcess.name}
                        </StyledDivRegular>
                    </TextWrapper>
                </>
            )}
        </Container>
    )
}

export default SubProcessComponent;

const Container = styled.div`
    position: relative;
    display: flex;
    width: 165px;
    height: 80px;
    border-radius: 8px;
    background: #E3E5E4;
`;

const TextWrapper = styled.div`
    display: flex;
    width: ${props => props.IsShowingTextInput ? '155px' : '145px'};
    height: ${props => props.IsShowingTextInput ? '70px' : '60px'};
    padding: ${props => props.IsShowingTextInput ? '5px' : '10px'};
    justify-content: flex-end;
    align-items: center;
    outline: none;
    z-index: 1;
`;

const OverlayWrapper = styled.div`
    position: absolute;
    top: 0;
    left: -40px;
    display: flex;
    width: 235px;
    height: 80px;
    padding: 0 5px;
    justify-content: ${props => props.ShowTextInput ? 'flex-end' : 'space-between'};

    img {
        -drag: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }
`;

const OverlaySectionWrapper = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
`;

const OverlayButtonWrapper = styled.div`
    display: flex;
    width: 30px;
    height: 30px;
    opacity: ${props => props.Disabled ? '0.5' : ''};
    transition: opacity 0.3s, filter 0.15s, transform 0.15s;
    cursor: ${props => props.Disabled ? '' : 'pointer'};

    &:hover {
        filter: ${props => props.Disabled ? '' : 'brightness(90%)'};
        transform: ${props => props.Disabled ? '' : 'scale(1.1)'};
    }
`;

const TextInput = styled.textarea`
    position: relative;
    display: flex;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    resize: none;
    padding: 5px;
    border-radius: 6px;
    border: 1px solid #E3E5E4;
    background: #FCFCFC;
    z-index: 1;

    color: #5F6366;
    font-family: Overpass;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    ::placeholder {
        color: #929699;
    }
`;