import {StyledDivBold, StyledDivButton, StyledDivRegular} from '../standardized-components-library/Styling-TextInput';
import { ReactComponent as NoAccessImage} from '../../assets/NoAccess.svg'
import { ReactComponent as ErrorImage} from '../../assets/ErrorOccurred.svg'
import styled from "styled-components";
import {useTranslation, Trans} from 'react-i18next';

// This is the error page that appears when you try to access somewhere that you don't have access or an error occurs
const ErrorPage = (props) => {

    const { t } = useTranslation();

    const backToHome = () => {
        window.location.replace(process.env.REACT_APP_APPURL + '/home');
    }

    return(
        <>
        {props.type == "Access" ?
            // No access page error
            <Container>
                <OverlayWrapper>
                    {/* image */}
                    <NoAccessImage style={{height: '80vh'}}/>
                    {/* text */}
                    <StyledDivBold Position='static' Width='auto' Height='auto' MarginTop='40px' MarginBottom='40px' FontSize='42px' LineHeight='52px'>
                        {t('ErrorPage.NoAccess')}
                    </StyledDivBold>
                </OverlayWrapper>
            </Container>
        :
            // Error page error
            <Container>
                <OverlayWrapper>
                    {/* image */}
                    <ErrorImage style={{height: '50vh'}}/>
                    {/* text */}
                    <StyledDivBold Position='static' Width='auto' Height='auto' MarginTop='40px' MarginBottom='40px' FontSize='42px' LineHeight='52px'>
                        {t('ErrorPage.Error')}
                    </StyledDivBold>
                    {/* button */}
                    <ButtonWrapper onClick={backToHome} Width='auto'>
                        <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' Color='#FCFCFC' FontWeight='700' LetterSpacing='0.3px'>
                                {t('ErrorPage.BackToHome')}
                        </StyledDivRegular>
                    </ButtonWrapper>
                </OverlayWrapper>
            </Container>
        }
        </>
    )
}

export default ErrorPage;

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f6f7fa;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

const OverlayWrapper = styled.div`
    display: flex;
    width: calc(100% - 80px);
    max-width: 1290px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    width: ${props => props.Width || 'calc(100% - 20px)'};
    padding: 15px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: ${props => props.Color || '#E2336B'};
    transition: filter 0.15s;
    cursor: pointer;
    user-select: none;

    &:hover {
        filter: brightness(90%);
    }
`;