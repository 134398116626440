import styled from '@emotion/styled';
import {useEffect, useRef, useState} from 'react';
import ComponentFancyCheckboxBad from './Component-Fancy-Checkbox-Bad';
import {useTranslation} from "react-i18next";
import { TooltipProcessBadFeedback } from '../../standardized-components/Survey-text-processing-tooltips';
import {ReactComponent as NextButton} from "../../common-assets/next_arrow_feedback.svg";
import {ReactComponent as PrevButton} from "../../common-assets/prev_arrow_feedback.svg";
import {ReactComponent as NextButtonGray} from "../../common-assets/next_arrow_feedback_gray.svg";
import {ReactComponent as PrevButtonGray} from "../../common-assets/prev_arrow_feedback_gray.svg";
import i18n from "../../../i18n";

/** Specific component for bad feedback, contains all three main inputs: tags, teams, and a textbox for proposals. */
export default function ComponentFeedbackInputBad(props) {
  
    const isFirstRender = useRef(true);
    const {t} = useTranslation();
    const [hover1, setHover1] = useState(false);
    const [hover2, setHover2] = useState(false);

    const [selectedAllTeams, setSelectedAllTeams] = useState(false);
    useEffect(() => {
        /** Used to select/deselect all teams. First render is ignored (page load) because it is not an interaction from the user. */
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        if (selectedAllTeams) {
            props.setSelectedTeams(props.companyTeams);
        } else {
            props.setSelectedTeams([]);
        }
    }, [selectedAllTeams])


    return (
      <WrapperRightTop>
      {/* Hoverable tooltips for information */}
        <TopContainer>
          {/* Tag selector */}
            <TagContainer>
            <InfoHover1
                  onMouseOver={() => setHover1(true)}
                  onMouseOut={() => setHover1(false)}
                >
                  ?
                {hover1 && (
                  <TooltipProcessBadFeedback
                    whichTooltip={"Tags"}
                  />
                )}
                </InfoHover1>
                <BoxTitle>{t('PageBadFeedback.BoxTitle1')}</BoxTitle>
                <TagCheckboxContainer>
                    <LoadedData>
                        {props.tags.map((tag) => {
                            return (
                                <button>
                                    <ComponentFancyCheckboxBad type={"tag"} tag={tag} tags={props.tags}
                                                                translatedTags={props.translatedTags}
                                                                currentProcessPointer={props.currentProcessPointer}
                                                                fullFeedbackContainer={props.fullFeedbackContainer}
                                                                selectedTags={props.selectedTags}
                                                                setSelectedTags={props.setSelectedTags}/>
                                </button>
                            )
                        })}
                    </LoadedData>
                </TagCheckboxContainer>
            </TagContainer>

            {/* Team selector */}
            <TeamContainer>
              <InfoHover2
              onMouseOver={() => setHover2(true)}
              onMouseOut={() => setHover2(false)}
              >
              ?
              {hover2 && (
                <TooltipProcessBadFeedback
                  whichTooltip={"Teams"}
                />
              )}
              </InfoHover2>
                <BoxTitle>{t('PageBadFeedback.BoxTitle2')}</BoxTitle>
                <TeamsTitle>
                    <button type="checkbox"
                            onClick={() => setSelectedAllTeams(!selectedAllTeams)}>{t('PageBadFeedback.AllCompanyButton')}</button>
                </TeamsTitle>
                <TagCheckboxContainerTeams>
                  {/* This conditional is because Avocado's teams come with the format: "Team name" / "Chinese Team name" so this fixes
                  translation and spacing */}
                    <LoadedDataTeams>
                        {i18n.language === "en-US" || i18n.language === "nl-NL" ?
                            props.companyTeams.map((team) => {
                                let teamToShow = team;
                                let splitTeams = team.split('/');
                                if (splitTeams.length > 1) {
                                    teamToShow = splitTeams[0];

                                    if (teamToShow === "Management Team") {
                                        teamToShow = "Management"
                                    }
                                }
                                return (
                                    <button>
                                        <ComponentFancyCheckboxBad type={"team"} team={team}
                                                                    teamToShow={teamToShow}
                                                                    selectedAllTeams={selectedAllTeams}
                                                                    currentProcessPointer={props.currentProcessPointer}
                                                                    fullFeedbackContainer={props.fullFeedbackContainer}
                                                                    selectedTeams={props.selectedTeams}
                                                                    setSelectedTeams={props.setSelectedTeams}/>
                                    </button>
                                )

                            })
                         :
                            props.companyTeams.map((team) => {
                                let teamToShow = team;
                                let splitTeams = team.split('/');
                                if (splitTeams.length > 1) {
                                    teamToShow = splitTeams[1];

                                    if (teamToShow === "Management Team") {
                                        teamToShow = "Management"
                                    }
                                }
                                return (
                                    <button>
                                        <ComponentFancyCheckboxBad type={"team"} tags={props.tags}
                                                                    translatedTags={props.translatedTags} team={team}
                                                                    teamToShow={teamToShow}
                                                                    selectedAllTeams={selectedAllTeams}
                                                                    currentProcessPointer={props.currentProcessPointer}
                                                                    fullFeedbackContainer={props.fullFeedbackContainer}
                                                                    selectedTeams={props.selectedTeams}
                                                                    setSelectedTeams={props.setSelectedTeams}/>
                                    </button>
                                )
                            })
                        }
                    </LoadedDataTeams>
                </TagCheckboxContainerTeams>
            </TeamContainer>
        </TopContainer>
    </WrapperRightTop>
    )
}

const InfoHover1 = styled.div`
  font-size: 18px;
  position: absolute;
  font-family: "Overpass";
  font-weight: 300;
  color: #FCFCFC;
  background-color: #e2336b;
  border: 2px solid #e2336b;
  top: 8px;
  border-radius: 500px;
  width: 20px;
  text-align: center;
  height: 20px;
  cursor: pointer;
  z-index: 20;
  right: 8px;
  float: right;
  `;


const InfoHover2 = styled.div`
  position: absolute;
  font-size: 18px;
  font-family: "Overpass";
  font-weight: 300;
  color: #FCFCFC;
  background-color: #e2336b;
  border: 2px solid #e2336b;
  top: 8px;
  border-radius: 500px;
  width: 20px;
  text-align: center;
  height: 20px;
  cursor: pointer;
  right: 8px;
  z-index: 20;
`;


const WrapperRightTop = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fcfcfc;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  flex: 1350px;
  height: 300px;
  margin: 0 0 0 20px;
  width: 100%;
  align-items: center;
  justify-content: center;
  max-width: 1400px;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  margin-top: 17px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
`;

const TagContainer = styled.div`
  position: relative;
  border-radius: 8px;
  border-style: solid;
  border: 1px solid #929699;
  flex: 900px;
  height: fit-content;
  min-height: 260px;
  width: 100%;
  min-width: 700px;
  margin: 24px;
`;

const BoxTitle = styled.div`
  position: absolute;
  top: -15px;
  left: 15px;
  height: 30px;
  width: fit-content;
  margin: 4px 4px 0 4px;
  padding: 0 4px 0 4px;
  background-color: #fcfcfc;
  font-family: 'Overpass';
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: center;

`;

const TagCheckboxContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: calc(100% - 24px);
  width: 100%;
  margin-top: 20px;
  background-color: #fcfcfc;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const TagCheckboxContainerTeams = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: fit-content;
  width: 100%;
  margin-top: 65px;
  background-color: #fcfcfc;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  border-radius: 10px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const TeamsTitle = styled.div`
  font-size: 18px;
  position: absolute;
  top: 0;
  margin-top: 24px;
  margin-left: 24px;

  button {
    background-color: #E3E5E4;
    border: none;
    color: #271B36;
    padding: 5px 32px;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;

    :hover {
      background-color: #271B36;
      color: #fcfcfc;
    }
  }
`;


const LoadedData = styled.div`
  position: relative;
  display: grid;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  border: none;
  width: fit-content;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  margin-left: 24px;
  margin-right: 24px;

  button {
    background: none;
    border: none;
    cursor: pointer;
    width: 32%;
  }
`;

const LoadedDataTeams = styled.div`
  position: relative;
  display: grid;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  border: none;
  width: fit-content;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  margin-left: 24px;
  margin-right: 24px;
  text-align: left;

  button {
    background: none;
    border: none;
    cursor: pointer;
    width: 30%;
  }
`;

const BoxTitleFeedback = styled.div`
  position: absolute;
  top: -15px;
  left: 15px;
  height: 30px;
  width: fit-content;
  margin: 4px 4px 0 4px;
  padding: 0 4px 0 4px;
  background-color: #fcfcfc;
  font-family: 'Overpass';
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: center;
`;

const TeamContainer = styled.div`
  position: relative;
  display: flex;
  border-radius: 8px;
  border: 1px solid #929699;
  flex: 460px;
  height: fit-content;
  min-height: 260px;
  width: 100%;
  margin: 24px 24px 24px 0px;
`;

const BottomContainer = styled.div`
  position: relative;
  display: flex;
  flex: 3;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const FeedbackContainer = styled.div`
  position: relative;
  border-radius: 8px;
  border: 1px solid #929699;
  height: calc(100% - 80px);
  width: 100%;
  margin: 40px 40px 62px 40px;
  background-color: #fcfcfc;

  input {
    width: 100%;
    height: calc(100% - 25px);

    margin-top: 20px;
  }
`;

const FeedbackInput = styled.textarea`
  position: relative;
  border: none;
  border-radius: 8px;
  width: calc(100% - 45px);
  padding: 8px;
  height: calc(100% - 40px);
  margin-top: 20px;
  background-color: #fcfcfc;
  resize: none;
  margin-left: 20px;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  outline: 0;

  ::placeholder {
    color: #929699;
    font-weight: 400;
  }
`;

const StyledNextButton = styled(NextButton)`
  margin: 12px;
  cursor: pointer;
`;

const StyledPrevButton = styled(PrevButton)`
  margin: 12px;
  cursor: pointer;
`;

const StyledNextButtonGray = styled(NextButtonGray)`
  margin: 12px;
  cursor: pointer;
`;

const StyledPrevButtonGray = styled(PrevButtonGray)`
  margin: 12px;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
  width: calc(100% - 60px);
  justify-content: space-between;
  align-items: center;

  p {
    font-family: 'Overpass';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0;
    text-align: center;
    color: #06D6A0;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
  }
`;

const NavCheckmarks = styled.div`
position: absolute;
  display: flex;
  flex-direction: row;
  bottom: 42px;
`;

const NavPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
`;

const SelectedCheck = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  outline: ${props => props.Outline || ""};
`;