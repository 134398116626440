import styled from 'styled-components';
import { StyledDivRegular } from '../../layout-and-styling/standardized-components-library/Styling-TextInput';
import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";

// This file contains all the explanatory tooltips used in the social network survey pages and its introductions

export const TooltipIntroductionCloseColleagueFilter = (props) => {
    const { i18n } = useTranslation();
    let margin = "0px";

    if (props.whichTooltip === "Interaction") {
        if (i18n.language === "ch-CH") margin = "-20px"
        else if (i18n.language === "nl-NL") margin = "-350px"
        else margin = "-20px"
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginTop="40px" MarginLeft={margin}>

                <ExplanationBoxContainer Width='456px' Height="fit-content" ZIndex='4' Position="absolute">

                    <IntroExplanationBox Width='456px' Height="fit-content" Position="static">

                        <StyledDivRegular Position="static" MarginTop='24px' MarginLeft='24px' MarginRight='24px' MarginBottom='24px'
                            Width='408px' Height='fit-content'
                            FontSize='18px' LineHeight='28px' TextAlign="left">
                            {i18next.t('IntroPageCloseColleagueFilter.HoverExplanationBox1')}
                        </StyledDivRegular>
                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='-48px' Left='10px' BorderBottom='30px solid #E2336B'
                        BorderRight='10px solid transparent' BorderTop=''
                        BorderLeft='10px solid transparent' />
                    <IntroExplanationInnerTriangle Top='-45px' Left='10px' BorderBottom='30px solid #FCFCFC'
                        BorderRight='10px solid transparent' BorderTop=''
                        BorderLeft='10px solid transparent' />

                </ExplanationBoxContainer>
            </IntroOverlayBoxWrapper>
        )
    }

    else if (props.whichTooltip === "TypicalWeek") {
        if (i18n.language === "ch-CH") margin = "-20px"
        else if (i18n.language === "nl-NL") margin = "0px"
        else margin = "-20px"
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginTop="40px" MarginLeft={margin}>

                <ExplanationBoxContainer Width='456px' Height="fit-content" ZIndex='4' Position="absolute">

                    <IntroExplanationBox Width='456px' Height="fit-content" Position="static">

                        <StyledDivRegular Position="static" MarginTop='24px' MarginLeft='24px' MarginRight='24px' MarginBottom='24px'
                            Width='408px' Height='fit-content'
                            FontSize='18px' LineHeight='28px' TextAlign="left">
                            {i18next.t('IntroPageCloseColleagueFilter.HoverExplanationBox2')}
                        </StyledDivRegular>
                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='-48px' Left='10px' BorderBottom='30px solid #E2336B'
                        BorderRight='10px solid transparent' BorderTop=''
                        BorderLeft='10px solid transparent' />
                    <IntroExplanationInnerTriangle Top='-45px' Left='10px' BorderBottom='30px solid #FCFCFC'
                        BorderRight='10px solid transparent' BorderTop=''
                        BorderLeft='10px solid transparent' />

                </ExplanationBoxContainer>
            </IntroOverlayBoxWrapper>
        )
    }

    else if (props.whichTooltip === "CustomerSupplier") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginTop={i18n.language === "nl-NL" ? "24px" : i18n.language === "ch-CH" ? "24px" : "0px"} MarginLeft={i18n.language === "ch-CH" ? "0px" : "24px"}>

                <ExplanationBoxContainer Width='456px' Height="fit-content" ZIndex='4' Position="absolute">

                    <IntroExplanationBox Width='456px' Height="fit-content" Position="static">

                        <StyledDivRegular Position="static" MarginTop='24px' MarginLeft='24px' MarginRight='24px' MarginBottom='24px'
                            Width='408px' Height='fit-content' FontSize='18px' LineHeight='28px' TextAlign="left">
                            {i18next.t('IntroPageCloseColleagueFilter.HoverExplanationBox3')}
                        </StyledDivRegular>
                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='-48px' Left='10px' BorderBottom='30px solid #E2336B'
                        BorderRight='10px solid transparent' BorderTop=''
                        BorderLeft='10px solid transparent' />
                    <IntroExplanationInnerTriangle Top='-45px' Left='10px' BorderBottom='30px solid #FCFCFC'
                        BorderRight='10px solid transparent' BorderTop=''
                        BorderLeft='10px solid transparent' />

                </ExplanationBoxContainer>
            </IntroOverlayBoxWrapper>
        )
    }

}

export const TooltipCloseColleagueFilter = (props) => {
    const { i18n } = useTranslation();

    if (props.whichTooltip === "CloseColleagues") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-620px" MarginTop="-90px">
                <ExplanationBoxContainer Width='565px' Height="fit-content" ZIndex='4'
                    Position="absolute">

                    <IntroExplanationBox Width='565px' Height="fit-content"
                        Position="static">
                        <StyledDivRegular Position="static" MarginTop='24px' MarginBottom='24px' MarginLeft='24px' MarginRight='24px'
                            Width='517px' Height="fit-content" FontSize='18px' LineHeight='28px'
                            TextAlign="left">
                            <Trans i18nKey="PageCloseColleagueFilter.IntroExplanationBox3">
                                Select the colleagues you interact with in a typical week. Interaction means spending time with your colleagues, this includes all <b>work related but also social interactions</b>, e.g. a meeting with your team, but also lunch or after work drinks. Whether this takes place <b>on site in person</b> or <b>online remotely</b> doesn’t matter, it all counts as an interaction. A typical work week is hard to estimate, but we have to start from somewhere. Take the <b>last one or two months as reference</b> and think of how your typical work week in that period looked like. For example a period where you have <b>abnormal events</b> like special project deadlines uncommon business travels should not count. <b>Tip: you can filter by team, if you are sure you don't interact with a specific team.</b>
                            </Trans>
                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='60px' Left='569px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='60px' Left='566px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />

                </ExplanationBoxContainer>

            </IntroOverlayBoxWrapper>
        )
    }

    if (props.whichTooltip === "CustomerSuppliers") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-500px" MarginTop="-40px">
                <ExplanationBoxContainer Width='450px' Height="fit-content" ZIndex='9'
                    Position="absolute">

                    <IntroExplanationBox Width="450px" Height="fit-content" Position="static">
                        <StyledDivRegular Position="static" MarginTop='24px' MarginBottom='24px' MarginLeft='24px' MarginRight='24px'
                            Width='517px' Height="fit-content" FontSize='18px' LineHeight='28px'
                            TextAlign="left">
                            <Trans i18nKey="PageCloseColleagueFilter.IntroExplanationBox5" />
                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='10px' Left='454px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='10px' Left='451px' BorderTop="10px solid transparent"
                        BorderRight='20px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />

                </ExplanationBoxContainer>

            </IntroOverlayBoxWrapper>
        )
    }

    if (props.whichTooltip === "Customer") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-400px" MarginTop="-60px">
                <ExplanationBoxContainer Width='365px' Height="fit-content" ZIndex='4'
                    Position="absolute">

                    <IntroExplanationBox Width='350px' Height="fit-content" Position="static">
                        <StyledDivRegular Position="static" MarginTop='24px' MarginBottom='24px' MarginLeft='24px' MarginRight='24px'
                            Width='350px' Height="fit-content" FontSize='18px' LineHeight='28px'
                            TextAlign="left">
                            <Trans i18nKey="PageCloseColleagueFilter.IntroExplanationBox6" />
                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='30px' Left='353px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='30px' Left='350px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />

                </ExplanationBoxContainer>

            </IntroOverlayBoxWrapper>
        )
    }

    if (props.whichTooltip === "Suppliers") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-416px" MarginTop="-60px">
                <ExplanationBoxContainer Width='365px' Height="fit-content" ZIndex='4'
                    Position="absolute">

                    <IntroExplanationBox Width='365px' Height="fit-content" Position="static">
                        <StyledDivRegular Position="static" MarginTop='24px' MarginBottom='24px' MarginLeft='32px' MarginRight='32px'
                            Width='350px' Height="fit-content" FontSize='18px' LineHeight='28px'
                            TextAlign="left">
                            <Trans i18nKey="PageCloseColleagueFilter.IntroExplanationBox7" />
                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='30px' Left='368px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='30px' Left='365px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />

                </ExplanationBoxContainer>

            </IntroOverlayBoxWrapper>
        )
    }
}

export const TooltipMeetingsPage = (props) => {
    const { i18n } = useTranslation();

    if (props.whichTooltip === "Meetings") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-600px" MarginTop="-40px">

                <ExplanationBoxContainer Width='580px' Height="fit-content" ZIndex='4'
                    Position="absolute">

                    <IntroExplanationBox Width='550px' Height="fit-content" Position="static">
                        <StyledDivRegular MarginTop='24px' MarginBottom='24px' MarginLeft='24px' Width='550px' Height='fit-content'
                            FontSize='18px' LineHeight='28px' Position="static" TextAlign="left">
                            <Trans i18nKey="PagePlannedMeetings.ExplanationBox4">
                                Think about all those planned meetings that you have almost every week. To add a meeting to a day, click on a day of the week and then press the <b>Add meeting</b> button, then the meeting will appear. Click on the duration field, that initially is 00:00 and a slider will pop up, drag it to set the meeting duration time. You can also change the name of the meeting by clicking on the default name, e.g Meeting 1. You can also remove meetings by clicking them and pressing the <b>Remove meeting</b> button afterwards. Add extra days if your working week is longer than 5 days.
                            </Trans>
                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='10px' Left='553px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='10px' Left='550px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />
                </ExplanationBoxContainer>

            </IntroOverlayBoxWrapper>
        )
    }
    if (props.whichTooltip === "PeopleSelected") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-580px" MarginTop="-40px">

                <ExplanationBoxContainer Width='520px' Height='fit-content' ZIndex='4'
                    Position="absolute">

                    <IntroExplanationBox Width='520px' Height='fit-content' Position="static">
                        <StyledDivRegular MarginTop='24px' MarginBottom='24px' MarginLeft='24px' Width='473px' Height='fit-content'
                            FontSize='18px' LineHeight='28px' Position="static" TextAlign="left">
                            {i18next.t('PagePlannedMeetings.ExplanationBox5')}
                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='10px' Left='524px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='10px' Left='521px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />
                </ExplanationBoxContainer>

            </IntroOverlayBoxWrapper>
        )
    }
}

export const TooltipIntroSpontaneousCollaborationPage = (props) => {
    const { i18n } = useTranslation();

    if (props.whichTooltip === "ExtraDays") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginTop="24px" MarginLeft={i18n.language == "nl-NL" ? "-504px" : "-364px"}>

                <ExplanationBoxContainer Width='456px' Height='fit-content' ZIndex='4' Position="absolute">

                    <IntroExplanationBox Width='456px' Height='fit-content' Position="static">

                        <StyledDivRegular Position="static" MarginTop='24px' MarginLeft='24px' MarginRight='24px'
                            Width='408px' Height='fit-content' MarginBottom='24px'
                            FontSize='18px' LineHeight='28px' TextAlign="left">
                            {i18next.t('IntroPageSpontaneousCollaboration.HoverExplanationBox')}
                        </StyledDivRegular>
                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='-48px' Left={i18n.language == "nl-NL" ? '164px' : '332px'} BorderBottom='30px solid #E2336B'
                        BorderRight='10px solid transparent' BorderTop=''
                        BorderLeft='10px solid transparent' />
                    <IntroExplanationInnerTriangle Top='-45px' Left={i18n.language == "nl-NL" ? '164px' : '332px'} BorderBottom='30px solid #FCFCFC'
                        BorderRight='10px solid transparent' BorderTop=''
                        BorderLeft='10px solid transparent' />

                </ExplanationBoxContainer>
            </IntroOverlayBoxWrapper>
        )
    }
}

export const TooltipSpontaneousCollaborationPage = (props) => {
    const { i18n } = useTranslation();

    if (props.whichTooltip === "SelectedPeople") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="50px" MarginTop="-40px">

                <ExplanationBoxContainer Width='520px' Height='fit-content' ZIndex='4'
                    Position="absolute">

                    <IntroExplanationBox Top='0px' Left='0' Width='581px' Height='fit-content' Position="static">
                        <StyledDivRegular MarginTop='24px' MarginBottom="24px" MarginLeft='32px' MarginRight='32px'
                            Width='517px' Height='fit-content' Position="static" TextAlign="left"
                            FontSize='18px' LineHeight='28px'>
                            <Trans i18nKey="PageSpontaneousCollaboration.ExplanationBox1">
                                This is the list of colleagues you previously selected that you usually interact with in a week. Scroll through the list and fill in for every colleague how much you interact with them on a weekly basis.
                            </Trans>

                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='10px' Left='-58px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B'
                        Rotate="rotate(180deg)" />
                    <IntroExplanationInnerTriangle Top='10px' Left='-53px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC'
                        Rotate="rotate(180deg)" />
                </ExplanationBoxContainer>

            </IntroOverlayBoxWrapper>
        )
    }

    else if (props.whichTooltip === "Sliders") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-600px" MarginTop="-56px">

                <ExplanationBoxContainer Width='540px' Height='fit-content' ZIndex='4' Position="absolute">

                    <IntroExplanationBox Top='0px' Left='0' Width='540px' Height='fit-content' Position="static">
                        <StyledDivRegular MarginTop='24px' MarginBottom="24px" MarginLeft='32px' MarginRight='32px'
                            Width='476px' Height='fit-content' Position="static" TextAlign="left"
                            FontSize='18px' LineHeight='28px'>
                            <Trans i18nKey="PageSpontaneousCollaboration.ExplanationBox2">
                                Fill in the time you <b>spontaneously</b> interact with your colleagues by dragging the daily average slider or the specific ones per day. <b>Spontaneous time</b> = communicating with a colleague outside of a planned meeting. It can be just going up to someone to talk, in breaks, or even outside of work. Drag the sliders to set the values. You can also see the interaction in meetings that you have filled in before for the specific colleague, in case you did.
                            </Trans>

                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='25px' Left='543px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='25px' Left='540px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />

                </ExplanationBoxContainer>

            </IntroOverlayBoxWrapper>
        )
    }
}


export const TooltipCollaborationMeasurement = (props) => {
    if (props.whichTooltip === "SelectedPeople") {
        return (
            <>
                <IntroOverlayBoxWrapper Position="absolute" MarginLeft="50px" MarginTop="-54px">
                    <ExplanationBoxContainer Width='581px' Height='fit-content' ZIndex='4' Position="absolute">

                        <IntroExplanationBox Top='0px' Left='0' Width='581px' Height='fit-content' Position="static">
                            <StyledDivRegular MarginTop='24px' MarginBottom="24px" MarginLeft='32px' MarginRight='32px'
                                Width='517px' Height='fit-content' Position="static" TextAlign="left"
                                FontSize='18px' LineHeight='28px'>
                                {i18next.t('PageCollaborationMeasurement.ExplanationBox1')}
                                <br /><br />
                                {i18next.t('PageCollaborationMeasurement.ExplanationBox2')}

                            </StyledDivRegular>

                        </IntroExplanationBox>

                        <IntroExplanationOuterTriangle Top='20px' Left='-30px' BorderTop='10px solid transparent'
                            BorderBottom="10px solid transparent" />
                        <IntroExplanationInnerTriangle Top='20px' Left='-27px' BorderTop="10px solid transparent"
                            BorderBottom="10px solid transparent" />

                    </ExplanationBoxContainer>

                </IntroOverlayBoxWrapper>

                <IntroOverlayBoxWrapper Position="absolute" MarginLeft="160px" MarginTop="264px">
                    <ExplanationBoxContainer Width='530px' Height='116px' ZIndex='4' Position="absolute">

                        <IntroExplanationBox Top='0px' Left='30px' Width='470px' Height='116px' Position="static">
                            <StyledDivRegular MarginTop='24px' MarginBottom="24px" MarginLeft='45px' MarginRight='32px'
                                Width='415px' Height='116px' Position="static" TextAlign="left"
                                FontSize='18px' LineHeight='28px'>
                                {i18next.t('PageCollaborationMeasurement.ExplanationBox3')}
                            </StyledDivRegular>

                        </IntroExplanationBox>
                        <IntroExplanationOuterTriangle Top='118px' Left='270px' BorderTop='30px solid #E2336B'
                            BorderBottom="" BorderRight='10px solid transparent'
                            BorderLeft='10px solid transparent' />
                        <IntroExplanationInnerTriangle Top='115px' Left='270px' BorderTop='30px solid #FCFCFC'
                            BorderRight="10px solid transparent" BorderBottom=''
                            BorderLeft="10px solid transparent" />

                    </ExplanationBoxContainer>
                </IntroOverlayBoxWrapper>
            </>
        )
    }

    if (props.whichTooltip === "ImpactPleasantness") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-590px" MarginTop="-55px">

                <ExplanationBoxContainer Width='540px' Height='fit-content' ZIndex='4' Position="absolute">

                    <IntroExplanationBox Top='0px' Left='0' Width='540px' Height='fit-content' Position="static">
                        <StyledDivRegular MarginTop='24px' MarginBottom="24px" MarginLeft='32px' MarginRight='32px'
                            Position="static" Width='476px' Height='fit-content' FontSize='18px' LineHeight='28px' TextAlign="left">
                            <Trans i18nKey="PageCollaborationMeasurement.ExplanationBox7">
                                Rate how you experience the overall <b>working interaction</b> with each colleague. <b>High Impact</b> = knowledgeable, contributes to your projects, trustworthy and solution oriented. This colleague enables a positive outcome for your work.<b>Pleasantness</b> = respectful, engaging conversation style, lively relation. This colleague contributes to an environment where people easily share information and keeps the fun alive in the office.
                            </Trans>

                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='25px' Left='543px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='25px' Left='540px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />
                </ExplanationBoxContainer>

            </IntroOverlayBoxWrapper>
        )
    }
}


export const TooltipProactivenessPage = (props) => {
    if (props.whichTooltip === "SelectedPeople") {
        return (
            <>
                <IntroOverlayBoxWrapper Position="absolute" MarginLeft="50px" MarginTop="-54px">
                    <ExplanationBoxContainer Width='581px' Height='fit-content' ZIndex='4' Position="absolute">

                        <IntroExplanationBox Top='0px' Left='0' Width='581px' Height='fit-content' Position="static">
                            <StyledDivRegular MarginTop='24px' MarginBottom="24px" MarginLeft='24px' MarginRight='24px'
                                Width='517px' Height='fit-content' Position="static" TextAlign="left"
                                FontSize='18px' LineHeight='28px'>
                                {i18next.t('PageCollaborationMeasurement.ExplanationBox1')}
                                <br /><br />
                                {i18next.t('PageCollaborationMeasurement.ExplanationBox2')}

                            </StyledDivRegular>

                        </IntroExplanationBox>

                        <IntroExplanationOuterTriangle Top='20px' Left='-30px' BorderTop='10px solid transparent'
                            BorderBottom="10px solid transparent" />
                        <IntroExplanationInnerTriangle Top='20px' Left='-27px' BorderTop="10px solid transparent"
                            BorderBottom="10px solid transparent" />

                    </ExplanationBoxContainer>

                </IntroOverlayBoxWrapper>

                <IntroOverlayBoxWrapper Position="absolute" MarginLeft="160px" MarginTop="264px">
                    <ExplanationBoxContainer Width='530px' Height='116px' ZIndex='4' Position="absolute">

                        <IntroExplanationBox Top='0px' Left='30px' Width='470px' Height='116px' Position="static">
                            <StyledDivRegular MarginTop='24px' MarginBottom="24px" MarginLeft='24px' MarginRight='24px'
                                Width='415px' Height='116px' Position="static" TextAlign="left"
                                FontSize='18px' LineHeight='28px'>
                                {i18next.t('PageCollaborationMeasurement.ExplanationBox3')}
                            </StyledDivRegular>

                        </IntroExplanationBox>
                        <IntroExplanationOuterTriangle Top='118px' Left='270px' BorderTop='30px solid #E2336B'
                            BorderBottom="" BorderRight='10px solid transparent'
                            BorderLeft='10px solid transparent' />
                        <IntroExplanationInnerTriangle Top='115px' Left='270px' BorderTop='30px solid #FCFCFC'
                            BorderRight="10px solid transparent" BorderBottom=''
                            BorderLeft="10px solid transparent" />

                    </ExplanationBoxContainer>
                </IntroOverlayBoxWrapper>
            </>
        )
    }

    if (props.whichTooltip === "Sliders") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-590px" MarginTop="-55px">

                <ExplanationBoxContainer Width='540px' Height='fit-content' ZIndex='4' Position="absolute">

                    <IntroExplanationBox Top='10px' Left='0' Width='540px' Height='fit-content' Position="static">

                        <StyledDivRegular MarginTop='24px' MarginBottom="24px" MarginLeft='24px' MarginRight='24px'
                            Width='476px' Height='fit-content' FontSize='18px' LineHeight='28px' Position="static"
                            TextAlign="left">
                            <Trans i18nKey="PageProactiveness.ExplanationBox1">
                                Rate the proactiveness of the selected colleague based on the three following questions. A colleague being <b>proactive</b> means that this colleague brings new ideas and communicates them to the rest of the team, but also takes responsability and pushes for them to be implemented.
                            </Trans>
                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='25px' Left='543px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='25px' Left='540px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />
                </ExplanationBoxContainer>

            </IntroOverlayBoxWrapper>
        )
    }
}

export const TooltipCulturePage = (props) => {
    const { i18n } = useTranslation();
    if (props.whichTooltip === "PageCulture") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-590px" MarginTop="-55px">

                <ExplanationBoxContainer Width='540px' Height='fit-content' ZIndex='4' Position="absolute">

                    <IntroExplanationBox Top='10px' Left='0' Width='540px' Height='fit-content' Position="static">

                        <StyledDivRegular Position="static" MarginTop='24px' MarginLeft='24px' MarginRight='24px' MarginBottom="24px"
                            Width='476px' Height='fit-content' FontSize='18px' LineHeight='28px'
                            TextAlign="left">
                            <Trans i18nKey="PageCulture.ExplanationBox1">

                            </Trans>
                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='25px' Left='543px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='25px' Left='540px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />
                </ExplanationBoxContainer>

            </IntroOverlayBoxWrapper>
        )
    }


    if (props.whichTooltip === "PageCulturePeerPeopleFilter") {

        return (

            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="50px" MarginTop="-40px">

                <ExplanationBoxContainer Width='520px' Height="fit-content" ZIndex='4' Position="absolute">

                    <IntroExplanationBox Top='0px' Left='0' Width='581px' Height='fit-content' Position="static">
                        <StyledDivRegular MarginTop='24px' MarginLeft='24px' MarginRight='24px' MarginBottom="24px"
                            Width='517px' Height='fit-content' Position="static" TextAlign="left"
                            FontSize='18px' LineHeight='28px'>
                            {i18next.t('PageCulture.TooltipSelectedColleagues')}
                            <br /><br />
                            {i18next.t('PageCulture.TooltipSelectedColleagues2')}

                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='10px' Left='-58px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B'
                        Rotate="rotate(180deg)" />
                    <IntroExplanationInnerTriangle Top='10px' Left='-53px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC'
                        Rotate="rotate(180deg)" />
                </ExplanationBoxContainer>
                {/* {i18next.t('IntroPageCulture.HoverExplanationBox1')}
                                <br/> <br/>
                                {i18next.t('IntroPageCulture.HoverExplanationBox2')} */}

            </IntroOverlayBoxWrapper>
        )
    }

    if (props.whichTooltip === "Slider") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginTop="32px" MarginLeft="-20px">

                <ExplanationBoxContainer Width='456px' Height='fit-content' ZIndex='4' Position="absolute">

                    <IntroExplanationBox Width='456px' Height='fit-content' Position="static">

                        <StyledDivRegular Position="static" MarginTop='24px' MarginLeft='24px' MarginRight='24px'
                            Width='408px' Height='fit-content' MarginBottom='24px'
                            FontSize='18px' LineHeight='28px' TextAlign="left">
                            {i18next.t('IntroPageCulture.HoverExplanationBox1')}
                            <br></br>
                        </StyledDivRegular>
                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='-48px' Left='10px' BorderBottom='30px solid #E2336B'
                        BorderRight='10px solid transparent' BorderTop=''
                        BorderLeft='10px solid transparent' />
                    <IntroExplanationInnerTriangle Top='-45px' Left='10px' BorderBottom='30px solid #FCFCFC'
                        BorderRight='10px solid transparent' BorderTop=''
                        BorderLeft='10px solid transparent' />

                </ExplanationBoxContainer>
            </IntroOverlayBoxWrapper>
        )
    }

}

export const ComponentTooltipRepresentativeChoice = (props) => {
    const { i18n } = useTranslation();

    if (props.whichTooltip === "RepresentativeChooser") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-620px" MarginTop="-90px">
                <ExplanationBoxContainer Width='565px' Height="fit-content" ZIndex='4'
                    Position="absolute">

                    <IntroExplanationBox Width='565px' Height="fit-content"
                        Position="static">
                        <StyledDivRegular Position="static" MarginTop='24px' MarginBottom='24px' MarginLeft='24px' MarginRight='24px'
                            Width='517px' Height="fit-content" FontSize='18px' LineHeight='28px'
                            TextAlign="left">
                            <Trans i18nKey="IntakeRepresentativeChoice.ExplanationBoxChooser1">
                                Now that you've created the first version of your business' process structure, we'd like some opinions from other people in the company about what you've created. They can make changes as they see fit and once all of them are done, one final meeting is held where the <b>final version</b> is made.
                            </Trans>
                            <br></br>
                            <br></br>
                            <Trans i18nKey="IntakeRepresentativeChoice.ExplanationBoxChooser2">
                                Please select one person per team <b>(if applicable)</b> to fill in the business' process structure. Consider who you think would know the most about the company and its processes. Once you've made your selection, press the <b>'Next'</b> button.
                            </Trans>
                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='60px' Left='569px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='60px' Left='566px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />

                </ExplanationBoxContainer>

            </IntroOverlayBoxWrapper>
        )
    }
}

const IntroOverlayBoxWrapper = styled.div`
  position: ${props => props.Position || "fixed"};
  margin-top: ${props => props.MarginTop || "0px"};
  margin-right: ${props => props.MarginRight || "0px"};
  margin-left: ${props => props.MarginLeft || "0px"};
  margin-bottom: ${props => props.MarginBottom || "0px"};
  transition: 2s ease-out 100ms;
  left: 50%;
  top: 100%;
  z-index: 4;
  transform: translate(-50%, 0.75rem);
`;

const ExplanationBoxContainer = styled.div`
  position: ${props => props.Position || "fixed"};
  top: ${props => props.Top || "0px"};
  left: ${props => props.Left || "0px"};
  min-width: ${props => props.Width || "550px"};
  height: ${props => props.Height || "300px"};
  display: ${props => props.Display || ""};
  z-index: ${props => props.ZIndex || "3"};
`;

const IntroExplanationBox = styled.div`
  position: ${props => props.Position || "absolute"};
  top: ${props => props.Top || "258px"};
  left: ${props => props.Left || "1000px"};
  width: ${props => props.Width || "540px"};
  height: ${props => props.Height || "250px"};
  background: #FCFCFC;
  z-index: ${props => props.ZIndex || "3"};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: ${props => props.FontFamily || 'Overpass'};
  font-size: ${props => props.FontSize || '18px'};
  line-height: ${props => props.LineHeight || '28px'};
  font-weight: ${props => props.FontWeight || '400'};
  border: 2px solid #E2336B;
  border-radius: 10px;
`;

const IntroExplanationOuterTriangle = styled.div`
  position: absolute;
  top: ${props => props.Top || "288px"};
  left: ${props => props.Left || "970px"};
  width: 0;
  height: 0;
  border-top: ${props => props.BorderTop || "20px solid transparent"};
  border-right: ${props => props.BorderRight || "30px solid #E2336B"};
  border-bottom: ${props => props.BorderBottom || "20px solid transparent"};
  border-left: ${props => props.BorderLeft || ""};
  z-index: ${props => props.ZIndex || "3"};
  transform: ${props => props.Rotate || ""};
`;

const IntroExplanationInnerTriangle = styled.div`
  position: absolute;
  top: ${props => props.Top || "288px"};
  left: ${props => props.Left || "973px"};
  width: 0;
  height: 0;
  border-top: ${props => props.BorderTop || "20px solid transparent"};
  border-right: ${props => props.BorderRight || "30px solid #FCFCFC"};
  border-bottom: ${props => props.BorderBottom || "20px solid transparent"};
  border-left: ${props => props.BorderLeft || ""};
  z-index: ${props => props.ZIndex || "3"};
  transform: ${props => props.Rotate || ""};
`;
