import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components";

import {useTranslation} from 'react-i18next';
import {ReactComponent as CloseIcon} from '../../../assets/intake/ButtonXSimple.svg';
import {StyledDivBold, StyledDivRegular} from '../../../layout-and-styling/standardized-components-library/Styling-TextInput';
import MainProcessComponent from './MainProcessComponent';
import SubProcessComponent from './SubProcessComponent';

const SidebarComponent = (props) => {
    const { t } = useTranslation();

    const [selectedBusinessProcess, setSelectedBusinessProcess] = useState({ mainProcessesIndex: null });
    const [isShowingOverlay, setIsShowingOverlay] = useState(false);
    const [selectedVersion, setSelectedVersion] = useState('');

    // const handleMouseEnter = (mainProcessesIndex) => {
    //     setSelectedBusinessProcess({ mainProcessesIndex: mainProcessesIndex });
    //     setIsShowingOverlay(true);
    // }

    // const handleMouseLeave = () => {
    //     setIsShowingOverlay(false);
    //     setSelectedBusinessProcess({ mainProcessesIndex: null });
    // }

    const handleClick = (mainProcessesIndex, version) => {
      if (!isShowingOverlay){
        setSelectedBusinessProcess({ mainProcessesIndex: mainProcessesIndex });
        setSelectedVersion(version);
        setIsShowingOverlay(true);
      }
      else{
        setIsShowingOverlay(false);
        setSelectedVersion('');
        setSelectedBusinessProcess({ mainProcessesIndex: null });
      }
    }

    const handleCloseClick = () => {
        setIsShowingOverlay(false);
        setSelectedVersion('');
      }

    return (
        <>
            <SidebarWrapper isShowingIntroduction={props.isShowingIntroduction}>
                <StyledDivRegular Position='static' Width='auto' Height='auto' MarginLeft='40px' FontSize='16px' LineHeight='20px' FontWeight='700' LetterSpacing='0.3px'>
                    {t('PageIntakeBuilder.SidebarTextHeader')}
                </StyledDivRegular>

                <VersionContainer>
                    {props.businessProcesses.map((businessProcess, businessProcessIndex) => (
                      // <VersionWrapper onMouseEnter={() => handleMouseEnter(businessProcessIndex)} onMouseLeave={handleMouseLeave}></VersionWrapper>
                      <VersionWrapper onClick={() => handleClick(businessProcessIndex, businessProcess.userName)} isSelected={selectedVersion === businessProcess.userName}>
                            <ProcessContainer>
                                <ProcessWrapper>
                                    {businessProcess.mainProcesses.map((mainProcess, mainProcessIndex) => (
                                        <MainProcessWrapper>
                                            <MainProcessComponent mainProcess={mainProcess} mainProcessIndex={mainProcessIndex}/>

                                            {mainProcess.subProcesses.map((subProcess, subProcessIndex) => (
                                                <SubProcessComponent subProcess={subProcess} subProcessIndex={subProcessIndex}/>
                                            ))}
                                        </MainProcessWrapper>
                                    ))}
                                </ProcessWrapper>
                            </ProcessContainer>

                            <StyledDivRegular Position='static' Width='145px' Height='auto' FontSize='16px' LineHeight='20px' Color='#5F6366' FontWeight='700' LetterSpacing='0.3px' TextAlign='center' WhiteSpace='nowrap' Overflow='hidden' TextOverflow='ellipsis'>
                                {businessProcess.userName}
                            </StyledDivRegular>
                        </VersionWrapper>
                    ))}               
                </VersionContainer>
            </SidebarWrapper>

            {isShowingOverlay && (
                <OverlayContainer>
                    <OverlayWrapper>
                        <StyledDivBold Position='static' Width='auto' Height='auto' FontSize='42px' LineHeight='52px'>
                            {t('PageIntakeBuilder.OverlayTextHeader', {userName: props.businessProcesses[selectedBusinessProcess.mainProcessesIndex].userName})}
                        </StyledDivBold>

                        <ProcessWrapper isShowingOverlay={isShowingOverlay}>
                            {props.businessProcesses[selectedBusinessProcess.mainProcessesIndex].mainProcesses.map((mainProcess, mainProcessIndex) => (
                                <MainProcessWrapper isShowingOverlay={isShowingOverlay}>
                                    <MainProcessComponent mainProcess={mainProcess} mainProcessIndex={mainProcessIndex}/>

                                    {mainProcess.subProcesses.map((subProcess, subProcessIndex) => (
                                        <SubProcessComponent subProcess={subProcess} subProcessIndex={subProcessIndex}/>
                                    ))}
                                </MainProcessWrapper>
                            ))}
                        </ProcessWrapper>
                        <IconWrapper>
                          <CloseIcon onClick={() => handleCloseClick()}/>
                        </IconWrapper>
                    </OverlayWrapper>
                </OverlayContainer>
            )}
        </>
    )
}

export default SidebarComponent;

const SidebarWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  height: calc(100% - 108px);
  padding: 88px 4px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  background: #FCFCFC;
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.05);
  pointer-events: ${props => props.isShowingIntroduction ? 'none' : 'auto'};
  z-index: 3;
`;

const VersionContainer = styled.div`
  display: flex;
  height: 100%;
  padding: 0 28px;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
`;

const VersionWrapper = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #FCFCFC;
  user-select: none;
  cursor: pointer;
  filter: ${props => props.isSelected ? 'brightness(85%)' : ''};
  scale: ${props => props.isSelected ? '0.9' : '1'};
  transition: filter 0.15s, scale 0.3s;
  &:hover {
    filter: brightness(90%);
  }
`;

const ProcessContainer = styled.div`
  display: flex;
  width: 129px;
  height: 68px;
  padding: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 8px;
  background: #F6F7FA;
`;

const ProcessWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 60px;
  transform: ${props => props.isShowingOverlay ? '' : 'scale(0.1)'};
  transform-origin: ${props => props.isShowingOverlay ? '' : 'top left'};

  @media (max-width: 1370px) or (max-height: 900px) {
    gap: ${props => props.isShowingOverlay ? '45px' : '60px'};
  }
`;

const MainProcessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  @media (max-width: 1370px) or (max-height: 900px) {
    gap: ${props => props.isShowingOverlay ? '10px' : '20px'};
  }
`;

const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(246, 247, 250, 0.75);
  backdrop-filter: blur(12.5px);
  z-index: 2;
`;

const OverlayWrapper = styled.div`
  display: flex;
  min-width: 1290px;
  min-height: 782px;
  flex-direction: column;
  align-items: flex-start;
  padding: 108px 40px 40px 257px;
  gap: 50px;

  @media (max-width: 1370px) or (max-height: 900px) {
    min-width: 1215px;
    min-height: 717px;
    gap: 45px;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 11vh;
  right: 2vw;
  max-width: 75px;
  max-height: 75px;
  padding: 10px 10px 6px 10px;
  cursor: pointer;
  background: none;
  border-radius: 10px;
  transition: background 0.3s;
  &:hover {
    background: rgba(196, 196, 196, 0.3);
  }
`;