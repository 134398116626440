import React, {useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from "react-i18next";
import { StyledDivRegular } from '../../../layout-and-styling/standardized-components-library/Styling-TextInput';

const ComponentAddDay = (props) => {
    const [hover, setHover] = useState(false);
    const {t} = useTranslation();

    return (
        <Container>

            <StyledDivRegular Position={"relative"} Width={"100px"} Height={"fit-content"} TextAlign={"center"}>
            {t("PagePlannedMeetings.AddDay")}
            </StyledDivRegular>

            <ButtonWrapper>
              <button onClick={props.addDay}>+</button>
            </ButtonWrapper>

              
        </Container>
    );
};

export default ComponentAddDay;

const Container = styled.div`
  opacity: ${props => props.Opacity || "1"};
  pointer-events: ${props => props.PointerEvents || ""};
  width: 167px;
  min-width: 167px;
  height: 100%;
  background-color: #F1F3F4;
  position: relative;
  border: ${props => props.Border || ''};
  border-radius: ${props => props.BorderRadius || '10px'};
  font-family: "Overpass";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const ButtonWrapper = styled.div`
  button {
    display: flex;
    vertical-align: middle;
    flex-direction: row;
    align-items: center;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    width: 50px;
    height: 50px;
    padding: 13px 16px 8px 11px;
    background: #FCFCFC;
    border-radius: 27px;
    border: solid 2px #FCFCFC;
    font-size: 40px;
    font-family: "Overpass";
    cursor: pointer;
    box-sizing: border-box;
  }

  button:hover {
    border: solid 2px #6E6E6E;
  }
`;