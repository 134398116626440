import React, {useEffect, useState, useRef} from 'react';
import styled from 'styled-components';

import {useTranslation} from "react-i18next";
import {StyledDivBold, StyledDivRegular} from '../../../layout-and-styling/standardized-components-library/Styling-TextInput';
import {ReactComponent as IconReplay} from '../assets/IconReplay.svg';

const VideoAnimation = (componentprops) => {
    const {t} = useTranslation();

    const [isPlaying, setIsPlaying] = useState(false);
    const [replayEnabled, setReplayEnabled] = useState(false);
    const videoRef = useRef(null);

    const [videoProgress, setVideoProgress] = useState(0);

    useEffect(() => {
      const componentClickedBefore = localStorage.getItem(`componentClicked${componentprops.identifier}`);
    
      if (componentClickedBefore) {
        componentprops.setComponentClicked(true)
      }

      const updateVideoProgress = () => {
        if (videoRef.current) {
          const currentTime = videoRef.current.currentTime;
          const duration = videoRef.current.duration;
          const progress = (currentTime / duration) * 100;
          setVideoProgress(progress);
        }
      };
  
      if (videoRef.current) {
        videoRef.current.addEventListener('timeupdate', updateVideoProgress);
      }
  
      return () => {
        if (videoRef.current) {
          videoRef.current.removeEventListener('timeupdate', updateVideoProgress);
        }
      };
    }, []);

    const playVideo = () => {
      setIsPlaying(true);
      componentprops.setComponentClicked(true)
      localStorage.setItem(`componentClicked${componentprops.identifier}`, 'true')
      videoRef.current.play();
    };

    const replayVideo = () => {
      setReplayEnabled(false);
      videoRef.current.currentTime = 0;
      playVideo()
    };

    const showReplay = () => {
      setIsPlaying(false)
      setReplayEnabled(true);
    };

    return (
        <Container>
            <VideoPlayer ref={videoRef} playsInline src={componentprops.video} isPlaying={isPlaying} replayEnabled={replayEnabled} onEnded={showReplay}/>

            <VideoOverlay onClick={playVideo} isPlaying={isPlaying} replayEnabled={replayEnabled}>
                <StyledDivBold Position='static' Width='auto' Height='auto' Color='#5F6366' FontSize='42px' LineHeight='52px' TextAlign='center'>
                    {t('PageBenefits.TextVideoTop')}
                </StyledDivBold>
            </VideoOverlay>

            {replayEnabled ? (
                <BottomTextWrapper onClick={replayVideo}>
                    <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#156EE5' FontSize='18px' LineHeight='28px'>
                        {t('PageBenefits.TextVideoBottom')}
                    </StyledDivRegular>

                    <IconReplay/>
                </BottomTextWrapper>
            ) : (isPlaying && (
              <ProgressBar>
                  <ProgressFill Width={`${videoProgress}%`}/>
              </ProgressBar>
            ))}
        </Container>
    );
}

export default VideoAnimation;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const VideoPlayer = styled.video`
  width: 100%;
  min-width: 475px;
  max-width: 950px;
  height: 100%;
  min-height: 298px;
  max-height: 50vh;
  margin-bottom: ${props => props.isPlaying || props.replayEnabled ? '0' : '48px'};
  opacity: ${props => props.isPlaying || props.replayEnabled ? 1 : 0.25};
  transition: opacity 0.8s ease-in-out;
  pointer-events: none;
`;

const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${props => props.replayEnabled ? '0' : '-24px'};
  cursor: pointer;
  visibility: ${props => props.isPlaying || props.replayEnabled ? 'hidden' : 'visible'};
  opacity: ${props => props.isPlaying || props.replayEnabled ? 0 : 1};
  transition: visibility 0.8s, opacity 0.8s ease-in-out;
`;

const BottomTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  gap: 3px;
  cursor: pointer;
`;

const ProgressBar = styled.div`
  width: 100%;
  max-width: 950px;
  height: 4px;
  background-color: #E3E5E4;
  border-radius: 8px;
  margin-bottom: 24px;
`;

const ProgressFill = styled.div`
  width:  ${props => props.Width || 0};
  height: 100%;
  background-color: #C6CBCB;
  border-radius: 8px;
  transition: width 0.5s;
`;
