import { useState, useEffect } from 'react';
import axios from 'axios';
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { StyledDivRegular, StyledDivBold } from '../layout-and-styling/standardized-components-library/Styling-TextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket, faX } from '@fortawesome/free-solid-svg-icons';

const LogoutModal = ({ onClose }) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(true);

    /** Check if already authenticated, and bring to dashboard if possible. */
    useEffect(() => {
        const axiosInstance = axios.create({ withCredentials: true });
        axiosInstance.get(process.env.REACT_APP_APIURL + '/api/auth/validatesession/')
            .catch((error) => {
                window.location.replace(process.env.REACT_APP_APPURL + '/login');
            });
    }, []);

    const handleCloseModal = () => {
        setShowModal(false); // Close modal when cancel is clicked
        onClose();
    };

    const handleLogout = (e) => {
        const axiosInstance = axios.create({withCredentials: true});
        // Perform GET request to get a CSRFToken from the API and store it in sessionStorage
        axiosInstance
            .get(process.env.REACT_APP_APIURL + "/api/auth/logout/")
            .then((res) => {
                localStorage.clear();
                sessionStorage.clear();
                setShowModal(false);
                window.location.replace(process.env.REACT_APP_APPURL + "/login");
            });
    };

    return (
      <>
        {showModal && (
          <ModalWrapper>
              <ModalContent>
                <ModalHeader>
                  <StyledDivBold Width='fit-content' FontSize="24px" LineHeight='36px' Position='relative' PhoneFontSize='48px' PhoneLineHeight='58px'>
                      {t('PhoneLogoutPopup.Title')} 
                  </StyledDivBold>
                </ModalHeader>
                <ModalDescription>
                  <StyledDivRegular Width='fit-content' FontSize="20px" LineHeight='30px' Position='relative' PhoneFontSize='38px' PhoneLineHeight='48px'>
                    {t('PhoneLogoutPopup.Text')} 
                  </StyledDivRegular>
                </ModalDescription>

                <ButtonContainer>
                  <LogoutButton onClick={handleLogout}>
                    {t('PhoneLogoutPopup.LogoutButton')} 
                    <FontAwesomeIcon icon={faRightFromBracket} /> 
                  </LogoutButton>
                  <CancelButton onClick={handleCloseModal}>
                    {t('PhoneLogoutPopup.CloseButton')} 
                    <FontAwesomeIcon icon={faX} />
                  </CancelButton>
                </ButtonContainer>
              </ModalContent>
          </ModalWrapper>
        )}
      </>
    );
};

export default LogoutModal;

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2); /* Dimming effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #FFFFFF;
  padding: 24px;
  border-radius: 10px;
  width: fit-content;
  height: fit-content;

  text-align: center;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 10px;
  text-align: left;

  @media (orientation: portrait) {
    border-radius: 20px;
    padding: 60px;
    min-height: 400px;  /* Min height for smaller screens */
    max-width: 700px;  /* Max width for smaller screens */
    gap: 40px;
  }
`;

const ModalHeader = styled.div`
  @media (orientation: portrait) {
    margin-top: 20px;
  }
`;

const ModalDescription = styled.div`

  @media (orientation: portrait) {
    margin-bottom: 20px;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 16px;
  display: flex;
  width: 100%;
  justify-content: space-between;  /* Ensures equal space between buttons */
  gap: 16px;

  @media (orientation: portrait) {
    height: 100px;
    gap: 40px;
  }
`;

const LogoutButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Overpass', sans-serif;
  background-color: #F1F3F4;
  color: black;
  border: none;
  border-radius: 10px;
  width: 50%; /* Each button takes 50% */
  font-size: 20px;
  line-height: 50px;
  font-weight: bold;
  cursor: pointer;

  @media (orientation: portrait) {
    font-size: 34px;
    border-radius: 20px;

    svg {
        width: 34px;
    }
  }

  &:hover {
      background-color: #E3E5E4; /* Change background on hover */
  }

  svg {
    width: 24px;
    padding: 10px;
    margin-bottom: 4px;
  }
`;

const CancelButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Overpass', sans-serif;
  background-color: #E2336B;
  color: #FFFFFF;
  border: none;
  border-radius: 10px;
  width: 50%; /* Each button takes 50% */
  font-size: 20px;
  line-height: 50px;
  font-weight: bold;
  cursor: pointer;

  @media (orientation: portrait) {
    font-size: 34px;
    border-radius: 20px;

    svg {
        width: 20px;
    }
  }

  &:hover {
    background-color: #B3194A; /* Change background on hover */
  }

  svg {
    width: 16px;
    padding: 10px;
    margin-bottom: 4px;
  }
`;