import React, {useContext, useState, useEffect} from 'react';
import styled from 'styled-components';
import {useTranslation} from "react-i18next";

import {ReactComponent as Checkbox_Filled} from "../assets/CircleChecked_dropdown.svg";
import {ReactComponent as Checkbox_Unfilled} from "../assets/CircleUnchecked_dropdown.svg";
import { TooltipCloseColleagueFilter } from '../../standardized-components/Survey-social-network-tooltips';
import {Slider} from '@mui/material';

const ComponentClientSuppliers = (props) => {
    const {t} = useTranslation();
    const min = t('PagePlannedMeetings.Mins');
    const hrs = t('PagePlannedMeetings.Hrs');
    const [selected, setSelected] = useState(false);
    const [hover, setHover] = useState(false);
    const [hover2, setHover2] = useState(false);
    const [hover3, setHover3] = useState(false);

    const changeSelected = () => {
        setSelected(!selected)
    };

    const marks = [
        {
            value: 0,
            label: "0 " + hrs
        },
        {
            value: 0.5
        },
        {
            value: 1,
            label: "1 " + hrs
        },
        {
            value: 1.5
        },
        {
            value: 2,
            label: "2 " + hrs
        },
        {
            value: 2.5
        },
        {
            value: 3,
            label: "3 " + hrs
        },
        {
            value: 3.5
        },
        {
            value: 4,
            label: "4 " + hrs
        },
        {
            value: 4.5
        },
        {
            value: 5,
            label: "5 " + hrs
        },
        {
            value: 5.5
        },
        {
            value: 6,
            label: "6 " + hrs
        },
        {
            value: 6.5
        },
        {
            value: 7,
            label: "7 " + hrs
        },
        {
            value: 7.5
        },
        {
            value: 8,
            label: "8 " + hrs
        },
        {
            value: 8.5
        },
        {
            value: 9,
            label: "9 " + hrs
        },
        {
            value: 9.5
        },
        {
            value: 10,
            label: "10 " + hrs
        },
    ];

    function label(hours) {
        return hours + " h";
    }

    useEffect(() => {
        let customerAndSupplierLS = JSON.parse(localStorage.getItem("CustomerAndSupplierData"));
        if (customerAndSupplierLS) {
            props.setCustomerAndSupplier(customerAndSupplierLS);
        }
    }, [])

    const changeChecked = (type) => {
        if (type === "customer") {
            props.customerAndSupplier['customer'].interaction = !props.customerAndSupplier['customer'].interaction;
            localStorage.setItem("CustomerAndSupplierData", JSON.stringify(props.customerAndSupplier))
        } else {
            props.customerAndSupplier['supplier'].interaction = !props.customerAndSupplier['supplier'].interaction;
            localStorage.setItem("CustomerAndSupplierData", JSON.stringify(props.customerAndSupplier))
        }
        if (type === "customer" && selected === true){
            updateHours(0, "customer")
        }
        else{
            updateHours(0, "supplier")
        }
        changeSelected();
    }

    const updateHours = (value, type) => {
        if (type === "customer") {
            props.customerAndSupplier['customer'].hours = value
            props.setCustomerAndSupplier(props.customerAndSupplier)
            localStorage.setItem("CustomerAndSupplierData", JSON.stringify(props.customerAndSupplier))
        } else {
            props.customerAndSupplier['supplier'].hours = value;
            props.setCustomerAndSupplier(props.customerAndSupplier)
            localStorage.setItem("CustomerAndSupplierData", JSON.stringify(props.customerAndSupplier))
        }
        props.reRender();
    }


    return (
        <Container>

            <ClientSupplierWrapper>
                <TopHeader>
                    <CustomerSupplierHeader>
                        {t('PageCloseColleagueFilter.CustomerSupplierHeader')}
                    </CustomerSupplierHeader>
                    <InfoHover onMouseOver={() => setHover(true)}
                               onMouseOut={() => setHover(false)}>?
                        {hover && (<TooltipCloseColleagueFilter whichTooltip={"CustomerSuppliers"}/>
                        )}
                    </InfoHover>
                </TopHeader>

                <SubComponentWrapper>

                    <PersonWrapper  id="customer" onClick={() => changeChecked("customer")}
                                   style={{background: props.customerAndSupplier['customer'].interaction ? "#B3194A" : "#FCFCFC"}}
                                   Width="720px" MarginLeft="20px" MarginRight="20px" >
                        <PersonName
                            style={{color: props.customerAndSupplier['customer'].interaction ? "#FCFCFC" : "#271B36"}}>
                            {t('PageCloseColleagueFilter.Customers')}
                        </PersonName>
                        <PersonCheckbox>
                            {props.customerAndSupplier['customer'].interaction ? <Checkbox_Filled/> :
                                <Checkbox_Unfilled/>}
                        </PersonCheckbox>
                    </PersonWrapper>

                    <PersonWrapper onClick={() => changeChecked("supplier")}
                                   style={{background: props.customerAndSupplier['supplier'].interaction ? "#B3194A" : "#FCFCFC"}}
                                   Width="720px" MarginLeft="20px" MarginRight="20px" >
                        <PersonName
                            style={{color: props.customerAndSupplier['supplier'].interaction ? "#FCFCFC" : "#271B36"}}>
                            {t('PageCloseColleagueFilter.Suppliers')}
                        </PersonName>
                        <PersonCheckbox>
                            {props.customerAndSupplier['supplier'].interaction ? <Checkbox_Filled/> :
                                <Checkbox_Unfilled/>}
                        </PersonCheckbox>
                    </PersonWrapper>

                </SubComponentWrapper>

            </ClientSupplierWrapper>

            {props.customerAndSupplier['customer'].interaction &&
                <ClientSliderWrapper outline={!props.customerAndSupplier.customer.hours & props.nextButtonClicked ? "2px solid #F72B2B" : ""}>
                    <IncompleteContentText display={!props.customerAndSupplier.customer.hours & props.nextButtonClicked ? "flex" : "none"}>
                      {t('PageCloseColleagueFilter.ErrorHours')}
                     </IncompleteContentText>
                    <TopHeader>
                        <CustomerSupplierHeader>
                            {t('PageCloseColleagueFilter.CustomerHeader')}
                        </CustomerSupplierHeader>
                        <InfoHover onMouseOver={() => setHover2(true)}
                                   onMouseOut={() => setHover2(false)}>?
                            {hover2 && (<TooltipCloseColleagueFilter whichTooltip={"Customer"}/>
                            )}
                        </InfoHover>
                    </TopHeader>
                    <StyledSliderClientSupplier onChange={(event, value) => updateHours(value, "customer")}
                                                value={props.customerAndSupplier['customer'].hours}
                                                valueLabelDisplay="auto" valueLabelFormat={label} size="small" marks={marks} 
                                                step={0.5} min={0} max={10} MarginTop='8px'
                                                sx={{
                                                    width: "calc(100% - 120px)",
                                                    height: 10,
                                                    '@media screen and (max-width: 1280px)': {
                                                        width: 32,
                                                        '& .MuiSlider-markLabel': {fontSize: "10px"},
                                                        MarginTop: 40,
                                                    },

                                                    color: '#C6CBCB',
                                                    '& .MuiSlider-thumb': {
                                                        color: "#5F6366",
                                                        width: '15px',
                                                        height: '15px',
                                                        '&:focus, &:hover, &.Mui-active': {
                                                            color: "#B3194A",
                                                            boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
                                                            '@media (hover: none)': {boxShadow: 'none',}
                                                        },
                                                    },
                                                    '& .MuiSlider-rail': {
                                                        opacity: 1,
                                                        width: 1,
                                                        backgroundColor: '#F1F3F4',
                                                    },
                                                    '& .MuiSlider-root': {
                                                        opacity: 1,
                                                        width: 1,
                                                        backgroundColor: '#F1F3F4',
                                                    },
                                                    // '& .MuiSlider-mark': {
                                                    //     background: '#C6CBCB', height: '1px', width: '3px',
                                                    //     '&.MuiSlider-markActive': {backgroundColor: '#C6CBCB',},
                                                    // },
                                                    '& .MuiSlider-track': {
                                                        width: "100%",
                                                        backgroundColor: '#B3194A',
                                                    },
                                                }}
                    />
                </ClientSliderWrapper>
            }

            {props.customerAndSupplier['supplier'].interaction &&
                <SupplierSliderWrapper outline={!props.customerAndSupplier.supplier.hours & props.nextButtonClicked ? "2px solid #F72B2B" : ""}>
                    <IncompleteContentText display={!props.customerAndSupplier.supplier.hours & props.nextButtonClicked ? "flex" : "none"}>
                        {t('PageCloseColleagueFilter.ErrorHours')}
                    </IncompleteContentText>
                    <TopHeader>
                        <CustomerSupplierHeader>
                            {t('PageCloseColleagueFilter.SupplierHeader')}
                        </CustomerSupplierHeader>
                        <InfoHover onMouseOver={() => setHover3(true)}
                                   onMouseOut={() => setHover3(false)}>?
                            {hover3 && (<TooltipCloseColleagueFilter whichTooltip={"Suppliers"}/>
                            )}
                        </InfoHover>
                    </TopHeader>
                    <StyledSliderClientSupplier onChange={(event, value) => updateHours(value, "supplier")}
                                                value={props.customerAndSupplier['supplier'].hours}
                                                valueLabelDisplay="auto" valueLabelFormat={label} size="small" marks={marks} 
                                                step={0.5} min={0} max={10} MarginTop='8px'
                                                sx={{
                                                    width: "calc(100% - 120px)",
                                                    height: 10,

                                                    '@media screen and (max-width: 1280px)': {
                                                        width: 32,
                                                        '& .MuiSlider-markLabel': {fontSize: "10px"},
                                                        MarginTop: 40,
                                                    },

                                                    color: '#C6CBCB',
                                                    '& .MuiSlider-thumb': {
                                                        color: "#5F6366",
                                                        width: '15px',
                                                        height: '15px',
                                                        '&:focus, &:hover, &.Mui-active': {
                                                            color: "#B3194A",
                                                            boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
                                                            '@media (hover: none)': {boxShadow: 'none',}
                                                        },
                                                    },
                                                    '& .MuiSlider-rail': {
                                                        opacity: 1,
                                                        width: 1,
                                                        backgroundColor: '#F1F3F4',
                                                    },
                                                    // '& .MuiSlider-mark': {
                                                    //     background: '#C6CBCB', height: '1px', width: '5px',
                                                    //     '&.MuiSlider-markActive': {backgroundColor: '#C6CBCB',},
                                                    // },
                                                    '& .MuiSlider-track': {
                                                        width: "100%",
                                                        backgroundColor: '#B3194A',
                                                    },
                                                }}
                    />
                </SupplierSliderWrapper>
            }
        </Container>
    )
}

export default ComponentClientSuppliers

const IncompleteContentText = styled.div`
  position: absolute;
  display: ${(props) => props.display || ""};
  margin-top: -4px;
  border-radius: ${(props) => props.BorderRadius || "5px"};
  color: #F72B2B;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
`;

const Container = styled.div`
  height: calc(100% - 90px);
  width: 100%;
  max-width: 1000px;
  margin: 0 20px 70px 10px;
  border-radius: 10px;
  z-index: 1;
  display: flex;
  flex-direction: column;  

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #929699;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #6b6e71;
  }
`;

export const StyledSliderClientSupplier = styled(Slider)`
  position: relative;
  height: 100%;
  font-family: 'Overpass', sans-serif;
  font-size: 16px;
  color: #271B36;
  width: calc(100% - 140px);
  margin-top: ${props => props.MarginTop || ""};
  margin-left: ${props => props.MarginLeft || ""};
  margin-right: ${props => props.MarginRight || ""};
  margin-bottom: ${props => props.MarginBottom || ""};

  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: 1.25;
  }

  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: 1.5;
  }

`;

const ClientSupplierWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #FCFCFC;
  border-radius: 10px;
  justify-content: flex-start;
  align-items: center;
  @media only screen and (max-width: 2000px) {
    height:40%;
  }
`;

const ClientSliderWrapper = styled.div`
  outline: ${props => props.outline || "none"}; 
  outline-offset: -2px; 
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  background: #FCFCFC;
  border-radius: 10px;
  height: fit-content;
  justify-content: flex-start;
  align-items: center;
  padding: 3.6% 0 3.6% 0;
  @media only screen and (max-height: 901px) {
    padding: 2.35% 0 2.35% 0;
  } 
`;

const SupplierSliderWrapper = styled.div`
  outline: ${props => props.outline || "none"};
  outline-offset: -2px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  background: #FCFCFC;
  border-radius: 10px;
  height: fit-content;
  justify-content: flex-start;
  align-items: center;
  padding: 3.6% 0 3.6% 0;
  @media only screen and (max-height: 901px) {
    padding: 2.35% 0 2.35% 0;
  } 
`;

const SubComponentWrapper = styled.div`
  background: #E3E5E4;
  border-radius: 10px;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 20px;
  width: calc(100% - 48px);
  height: 100%;
`;

const CustomerSupplierHeader = styled.div`
  width: fit-content;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-left: 20px;
  color: #271B36;
`;

const TopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
`;


const PersonWrapper = styled.div`
  width: 100%;
  border: 1px solid #FCFCFC;
  border-radius: 10px;
  background: ${props => props.Backgroundcolor || "#F1F3F4"};
  color: #271B36;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${props => props.FontFamily || 'Overpass'};
  font-size: ${props => props.FontSize || '16px'};
  line-height: ${props => props.LineHeight || '20px'};
  font-weight: ${props => props.FontWeight || '400'};
  cursor: pointer;
  opacity: ${props => props.Opacity || "1"};
  margin-left: ${props => props.MarginLeft || ""};
  margin-right: ${props => props.MarginRight || ""};
  margin-bottom: ${props => props.MarginBottom || ""};
  margin-top: 4px;
  padding: 0 20px;

  &:hover {
    border: 1px solid #B3194A;
  }

  @media only screen and(max-width: 1900px) and (-webkit-device-pixel-ratio: 1) {
    margin-right: 10px;
    margin-left: 10px;
  }

  @media only screen and (max-width: 1500px)  and (-webkit-device-pixel-ratio: 1.25) {
    margin: 4px 20px;
  }

  @media only screen and (max-width: 1360px) and (-webkit-device-pixel-ratio: 1.25) {
    margin: 4px 15px;
  }

  @media only screen and (max-width: 1380px) and (-webkit-device-pixel-ratio: 1.5) {
    margin: 4px 15px;
  }
`;

const PersonName = styled.div`
  width: 40%;
`;

const PersonCheckbox = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  margin-right: 20px;
`;

const InfoHover = styled.div`
  float: right;
  font-size: 18px;
  font-family: 'Overpass', sans-serif;
  font-weight: 300;
  position: relative;
  color: #FCFCFC;
  background-color: #E2336B;
  margin-right: 20px;
  border: 2px solid #E2336B;
  border-radius: 500px;
  width: 20px;
  text-align: center;
  height: 20px;
  cursor: pointer;
`;
