import React, {useRef, useEffect, useState} from 'react';
import styled from "styled-components";

import {StyledDivRegular} from '../../../layout-and-styling/standardized-components-library/Styling-TextInput';
import {ReactComponent as ButtonArrowLeft} from '../../../assets/intake/ButtonArrowLeft.svg';
import {ReactComponent as ButtonArrowRight} from '../../../assets/intake/ButtonArrowRight.svg';
import {ReactComponent as ButtonPencil} from '../../../assets/intake/ButtonPencil.svg';
import {ReactComponent as ButtonTrash} from '../../../assets/intake/ButtonTrash.svg';
import {ReactComponent as ButtonX} from '../../../assets/intake/ButtonX.svg';
import {ReactComponent as ButtonCheck} from '../../../assets/intake/ButtonCheck.svg';
import {ReactComponent as MainProcessStart} from '../../../assets/intake/MainProcessStart.svg';
import {ReactComponent as MainProcessChain} from '../../../assets/intake/MainProcessChain.svg';
import {ReactComponent as MainProcessEnd} from '../../../assets/intake/MainProcessEnd.svg';

const MainProcessComponent = (props) => {
    const mainProcessRef = useRef(null);
    const textInputRef = useRef(null);

    useEffect(() => {
        if (props.isShowingTextInput) {
            textInputRef.current.focus();
            textInputRef.current.select();

            const handleKeyDown = (e) => {
                if (e.key === 'Escape') {
                    hideTextInput();
                }
            };

            window.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [props.isShowingTextInput]);

    const [isShowingOverlay, setIsShowingOverlay] = useState(false);
    const [newName, setNewName] = useState('');
    const [characterLength, setCharacterLength] = useState(0);

    const showTextInput = () => {
        if(props.isOverlayEnabled) {
            if(!props.isShowingTextInput) {
                setNewName(props.mainProcess.name);
                setCharacterLength(props.mainProcess.name.length);
                setIsShowingOverlay(false);
                props.showTextInput(props.mainProcessIndex);
            }
        }
    }

    const hideTextInput = () => {
        if(!props.isOverlayEnabled) {
            if(props.isShowingTextInput) {
                props.hideTextInput();
            }
        }
    }

    const renameMainProcess = () => {
        if(props.isShowingTextInput) {
            if (newName.trim().length !== 0) {
                props.renameMainProcess(newName);
            } else {
                props.renameMainProcess('Empty');
            }
        }

        hideTextInput();
    }

    const showAlert = () => {
        if (!props.isDeleteDisabled) {
            props.showAlert();
        }
    }

    const handleMouseEnter = () => {
        if (props.isOverlayEnabled) {
            setIsShowingOverlay(true);
            mainProcessRef.current.focus();
        }
    }

    const handleMouseLeave = () => {
        if (props.isOverlayEnabled) {
            setIsShowingOverlay(false);
            mainProcessRef.current.blur();
        }
    }

    const handleChange = (e) => {
        const { selectionStart, value } = e.target;
        const formattedValue = e.target.value.replace(/\s+/g, ' ');
        setNewName(formattedValue);
        const cursorPosition = selectionStart + (formattedValue.length - value.length);
        textInputRef.current.setSelectionRange(cursorPosition, cursorPosition);
        setCharacterLength(formattedValue.length);

        setTimeout(() => {
            e.target.setSelectionRange(cursorPosition, cursorPosition);
        });
    };

    const handleKeyDown = (e) => {
        if (props.isShowingTextInput) {
            if (e.key === 'Enter') {
                renameMainProcess();
            }
        } else {
            if (e.key === 'ArrowLeft') {
                props.moveMainProcessLeft();
            }

            if (e.key === 'ArrowRight') {
                props.moveMainProcessRight();
            }

            if (e.key === 'Backspace' || e.key === 'Delete') {
                showAlert();
            }
        }
    };

    return (
        <Container key={props.mainProcessIndex} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {props.isShowingTextInput ? (
                <>
                    <OverlayWrapper IsShowingTextInput={props.isShowingTextInput}>
                        <OverlaySectionWrapper>
                            <OverlayButtonWrapper onClick={hideTextInput}> 
                                <ButtonX/>
                            </OverlayButtonWrapper>

                            <OverlayButtonWrapper onClick={renameMainProcess}>
                                <ButtonCheck/>
                            </OverlayButtonWrapper>
                        </OverlaySectionWrapper>
                    </OverlayWrapper>

                    <TextWrapper IsShowingTextInput={props.isShowingTextInput}>
                        <TextInput ref={textInputRef} maxLength={40} type="text" value={newName} onChange={handleChange} onKeyDown={handleKeyDown} placeholder='Edit text...' MainProcessIndex={props.mainProcessIndex}/>
                        
                        <StyledDivRegular Position='absolute' Width='auto' Height='auto' FontSize='12px' LineHeight='16px' FontWeight='400'
                                          Color='#929699' TextAlign='right' ZIndex='1' Right='10px' Bottom='10px'>
                            {characterLength}/40
                        </StyledDivRegular>
                    </TextWrapper>
                </>
            ) : (
                <>
                    {isShowingOverlay && (
                        <OverlayWrapper onMouseEnter={handleMouseEnter}>
                            <OverlaySectionWrapper>
                                <OverlayButtonWrapper onClick={props.moveMainProcessLeft} Disabled={props.isMoveLeftDisabled}>
                                    <ButtonArrowLeft/>
                                </OverlayButtonWrapper>

                                <OverlayButtonWrapper onClick={props.moveMainProcessRight} Disabled={props.isMoveRightDisabled}>
                                    <ButtonArrowRight/>
                                </OverlayButtonWrapper>
                            </OverlaySectionWrapper>

                            <OverlaySectionWrapper>
                                <OverlayButtonWrapper onClick={showTextInput}>
                                    <ButtonPencil/>
                                </OverlayButtonWrapper>

                                <OverlayButtonWrapper onClick={showAlert} Disabled={props.isDeleteDisabled}>
                                    <ButtonTrash/>
                                </OverlayButtonWrapper>
                            </OverlaySectionWrapper>
                        </OverlayWrapper>
                    )}

                    <TextWrapper ref={mainProcessRef} tabIndex='0' onKeyDown={handleKeyDown} onDoubleClick={showTextInput} IsShowingTextInput={props.isShowingTextInput}>
                        <StyledDivRegular Position='static' Width='100%' Height='auto' Padding={(props.mainProcessIndex === 0) ? '0' : '0 0 0 28px'}
                                          FontSize='16px' LineHeight='20px' FontWeight='400' OverflowWrap='break-word' Hyphens='auto' Color='#FCFCFC' 
                                          TextAlign='center' ZIndex='1'>
                            {props.mainProcess.name}
                        </StyledDivRegular>
                    </TextWrapper>
                </>
            )}

            <BackgroundWrapper>
                {(props.mainProcessIndex === 0) ? (
                    <MainProcessStart/>
                ) : (props.mainProcessIndex === 5) ? (
                    <MainProcessEnd/>
                ) : (
                    <MainProcessChain/>
                )}
            </BackgroundWrapper>
        </Container>
    )
}

export default MainProcessComponent;

const Container = styled.div`
    position: relative;
    display: flex;
    width: 165px;
    height: 80px;
    border-radius: 8px;
`;

const TextWrapper = styled.div`
    display: flex;
    width: ${props => props.IsShowingTextInput ? '155px' : '145px'};
    height: ${props => props.IsShowingTextInput ? '70px' : '60px'};
    padding: ${props => props.IsShowingTextInput ? '5px' : '10px'};
    justify-content: flex-end;
    align-items: center;
    outline: none;
    z-index: 1;
`;

const OverlayWrapper = styled.div`
    position: absolute;
    top: -40px;
    left: 0;
    display: flex;
    width: 165px;
    height: 115px;
    padding-top: 5px;
    justify-content: ${props => props.IsShowingTextInput ? 'flex-end' : 'space-between'};
`;

const OverlaySectionWrapper = styled.div`
    display: flex;
    gap: 5px;
`;

const OverlayButtonWrapper = styled.div`
    display: flex;
    width: 30px;
    height: 30px;
    opacity: ${props => props.Disabled ? '0.5' : ''};
    transition: opacity 0.3s, filter 0.15s, transform 0.15s;
    cursor: ${props => props.Disabled ? '' : 'pointer'};

    &:hover {
        filter: ${props => props.Disabled ? '' : 'brightness(90%)'};
        transform: ${props => props.Disabled ? '' : 'scale(1.1)'};
    }
`;

const BackgroundWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px;
`;

const TextInput = styled.textarea`
    position: relative;
    display: flex;
    width: ${props => props.MainProcessIndex > 0 ? 'calc(100% - 43px)' : 'calc(100% - 10px)'};
    height: calc(100% - 10px);
    resize: none;
    padding: 5px;
    border-radius: 6px;
    border: 1px solid #E3E5E4;
    background: #FCFCFC;
    z-index: 1;

    color: #5F6366;
    font-family: Overpass;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    ::placeholder {
        color: #929699;
    }
`;