import React, {useState, useRef, useEffect} from 'react';
import styled from '@emotion/styled';
import {useTranslation} from 'react-i18next';

export default function ComponentOptionSelector(props) {
    const {t} = useTranslation();
    const [selectedOption, setSelectedOption] = useState(-1);
    const isPortraitMode = window.matchMedia("(orientation: portrait)").matches;

    const containerRef = useRef(null);
    
    // Calculate height and pass it to the parent component
    useEffect(() => {
      if (containerRef.current && isPortraitMode) {
          const height = containerRef.current.offsetHeight;
          props.setSelectOptionsHeight(height + 156); // Pass the calculated height to the parent component
      }
    }, []);
    
    // handles which one is the option selected
    const handleSelectedOption = (option) => {
        if(option === selectedOption){
            setSelectedOption(-1)
            props.setEnable(false)
        }
        else{
            setSelectedOption(option)
            props.setEnable(true)
        }
      };

    return (
        <Container ref={containerRef}>
            {props.options.map((option, index)=>{
                return(
                    <Option id={"option_"+ option} isSelected={index === selectedOption} onClick={() => { props.handleAnswer(option); handleSelectedOption(index); }}>
                      {isPortraitMode ? (
                        <RadioButtonWrapper>
                          <RadioButton type="radio" name="option" checked={index === selectedOption} onChange={() => {}} />
                            <label>{option}</label>
                          </RadioButtonWrapper> ) 
                        : option }
                    </Option>
                ); 
            })}
        </Container>
    );
};


const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  height: 80px;
  gap: 16px;

  @media (orientation: portrait) {
    position: fixed;
    bottom: 160px;
    width: 100%;
    min-height: 100px;
    height: auto;
    max-height: 50vh;
    overflow-y: auto;
    background-color: #FCFCFC;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    gap: 30px;
  }
`;

const Option = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%; 
  text-align: center;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  font-family: 'Overpass', sans-serif;
  font-style: normal;
  font-size: 18px; 
  cursor: ${props => props.Cursor || "pointer"};
  background-color: ${props => props.isSelected ? "#E2336B" : "#F1F3F4"};
  color: ${props => props.isSelected ? "#FCFCFC" : "#271B36"};
  
  :hover{
      outline: ${props => props.Outline || ""};
  }

  @media (orientation: portrait) {
    width: 90%;
    font-size: 34px;
    line-height: 44px;
    justify-content: start;
    text-align: left;
    align-items: center;
    border-radius: 20px;

    padding: 10px;

    min-height: 80px;
    height: auto !important;
    flex-grow: 1;

    word-break: break-word; /* Break long words */
    white-space: pre-wrap; /* Allow wrapping but respect spaces */
    overflow: hidden; /* Prevent overflow */

    color: #271B36;

    background-color: ${props => props.isSelected ? "#F9D6E1" : "#FFFFFF"};
    border: ${props => props.isSelected ? "solid 4px #E2336B !important" : "solid 4px #D1D5DB"};
    font-weight: ${props => props.isSelected ? "600" : "400"};
    transition: background-color 0.3s, border 0.3s, font-weight 0.3s;
  }
`;

const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const RadioButton = styled.input`
  margin-left: 40px;
  appearance: none;
  min-width: 50px;
  min-height: 50px;
  border: 4px solid #D1D5DB;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;
  transition: border 0.3s;

  :checked {
    background-color: #FFFFFF;
    border: 16px solid #E2336B;
  }

  :checked::before {
    content: "";
    width: 15px; /* Custom size */
    height: 15px; /* Custom size */
    background-color: #FFFFFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
`;