import styled from '@emotion/styled';
import React, {useEffect, useRef, useState} from 'react'

export default function ComponentFancyCheckboxBad(props) {
    
    const [checked, setChecked] = useState(false)
    const isFirstRender = useRef(true);

    function checkIfChecked() {
        if (props.type === "team") {
            if (props.fullFeedbackContainer[props.currentProcessPointer].teams.includes(props.team)) {
                setChecked(true)
            } else {
                setChecked(false)
            }
        } else if (props.type === "tag") {
            if (props.fullFeedbackContainer[props.currentProcessPointer].tags.includes(props.tag)) {
                setChecked(true)
            } else {
                setChecked(false)
            }
        }
    }

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        if (props.selectedAllTeams) {
            setChecked(true);
        } else {
            setChecked(false);
        }

    }, [props.selectedAllTeams])


    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        checkIfChecked()
    }, [props.currentProcessPointer])


    if (props.type === "team") {
        function handleCheck() {
            setChecked(!checked)

            if (!checked) {
                props.setSelectedTeams([...props.selectedTeams, props.team])
            } else {
                var arrayCopy = [...props.selectedTeams];
                arrayCopy.splice(arrayCopy.indexOf(props.team), 1)
                props.setSelectedTeams(arrayCopy)
            }
        }

        return (
            <Container onClick={handleCheck} checked={checked}>
                {props.teamToShow}
            </Container>
        )
    } else if (props.type === "tag") {
        function handleCheck() {
            setChecked(!checked)

            if (!checked) {
                props.setSelectedTags([...props.selectedTags, props.tag]);
            } else {
                var arrayCopy = [...props.selectedTags];
                arrayCopy.splice(arrayCopy.indexOf(props.tag), 1)
                props.setSelectedTags(arrayCopy)
            }
        }

        return (
            <Container onClick={handleCheck} checked={checked}>
                {props.translatedTags[props.tags.indexOf(props.tag)]}
            </Container>
        )
    }
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: fit-content;
  min-width: 64px;
  height: 24px;
  background-color: ${({checked}) => (checked ? '#E2336B' : '#F1F3F4')};
  color: ${({checked}) => (checked ? '#fcfcfc' : '#271b36')};
  border-radius: 20px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  padding: 2px 12px 2px 12px;
  margin-left: -10px;
  margin-bottom: 4px;
  white-space: nowrap;
  
  :hover {
    background-color: ${({checked}) => (checked ? 'rgba(226, 51, 107, 0.2)' : 'rgba(226, 51, 107, 0.5)')};
    color: #271b36;
  }
`;