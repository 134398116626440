import React, {useContext, useState, useEffect} from "react";
import styled from 'styled-components';
import * as d3 from "d3";
import {useTranslation} from 'react-i18next';
import {OverlayContext} from "../../layout-and-styling/context-hooks/OverlayContext";
import {DashboardContext} from "../../dashboard/DashboardContext";
import SocialNetworkPage from "../social-network/components/SocialNetworkPage";
import ProblemComponent from "./Components/Problem-component";
import ActionsComponent from "./Components/Actions-component";

export var PresentationVis = []; // where the social network is stored

const SocialNetworkFeedback = () => {
    const context = useContext(OverlayContext);
    const dashboard_context_value = useContext(DashboardContext);

    //rerendering helps team legend rerender?...where to trigger this new render, not finished
    const [render, setRender] = useState(true);
    const reRender = () => {
      setRender(!render);
    };

    return (
      <Container>

          <>
            {/* Different component depending on the page we are */}
            {dashboard_context_value.selectedSubTab == "SubTabNetworkFeedback" ? (
              <ProblemComponent PresentationVis={PresentationVis}/>
            ) : (
              <ActionsComponent PresentationVis={PresentationVis}/>
            )}

            {/* Social Network */}
            <SocialNetworkPage Page="Presentation"/>
          </>

      </Container>

    )
}
export default SocialNetworkFeedback;

const Container = styled.div`
  font-family: 'Overpass', sans-serif;
  width: calc(100%);
  display: flex;
`;
