const RandomNames = [
    {"first_name": "Harlan", "last_name": "Wideman", "full_name": "Harlan Wideman"},
    {"first_name": "Ailee", "last_name": "Hilbert", "full_name": "Ailee Hilbert"},
    {"first_name": "Brenden", "last_name": "Read", "full_name": "Brenden Read"},
    {"first_name": "Charley", "last_name": "Ives", "full_name": "Charley Ives"},
    {"first_name": "Jarell", "last_name": "Delano", "full_name": "Jarell Delano"},
    {"first_name": "Grady", "last_name": "Woodard", "full_name": "Grady Woodard"},
    {"first_name": "Uziel", "last_name": "Lapp", "full_name": "Uziel Lapp"},
    {"first_name": "Radley", "last_name": "Hume", "full_name": "Radley Hume"},
    {"first_name": "Yaziel", "last_name": "Lopresti", "full_name": "Yaziel Lopresti"},
    {"first_name": "Rodney", "last_name": "Engel", "full_name": "Rodney Engel"},
    {"first_name": "Paxson", "last_name": "Lesher", "full_name": "Paxson Lesher"},
    {"first_name": "Claire", "last_name": "Webb", "full_name": "Claire Webb"},
    {"first_name": "Hank", "last_name": "Farris", "full_name": "Hank Farris"},
    {"first_name": "Ladarius", "last_name": "Hilbert", "full_name": "Ladarius Claudio"},
    {"first_name": "Jacky", "last_name": "Mariani", "full_name": "Jacky Mariani"},
    {"first_name": "Braelyn", "last_name": "Beltran", "full_name": "Braelyn Beltran"},
    {"first_name": "Jacobi", "last_name": "Wallis", "full_name": "Jacobi Wallis"},
    {"first_name": "Svea", "last_name": "Brann", "full_name": "Svea Brann"},
    {"first_name": "Layne", "last_name": "Norwood", "full_name": "Layne Norwood"},
    {"first_name": "Echo", "last_name": "Weinberg", "full_name": "Echo Weinberg"},
    {"first_name": "Gina", "last_name": "Jauregui", "full_name": "Gina Jauregui"},
    {"first_name": "Billy", "last_name": "Dietz", "full_name": "Billy Dietz"},
    {"first_name": "Bridgett", "last_name": "Halley", "full_name": "Bridgett Halley"},
    {"first_name": "Stratton", "last_name": "Real", "full_name": "Stratton Real"},
    {"first_name": "Abdoulaye", "last_name": "Waddle", "full_name": "Abdoulaye Waddle"},
    {"first_name": "Mildred", "last_name": "Andre", "full_name": "Mildred Andre"},
    {"first_name": "Gibran", "last_name": "Bevins", "full_name": "Gibran Bevins"},
    {"first_name": "Goldy", "last_name": "Uhl", "full_name": "Goldy Uhl"},
    {"first_name": "Baruch", "last_name": "Nowell", "full_name": "Baruch Nowell"},
    {"first_name": "Deonte", "last_name": "Griffis", "full_name": "Deonte Griffis"},
    {"first_name": "Monserrath", "last_name": "Liao", "full_name": "Monserrath Liao"},
    {"first_name": "Rigel", "last_name": "Brack", "full_name": "Rigel Brack"},
    {"first_name": "Anaiyah", "last_name": "Lilley", "full_name": "Anaiyah Lilley"},
    {"first_name": "Evamarie", "last_name": "Kirschner", "full_name": "Evamarie Kirschner"},
    {"first_name": "Brookelynn", "last_name": "Windham", "full_name": "Brookelynn Windham"},
    {"first_name": "Trayvon", "last_name": "Trayvon", "full_name": "Hutcherson"},
    {"first_name": "Siddharth", "last_name": "Palumbo", "full_name": "Siddharth Palumbo"},
    {"first_name": "Ziyah", "last_name": "Edmunds", "full_name": "Ziyah Edmunds"},
    {"first_name": "Emran", "last_name": "Kwak", "full_name": "Emran Kwak"},
    {"first_name": "Irma", "last_name": "Trowbridge", "full_name": "Irma Trowbridge"},
    {"first_name": "Kimi", "last_name": "Nez", "full_name": "Kimi Nez"},
    {"first_name": "Ellinor", "last_name": "Garry", "full_name": "Ellinor Garry"},
    {"first_name": "Royalty", "last_name": "Forehand", "full_name": "Royalty Forehand"},
    {"first_name": "Shaurya", "last_name": "Parkinson", "full_name": "Shaurya Parkinson"},
    {"first_name": "Advait", "last_name": "Cosme", "full_name": "Advait Cosme"},
    {"first_name": "Marley", "last_name": "Casey", "full_name": "Marley Casey"},
    {"first_name": "Johnna", "last_name": "Cofer", "full_name": "Johnna Cofer"},
    {"first_name": "Rex", "last_name": "Swenson", "full_name": "Rex Swenson"},
    {"first_name": "Spencer", "last_name": "Michaels", "full_name": "Spencer Michaels"},
    {"first_name": "Ezriel", "last_name": "Summerville", "full_name": "Ezriel Summerville"},
    {"first_name": "Maddisyn", "last_name": "Welborn", "full_name": "Maddisyn Welborn"},
    {"first_name": "Javion", "last_name": "Granger", "full_name": "Javion Granger"},
    {"first_name": "Gustavo", "last_name": "Richter", "full_name": "Gustavo Richter"},
    {"first_name": "Garry", "last_name": "Deleo", "full_name": "Garry Deleo"},
    {"first_name": "Shauna", "last_name": "Groover", "full_name": "Shauna Groover"},
    {"first_name": "Terri", "last_name": "Desantiago", "full_name": "Terri Desantiago"},
    {"first_name": "Caleb", "last_name": "Edwards", "full_name": "Caleb Edward"},
    {"first_name": "Benedict", "last_name": "Tubbs", "full_name": "Benedict Tubbs"},
    {"first_name": "Dmitry", "last_name": "Chesney", "full_name": "Dmitry Chesney"},
    {"first_name": "Theia", "last_name": "Sledge", "full_name": "Theia Sledge"}
]

export default RandomNames

