import React, {useEffect, useState} from "react";
import axios from "axios";
import styled from "styled-components";

import SurveyTextProcessingHeader from "../standardized-components/Survey-text-processing-header";
import SurveyTextProcessingFooter from "../standardized-components/Survey-text-processing-Footer";
import {useWindowSize} from "../../layout-and-styling/standardized-components-library/Component-Responsive";
import ComponentPrePageProcessBadFeedback from "./page-specific-components/Compontent-PrePageProcessBadFeedback";

const PrePageProcessBadFeedback = () => {
    /** Survey page for giving feedback on subprocess steps that are the best practices */

    /** Check if already authenticated, and bring to dashboard if possible.*/
    useEffect(() => {
        const axiosInstance = axios.create({withCredentials: true});
        // Perform GET request to get a CSRFToken from the API and store it in sessionStorage
        axiosInstance
            .get(process.env.REACT_APP_APIURL + "/api/auth/validatesession/")
            .catch((error) => {
                window.location.replace(process.env.REACT_APP_APPURL + "/login");
            });
    }, []);

    /** States to check if page is properly filled in, before going to next page */
    const [InputCheck, setInputCheck] = useState(false);
    const [render, setRender] = useState(false);
    const reRender = () => {
        setRender(!render);
    };
    const [nextButtonClicked, setNextButtonClicked] = useState(false);

    return (
        <Container>
            <HeaderAndOverlayWrapper>
                <SurveyTextProcessingHeader page="PageBusinessProcessBadFeedback" reRender={reRender} directory="/survey-business-process-feedback-improvement" />
            </HeaderAndOverlayWrapper>

            <ComponentPrePageProcessBadFeedback input_check={InputCheck} set_input_check={setInputCheck} nextButtonClicked={nextButtonClicked} />

            <SurveyTextProcessingFooter page="PrePageProcessImprovementFeedback" backDirectory="/survey-feedback-optimal" FooterMarginTop={(useWindowSize()[1] - 104).toString() + "px"}
                            FooterMarginLeft="0px" FooterHeight="104px" FooterWidth="100%" MarginTop="25px" BackButtonMarginLeft="65%" NextButtonMarginLeft="calc(65% + 66px)" input_check={InputCheck}
                            nextButtonClicked={nextButtonClicked} setNextButtonClicked={setNextButtonClicked} 
                            // determines the margins with percentages in order to not push the footer offscreen for smaller devices
                            // calc is used in order to push the second button on the same % as the first + how big the 1st button is (56pxs) + some distance
            />
        </Container>
    );
};
export default PrePageProcessBadFeedback;

const Container = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #f1f3f4;
  display: flex;
  justify-content: center;

  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: calc(1 / 1.25);
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: calc(1 / 1.5);
  }
`;

const HeaderAndOverlayWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
`;