import axios from "axios";
import getCookieValue from "../../utils";

/** API URL, variable dependent on what branch you are on, dev staging, or main branches*/
// const baseURL = process.env.REACT_APP_APIURL

function sendPageTime(page, windowReplace)
{
    const axiosInstance = axios.create({withCredentials: true});

    axiosInstance
            .post(
                process.env.REACT_APP_APIURL +
                "/api/pagetimestamp/",
                {"page": page},
                {
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": getCookieValue("csrftoken"),
                    },
                }
            )
            .then (() => {
                window.location.replace(process.env.REACT_APP_APPURL + windowReplace);
            })
            .catch((error) => {
                console.log(error);
            });
}

const reverseFormatMainProcesses = (formattedMainProcesses) => {
    const reverseFormattedMainProcesses = [];

    const mainProcessesData = formattedMainProcesses.map((mainProcess, mainProcessIndex) => ({ 
        name: mainProcess.name, 
        order_id: mainProcessIndex
    }));

    reverseFormattedMainProcesses.push(mainProcessesData);

    let subProcessesData = [];

    formattedMainProcesses.forEach((mainProcess) => {
        if (mainProcess.subProcesses) {
            mainProcess.subProcesses.forEach((subProcess, subIndex) => {
                subProcessesData.push({
                    name: subProcess.name,
                    order_id: subIndex,
                    parent: mainProcess.name
                });
            });
        }
    });

    reverseFormattedMainProcesses.push(subProcessesData);
    return reverseFormattedMainProcesses;
};


/** View from builder into Representative choice page */
export async function FollowUpRepresentativePage() {
    const axiosInstance = axios.create({withCredentials: true});
    try {
        /** Get ALL employees except from the logged in user's team via API /api/teamexcludedemployees/ */
        const promise1 = await new Promise((resolve, reject) => {
            axiosInstance
                .get(
                    process.env.REACT_APP_APIURL +
                    "/api/teamexcludedemployees/")
                    .then((res)=> {
                        localStorage.setItem("intake_employee_data", JSON.stringify(res.data));
                        resolve('promise1');
                    })
                    .catch((error) => {
                        console.log( "ERROR: Could not fetch /api/teamexcludedemployees/", error );
                    })
                })
            if (promise1){
                window.location.replace(process.env.REACT_APP_APPURL + '/intake-representative-choice')
            }
    } catch (error) {
        console.log( error );
    };
}

export async function FollowUpProcessTimePage(){
    window.location.replace(process.env.REACT_APP_APPURL + '/intake-process-time')
}

/** View from Intake Representative to Home */
export async function SendRepresentativeIntake(){
    const IntakeChosenRepresentatives = JSON.parse(localStorage.getItem("repByProcess"))
    const RepresentativeList = []
    IntakeChosenRepresentatives.map((process, index) => {
        let allowEntry =  true
        if (RepresentativeList.length > 0){
            RepresentativeList.map((representative, index) => {
                if(representative.employee_id === process.employee){
                    allowEntry = false
                }
            })
        }
        if(allowEntry){
            RepresentativeList.push({
                "employee_id": process.employee
            })
        }
    })
    const axiosInstance = axios.create({withCredentials: true});
    try{
        const promise1 = await new Promise((resolve, reject) => {
            const businessProcess = JSON.parse(localStorage.getItem(`businessProcessIntake`));

            if (businessProcess !== null && businessProcess.length !== 0) {
                const reverseFormattedData = JSON.stringify(reverseFormatMainProcesses(businessProcess));
                const axiosInstance = axios.create({withCredentials: true});

                axiosInstance
                    .post(
                        process.env.REACT_APP_APIURL +
                        '/api/myintakeprocesses/update_batch/',
                        reverseFormattedData,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                "X-CSRFToken": getCookieValue("csrftoken"),
                            },
                        }
                    )
                    .then (() => {
                        resolve("resolved promise1");
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        })

        if (promise1){
            const promise2 = await new Promise((resolve, reject) => {
                axiosInstance
                    .post(
                        process.env.REACT_APP_APIURL +
                        "/api/myintakeprocesses/create_intakes/",
                        RepresentativeList,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                "X-CSRFToken": getCookieValue("csrftoken"),
                            },
                        }
                    )
                    .then((response) => {
                        resolve("resolved promise1");
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                })
                if (promise2){
                    sendPageTime("intake-representative-choice", "/intake-builder")
                }
        }
    }
    catch (error) {
        console.log(error);
    }
}
