import React from 'react'
import {StyledDivRegular} from "../../layout-and-styling/standardized-components-library/Styling-TextInput";
import styled from "styled-components";
import {Slider} from "@mui/material";

/**
 * Reusable styled sliders that functionally update an array with the values selected based on what function was given as input.
 * Other parameters are also adjustable like min, max, marks, title, and colors can also easily be adjusted if needed.
 */
export default function ComponentStyledSlider({
                                                  updateArray, ArrayQuality, factor, min,
                                                  max, marks, title, thumbColorBool, index
                                              }) {
    const valueLabelFormat = (value) => {
      const index = marks.findIndex((mark) => mark.value === value);
      return marks[index].labelDescription;
    };

    return (
      <SliderContainer>
          <StyledDivRegular LineHeight='18px' Width='105%' FontSize='18px' Color="#271B36" TextAlign="center"
                            FontWeight="400" Position='relative'>
                {title}
            </StyledDivRegular>
          <StyledSlider
              onClick={(event, slider_value) => updateArray(slider_value, factor, true)}
              onChange={(event, slider_value) => updateArray(slider_value, factor, false)}
              value={ArrayQuality[index][factor] ? ArrayQuality[index][factor] : 1}
              valueLabelFormat={valueLabelFormat}
              valueLabelDisplay="auto"
              marks={marks} step={1} min={min} max={max}
              sx={{
                  width: "calc(100% - 120px)",
                  height: 10,
                  '@media screen and (max-width: 1280px)': {
                      width: 32,
                      '& .MuiSlider-markLabel': {fontSize: "10px"},
                      MarginTop: 40,
                  },

                  color: '#6E6E6E',
                  '& .MuiSlider-thumb': {
                      color: thumbColorBool ? "#B3194A" : "#5F6366",
                      width: '15px',
                      height: '15px',
                      '&:focus, &:hover, &.Mui-active': {
                          color: "#D13C70",
                          boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
                          '@media (hover: none)': {boxShadow: 'none',}
                      },
                  },
                  '& .MuiSlider-rail': {
                      opacity: 1,
                      width: 1,
                      backgroundColor: '#F1F3F4',
                  },
                  '& .MuiSlider-root': {
                      opacity: 1,
                      width: 1,
                      backgroundColor: '#F1F3F4',
                  },
                  // '& .MuiSlider-mark': {
                  //     background: '#C6CBCB', height: '1px', width: '3px',
                  //     '&.MuiSlider-markActive': {backgroundColor: '#C6CBCB',},
                  // },
                  '& .MuiSlider-markLabel': {
                    color: '#929699',
                    fontWeight: '400',
                    fontSize: '14px',
                    fontFamily: 'Overpass',
                    lineHeight: '18px'
                },
                  '& .MuiSlider-track': {
                      width: "100%",
                      backgroundColor: '#B3194A',
                  },

              }}
          />
      </SliderContainer>
    )
}

const SliderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const StyledSlider = styled(Slider)`
  position: relative;
  width: 100%;
  height: ${props => props.Height || "8px"};
  font-family: "Overpass";
  color: #271b36;
  z-index: 1;

  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: 1.25;
  }

  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: 1.5;
  }

  @media (-webkit-device-pixel-ratio: 1.75) {
    zoom: 1.75;
  }

  &:focus {
    outline: none !important;
    border: 1px solid #e2336b;
  }
`;
