import React, {useEffect, useState} from 'react';
import axios from 'axios';
import styled from 'styled-components';

import {StyledDivBold, StyledDivRegular} from '../../layout-and-styling/standardized-components-library/Styling-TextInput';
import Logo from '../../assets/LogoElephantsLanding.svg';
import SurveySocialNetworkFooter from '../standardized-components/Survey-social-network-Footer';
import ComponentLanguageSwitcher from "../../layout-and-styling/standardized-components-library/Component-language-switcher";
import ComponentVideoAnimation from "./page-specific-components/ComponentVideoAnimation";
import {useTranslation, Trans} from 'react-i18next';
import {ReactComponent as IconCheckCircleFill} from './assets/IconCheckCircleFill.svg';
import {ReactComponent as IconTwoCircleFill} from './assets/IconTwoCircleFill.svg';
import {ReactComponent as IconThreeCircle} from './assets/IconThreeCircle.svg';
import {ReactComponent as IconArrowRight} from './assets/IconArrowRight.svg';
import {ReactComponent as BottomWave} from './assets/BottomWave.svg';
import VideoBenefits from './assets/VideoBenefits.mp4';



const PageBenefits = () => {
    const {t} = useTranslation();

    /** Check if already authenticated, and bring to dashboard if possible.*/
    useEffect(() => {
        const axiosInstance = axios.create({withCredentials: true})

        // Perform GET request to get a CSRFToken from the API and store it in sessionStorage
        axiosInstance.get(process.env.REACT_APP_APIURL + '/api/auth/validatesession/')
            .catch((error) => {
                console.log('error, you are not logged in yet!')
                window.location.replace(process.env.REACT_APP_APPURL + '/login');
            })
    }, []);

    const [render, setRender] = useState(false);
    const reRender = () => {
        setRender(!render)
    };

    const [componentClicked, setComponentClicked] = useState(false);

    return (
        <Container>
            <Wrapper>

                <LeftContainer>

                    <LogoWrapper>
                      <img src={Logo} alt="" width={"75%"}/>
                    </LogoWrapper>

                    <LeftTextWrapper>

                      <PointWrapper>
                        <IconCheckCircleFill/>

                        <StyledDivBold Position='static' Width='auto' Height='auto' Color='#c6cbcb' FontSize='18px' LineHeight='28px' FontWeight='500' LetterSpacing='1.8px'>
                          {t('PageBenefits.TextSidebarPoint1')}
                        </StyledDivBold>
                      </PointWrapper>

                      <PointWrapper>
                        <IconTwoCircleFill/>
                        
                        <StyledDivBold Position='static' MarginRight='auto' Width='auto' Height='auto' Color='#E52D6B' FontSize='18px' LineHeight='28px' FontWeight='500' LetterSpacing='1.8px'>
                          {t('PageBenefits.TextSidebarPoint2')}
                        </StyledDivBold>

                        <IconArrowRight/>
                      </PointWrapper>

                      <PointWrapper>
                        <IconThreeCircle/>

                        <StyledDivBold Position='static' Width='auto' Height='auto' Color='#929699' FontSize='18px' LineHeight='28px' FontWeight='500' LetterSpacing='1.8px'>
                          {t('PageBenefits.TextSidebarPoint3')}
                        </StyledDivBold>
                      </PointWrapper>

                    </LeftTextWrapper>

                    <BottomWaveWrapper>
                      <BottomWave style={{width: '100%', height: 'auto'}}/>
                    </BottomWaveWrapper>

                </LeftContainer>

                <RightContainer>
                  
                    <LanguageSwitcherWrapper>
                      <ComponentLanguageSwitcher reRender={reRender} Position='static' MarginTop='0' MarginLeft='0' MarginRight='0'/>
                    </LanguageSwitcherWrapper>
                    
                    <RightTextWrapper>

                      <StyledDivBold Position='static' Width='100%' Height='auto' FontSize='42px' LineHeight='52px'>
                        {t('PageBenefits.TextHeader')}
                      </StyledDivBold>

                      <StyledDivRegular Position='static' MarginTop='40px' MarginBottom='40px' Width='100%' Height='auto' FontSize='18px' LineHeight='28px'>
                        <Trans i18nKey="PageBenefits.TextBody">
                          Let’s find out together! For example, this is how your organization would look like <b>before</b> and <b>after</b> using Elephants in the Room. Click on the picture below to see your potential benefits.
                        </Trans>
                      </StyledDivRegular>

                      <ComponentVideoAnimation identifier='VideoBenefits' video={VideoBenefits} setComponentClicked={setComponentClicked}/>

                    </RightTextWrapper>
                    
                    <SurveySocialNetworkFooter componentClicked={componentClicked} setComponentClicked={setComponentClicked} identifier='Benefits' page='PageBenefits' nextdirectory='/user-instructions' prevdirectory='/user-welcome' ButtonText='Next' Position='static' MarginTop='0' BackMarginLeft='0' BackWidth="56px" BackHeight="56px" MarginLeft='20px' Width='160px' Height='56px'/>

                </RightContainer>
                
            </Wrapper>
        </Container>
    );
};

export default PageBenefits

const Container = styled.div`
  background: #f6f7fa;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: calc(1 / 1.25);
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: calc(1 / 1.5);
  }
  @media (-webkit-device-pixel-ratio: 1.75) {
    zoom: calc(1 / 1.75);
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const LeftContainer = styled.div`
  position: absolute;
  width: 26%;
  min-width: 400px;
  max-width: 500px;
  height: 100%;
  display: flex;
  background: #fcfcfc;
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.05);

  @media (max-width: 1420px) {
    display: none;
  }
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: 40px;
  left: 60px;
`;

const LeftTextWrapper = styled.div`
  position: absolute;
  top: 18%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;

const PointWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 60px 0 64px;
  gap: 30px;
`;

const BottomWaveWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: -3px;
`;

const RightContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;

  @media (min-width: 1921px) {
    justify-content: center;
  }
`;

const LanguageSwitcherWrapper = styled.div`
  position: absolute;
  top: 39px;
  display: flex;
  justify-content: flex-end;
  width: 1300px;
  padding: 0 60px;

  @media (max-width: 2420px) {
    margin-left: max(400px, 26%);
  }

  @media (max-width: 1920px) {
    right: 0;
  }
`;

const RightTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1030px;
  padding: 0 220px 0 170px;

  @media (max-width: 2420px) {
    margin-left: max(400px, 26%);
  }

  @media (max-width: 1420px) {
    padding: 0 60px;
    margin: auto;
  }
`;


{/* <ComponentImageComparison identifier='SocialWeb' imageLeft1={<SocialWebBefore1/>} imageRight1={<SocialWebAfter1/>} imageLeft2={<SocialWebBefore2/>} imageRight2={<SocialWebAfter2/>} imageLeft3={<SocialWebBefore3/>} imageRight3={<SocialWebAfter3/>}/> */}