import styled from 'styled-components';
import { StyledSlider } from '../../../layout-and-styling/standardized-components-library/Styling-TextInput';
import {useTranslation} from "react-i18next";
import clockThumb from "../../common-assets/clockThumb.png";

const ComponentFrequencySlider = (props) => {
    const {t} = useTranslation();
    const marks = [
        {
            value: 1,
            label: "0 min"
        },
        {
            value: 30,
            label: "30 min"
        },
        {
            value: 60,
            label: "1 h"
        },
        {
            value: 90,
            label: "1.5 h"
        },
        {
            value: 120,
            label: "2 h"
        },
    ];

    function toHHMMSS(totalMinutes) {
        const minutes = totalMinutes % 60;
        const hours = Math.floor(totalMinutes / 60);
        if (!hours) {
            return `00:${padTo2Digits(Math.floor(minutes))}`;

        } else {
            return `${padTo2Digits(hours)}:${padTo2Digits(Math.floor(minutes))}`;
        }
    }

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    function mapping_function(mark) {
        mark.value = mark.value - props.meetingTime
        return mark
    }

    return (
        <SliderWrapper MarginTop="12px">

            <MeetingsTime Width={props.meetingTime/120 * 100 + "%"}/>
            
            {props.element == "average" && props.average || props.element == "days" && !props.average ? (
                <StyledSlider MarginLeft={props.meetingTime/120 * 100 + "%"} MarginTop="4px" height="32px" Position="absolute"
                    onChange={(event, value) => { props.sliderChange(value); }}
                    value={props.spontaneousTime}
                    valueLabelDisplay={"auto"} valueLabelFormat={toHHMMSS}
                    size="small" marks={marks.filter( m => m.value > props.meetingTime).map(mapping_function)} step={5} min={0} max={120 - props.meetingTime} orientation="horizontal"
                    sx={{
                        height: "12px",
                        width: "fill-available",
                        color: '#E3E5E4',
                        zIndex: 2,
                        '& .MuiSlider-thumb': {
                            color: "#5F6366",
                            width: '20px',
                            height: '20px',
                            backgroundImage: "url(" + clockThumb + ")",
                            '&:focus, &:hover, &.Mui-active': {
                                boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',  
                            },
                        },
                        '& .MuiSlider-track': {
                            backgroundColor: '#B3194A',
                        },
                        '& .MuiSlider-markLabel': {
                            fontSize: '12px',
                        },
                    }}
                />
            ) : (
                <StyledSlider MarginLeft={props.meetingTime/120 * 100 + "%"} MarginTop="4px" height="32px" Position="absolute"
                    onChange={(event, value) => { props.sliderChange(value); }}
                    value={props.spontaneousTime}
                    size="small" marks={marks.filter( m => m.value > props.meetingTime).map(mapping_function)} step={5} min={0} max={120 - props.meetingTime} orientation="horizontal"
                    sx={{
                        height: "12px",
                        width: "fill-available",
                        color: '#E3E5E4',
                        cursor: 'auto',
                        zIndex: 2,
                        '& .MuiSlider-thumb': {
                            color: "#5F6366",
                            width: '20px',
                            height: '20px',
                            backgroundImage: "url(" + clockThumb + ")",
                            '&:focus, &:hover, &.Mui-active': {
                                boxShadow: 'none',
                            },
                        },
                        '& .MuiSlider-track': {
                            backgroundColor: '#B3194A',
                        },
                        '& .MuiSlider-markLabel': {
                            fontSize: '12px',
                        },
                    }}
                />
            )}

        </SliderWrapper>
    )
}

export default ComponentFrequencySlider;

const SliderWrapper = styled.div`
  width: 100%;
  height: 30px;
  background-color: #FCFCFC;
  margin-top: ${props => props.MarginTop || "177px" };
  margin-left: ${props => props.MarginLeft || "0px" };
  opacity: ${props => props.Opacity || "1"};
  pointer-events: ${props => props.PointerEvents || ""};
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: ${props => props.BorderRadius || '10px'};
  z-index: 0;
`;

const MeetingsTime = styled.div`
  position: absolute;
  width: ${props => props.Width || "0px" };
  height: 16px;
  background: #271B36;
  margin-right: 0px;
  margin-top: -20px;
  border-radius: ${props => props.BorderRadius || '10px'};
  z-index: 1;
`;
