import styled from 'styled-components';
import { StyledDivRegular } from '../../layout-and-styling/standardized-components-library/Styling-TextInput';
import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";

// This file contains all the explanatory tooltips used in the text processing survey pages and its introductions

export const TooltipKnowledgePage = (props) => {

    if (props.whichTooltip === "BusinessProcess") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="45px" MarginTop="-55px">
                <ExplanationBoxContainer Width='565px' Height='fit-content' ZIndex='10' Position="absolute">

                    <IntroExplanationBox Position="static" Top="0" Left="0" Width='565px' Height='fit-content'>

                        <StyledDivRegular Position="static" MarginTop='24px' MarginBottom='24px' MarginLeft='24px' Width='517px'
                            Height='fit-content' FontSize='18px' LineHeight='28px' TextAlign="left">
                            <Trans i18nKey="PageKnowledge.ExplanationBox5"></Trans>
                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='25px' Left='-60px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        Rotate="rotate(180deg)"
                        BorderLeft='30px solid #E2336B' />

                    <IntroExplanationInnerTriangle Top='25px' Left='-58px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC'
                        Rotate="rotate(180deg)" />

                </ExplanationBoxContainer>

            </IntroOverlayBoxWrapper>
        )
    }


    if (props.whichTooltip === "BusinessProcessKnowledge") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-615px" MarginTop="-55px">
                <ExplanationBoxContainer Width='565px' Height='fit-content' ZIndex='4' Position="absolute">

                    <IntroExplanationBox Position="static" Top="0" Left="0" Width='565px' Height='fit-content'>

                        <StyledDivRegular Position="static" MarginTop='24px' MarginBottom='24px' MarginLeft='26px' Width='517px'
                            Height='fit-content' FontSize='18px' LineHeight='28px' TextAlign="left">
                            <Trans i18nKey="PageKnowledge.ExplanationBox1">
                                Fill in what is your <b>knowledge</b> in each business processes, even though you are not involved in them.
                            </Trans>
                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='25px' Left='568px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='25px' Left='565px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />

                </ExplanationBoxContainer>

            </IntroOverlayBoxWrapper>
        )
    }

    if (props.whichTooltip === "SliderKnowledge") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-620px" MarginTop="-56px">

                <ExplanationBoxContainer Width='565px' Height='fit-content' ZIndex='4' Position="absolute">

                    <IntroExplanationBox Top='0px' Left='0px' Width='565px' Height='fit-content' Position="static">

                        <StyledDivRegular Position="static" MarginTop='24px' MarginBottom='24px' MarginLeft='24px' Width='517px'
                            Height='fit-content' FontSize='18px' LineHeight='28px' TextAlign="left">
                            {i18next.t('PageKnowledge.ExplanationBox2')}
                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='25px' Left='568px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='25px' Left='565px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />

                </ExplanationBoxContainer>

            </IntroOverlayBoxWrapper>
        )
    }

    if (props.whichTooltip === "IndividualImpact") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-620px" MarginTop="-56px">
                <ExplanationBoxContainer Width='565px' Height='fit-content' ZIndex='4' Position="absolute">

                    <IntroExplanationBox Top='0px' Left='0px' Width='565px' Height='fit-content' Position="static">

                        <StyledDivRegular MarginTop='24px' MarginBottom='24px' MarginLeft='24px' Position="static"
                            Width='517px' Height='fit-content' FontSize='18px' TextAlign="left" LineHeight='28px'>
                            {i18next.t('PageKnowledge.ExplanationBox3')}
                            <br /><br />
                            {i18next.t('PageKnowledge.ExplanationBox4')}

                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='25px' Left='568px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='25px' Left='565px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />
                </ExplanationBoxContainer>

            </IntroOverlayBoxWrapper>
        )
    }
    if (props.whichTooltip === "Step1") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginTop="32px" MarginLeft="-20px">

                <ExplanationBoxContainer Width='456px' Height='fit-content' ZIndex='4' Position="absolute">

                    <IntroExplanationBox Width='456px' Height='fit-content' Position="static">

                        <StyledDivRegular Position="static" MarginTop='24px' MarginLeft='24px' MarginRight='24px'
                            Width='408px' Height='fit-content' MarginBottom='24px'
                            FontSize='18px' LineHeight='28px' TextAlign="left">
                            {i18next.t('IntroPageKnowledge.HoverTooltipStep1')}
                        </StyledDivRegular>
                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='-48px' Left='10px' BorderBottom='30px solid #E2336B'
                        BorderRight='10px solid transparent' BorderTop=''
                        BorderLeft='10px solid transparent' />
                    <IntroExplanationInnerTriangle Top='-45px' Left='10px' BorderBottom='30px solid #FCFCFC'
                        BorderRight='10px solid transparent' BorderTop=''
                        BorderLeft='10px solid transparent' />

                </ExplanationBoxContainer>
            </IntroOverlayBoxWrapper>
        )
    }
    if (props.whichTooltip === "Step2") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginTop="32px" MarginLeft="-20px">

                <ExplanationBoxContainer Width='456px' Height='fit-content' ZIndex='4' Position="absolute">

                    <IntroExplanationBox Width='456px' Height='fit-content' Position="static">

                        <StyledDivRegular Position="static" MarginTop='24px' MarginLeft='24px' MarginRight='24px'
                            Width='408px' Height='fit-content' MarginBottom='24px'
                            FontSize='18px' LineHeight='28px' TextAlign="left">
                            {i18next.t('IntroPageKnowledge.HoverTooltipStep2')}
                        </StyledDivRegular>
                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='-48px' Left='10px' BorderBottom='30px solid #E2336B'
                        BorderRight='10px solid transparent' BorderTop=''
                        BorderLeft='10px solid transparent' />
                    <IntroExplanationInnerTriangle Top='-45px' Left='10px' BorderBottom='30px solid #FCFCFC'
                        BorderRight='10px solid transparent' BorderTop=''
                        BorderLeft='10px solid transparent' />

                </ExplanationBoxContainer>
            </IntroOverlayBoxWrapper>
        )
    }
    if (props.whichTooltip === "Step3") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginTop="32px" MarginLeft="-264px">

                <ExplanationBoxContainer Width='456px' Height='fit-content' ZIndex='4' Position="absolute">

                    <IntroExplanationBox Width='456px' Height='fit-content' Position="static">

                        <StyledDivRegular Position="static" MarginTop='24px' MarginLeft='24px' MarginRight='24px'
                            Width='408px' Height='fit-content' MarginBottom='24px'
                            FontSize='18px' LineHeight='28px' TextAlign="left">
                            {i18next.t('IntroPageKnowledge.HoverTooltipStep3')}
                            <br></br>
                            <br></br>
                            {i18next.t('IntroPageKnowledge.HoverTooltipStep4')}
                        </StyledDivRegular>
                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='-48px' Left='252px' BorderBottom='30px solid #E2336B'
                        BorderRight='10px solid transparent' BorderTop=''
                        BorderLeft='10px solid transparent' />
                    <IntroExplanationInnerTriangle Top='-45px' Left='252px' BorderBottom='30px solid #FCFCFC'
                        BorderRight='10px solid transparent' BorderTop=''
                        BorderLeft='10px solid transparent' />

                </ExplanationBoxContainer>
            </IntroOverlayBoxWrapper>
        )
    }
}

export const TooltipIntroductionProcessGoodFeedback = (props) => {

    if (props.whichTooltip === "DoingGood") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginTop="30px" MarginLeft="-20px">

                <ExplanationBoxContainer Width='346px' Height='fit-content' ZIndex='4' Position="absolute">

                    <IntroExplanationBox Width='346px' Height='fit-content' Position="static">

                        <StyledDivRegular Position="static" MarginTop='24px' MarginBottom='24px' MarginLeft='24px' MarginRight='24px'
                            Width='408px' Height='fit-content'
                            FontSize='18px' LineHeight='28px' TextAlign="left">
                            {i18next.t('PageGoodFeedback.Tooltip')}
                        </StyledDivRegular>
                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='-48px' Left='10px' BorderBottom='30px solid #E2336B'
                        BorderRight='10px solid transparent' BorderTop=''
                        BorderLeft='10px solid transparent' />
                    <IntroExplanationInnerTriangle Top='-45px' Left='10px' BorderBottom='30px solid #FCFCFC'
                        BorderRight='10px solid transparent' BorderTop=''
                        BorderLeft='10px solid transparent' />

                </ExplanationBoxContainer>
            </IntroOverlayBoxWrapper>
        )
    }
}


export const TooltipProcessGoodFeedback = (props) => {

    if (props.whichTooltip === "Subprocess") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-640px" MarginTop="-55px">

                <ExplanationBoxContainer Width='581px' Height='fit-content' ZIndex='4' Position="absolute">

                    <IntroExplanationBox Top='0px' Left='0' Width='581px' Height='fit-content' Position="static">

                        <StyledDivRegular MarginTop='24px' MarginLeft='24px' MarginRight='24px' MarginBottom="24px"
                            Width='517px' Height='fit-content' TextAlign="left"
                            FontSize='18px' LineHeight='28px' Position="static">
                            {i18next.t('PageGoodFeedback.ExplanationBox1')}
                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='25px' Left='585px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='25px' Left='582px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />

                </ExplanationBoxContainer>

            </IntroOverlayBoxWrapper>
        )
    }
    if (props.whichTooltip === "Tags") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-648px" MarginTop="-55px">

                <ExplanationBoxContainer Width='581px' Height='fit-content' ZIndex='4' Position="absolute">

                    <IntroExplanationBox Top='0px' Left='0' Width='581px' Height='fit-content' Position="static">

                        <StyledDivRegular MarginTop='24px' MarginLeft='24px' MarginRight='24px' MarginBottom="24px"
                            Width='517px' Height='fit-content' TextAlign="left"
                            FontSize='18px' LineHeight='28px' Position="static">
                            {i18next.t('PageGoodFeedback.ExplanationBox2')}
                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='25px' Left='585px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='25px' Left='582px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />

                </ExplanationBoxContainer>
            </IntroOverlayBoxWrapper>
        )
    }
    if (props.whichTooltip === "Teams") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-640px" MarginTop="-55px">
                <ExplanationBoxContainer Width='581px' Height='fit-content' ZIndex='4' Position="absolute">

                    <IntroExplanationBox Top='0px' Left='0' Width='581px' Height='fit-content' Position="static">

                        <StyledDivRegular MarginTop='24px' MarginLeft='24px' MarginRight='24px' MarginBottom="24px"
                            Width='517px' Height='fit-content' TextAlign="left"
                            FontSize='18px' LineHeight='28px' Position="static">
                            {i18next.t('PageGoodFeedback.ExplanationBox3')}
                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='25px' Left='585px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='25px' Left='582px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />

                </ExplanationBoxContainer>
            </IntroOverlayBoxWrapper>
        )
    }
    if (props.whichTooltip === "Text1") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-560px" MarginTop="-55px">
                <ExplanationBoxContainer Width='500px' Height='fit-content' ZIndex='4' Position="absolute">

                    <IntroExplanationBox Top='0px' Left='0' Width='500px' Height='fit-content' Position="static">

                        <StyledDivRegular MarginTop='24px' MarginLeft='24px' MarginRight='24px' MarginBottom="24px"
                            Width='450px' Height='fit-content' TextAlign="left"
                            FontSize='18px' LineHeight='28px' Position="static">
                            {i18next.t('PageGoodFeedback.ExplanationBox4')}
                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='25px' Left='502px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='25px' Left='498px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />

                </ExplanationBoxContainer>
            </IntroOverlayBoxWrapper>
        )
    }
    if (props.whichTooltip === "Text2") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-640px" MarginTop="-55px">
                <ExplanationBoxContainer Width='581px' Height='fit-content' ZIndex='4' Position="absolute">

                    <IntroExplanationBox Top='0px' Left='0' Width='581px' Height='fit-content' Position="static">

                        <StyledDivRegular MarginTop='24px' MarginLeft='24px' MarginRight='24px' MarginBottom="24px"
                            Width='517px' Height='fit-content' TextAlign="left"
                            FontSize='18px' LineHeight='28px' Position="static">
                            {i18next.t('PageGoodFeedback.ExplanationBox5')}
                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='25px' Left='585px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='25px' Left='582px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />

                </ExplanationBoxContainer>
            </IntroOverlayBoxWrapper>
        )
    }
    if (props.whichTooltip === "Text3") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-640px" MarginTop="-55px">
                <ExplanationBoxContainer Width='581px' Height='fit-content' ZIndex='4' Position="absolute">

                    <IntroExplanationBox Top='0px' Left='0' Width='581px' Height='fit-content' Position="static">

                        <StyledDivRegular MarginTop='24px' MarginLeft='24px' MarginRight='24px' MarginBottom="24px"
                            Width='517px' Height='fit-content' TextAlign="left"
                            FontSize='18px' LineHeight='28px' Position="static">
                            {i18next.t('PageGoodFeedback.ExplanationBox6')}
                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='25px' Left='585px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='25px' Left='582px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />

                </ExplanationBoxContainer>
            </IntroOverlayBoxWrapper>
        )
    }
    if (props.whichTooltip === "Feedback") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-34em" MarginTop="-40px">

                <ExplanationBoxContainer Width='540px' Height='fit-content' ZIndex='4' Position="absolute">

                    <IntroExplanationBox Top='0px' Left='0px' Width='540px' Height='fit-content' Position="static">

                        <StyledDivRegular MarginTop='24px' MarginLeft='24px' MarginRight='24px' MarginBottom="24px"
                            Width='476px' Height='fit-content' TextAlign="left"
                            FontSize='18px' LineHeight='28px' Position="static">
                            {i18next.t('PageGoodFeedback.ExplanationBox2')}
                            <br /><br />
                            {i18next.t('PageGoodFeedback.ExplanationBox3')}
                            <br /><br />
                            {i18next.t('PageGoodFeedback.ExplanationBox4')}
                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='25px' Left='545px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='25px' Left='542px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />

                </ExplanationBoxContainer>

            </IntroOverlayBoxWrapper>
        )
    }
}

export const TooltipIntroductionProcessBadFeedback = (props) => {

    if (props.whichTooltip === "Improvements") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginTop="32px" MarginLeft="-64px">

                <ExplanationBoxContainer Width='324px' Height='fit-content' ZIndex='4' Position="absolute">

                    <IntroExplanationBox Width='324px' Height='fit-content' Position="static">

                        <StyledDivRegular Position="static" MarginTop='24px' MarginBottom='24px' MarginLeft='24px' MarginRight='24px'
                            Width='408px' Height='fit-content'
                            FontSize='18px' LineHeight='28px' TextAlign="left">
                            {i18next.t('IntroPageBadFeedback.Tooltip')}

                        </StyledDivRegular>
                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='-48px' Left='32px' BorderBottom='30px solid #E2336B'
                        BorderRight='10px solid transparent' BorderTop=''
                        BorderLeft='10px solid transparent' />
                    <IntroExplanationInnerTriangle Top='-45px' Left='32px' BorderBottom='30px solid #FCFCFC'
                        BorderRight='10px solid transparent' BorderTop=''
                        BorderLeft='10px solid transparent' />

                </ExplanationBoxContainer>
            </IntroOverlayBoxWrapper>
        )
    }
}

export const TooltipProcessBadFeedback = (props) => {

    if (props.whichTooltip === "Subprocess") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-640px" MarginTop="-55px">

                <ExplanationBoxContainer Width='581px' Height='fit-content' ZIndex='4' Position="absolute">

                    <IntroExplanationBox Top='0px' Left='0' Width='581px' Height='fit-content' Position="static">

                        <StyledDivRegular MarginTop='24px' MarginLeft='24px' MarginRight='24px' MarginBottom="24px"
                            Width='517px' Height='fit-content' TextAlign="left"
                            FontSize='18px' LineHeight='28px' Position="static">
                            {i18next.t('PageBadFeedback.ExplanationBox1')}
                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='25px' Left='585px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='25px' Left='582px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />

                </ExplanationBoxContainer>

            </IntroOverlayBoxWrapper>
        )
    }
    if (props.whichTooltip === "Tags") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-648px" MarginTop="-55px">

                <ExplanationBoxContainer Width='581px' Height='fit-content' ZIndex='4' Position="absolute">

                    <IntroExplanationBox Top='0px' Left='0' Width='581px' Height='fit-content' Position="static">

                        <StyledDivRegular MarginTop='24px' MarginLeft='24px' MarginRight='24px' MarginBottom="24px"
                            Width='517px' Height='fit-content' TextAlign="left"
                            FontSize='18px' LineHeight='28px' Position="static">
                            {i18next.t('PageBadFeedback.ExplanationBox2')}
                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='25px' Left='585px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='25px' Left='582px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />

                </ExplanationBoxContainer>
            </IntroOverlayBoxWrapper>
        )
    }
    if (props.whichTooltip === "Teams") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-640px" MarginTop="-55px">
                <ExplanationBoxContainer Width='581px' Height='fit-content' ZIndex='4' Position="absolute">

                    <IntroExplanationBox Top='0px' Left='0' Width='581px' Height='fit-content' Position="static">

                        <StyledDivRegular MarginTop='24px' MarginLeft='24px' MarginRight='24px' MarginBottom="24px"
                            Width='517px' Height='fit-content' TextAlign="left"
                            FontSize='18px' LineHeight='28px' Position="static">
                            {i18next.t('PageBadFeedback.ExplanationBox3')}
                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='25px' Left='585px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='25px' Left='582px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />

                </ExplanationBoxContainer>
            </IntroOverlayBoxWrapper>
        )
    }
    if (props.whichTooltip === "Text1") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-640px" MarginTop="-55px">
                <ExplanationBoxContainer Width='581px' Height='fit-content' ZIndex='4' Position="absolute">

                    <IntroExplanationBox Top='0px' Left='0' Width='581px' Height='fit-content' Position="static">

                        <StyledDivRegular MarginTop='24px' MarginLeft='24px' MarginRight='24px' MarginBottom="24px"
                            Width='517px' Height='fit-content' TextAlign="left"
                            FontSize='18px' LineHeight='28px' Position="static">
                            {i18next.t('PageBadFeedback.ExplanationBox4')}
                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='25px' Left='585px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='25px' Left='582px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />

                </ExplanationBoxContainer>
            </IntroOverlayBoxWrapper>
        )
    }
    if (props.whichTooltip === "Text2") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-640px" MarginTop="-55px">
                <ExplanationBoxContainer Width='581px' Height='fit-content' ZIndex='4' Position="absolute">

                    <IntroExplanationBox Top='0px' Left='0' Width='581px' Height='fit-content' Position="static">

                        <StyledDivRegular MarginTop='24px' MarginLeft='24px' MarginRight='24px' MarginBottom="24px"
                            Width='517px' Height='fit-content' TextAlign="left"
                            FontSize='18px' LineHeight='28px' Position="static">
                            {i18next.t('PageBadFeedback.ExplanationBox5')}
                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='25px' Left='585px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='25px' Left='582px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />

                </ExplanationBoxContainer>
            </IntroOverlayBoxWrapper>
        )
    }
    if (props.whichTooltip === "Text3") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-640px" MarginTop="-55px">
                <ExplanationBoxContainer Width='581px' Height='fit-content' ZIndex='4' Position="absolute">

                    <IntroExplanationBox Top='0px' Left='0' Width='581px' Height='fit-content' Position="static">

                        <StyledDivRegular MarginTop='24px' MarginLeft='24px' MarginRight='24px' MarginBottom="24px"
                            Width='517px' Height='fit-content' TextAlign="left"
                            FontSize='18px' LineHeight='28px' Position="static">
                            {i18next.t('PageBadFeedback.ExplanationBox6')}
                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='25px' Left='585px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='25px' Left='582px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />

                </ExplanationBoxContainer>
            </IntroOverlayBoxWrapper>
        )
    }
    if (props.whichTooltip === "Feedback") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-34em" MarginTop="-40px">

                <ExplanationBoxContainer Width='540px' Height='fit-content' ZIndex='4' Position="absolute">

                    <IntroExplanationBox Top='0px' Left='0px' Width='540px' Height='fit-content' Position="static">

                        <StyledDivRegular MarginTop='24px' MarginLeft='24px' MarginRight='24px' MarginBottom="24px"
                            Width='476px' Height='fit-content' TextAlign="left"
                            FontSize='18px' LineHeight='28px' Position="static">
                            {i18next.t('PageBadFeedback.ExplanationBox2')}
                            <br /><br />
                            {i18next.t('PageBadFeedback.ExplanationBox3')}
                            <br /><br />
                            {i18next.t('PageBadFeedback.ExplanationBox4')}
                        </StyledDivRegular>

                    </IntroExplanationBox>

                    <IntroExplanationOuterTriangle Top='25px' Left='545px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='25px' Left='542px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />

                </ExplanationBoxContainer>

            </IntroOverlayBoxWrapper>
        )
    }
    if (props.whichTooltip === "businessFlow") {
        return (
            <>
                <IntroOverlayBoxWrapper Position="absolute" MarginTop="24px" MarginLeft="-424px">

                    <ExplanationBoxContainer Width='581px' Height='fit-content' ZIndex='4' Position="absolute" Left="200px">

                        <IntroExplanationBox Width='581px' Height='fit-content' Position="static">

                            <StyledDivRegular Position="static" MarginTop='24px' MarginLeft='24px' MarginRight='24px'
                                MarginBottom="24px" Width='517px'
                                Height='fit-content' FontSize='18px' LineHeight='28px' TextAlign='left'>
                                {i18next.t('IntroPageBadFeedback.HoverBusinessFlow')}
                            </StyledDivRegular>
                        </IntroExplanationBox>

                        <IntroExplanationOuterTriangle Top='-48px' Left='224px' BorderBottom='30px solid #E2336B'
                            BorderRight='10px solid transparent' BorderTop=''
                            BorderLeft='10px solid transparent' />
                        <IntroExplanationInnerTriangle Top='-45px' Left='224px' BorderBottom='30px solid #FCFCFC'
                            BorderRight='10px solid transparent' BorderTop=''
                            BorderLeft='10px solid transparent' />

                    </ExplanationBoxContainer>
                </IntroOverlayBoxWrapper>
            </>
        )
    }
    if (props.whichTooltip === "chatbot") {
        return (
            <>
                <IntroOverlayBoxWrapper Position="absolute" MarginTop="24px" MarginLeft="-300px">

                    <ExplanationBoxContainer Width='600px' Height='fit-content' ZIndex='4' Position="absolute">

                        <IntroExplanationBox Width='600px' Height='fit-content' Position="static">

                            <StyledDivRegular Position="static" MarginTop='24px' MarginLeft='24px' MarginRight='24px'
                                MarginBottom="24px" Width='600px' TextAlign='left'
                                Height='fit-content' FontSize='18px' LineHeight='28px'>
                                {i18next.t('IntroPageBadFeedback.HoverChatbot1')}
                                <br></br><br></br>
                                {i18next.t('IntroPageBadFeedback.HoverChatbot2')}
                            </StyledDivRegular>
                        </IntroExplanationBox>

                        <IntroExplanationOuterTriangle Top='-48px' Left='286px' BorderBottom='30px solid #E2336B'
                            BorderRight='10px solid transparent' BorderTop=''
                            BorderLeft='10px solid transparent' />
                        <IntroExplanationInnerTriangle Top='-45px' Left='286px' BorderBottom='30px solid #FCFCFC'
                            BorderRight='10px solid transparent' BorderTop=''
                            BorderLeft='10px solid transparent' />

                    </ExplanationBoxContainer>
                </IntroOverlayBoxWrapper>
            </>
        )
    }
    if (props.whichTooltip === "generatedAnswers") {
        return (
            <>
                <IntroOverlayBoxWrapper Position="absolute" MarginTop="24px" MarginLeft="-450px">

                    <ExplanationBoxContainer Width='581px' Height='fit-content' ZIndex='4' Position="absolute">

                        <IntroExplanationBox Width='581px' Height='fit-content' Position="static">

                            <StyledDivRegular Position="static" MarginTop='24px' MarginLeft='24px' MarginRight='24px'
                                MarginBottom="24px" Width='517px' TextAlign='left'
                                Height='fit-content' FontSize='18px' LineHeight='28px'>
                                {i18next.t('IntroPageBadFeedback.Hover1Part1')}
                                <br></br><br></br>
                                {i18next.t('IntroPageBadFeedback.Hover1Part2')}
                            </StyledDivRegular>
                        </IntroExplanationBox>

                        <IntroExplanationOuterTriangle Top='-48px' Left='432px' BorderBottom='30px solid #E2336B'
                            BorderRight='10px solid transparent' BorderTop=''
                            BorderLeft='10px solid transparent' />
                        <IntroExplanationInnerTriangle Top='-45px' Left='432px' BorderBottom='30px solid #FCFCFC'
                            BorderRight='10px solid transparent' BorderTop=''
                            BorderLeft='10px solid transparent' />

                    </ExplanationBoxContainer>
                </IntroOverlayBoxWrapper>
            </>
        )
    }
}

export const TooltipRootcauseChatbot = (props) => {
    if(props.tooltip == "MiniIntake") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-648px" MarginTop="-55px">
    
                <ExplanationBoxContainer Width='581px' Height='fit-content' ZIndex='4' Position="absolute">
    
                    <IntroExplanationBox Top='0px' Left='0' Width='581px' Height='fit-content' Position="static">
    
                        <StyledDivRegular MarginTop='24px' MarginLeft='24px' MarginRight='24px' MarginBottom="24px"
                            Width='517px' Height='fit-content' TextAlign="left"
                            FontSize='18px' LineHeight='28px' Position="static">
                            {i18next.t('PageRootcauseChatbot.MiniIntakeTooltip1')}
                            <br></br><br></br>
                            {i18next.t('PageRootcauseChatbot.MiniIntakeTooltip2')}
                        </StyledDivRegular>
    
                    </IntroExplanationBox>
    
                    <IntroExplanationOuterTriangle Top='25px' Left='585px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='25px' Left='582px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />
    
                </ExplanationBoxContainer>
            </IntroOverlayBoxWrapper>
        )
    }
    else if(props.tooltip == "Chatbot") {
        return (
            <IntroOverlayBoxWrapper Position="absolute" MarginLeft="-648px" MarginTop="-55px">
    
                <ExplanationBoxContainer Width='581px' Height='fit-content' ZIndex='4' Position="absolute">
    
                    <IntroExplanationBox Top='0px' Left='0' Width='581px' Height='fit-content' Position="static">
    
                        <StyledDivRegular MarginTop='24px' MarginLeft='24px' MarginRight='24px' MarginBottom="24px"
                            Width='517px' Height='fit-content' TextAlign="left"
                            FontSize='18px' LineHeight='28px' Position="static">
                            {i18next.t('PageRootcauseChatbot.Tooltip1')}
                            <br></br><br></br>
                            {i18next.t('PageRootcauseChatbot.Tooltip2')}
                            <br></br><br></br>
                            {i18next.t('PageRootcauseChatbot.Tooltip3')}
                        </StyledDivRegular>
    
                    </IntroExplanationBox>
    
                    <IntroExplanationOuterTriangle Top='25px' Left='585px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #E2336B' />
                    <IntroExplanationInnerTriangle Top='25px' Left='582px' BorderTop="10px solid transparent"
                        BorderRight='30px solid transparent'
                        BorderBottom="10px solid transparent"
                        BorderLeft='30px solid #FCFCFC' />
    
                </ExplanationBoxContainer>
            </IntroOverlayBoxWrapper>
        )
    }
}

const IntroOverlayBoxWrapper = styled.div`
  position: ${props => props.Position || "fixed"};
  margin-top: ${props => props.MarginTop || "0px"};
  margin-right: ${props => props.MarginRight || "0px"};
  margin-left: ${props => props.MarginLeft || "0px"};
  margin-bottom: ${props => props.MarginBottom || "0px"};
  transition: 2s ease-out 100ms;
  left: 50%;
  top: 100%;
  z-index: 4;
  transform: translate(-50%, 0.75rem);
`;

const ExplanationBoxContainer = styled.div`
  position: ${props => props.Position || "fixed"};
  top: ${props => props.Top || "0px"};
  left: ${props => props.Left || "0px"};
  min-width: ${props => props.Width || "550px"};
  height: ${props => props.Height || "300px"};
  display: ${props => props.Display || ""};
  z-index: ${props => props.ZIndex || "3"};
`;

const IntroExplanationBox = styled.div`
  position: ${props => props.Position || "absolute"};
  top: ${props => props.Top || "258px"};
  left: ${props => props.Left || "1000px"};
  width: ${props => props.Width || "540px"};
  height: ${props => props.Height || "250px"};
  background: #FCFCFC;
  z-index: ${props => props.ZIndex || "3"};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: ${props => props.FontFamily || 'Overpass'};
  font-size: ${props => props.FontSize || '18px'};
  line-height: ${props => props.LineHeight || '28px'};
  font-weight: ${props => props.FontWeight || '400'};
  border: 2px solid #E2336B;
  border-radius: 10px;
`;

const IntroExplanationOuterTriangle = styled.div`
  position: absolute;
  top: ${props => props.Top || "288px"};
  left: ${props => props.Left || "970px"};
  width: 0;
  height: 0;
  border-top: ${props => props.BorderTop || "20px solid transparent"};
  border-right: ${props => props.BorderRight || "30px solid #E2336B"};
  border-bottom: ${props => props.BorderBottom || "20px solid transparent"};
  border-left: ${props => props.BorderLeft || ""};
  z-index: ${props => props.ZIndex || "3"};
  transform: ${props => props.Rotate || ""};
`;

const IntroExplanationInnerTriangle = styled.div`
  position: absolute;
  top: ${props => props.Top || "288px"};
  left: ${props => props.Left || "973px"};
  width: 0;
  height: 0;
  border-top: ${props => props.BorderTop || "20px solid transparent"};
  border-right: ${props => props.BorderRight || "30px solid #FCFCFC"};
  border-bottom: ${props => props.BorderBottom || "20px solid transparent"};
  border-left: ${props => props.BorderLeft || ""};
  z-index: ${props => props.ZIndex || "3"};
  transform: ${props => props.Rotate || ""};
`;
