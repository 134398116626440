import React, {useContext, useState, useEffect, useRef, Component} from "react";
import styled from "styled-components";
import { OverlayContext } from "../../layout-and-styling/context-hooks/OverlayContext";
import {StyledDivButton, StyledDivRegular} from "../../layout-and-styling/standardized-components-library/Styling-TextInput";
import RenderComponent from "./components/RenderComponent";
import {useTranslation} from 'react-i18next';
import { TagNetworkPage } from "../api-calls/api-calls"
import load from "../../assets/dashboard/load.gif";
import DropdownClosed from "../../assets/dashboard/DropdownClosed.svg";
import DropdownOpened from "../../assets/dashboard/DropdownOpened.svg";

export var ProblemNetworkVis = []; // where the tag network is stored

const ProblemNetwork = (props) => {
    const context = useContext(OverlayContext);
    const {t, i18n} = useTranslation();
    
    /** The useRef Hook creates a variable that "holds on" to a value across rendering passes.
     * In this case it will hold our component's SVG DOM element.
     * It's initialized null and React will assign it later (see the return statement) */

    const currentUser = JSON.parse(localStorage.getItem("current_user_data"))[0];
    const [selectedCompany, setSelectedCompany] = useState(JSON.parse(localStorage.getItem("selected_company")));
    const [selectedSurvey, setSelectedSurvey] = useState(JSON.parse(localStorage.getItem("selected_survey")));
    
    // default sizes and zooms of the networks of the different surveys, after render they are recalculated automatically
    // every network has different dimensions, which are calculated in the file TagNetwork-render-component in the useEffect when props.graphData changes
    // these are the dimensions obtained there. On reload of the page these sizes are automatically assigned but at the first time it does not, that why we have to add these values here
    // TODO: make it automated also for the first load of the page
    const [zoom, setZoom] = useState(0.4);
    const [graphWidth, setGraphWidth] = useState(3840);
    const [graphHeight, setGraphHeight] = useState(3840);
    
    
    const [graphData, setGraphData] = useState(JSON.parse(localStorage.getItem("tag_network_data")) && JSON.parse(localStorage.getItem("tag_network_data")).survey == selectedSurvey ? JSON.parse(localStorage.getItem("tag_network_data")) : null);
    const [ProcessData, setProcessData] = useState(["Quotation / 报价", "Sample production / 样品制作", "Volume production / 生产量", "Volume quality & shipping / 批量质量和运输", "After sales / 售后服务", "Complaint & customer support / 投诉和客户支持"]);
    const [selectedProcess, setSelectedProcess] = useState(JSON.parse(localStorage.getItem("selected_process")) ? JSON.parse(localStorage.getItem("selected_process")) : ProcessData[0]);
    const [selectedElement, setSelectedElement] = useState(JSON.parse(localStorage.getItem("selected_element")));
    const [selectedConversation, setSelectedConversation] = useState(-1);

    // Function that make the api call to get the social network data if necessary
    async function loadData() {
      if (currentUser.is_developer) {
      if(! graphData) await TagNetworkPage(selectedCompany.id, selectedSurvey);
      }
    } 

    /** Adding a listener that runs when graph data is stored in the local storage */
    useEffect(() => {   
      // API call
      loadData()

      // listener to wait for the answer from the backend
      window.addEventListener('tag_network_data_event', () => {
          setGraphData(JSON.parse(localStorage.getItem("tag_network_data")));
        })

      window.addEventListener('selected_element_changed', () => {       
        setSelectedElement(JSON.parse(localStorage.getItem("selected_element")));
        reRender()
      })

    }, [])


    //rerendering helps Question legend rerender?...where to trigger this new render, not finished
    const [render, setRender] = useState(true);
    const reRender = () => {
      setRender(!render);
    };

    const changeProcess = (process) => {
      setSelectedProcess(process);
      localStorage.setItem('selected_process', JSON.stringify(process))
      localStorage.removeItem("selected_element")
    };


    return (
        <Container>

          {/* Top Container */}
          <TopContainer>

            {/* Title */}
            <TitleContainer>
              <StyledDivRegular Position="relative" MarginLeft="0px" Width="fit-content" MarginBottom="0px" FontSize="20px" FontWeight="700">
                Problems per process
              </StyledDivRegular>
            </TitleContainer>

            {/* Processes */}
            <ProcessComponentWrapper>
              {ProcessData.map((process, index) => {
                  return (
                      <StyledDivButton Position="relative" Display="flex" Width="100%" Height="fit-content" MarginTop="0px" MarginLeft="0px" BackgroundColor='#FCFCFC'
                                        JustifyContent="space-between" BorderRadius="4px">
                        <button onClick={() => {changeProcess(process)}}>
                          {index === 0 ? (
                            <ArrowProcess
                              RightBorderTop={"40px solid transparent"}
                              RightBorderBottom={"40px solid transparent"}
                              RightBorderLeft={selectedProcess == process ? "40px solid #E2336B" : "40px solid #271B36"}
                              RightBorderRight={"none"}
                              Background={selectedProcess == process ? "#E2336B" : "#271B36"}>
                              <ArrowTitle>
                                  {context.setProcess(process, i18n.language)}
                              </ArrowTitle>
                            </ArrowProcess>
                          ) : index === ProcessData.length - 1 ? (
                            <ArrowProcess3
                                  LeftBorderTop={"40px solid transparent"}
                                  LeftBorderBottom={"40px solid transparent"}
                                  LeftBorderLeft={"40px solid #FCFCFC"}
                                  LeftBorderRight={"none"}
                                  Background={selectedProcess == process ? "#E2336B" : "#271B36"}>
                                  <ArrowTitle>
                                      {context.setProcess(process, i18n.language)}
                                  </ArrowTitle>
                              </ArrowProcess3>
                          ) : (
                            <ArrowProcess2
                                  LeftBorderTop={"40px solid transparent"}
                                  LeftBorderBottom={"40px solid transparent"}
                                  LeftBorderLeft={"40px solid #FCFCFC"}
                                  LeftBorderRight={"none"}
                                  RightBorderTop={"40px solid transparent"}
                                  RightBorderBottom={"40px solid transparent"}
                                  RightBorderLeft={selectedProcess == process ? "40px solid #E2336B" : "40px solid #271B36"}
                                  RightBorderRight={"none"}
                                  Background={selectedProcess == process ? "#E2336B" : "#271B36"}>
                                  <ArrowTitle>
                                      {context.setProcess(process, i18n.language)}
                                  </ArrowTitle>
                              </ArrowProcess2>
                          )}
                        </button>
                        {graphData && graphData["num_conversations"] > 0 ? (
                          <ProcessPercentage BackgroundColor={"rgba(217, 8, 8," + 2*(graphData["processes"][process]["num_conversations_process"]/graphData["num_conversations"]) + ");"}>
                            {/* {graphData["processes"][process]["num_conversations_process"]}/{graphData["num_conversations"]}  {Math.ceil(graphData["processes"][process]["num_conversations_process"]/graphData["num_conversations"] * 100)}% */}
                            {Math.ceil(graphData["processes"][process]["num_conversations_process"]/graphData["num_conversations"] * 100)}%
                          </ProcessPercentage>
                        ) : (
                          ""
                        )}
                      </StyledDivButton>
                  )
                })}
            </ProcessComponentWrapper>
          </TopContainer>
            
          {/* Bottom Container */}
          <BottomContainer>

            {/* Network */}
            <NetworkContainer>
              {graphData && graphData["num_conversations"] > 0 ? (
                  
                    <RenderComponent id="RenderComponent" graphData={graphData["processes"][selectedProcess]} Zoom={zoom} setZoom={setZoom} graphWidth={graphWidth} setGraphWidth={setGraphWidth}
                        graphHeight={graphHeight} setGraphHeight={setGraphHeight} language={i18n.language}  Presentation={false}/>

              ) : (
                  <LoadLogo>
                      {" "}
                      <img src={load} alt="loading..."/>
                  </LoadLogo>
              )}
            </NetworkContainer>
            
            {/* Feedback */}
            <FeedbackContainer>
              {graphData && graphData["num_conversations"] > 0 && JSON.parse(localStorage.getItem("selected_element")) ? (
                <>
                  <TitleContainer>
                    {JSON.parse(localStorage.getItem("selected_element")).length == 1 ? (
                      <>
                        <StyledDivRegular Position="relative" MarginLeft="0px" Width="fit-content" MarginBottom="0px" FontSize="20px" FontWeight="700">
                          Why is {JSON.parse(localStorage.getItem("selected_element"))[0].name} a problem
                        </StyledDivRegular>
                        <StyledDivRegular Position="relative" MarginLeft="0px" Width="fit-content" MarginBottom="0px" FontSize="16px" FontWeight="400">
                          Why is it critical according to your own people
                        </StyledDivRegular>
                      </>
                    ) : (
                      <>
                        <StyledDivRegular Position="relative" MarginLeft="0px" Width="fit-content" MarginBottom="0px" FontSize="20px" FontWeight="700">
                          {JSON.parse(localStorage.getItem("selected_element"))[1].name} to {JSON.parse(localStorage.getItem("selected_element"))[0].name}
                        </StyledDivRegular>
                        <StyledDivRegular Position="relative" MarginLeft="0px" Width="fit-content" MarginBottom="0px" FontSize="16px" FontWeight="400">
                          Why is it critical according to your own people
                        </StyledDivRegular>
                      </>
                    )}
                      
                  </TitleContainer>
                  
                  {JSON.parse(localStorage.getItem("selected_element")).length == 1 ? (
                    <FeedbackWrapper>
                      {JSON.parse(localStorage.getItem("selected_element"))[0]["messages"].map((feedback, index) => {
                        return(
                          <Feedback>

                            <StyledDivRegular Position="relative" Width="fit-content" Height="fit-content" FontWeight="400" FontSize="16px" Color="#271B36">
                              {"(" + feedback.quality + ") " + feedback.text}
                            </StyledDivRegular>
                            
                          </Feedback>
                        )
                      })}
                    </FeedbackWrapper>
                  ) : (
                    <ConversationsWrapper>
                      {graphData["processes"][selectedProcess]["edges"].filter((edge) => edge.source == JSON.parse(localStorage.getItem("selected_element"))[0].name && edge.target == JSON.parse(localStorage.getItem("selected_element"))[1].name)[0]["message_pairs"].map((conversation, index) => {
                          return(
                            <>
                              <StyledDivButton Position="relative" Display="flex" Width="100%" Height="100%" MarginTop="0px" MarginLeft="0px" Padding="12px 12px 12px 12px" BackgroundColor='#FCFCFC'
                                                Border={selectedConversation == index ? "1px solid #E2336B" : ""} JustifyContent="space-between" BorderRadius="4px">
                                <button onClick={() => {selectedConversation == index ? setSelectedConversation(-1) : setSelectedConversation(index)}}>
                                  <StyledDivRegular MarginTop='0px' MarginLeft='0px' Width='100%' Height='fit-content' FontSize='18px' SpanColor="#C6CBCB"
                                                  Display='flex' JustifyContent='flex-start' Position="relative" BorderRadius="4px" Color='#271B36' Gap="6px">
                                      Conversation {index + 1}
                                  </StyledDivRegular>
                                  {selectedConversation == index ? (
                                    <img src={DropdownOpened}/>
                                  ) : (
                                      <img src={DropdownClosed}/>
                                  )}
                                </button>
                              </StyledDivButton>
                              {selectedConversation == index ? (
                                <Conversation>
                                  <StyledDivRegular MarginTop='0px' MarginLeft='0px' Width='calc(90%-24px)' Height='fit-content' FontSize='18px' Padding="8px 12px 8px 12px"
                                                Display='flex' JustifyContent='flex-end' Position="relative" Color='#271B36' Background="#D9E6FC" Left="12px">
                                    {"(" + conversation["consequence"]["quality"] + ") " + conversation["consequence"]["text"]}
                                  </StyledDivRegular>

                                  <StyledDivRegular MarginTop='0px' MarginLeft='0px' Width='fit-content' Height='fit-content' FontSize='18px' Padding="8px 24px 8px 0px"
                                                Display='flex' JustifyContent='flex-start' Position="relative" Color='#271B36'>
                                    Why is that?
                                  </StyledDivRegular>

                                  <StyledDivRegular MarginTop='0px' MarginLeft='0px' Width='calc(90%-24px)' Height='fit-content' FontSize='18px' Padding="8px 12px 8px 12px"
                                                Display='flex' JustifyContent='flex-end' Position="relative" Color='#271B36' Background="#D9E6FC" Left="12px">
                                    {"(" + conversation["reason"]["quality"] + ") " + conversation["reason"]["text"]}
                                  </StyledDivRegular>
                    
                                </Conversation>
                              ) : (
                                  ""
                              )}
                            </>
                        )
                      })}
                    </ConversationsWrapper>
                  )}
                </>
              ) : (	
                <>
                  <TitleContainer>
                    <StyledDivRegular Position="relative" MarginLeft="0px" Width="fit-content" MarginBottom="0px" FontSize="20px" FontWeight="700">
                      Click a problem
                    </StyledDivRegular>
                  </TitleContainer>
                  <LoadLogo>
                      {" "}
                      <img src={load} alt="loading..."/>
                  </LoadLogo>
                </>
              )}
            </FeedbackContainer>
            

          </BottomContainer>
            
        </Container>
    );
};

export default ProblemNetwork;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 32px 42px;
  background-color: #F1F3F4;
  gap: 24px;
  @media only screen and (min-width: 1980px) {
    zoom: 1;
  }
`;

const TopContainer = styled.div`
  position: relative;
  display: flex;
  width: calc(100% - 192px);
  height: fit-content;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 24px;
  gap: 24px;
  background-color: #FCFCFC;
  @media only screen and (min-width: 1980px) {
    zoom: 1;
  }
`;

const BottomContainer = styled.div`
  position: relative;
  display: flex;
  width: calc(100% - 192px);
  height: calc(80% - 102px);
  flex-direction: row;
  justify-content: flex-start;
  background-color: #FCFCFC;
  padding: 24px;
  gap: 24px;
  @media only screen and (min-width: 1980px) {
    zoom: 1;
  }
`;


const TitleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  @media only screen and (min-width: 1980px) {
    zoom: 1;
  }
`;

const ProcessComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 34px;
`;


const ArrowProcess = styled.div`
  width: 165px;
  height: 80px;
  background: ${
          props => props.Background || "#271B36"
  };
  border-radius: 2px;
  color: #FCFCFC;
  font-family: 'Overpass';
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: " ";
    z-index: 2;
    position: absolute;
    margin-left: 205px;
    border-right: ${props => props.RightBorderRight || "28px solid #271B36"};
    border-top: ${props => props.RightBorderTop || "28px solid transparent"};
    border-bottom: ${props => props.RightBorderBottom || "28px solid transparent;"};
    border-left: ${props => props.RightBorderLeft || "28px solid #271B36"};
  }
`;

const ArrowProcess2 = styled.div`
  width: 165px;
  height: 80px;

  background: ${
          props => props.Background || "#271B36"
  };
  border-radius: 2px;
  color: #FCFCFC;
  font-family: 'Overpass';
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: " ";
    position: absolute;
    margin-right: 125px;
    border-top: ${props => props.LeftBorderTop || "28px solid #271B36"};
    border-right: ${props => props.LeftBorderRight || "28px solid #271B36"};
    border-bottom: ${props => props.LeftBorderBottom || "28px solid #271B36"};
    border-left: ${props => props.LeftBorderLeft || "28px solid transparent"};
  }

  &:after {
    content: " ";
    z-index: 2;
    position: absolute;
    margin-left: 205px;
    border-right: ${props => props.RightBorderRight || "28px solid #271B36"};
    border-top: ${props => props.RightBorderTop || "28px solid transparent"};
    border-bottom: ${props => props.RightBorderBottom || "28px solid transparent;"};
    border-left: ${props => props.RightBorderLeft || "28px solid #271B36"};
  }
`;

const ArrowProcess3 = styled.div`
  width: 165px;
  height: 80px;
  background: ${
          props => props.Background || "#271B36"
  };
  border-radius: 2px;
  color: #FCFCFC;
  font-family: 'Overpass';
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: " ";
    position: absolute;
    margin-right: 125px;
    border-top: ${props => props.LeftBorderTop || "28px solid #271B36"};
    border-right: ${props => props.LeftBorderRight || "28px solid #271B36"};
    border-bottom: ${props => props.LeftBorderBottom || "28px solid #271B36"};
    border-left: ${props => props.LeftBorderLeft || "28px solid transparent"};
  }
`;

const ArrowTitle = styled.div`
  margin-left: 40px;
  text-align: center;
  width: 88px;
`;

const ProcessPercentage = styled.div`
  font-family: "Overpass";
  text-align: center;
  width: 165px;
  margin-left: 6px;
  background-color: ${props => props.BackgroundColor || "#FCFCFC"};;
  border-radius: 5px;
`;


const NetworkContainer = styled.div`
  position: relative;
  display: flex;
  width: calc(65%);
  height: calc(100%);
  flex-direction: row;
  justify-content: center;
  background-color: #F1F3F4;
  z-index: 1;
  @media only screen and (min-width: 1980px) {
    zoom: 1;
  }
`;

const FeedbackWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #FCFCFC;
  height: calc(100% - 169px);
  gap: 16px;

  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #929699;
  }
`

const Feedback = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #F1F3F4;
  height: fit-content;
  gap: 4px;
  padding: 12px;
  border-radius: 4px;
`

const FeedbackContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(35%);
  height: 100%;
  background: #FCFCFC;
  border-radius: 4px;
  gap: 12px;
  padding: 12px;
`;

const ConversationsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100% - 190px);
  background: #F1F3F4;
  border-radius: 4px;
  gap: 12px;
  padding: 12px;
  overflow-y: scroll;
  overflow-x: hidden;
  
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #929699;
  }

`;

const Conversation = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: fit-content;
  border-radius: 4px;
  gap: 12px;
  padding: 12px;

`;


const LoadLogo = styled.div`
  img {
    width: 50px;
  }

  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
`;
