import {useState} from "react";

import styled from 'styled-components';
import ComponentFrequencySlider from "./Component-frequency-slider";
import {useTranslation} from "react-i18next";

/**  function to convert type Number to type String, into String format that resembles Type time'xx:xx' */
function decimalHoursToString(hoursDecimal) {
    let hours = (hoursDecimal / 60);
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);

    if (rminutes >= 0) {
        return `${rhours < 10 ? "0" : ""}${rhours}:${rminutes < 10 ? "0" : ""}${rminutes}`
    } else {
        return `${rhours < 10 ? "0" : ""}${rhours}:{"0" : "0"}`
    }
}

const ComponentMeeting = (props) => {
  const {t} = useTranslation();

    const [slider, setSlider] = useState(false);
    
    function changeMeetingName(meetingName) {  
        props.MeetingsDays[props.selectedDay].meetings.map((meeting, index) => {
            if (index === props.meeting_id) {
                meeting.name = meetingName
            }
        });
        props.reRender()
        props.setMeetingsDays(props.MeetingsDays)
        localStorage.setItem('MeetingsDays', JSON.stringify(props.MeetingsDays))
      }

    function meetingClicked(meeting_id, day_id) {  
      props.changeMeetingSelection(meeting_id, day_id);
      setSlider(!slider)
    }

    function getMarginLeft() {
      if(props.day <= props.MeetingsDays.length/2) return 520
      else return -520
    }

    function getMarginTop() {
      if(props.MeetingsDays[props.day].meetings.length>=3 && props.meeting_id === props.MeetingsDays[props.day].meetings.length-1) return (-2*props.scrollTop - 15)
      else return -2*props.scrollTop
    }

    function sliderChange(time) {
      var weekTime = 0
      props.MeetingsDays[props.selectedDay].meetings.map((meeting, index) => {
        if (index === props.meeting_id) {
            meeting.duration = time
        }
        weekTime = weekTime + meeting.duration
      });
      props.MeetingsDays[props.selectedDay].meetingsDuration = weekTime
      props.reRender()
      props.setMeetingsDays(props.MeetingsDays)
      localStorage.setItem('MeetingsDays', JSON.stringify(props.MeetingsDays))
    }

      
    return (
        /** Add ZIndex for toggling the component to highlight in the two container */

        <MeetingWrapper Border={props.selectedMeeting === props.meeting_id && props.selectedDay === props.day ? '1px solid #B3194A':'0px'}
                onClick={() => meetingClicked(props.meeting_id, props.day)}>
            <TopWrapper>
              <input type="text" value={props.meeting.name} onChange={(e) => changeMeetingName(e.currentTarget.value)} />
            </TopWrapper>

            <BottomWrapper>
              <TimeBox id="timebox">
                {decimalHoursToString(props.meeting.duration)}
              </TimeBox>
            </BottomWrapper>

            {props.selectedDay === props.day && props.selectedMeeting === props.meeting_id && slider &&
                <ComponentFrequencySlider 
                    marginLeft={getMarginLeft() + "px"}
                    marginTop={getMarginTop() + "px"}
                    MeetingsDays={props.MeetingsDays} 
                    selectedDay={props.selectedDay} 
                    selectedMeeting={props.selectedMeeting} 
                    meetingDuration={props.meeting.duration}
                    sliderChange={sliderChange}
              />
            }

        </MeetingWrapper>                

    )
}
export default ComponentMeeting

const MeetingWrapper = styled.div`
  height: 61px;
  width: 156px;
  min-width: 156px;
  min-height: 61px;
  margin-bottom: 8px;
  border: ${props => props.Border || '0px'};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #FCFCFC;
  align-items: center;
  max-width: 410px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
`;

const TopWrapper = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  input{
    width: 80px;
    border: none;
    background: #FCFCFC;
    font-family: 'Overpass';
    font-size: 14px;
    text-align: center;
    padding-top: 8px;
    :focus{
      outline-style: solid;
      outline-width: 1px;
      outline-color: #271B36;
      outline-radius: 4px;
    }
  }
`;

const BottomWrapper = styled.div`
  width: 100%;
  height: 30px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-content: center;
  input{
    height: 0px;
    width: 60px;
    background: #FCFCFC;
    border: solid 1px #271B36;
    border-radius: 8px;
  }
`;

const TimeBox = styled.div`
border: solid 1px #271B36;
width: 60px;
text-align: center;
border-radius: 5px;
height: 18px;
`